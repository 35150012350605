<template>

</template>

<script>
export default {
	// #region configs
  name: "logout",
  path: "/logout",
	// #endregion

	// #region triggers
  mounted() {
    this.$vsLogout()
  }
	// #endregion
}
</script>
