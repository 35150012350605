<template>
  <div>
		<div class="titulo">
			<h1>Operacional</h1>
			<h4>Cadastro de filiais e colaboradores</h4>
		</div>

    <b-tabs content-class="pt-4 px-2 h5">
      <!-- #region Filiais -->
      <b-tab title="Filiais" active title-item-class="h5">
        <!-- #region novo cadastro -->
        <a v-b-toggle.novaFilial @click.prevent>
          <span class="cor-azul"><b-icon icon="plus" scale="1" variant="primary" />&nbsp;Novo cadastro</span>
        </a>
        <b-collapse id="novaFilial" class="mt-3">
          <vs-form-usuario 
						:obj="formFilial" 
						:isPJ="true" 
						:exibeFoto="true" 
						:exibeEndereco="true" 
						:exibeSenha="true"
            :exibeMensagem="true" 
						:exibePerfilVipp="true" 
						:isFilial="true" 
						:temPesquisaCpfCnpj="true" 
						v-on:evento="listaFiliais"
						v-on:eventoPesquisaCpfCnpj="pesquisaCpfCnpjFilial" />
        </b-collapse>
        <!-- #endregion -->

        <!-- #region filtro -->
				<form @submit.prevent="listaFiliais()">
					<b-form-group label="Filtrar por:" label-size="lg" class="mt-5">
						<b-form-row>
							<b-col md="4">
								<b-form-group class="form-input-cinza" label="Nome" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.filial.nome" autocomplete="on" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
        <!-- #endregion -->

        <!-- #region listagem/edicao -->
        <div class="accordion" role="tablist">
          <b-table 
						class="text-nowrap h5" 
						id="tabelaFilial" 
						:items="formFilial.cadastros"
            :fields="cabecalhoTabelaFilial" 
						:per-page="totalPaginaFilial" 
						:current-page="paginaAtualFilial" 
						borderless
						small 
						responsive>
            <template #cell(filial)="fil">
              <div class="abre-fecha bg-white py-3 px-2 rounded" :id="`cadastro${fil.item.id}`">        
                <!-- #region cabecalho -->
                <div @click="indiceAbaFilial--">
                  <a v-b-toggle :href="`#editaCadastro${fil.item.id}`" @click.prevent="configuraEdicao(fil.item)"
                    v-b-tooltip.hover title="Mostrar/Ocultar cadastro">
                    <span class="h3">
                      <b-img rounded="circle" alt="Foto" v-bind:src="$vsDefineFoto(fil.item.fotoBase64)" width="70" height="70" />
                      &nbsp;&nbsp;&nbsp;{{ fil.item.nome }}
                    </span>
                    <span>&nbsp;.&nbsp;{{ fil.item.cargo }}</span>
                  </a>

                  <span v-b-tooltip.hover title="Mudar status do cadastro">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b-button v-if="fil.item.isAtivo" variant="outline-info"
                      @click.prevent="mudarStatus(fil.item)">ativo</b-button>
                    <b-button v-else variant="outline-danger"
                      @click.prevent="mudarStatus(fil.item)">inativo</b-button>
                  </span>

                  <a class="float-right mt-3 mr-2" v-b-toggle :href="`#editaCadastro${fil.item.id}`"
                    @click.prevent="configuraEdicao(fil.item)" v-b-tooltip.hover title="Mostrar/Ocultar cadastro">
                    <b-icon class="when-opened" icon="chevron-up" scale="1.2" />
                    <b-icon class="when-closed" icon="chevron-down" scale="1.2" />
                  </a>
                </div>
                <!-- #endregion -->

                <!-- #region corpo -->
                <b-collapse v-if="idSanfonaAberta === fil.item.id" :id="`editaCadastro${fil.item.id}`" accordion="sanfonaFluxo" role="tabpanel">
                  <b-tabs class="pt-4" content-class="pt-4 bg-white" v-model="indiceAbaFilial">
										<!-- #region cadastros -->
                    <b-tab title="Cadastro" active>
                      <div class="h4 mb-4">Edição de cadastro de filiais</div>

                      <vs-form-usuario 
												:obj="fil.item" 
												:isEdicao="true" 
												:isPJ="true" 
												:exibeFoto="true"
                        :exibeEndereco="true" 
												:exibeSenha="true" 
												:exibeMensagem="true" 
												:exibePerfilVipp="true"
                        :exibeExclusao="true" 
												:isFilial="true"
												v-on:evento="listaFiliais" />
                    </b-tab>
										<!-- #endregion -->

										<!-- #region servicos -->
                    <b-tab title="Serviços" @click="listaServicosFilial(fil.item.id)">
                      <div class="h4 mb-4">Cadastro das regras de negocio para precificação</div>
                      <div>
                        <!-- #region cadastro -->
                        <div class="mb-4">
                          <form>
                            <!-- #region dados -->
                            <b-form-row>
                              <!-- #region bloco transportadora -->
                              <b-col md="12" class="mb-3">
                                <fieldset class="border px-2">
                                  <legend class="w-auto h5 text-secondary">Operações Disponíveis</legend>
                                  <b-form-row>
                                    <b-col md="3">
                                      <b-form-group class="form-input-cinza" label="Transportadora" label-cols="12">
                                        <b-form-select size="sm" v-model="formServicoFilial.idTransportadora" :options="comboTransportadoras.filter(el => el.isAtivo)" @change="filtraServicosTransportadora()" />
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="2">
                                      <b-form-group class="form-input-cinza" label="Código Serviço" label-cols="12">
                                        <b-form-select size="sm" v-model="formServicoFilial.codigoServicoTransportadora" :options="comboServicosTransportadora" @change="filtraNiveisContratoTransportadora()" />
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3">
                                      <b-form-group class="form-input-cinza" label="Descrição Serviço" label-cols="12">
                                        <span class="small">{{ formServicoFilial.descricaoServicoTransportadora }}</span>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3" v-if="exibeNivelContrato()">
                                      <b-form-group class="form-input-cinza" label="Nível Contrato Serviço" label-cols="12">
                                        <b-form-select size="sm" v-model="formServicoFilial.nivelContratoTransportadora" :options="comboNiveisContratoTransportadora" />
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                </fieldset>
                              </b-col>
                              <!-- #endregion -->

                              <!-- #region bloco filial -->
                              <b-col md="12" class="mb-3">
                                <fieldset class="border px-2 pb-3">
                                  <legend class="w-auto h5 text-secondary">Regra Precificação Filial</legend>
                                  <b-form-row>
                                    <b-col md="2">
                                      <b-form-group class="form-input-cinza" label="Código Cobrança" label-cols="12">
                                        <b-form-select size="sm" v-model="formServicoFilial.codigoServicoFilial" :options="comboServicosTransportadora" @change="filtraNiveisContratoFilial()" />
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3">
                                      <b-form-group class="form-input-cinza" label="Descrição Cobrança" label-cols="12">
                                        <span class="small">{{ formServicoFilial.descricaoServicoFilial }}</span>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3" v-if="exibeNivelContrato()">
                                      <b-form-group class="form-input-cinza" label="Nível Contrato Cobrança" label-cols="12">
                                        <b-form-select size="sm" v-model="formServicoFilial.nivelContratoFilial" :options="comboNiveisContratoFilial" />
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="2">
                                      <b-form-group class="form-input-cinza" label="Spread %" label-cols="12">
                                        <b-form-input size="sm" class="text-right" type="text" v-model="formServicoFilial.spreadPercentualContratante" autocomplete="on" @keyup="mascaraMoeda" />
                                        <div style="color: #808080;" class="h6">Negativo para desconto</div>
                                      </b-form-group>
                                    </b-col>
                                    <b-col md="2">
                                      <b-form-group class="form-input-cinza" label="Spread R$" label-cols="12">
                                        <b-form-input size="sm" class="text-right" type="text" v-model="formServicoFilial.spreadMonetarioContratante" autocomplete="on" @keyup="mascaraMoeda" />
                                        <div style="color: #808080;" class="h6">Negativo para desconto</div>
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                </fieldset>
                              </b-col>
                              <!-- #endregion -->

                              <!-- #region bloco atendimento -->
                              <b-col md="12" class="mb-3">
                                <fieldset class="border px-2">
                                  <legend class="w-auto h5 text-secondary">Regra Precificação Atendimento</legend>
                                  <b-form-row>
                                    <b-col md="3">
                                      <b-form-group class="form-input-cinza" label="Código Atendimento" label-cols="12">
                                        <b-form-select size="sm" v-model="formServicoFilial.codigoServicoAtendimento" :options="comboServicosTransportadora" @change="filtraNiveisContratoAtendimento()" />
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3">
                                      <b-form-group class="form-input-cinza" label="Descrição Atendimento" label-cols="12">
                                        <span class="small">{{ formServicoFilial.descricaoServicoAtendimento }}</span>
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3" v-if="exibeNivelContrato()">
                                      <b-form-group class="form-input-cinza" label="Nível Contrato Atendimento" label-cols="12">
                                        <b-form-select size="sm" v-model="formServicoFilial.nivelContratoAtendimento" :options="comboNiveisContratoAtendimento" />
                                      </b-form-group>
                                    </b-col>

                                    <b-col md="3">
                                      <b-form-group class="form-input-cinza" label="Nome para Atendimento" label-cols="12">
                                        <b-form-input size="sm" type="text" v-model="formServicoFilial.descricaoServicoAtendimentoTela" autocomplete="on" />
                                      </b-form-group>
                                    </b-col>
                                  </b-form-row>
                                </fieldset>
                              </b-col>
                              <!-- #endregion -->
                            </b-form-row>

                            <!-- #region opcoes -->
                            <b-form-row class="mb-3">
                              <b-col md="2">
                                <b-form-checkbox class="form-input-cinza" v-model="formServicoFilial.disponivelFilial" size="lg" switch @change="mudaStatusServicoFilial()">
                                  <span style="cursor: pointer">Ativo?</span>
                                </b-form-checkbox>
                              </b-col>
                              <b-col md="3" v-if="formServicoFilial.disponivelFilial">
                                <b-form-checkbox class="form-input-cinza" v-model="formServicoFilial.disponivelAtendimentoFilial" size="lg" switch>
                                  <span style="cursor: pointer">Exibir no atendimento?</span>
                                </b-form-checkbox>
                              </b-col>
                            </b-form-row>
                            <!-- #endregion -->
                            <!-- #endregion -->

                            <!-- #region acoes -->
                            <b-form-row>
                              <b-col md="2">
                                <b-button class="btn-azul" @click.prevent="gravaServicoFilial(fil.item)">Gravar</b-button>
                              </b-col>
                              <b-col md="2">
                                <b-button class="btn-cinza" @click.prevent="limpaFormServicoFilial()">Cancelar</b-button>
                              </b-col>
                            </b-form-row>
                            <!-- #endregion -->
                          </form>
                        </div>
                        <!-- #endregion -->

                        <!-- #region listagem/edicao -->
												<div v-if="tabelaServicosFilial.length">
													<div style="display: grid;">
														<b-table 
															class="text-nowrap h5" 
															:id="`tabelaServicosFilial${fil.item.id}`" 
															:items="tabelaServicosFilial" 
															:fields="cabecalhoTabelaServicosFilial"
															:per-page="totalPaginaServicos" 
															:current-page="paginaAtualServicos"
															small 
															responsive 
															hover>
															<!--===================================================================================
																mapa de colunas (atua em campos sem tratamento) 
																se precisar debugar ou visualizar os dados para ajustes basta descomentar...

																obj.index = indice da linha
																obj.field = nome do campo/coluna/chave json
																obj.item  = objeto json bruto
																obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
															====================================================================================-->
															<!--<template #cell()="obj">
																{{ obj.field }}
																{{ obj.item }}
															</template>-->

															<!--===================================================================================
																celulas que precisam de tratamento, 
																sobrescrevem mapa de colunas pela identificacao da chave
																e nao precisam estar em ordem/sequencia
															====================================================================================-->
															<template #cell(idTransportadora)="obj">
																{{ nomeTransportadora(obj.value) }}
															</template>

															<template #cell(nivelContratoTransportadora)="obj">
																{{ nomeNivelContrato(obj.item, 0) }}
															</template>

															<template #cell(nivelContratoFilial)="obj">
																{{ nomeNivelContrato(obj.item, 1) }}
															</template>

															<template #cell(nivelContratoAtendimento)="obj">
																{{ nomeNivelContrato(obj.item, 2) }}
															</template>

															<template #cell(spreadPercentualContratante)="obj">
																{{ $vsFormataMoeda(obj.value) }}
															</template>

															<template #cell(spreadMonetarioContratante)="obj">
																{{ $vsFormataMoeda(obj.value) }}
															</template>

															<template #cell(disponivelFilial)="obj">
																<span v-if="obj.value">Sim</span>
																<span v-else>Não</span>
															</template>

															<template #cell(disponivelAtendimentoFilial)="obj">
																<span v-if="obj.value">Sim</span>
																<span v-else>Não</span>
															</template>

															<template #cell(opcoes)="obj">
																<div class="pr-5">
																	<button type="button" class="btn-laranja" style="width: 100px; height: 40px;" @click="editaServicoFilial(obj.item)">Editar</button>
																</div>
															</template>
														</b-table>
													</div>

													<div class="h5 mt-4">
														<span style="padding-left: 5px;padding-right: 20px;">
															<b-form-select class="numPerPage" v-model="totalPaginaServicos" :options="comboPaginacao" style="width: 100px;" />
														</span>
														<span>Total de Registros: {{ $vsFormataNumero(tabelaServicosFilial.length) }}</span>
														<b-pagination class="mt-3 d-flex justify-content-center"
															:total-rows="tabelaServicosFilial.length" 
															:per-page="totalPaginaServicos"
															:aria-controls="`tabelaServicosFilial${fil.item.id}`" 
															v-model="paginaAtualServicos" />
													</div>
												</div>
                        <!-- #endregion -->
                      </div>
                    </b-tab>
										<!-- #endregion -->
                  </b-tabs>
                </b-collapse>
                <!-- #endregion -->
              </div>
            </template>
          </b-table>

          <div v-if="formFilial.cadastros.length" class="mt-4">
            <span style="padding-left: 5px;padding-right: 20px;">
              <b-form-select class="numPerPage" v-model="totalPaginaFilial" :options="comboPaginacao"
                style="width: 100px;" />
            </span>
            <span>Total de Registros: {{ $vsFormataNumero(formFilial.cadastros.length) }}</span>
            <b-pagination class="mt-3 d-flex justify-content-center" :total-rows="formFilial.cadastros.length"
              :per-page="totalPaginaFilial" v-model="paginaAtualFilial" aria-controls="tabelaFilial" />
          </div>
        </div>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->

      <!-- #region Equipe -->
      <b-tab title="Colaboradores" title-item-class="h5" @click="listaFiliais()">
        <div class="h4 mb-4">Cadastro e edição de colaboradores</div>

        <!-- #region novo cadastro -->
        <a v-b-toggle.novoFuncionario @click.prevent>
          <span class="cor-azul"><b-icon icon="plus" scale="1" variant="primary" />&nbsp;Novo cadastro</span>
        </a>
        <b-collapse id="novoFuncionario" class="mt-3">
          <vs-form-usuario 
						:obj="formEquipe" 
						:arrayPerfis="formEquipe.perfis" 
						:exibePerfil="true" 
						:exibeFoto="true"
            :isPF="true" 
						:arrayFiliais="formFilial.cadastros" 
						:exibeSenha="true" 
						:temPesquisaCpfCnpj="true" 
						v-on:evento="listaEquipe"
						v-on:eventoPesquisaCpfCnpj="pesquisaCpfCnpjEquipe" />
        </b-collapse>
        <!-- #endregion -->

        <!-- #region filtro -->
				<form @submit.prevent="listaEquipe()">
					<b-form-group label="Filtrar por:" label-size="lg" class="mt-5">
						<b-form-row>
							<b-col md="4">
								<b-form-group class="form-input-cinza" label="Nome" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.equipe.nome" autocomplete="on" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
        <!-- #endregion -->

        <!-- #region listagem/edicao -->
        <div class="accordion" role="tablist">
          <b-table 
						class="text-nowrap h5" 
						id="tabelaEquipe" 
						:items="formEquipe.cadastros"
            :fields="cabecalhoTabelaEquipe" 
						:per-page="totalPaginaEquipe" 
						:current-page="paginaAtualEquipe" 
						borderless
						small 
						responsive>
            <template #cell(equipe)="eqp">
              <div class="abre-fecha bg-white py-3 px-2 rounded" :id="`cadastro${eqp.item.id}`">
                <!-- #region cabecalho -->
                <div>
                  <a v-b-toggle :href="`#editaCadastro${eqp.item.id}`" @click.prevent="configuraEdicao(eqp.item)"
                    v-b-tooltip.hover title="Mostrar/Ocultar cadastro">
                    <span class="h3">
                      <b-img rounded="circle" alt="Foto" v-bind:src="$vsDefineFoto(eqp.item.fotoBase64)" width="70" height="70" />
                      &nbsp;&nbsp;&nbsp;{{ eqp.item.nome }}
                    </span>
                    <span>&nbsp;.&nbsp;{{ eqp.item.cargo }}</span>
                  </a>

                  <span v-b-tooltip.hover title="Mudar status do cadastro">
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b-button v-if="eqp.item.isAtivo" variant="outline-info" @click.prevent="mudarStatus(eqp.item)">ativo</b-button>
                    <b-button v-else variant="outline-danger" @click.prevent="mudarStatus(eqp.item)">inativo</b-button>
                  </span>

                  <a class="float-right mt-3 mr-2" v-b-toggle :href="`#editaCadastro${eqp.item.id}`" @click.prevent="configuraEdicao(eqp.item)" v-b-tooltip.hover title="Mostrar/Ocultar cadastro">
                    <b-icon class="when-opened" icon="chevron-up" scale="1.2" />
                    <b-icon class="when-closed" icon="chevron-down" scale="1.2" />
                  </a>
                </div>
                <!-- #endregion -->

                <!-- #region corpo -->
                <b-collapse v-if="idSanfonaAberta === eqp.item.id" :id="`editaCadastro${eqp.item.id}`"
                  accordion="sanfonaFluxo" role="tabpanel">
                  <vs-form-usuario 
										:obj="eqp.item" 
										:arrayPerfis="formEquipe.perfis" 
										:isEdicao="true"
                    :isPF="true" 
										:exibeFoto="true" 
										:exibePerfil="true" 
										:exibeStatus="true" 
										:exibeExclusao="true"
                    :arrayFiliais="formFilial.cadastros" 
										:exibeSenha="true" 
										v-on:evento="listaEquipe" />
                </b-collapse>
                <!-- #endregion -->
              </div>
            </template>
          </b-table>

          <div v-if="formEquipe.cadastros.length" class="mt-4">
            <span style="padding-left: 5px;padding-right: 20px;">
              <b-form-select class="numPerPage" v-model="totalPaginaEquipe" :options="comboPaginacao" style="width: 100px;" />
            </span>
            <span>Total de Registros: {{ $vsFormataNumero(formEquipe.cadastros.length) }}</span>
            <b-pagination 
						  class="mt-3 d-flex justify-content-center" 
							:total-rows="formEquipe.cadastros.length"
              :per-page="totalPaginaEquipe" 
							v-model="paginaAtualEquipe" 
							aria-controls="tabelaEquipe" />
          </div>
        </div>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->
    </b-tabs>
  </div>
</template>

<script>
import VsFormUsuario from "../templates/vs-form-usuario.vue"

export default {
  // #region configs
  name: "operacional",
  path: "/operacional",
  requireAuth: true,
  components: { VsFormUsuario },
  // #endregion

  // #region dados
  data() {
    return {
      idSanfonaAberta: null,
      indiceAbaFilial: 0,
      filtro: {
        filial: {
          nome: "",
					cpfCnpj: "",
        },
        equipe: {
          nome: "",
					cpfCnpj: "",
        }
      },
      formFilial: null,
      formEquipe: null,
      filiais: [],
      servicosContratante: [],
      cabecalhoTabelaFilial: [
        { key: "filial", sortable: false, label: "Filial", thClass: "d-none", tdClass: "py-2" },
      ],
      cabecalhoTabelaServicosFilial: [
        { key: "idTransportadora", sortable: true, label: "Transportadora", tdClass: "text-left", },

        { key: "codigoServicoTransportadora", sortable: true, label: "Código Serviço", tdClass: "text-left", },
        { key: "descricaoServicoTransportadora", sortable: true, label: "Descrição Serviço", tdClass: "text-left", },
        { key: "nivelContratoTransportadora", sortable: true, label: "Nível", tdClass: "text-left", },

        { key: "codigoServicoFilial", sortable: true, label: "Código Cobrança", tdClass: "text-left", },
        { key: "descricaoServicoFilial", sortable: true, label: "Descrição Cobrança", tdClass: "text-left", },
        { key: "nivelContratoFilial", sortable: true, label: "Nível", tdClass: "text-left", },
        { key: "spreadPercentualContratante", sortable: true, label: "Spread %", tdClass: "text-right", },
        { key: "spreadMonetarioContratante", sortable: true, label: "Spread R$", tdClass: "text-right", },

        { key: "codigoServicoAtendimento", sortable: true, label: "Código Atendimento", tdClass: "text-left", },
        { key: "descricaoServicoAtendimento", sortable: true, label: "Descrição Atendimento", tdClass: "text-left", },
        { key: "nivelContratoAtendimento", sortable: true, label: "Nível", tdClass: "text-left", },
        { key: "descricaoServicoAtendimentoTela", sortable: true, label: "Nome Atendimento", tdClass: "text-left", },


        { key: "disponivelFilial", sortable: true, label: "Ativo", tdClass: "text-left", },
        { key: "disponivelAtendimentoFilial", sortable: true, label: "Exibir no atendimento?", tdClass: "text-left", },

        { key: "opcoes", sortable: false, label: "Opções", tdClass: "text-left", },
      ],
      tabelaServicosFilial: [],
      formServicoFilial: {
        id: null,
        idTransportadora: null,
        idContratante: this.$root.sessao.id,
        idFilial: null,
        idServicoTransportadora: null,
        idServicoFilial: null,
        idServicoAtendimento: null,
        codigoServicoTransportadora: "",
        codigoServicoFilial: "",
        codigoServicoAtendimento: "",
        nivelContratoTransportadora: "",
        nivelContratoFilial: "",
        nivelContratoAtendimento: "",
        descricaoServicoTransportadora: "",
        descricaoServicoFilial: "",
        descricaoServicoAtendimento: "",
        descricaoServicoAtendimentoTela: "",
        spreadPercentualContratante: 0,
        spreadMonetarioContratante: 0,
        spreadPercentualFilial: 0,
        spreadMonetarioFilial: 0,
        disponivelFilial: true,
        disponivelAtendimentoFilial: false,
      },
      cabecalhoTabelaEquipe: [
        { key: "equipe", sortable: false, label: "Equipe", thClass: "d-none", tdClass: "py-2" },
      ],
      comboTransportadoras: [],
      comboServicosTransportadora: [],
      comboNiveisContratoTransportadora: [],
      comboNiveisContratoFilial: [],
      comboNiveisContratoAtendimento: [],
      paginaAtualFilial: 1,
      paginaAtualServicos: 1,
      paginaAtualEquipe: 1,
      totalPaginaFilial: 100,
      totalPaginaServicos: 100,
      totalPaginaEquipe: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_CONTRATANTE])

    this.formFilial = this.formBasico([{ value: this.VS_PERFIL_FILIAL, text: "Filial" }])
    this.formEquipe = this.formBasico([
      { value: this.VS_PERFIL_GERENTE, text: "Gerente" },
      { value: this.VS_PERFIL_OPERADOR, text: "Operador" },
    ])
  },
  mounted() {
    this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
		async bootstrap() {
			await this.listaTransportadoras()
    	await this.listaServicosContratante()
		},
		async pesquisaCpfCnpjFilial() {
			let form = this.formFilial
			let filtro = this.filtro.filial
			let cpfCnpj = this.$vsLimpaFormatacao(form.cpfCnpj)

			filtro.cpfCnpj = cpfCnpj

			if (![11, 14].includes(cpfCnpj.length)) {
				return
			}

			await this.listaCadastros(form, "filiais", filtro)

			if (form.cadastros.length) {
				this.$vsNotificacao(`CPF/CNPJ: ${this.$vsFormataCpfCnpj(cpfCnpj)} já está cadastrado, verifique a lista abaixo`, "info")
			}
		},
		async pesquisaCpfCnpjEquipe() {
			let form = this.formEquipe
			let filtro = this.filtro.equipe
			let cpfCnpj = this.$vsLimpaFormatacao(form.cpfCnpj)

			filtro.cpfCnpj = cpfCnpj

			if (![11, 14].includes(cpfCnpj.length)) {
				return
			}

			await this.listaCadastros(form, "equipe", filtro)

			if (form.cadastros.length) {
				this.$vsNotificacao(`CPF/CNPJ: ${this.$vsFormataCpfCnpj(cpfCnpj)} já está cadastrado, verifique a lista abaixo`, "info")
			}
		},
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    formBasico(comboPerfis) {
      return {
        nome: "",
        fotoUpload: null,
        telefone: "",
        cpfCnpj: "",
        email: "",
        senha: "",
        isAtivo: true,
        isExcluido: false,
        verSenha: false,
        mensagemCustomizada: "",
        perfil: comboPerfis[0].value || this.VS_PERFIL_VISITANTE,
        perfis: comboPerfis,
        cadastros: [],
        vinculos: [],
        endereco: {
          tipo: this.VS_TIPO_ENDERECO_RUA,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          apelido: "",
          complemento: "",
          isComercial: false,
        }
      }
    },
    async mudarStatus(obj) {
      let status = !obj.isAtivo
      let resp = await this.$vsApiRest("PUT", `/usuarios/status/${obj.id}/${status}`)
        
			if (resp.status === 200) {
        obj.isAtivo = status
        this.$vsNotificacao(resp.data.msg, "success")
      }
    },
    async listaCadastros(obj, tipo, filtro) {
      obj.cadastros = []

      let query = `idContratante=${this.$root.sessao.id}`
			let nome = filtro.nome
			let cpfCnpj = filtro.cpfCnpj

      if (nome) {
        query += `&nome=${nome}`
      }
			if (cpfCnpj.length) {
				query += `&cpfCnpj=${cpfCnpj}`
			}

      let resp = await this.$vsApiRest("GET", `/usuarios/${tipo}/?${query}`)
      
			if (resp.status === 200) {
        obj.cadastros = resp.data.filter(el => !el.isExcluido)
      }
    },
    configuraEdicao(obj) {
      obj.edicao = true
      obj.senha = ""
      obj.fotoUpload = null
      obj.cpfCnpj = this.$vsFormataCpfCnpj(obj.cpfCnpj)
      obj.telefone = this.$vsFormataTelefone(obj.telefone)
      obj.mensagemCustomizada = obj.mensagemCustomizada || ""

      if (!obj.endereco) {
        obj.endereco = {
          tipo: this.VS_TIPO_ENDERECO_RUA,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          apelido: "",
          complemento: "",
          isComercial: false,
        }
      }
      obj.endereco.cep = this.$vsFormataCep(obj.endereco.cep)
      obj.vinculos = obj.vinculos || []

      this.lazyLoadSanfona(obj.id)
    },
    listaFiliais() {
      this.listaCadastros(this.formFilial, "filiais", this.filtro.filial)
    },
    listaEquipe() {
      this.listaCadastros(this.formEquipe, "equipe", this.filtro.equipe)
    },
    async listaServicosContratante() {
      let query = `idContratante=${this.$root.sessao.id}`
      this.servicosContratante = []

      let resp = await this.$vsApiRest("GET", `/tarifacao/servicos/contratante/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.text = el.codigo
          el.value = el.codigo
        })
        this.servicosContratante = resp.data
      }
    },
    filtraServicosTransportadora() {
      let id = this.formServicoFilial.idTransportadora

      if (id) {
				let transp = this.comboTransportadoras.find(el => el.id === id)
				let servicos = this.servicosContratante.filter(el => el.idTransportadora === id)
        let codigos = [...new Set(servicos.map(el => el.codigo))]
        this.comboServicosTransportadora = []

        codigos.forEach(cod => {
					let niveis = [...new Set(servicos.filter(el => el.codigo === cod).map(el => el.nivelContrato))]
					let niveisTransp = transp.servicos.find(el => el.codigo === cod)
								.niveisContrato.filter(el => niveis.includes(el.codigo))

					niveisTransp.sort(function (a, b) {
						return a.codigo > b.codigo
					})

          this.comboServicosTransportadora.push({
            text: cod,
            value: cod,
            niveisContrato: niveisTransp
          })
        })

        this.comboServicosTransportadora.sort(function (a, b) {
          return a.value > b.value
        })
      }
    },
    async listaServicosFilial(idFilial) {
      let query = `idFilial=${idFilial}&excluido=false`
      this.tabelaServicosFilial = []

      let resp = await this.$vsApiRest("GET", `/tarifacao/servicos/filial/?${query}`)
      
			if (resp.status === 200) {
        this.limpaFormServicoFilial()
        this.tabelaServicosFilial = resp.data
      }
    },
    validaServicoFilial() {
      let ok = true
      let form = this.formServicoFilial
      let usaNivelContrato = this.exibeNivelContrato()

      if (!form.idTransportadora || !form.idTransportadora.length) {
        this.$vsNotificacao("Operações Disponíveis: Informe a Transportadora", "warning")
        ok = false
      }
      if (!form.codigoServicoTransportadora || !form.codigoServicoTransportadora.length) {
        this.$vsNotificacao("Operações Disponíveis: Informe o Código do Serviço", "warning")
        ok = false
      }
      if (!form.codigoServicoFilial || !form.codigoServicoFilial.length) {
        this.$vsNotificacao("Regra Precificação Filial: Informe o Código de Cobrança", "warning")
        ok = false
      }
      if (usaNivelContrato && (!form.nivelContratoFilial || !form.nivelContratoFilial.length)) {
        this.$vsNotificacao("Regra Precificação Filial: Informe o Nível de Contrato da Cobrança", "warning")
        ok = false
      }
      if (!form.codigoServicoAtendimento || !form.codigoServicoAtendimento.length) {
        this.$vsNotificacao("Regra Precificação Atendimento: Informe o Código de Atendimento", "warning")
        ok = false
      }
      if (usaNivelContrato && (!form.nivelContratoAtendimento || !form.nivelContratoAtendimento.length)) {
        this.$vsNotificacao("Regra Precificação Atendimento: Informe o Nível de Contrato do Atendimento", "warning")
        ok = false
      }
      if (!form.descricaoServicoAtendimentoTela || !form.descricaoServicoAtendimentoTela.trim().length) {
        this.$vsNotificacao("Regra Precificação Atendimento: Informe o Nome para Atendimento", "warning")
        ok = false
      }
      return ok
    },
    async gravaServicoFilial(obj) {
      if (!this.validaServicoFilial()) {
        return
      }

      let form = this.formServicoFilial
      form.idFilial = obj.id
      form.spreadPercentualContratante = this.$vsConverteMoeda(form.spreadPercentualContratante)
      form.spreadMonetarioContratante = this.$vsConverteMoeda(form.spreadMonetarioContratante)

      let resp = await this.$vsApiRest("POST", `/tarifacao/servicos/filial`, form)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Serviço cadastrado com sucesso", "success")
        this.listaServicosFilial(obj.id)
      }
    },
    editaServicoFilial(obj) {
      let form = this.formServicoFilial

      form.id = obj.id
      form.idTransportadora = obj.idTransportadora
      form.idFilial = obj.idFilial
      form.idServicoTransportadora = obj.idServicoTransportadora
      form.idServicoFilial = obj.idServicoFilial
      form.idServicoAtendimento = obj.idServicoAtendimento

      this.filtraServicosTransportadora()

      form.codigoServicoTransportadora = obj.codigoServicoTransportadora
      form.codigoServicoFilial = obj.codigoServicoFilial
      form.codigoServicoAtendimento = obj.codigoServicoAtendimento

      this.filtraNiveisContratoTransportadora()
      this.filtraNiveisContratoFilial()
      this.filtraNiveisContratoAtendimento()

      form.nivelContratoTransportadora = obj.nivelContratoTransportadora
      form.nivelContratoFilial = obj.nivelContratoFilial
      form.nivelContratoAtendimento = obj.nivelContratoAtendimento

      form.descricaoServicoTransportadora = obj.descricaoServicoTransportadora
      form.descricaoServicoFilial = obj.descricaoServicoFilial
      form.descricaoServicoAtendimento = obj.descricaoServicoAtendimento
      form.descricaoServicoAtendimentoTela = obj.descricaoServicoAtendimentoTela

      form.spreadPercentualContratante = obj.spreadPercentualContratante
      form.spreadMonetarioContratante = obj.spreadMonetarioContratante
      form.spreadPercentualFilial = obj.spreadPercentualFilial
      form.spreadMonetarioFilial = obj.spreadMonetarioFilial

      form.disponivelFilial = obj.disponivelFilial
      form.disponivelAtendimentoFilial = obj.disponivelAtendimentoFilial
    },
    limpaFormServicoFilial() {
      let form = this.formServicoFilial

      form.id = null
      form.idTransportadora = null
      form.idFilial = null
      form.idServicoTransportadora = null
      form.idServicoFilial = null
      form.idServicoAtendimento = null

      form.codigoServicoTransportadora = ""
      form.codigoServicoFilial = ""
      form.codigoServicoAtendimento = ""

      form.nivelContratoTransportadora = ""
      form.nivelContratoFilial = ""
      form.nivelContratoAtendimento = ""

      form.descricaoServicoTransportadora = ""
      form.descricaoServicoFilial = ""
      form.descricaoServicoAtendimento = ""
      form.descricaoServicoAtendimentoTela = ""

      form.spreadPercentualContratante = 0
      form.spreadMonetarioContratante = 0
      form.spreadPercentualFilial = 0
      form.spreadMonetarioFilial = 0

      form.disponivelFilial = true
      form.disponivelAtendimentoFilial = false

      this.comboServicosTransportadora = []
      this.comboNiveisContratoTransportadora = []
      this.comboNiveisContratoFilial = []
      this.comboNiveisContratoAtendimento = []
    },
    async listaTransportadoras() {
      let query = "excluido=false"

      let resp = await this.$vsApiRest("GET", `/transportadoras/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          // nivel 1: transportadora
          el.text = `${el.nomeFantasia}`
          el.value = el.id

          // nivel 2: servicos
          el.servicos.forEach(el2 => {
            el2.text = el2.codigo
            el2.value = el2.codigo

            // nivel 3: niveis de contrato do servico
            el2.niveisContrato.forEach(el3 => {
              el3.text = `${el3.codigo} - ${el3.descricao}`
              el3.value = el3.codigo
            })
          })
        })
        this.comboTransportadoras = resp.data
      }
    },
    nomeTransportadora(id) {
      let transp = this.comboTransportadoras.find(el => el.id === id)
      return (transp) ? `${transp.nomeFantasia}` : ""
    },
    mudaStatusServicoFilial() {
      let form = this.formServicoFilial
      form.disponivelAtendimentoFilial = form.disponivelFilial
    },
    filtraNiveisContratoTransportadora() {
      let form = this.formServicoFilial
      let codigo = form.codigoServicoTransportadora
      let srvContratante = this.servicosContratante.find(el => el.value === codigo)
      let srvTransp = this.comboServicosTransportadora.find(el => el.value === codigo)

      if (srvContratante && srvTransp) {
        form.descricaoServicoTransportadora = srvContratante.descricao
        form.idServicoTransportadora = srvContratante.id
        form.nivelContratoTransportadora = (srvTransp.niveisContrato.length === 1)
          ? srvTransp.niveisContrato[0].codigo : ""

        this.comboNiveisContratoTransportadora = srvTransp.niveisContrato
      }
    },
    filtraNiveisContratoFilial() {
      let form = this.formServicoFilial
      let codigo = form.codigoServicoFilial
      let servContratante = this.servicosContratante.find(el => el.value === codigo)
      let srvTransp = this.comboServicosTransportadora.find(el => el.value === codigo)

      if (servContratante && srvTransp) {
        form.descricaoServicoFilial = servContratante.descricao
        form.idServicoFilial = servContratante.id
        form.nivelContratoFilial = (srvTransp.niveisContrato.length === 1)
          ? srvTransp.niveisContrato[0].codigo : ""

        this.comboNiveisContratoFilial = srvTransp.niveisContrato
      }
    },
    filtraNiveisContratoAtendimento() {
      let form = this.formServicoFilial
      let codigo = form.codigoServicoAtendimento
      let servContratante = this.servicosContratante.find(el => el.value === codigo)
      let srvTransp = this.comboServicosTransportadora.find(el => el.value === codigo)

      if (servContratante && srvTransp) {
        form.descricaoServicoAtendimento = servContratante.descricao
        form.idServicoAtendimento = servContratante.id
        form.nivelContratoAtendimento = (srvTransp.niveisContrato.length === 1)
          ? srvTransp.niveisContrato[0].codigo : ""

        this.comboNiveisContratoAtendimento = srvTransp.niveisContrato
      }
    },
    exibeNivelContrato() {
      let form = this.formServicoFilial
      let tra = this.comboTransportadoras.find(el => el.id === form.idTransportadora)
      return (tra && tra.usaNivelContrato)
    },
    lazyLoadSanfona(id) {
      this.$vsLoadingStart(true, 300)
      this.idSanfonaAberta = id
    },
    nomeNivelContrato(obj, tipoNivel = 0) {
      let codigoServico = obj.codigoServicoTransportadora
      let codigoNivel = obj.nivelContratoTransportadora

      if (tipoNivel === 1) {
        codigoServico = obj.codigoServicoFilial
        codigoNivel = obj.nivelContratoFilial
      }
      if (tipoNivel === 2) {
        codigoServico = obj.codigoServicoAtendimento
        codigoNivel = obj.nivelContratoAtendimento
      }

      try {
        let transp = this.comboTransportadoras.find(el => el.id === obj.idTransportadora)
        let serv = transp.servicos.find(el => el.codigo === codigoServico)
        let nivel = serv.niveisContrato.find(el => el.codigo === codigoNivel)

        return `${nivel.codigo} - ${nivel.descricao}`
      } catch {
        return ""
      }
    },
  },
  // #endregion
}
</script>
