<template>
  <div>
		<div class="titulo">
			<h1>Cotação</h1>
			<h4>Cotação de Frete</h4>
		</div>

    <!-- #region nota fiscal e declarado -->
    <b-form-row>
      <b-col md="2">
        <b-form-group class="form-input-cinza" label="Cep" label-align-md="right" label-size="" label-cols-md="2"
          content-cols-md="">
          <b-form-input type="text" v-model="objTarifacao.cep" @keyup="mascaraCep()" maxlength="9" autocomplete="on" />
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Valor NF" label-align-md="right" label-size="" label-cols-md="3">
          <b-form-input class="text-right" type="text" v-model="objTarifacao.valorNotaFiscal" @keyup="mascaraMoeda" />
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Valor declarado" label-align-md="right" label-size=""
          label-cols-md="5">
          <b-form-input class="text-right" type="text" v-model="objTarifacao.valorDeclarado" @keyup="mascaraMoeda" />
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <!-- #region tipo e dimensoes -->
    <b-form-row>
			<b-col md="3">
        <b-form-group class="form-input-cinza" label="Tipo" label-align-md="right" label-size="" label-cols-md="3">
          <b-form-select :options="$vsListaTiposObjetos()" v-model="objTarifacao.tipoObjeto" @change="liberaDimensoes()" />
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Altura (cm)" label-align-md="right" label-size=""
          label-cols-md="5" content-cols-md="">
          <b-form-input v-if="dimensoesLiberadas" class="text-right" type="text" v-model="objTarifacao.altura" @keyup="mascaraInteiro" />
					<b-form-input v-else class="text-right" type="text" :value="objTarifacao.altura" disabled />
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Largura (cm)" label-align-md="right" label-size=""
          label-cols-md="5" content-cols-md="">
          <b-form-input v-if="dimensoesLiberadas" class="text-right" type="text" v-model="objTarifacao.largura" @keyup="mascaraInteiro" />
					<b-form-input v-else class="text-right" type="text" :value="objTarifacao.largura" disabled />
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Comprimento (cm)" label-align-md="right" label-size=""
          label-cols-md="6" content-cols-md="">
          <b-form-input v-if="dimensoesLiberadas" class="text-right" type="text" v-model="objTarifacao.comprimento" @keyup="mascaraInteiro" />
					<b-form-input v-else class="text-right" type="text" :value="objTarifacao.comprimento" disabled />
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <!-- #region peso, obs e adicionais -->
    <b-form-row>
      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Peso (gramas)" label-align-md="right" label-size=""
          label-cols-md="5">
          <b-form-input class="text-right" type="text" v-model="objTarifacao.peso" @keyup="mascaraInteiro" />
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-checkbox class="form-input-cinza" size="lg" v-model="objTarifacao.adicionais[0]" value="AR" switch>
          <span style="cursor: pointer; color: #808080; opacity: 0.7; font-size: 16px;">Adicionais (AR)</span>
        </b-form-checkbox>
      </b-col>

      <b-col md="2">
        <button type="button" class="btn-laranja" @click="consultaTarifacao()">Calcular frete</button>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <!-- #region tabela de frete -->
    <b-form-row>
      <b-col>
        <b-form-group class="form-input-cinza" label="Modalidade de envio" label-size="lg" label-cols-md="12" content-cols-md="10">
          <b-table 
						class="text-nowrap h5" 
						id="tabelaTarifacao" 
						:items="tarifacaoServicos"
            :fields="tarifacaoCabecalho"
						small 
						responsive 
						hover>
            <!--===================================================================================
            mapa de colunas (atua em campos sem tratamento) 
            se precisar debugar ou visualizar os dados para ajustes basta descomentar...

            obj.index = indice da linha
            obj.field = nome do campo/coluna/chave json
            obj.item  = objeto json bruto
            obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
            ====================================================================================-->
            <!--<template #cell()="obj">
              {{ obj.field }}
              {{ obj.item }}
            </template>-->

            <!--===================================================================================
              celulas que precisam de tratamento, 
              sobrescrevem mapa de colunas pela identificacao da chave
              e nao precisam estar em ordem/sequencia
            ====================================================================================-->
            <template #cell(valorFrete)="obj">
              {{ $vsFormataMoeda(parseFloat(obj.value)) }}
            </template>
          </b-table>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->
  </div>
</template>

<script>

export default {
  // #region configs
  name: "cotacao",
  path: "/cotacao",
  requireAuth: true,
  components: {},
  // #endregion

  // #region dados
  data() {
    return {
      CORINGA_PESQUISA: " | ",
			dimensoesLiberadas: true,
      clientes: [],
      pessoas: [],
      tarifacaoServicos: [],
      tarifacaoCabecalho: [
        //{ key: "transportadora.nome", sortable: true,  label: "Transportadora", },
        { key: "servicoDescricao", sortable: true, label: "Serviço", tdClass: "text-left", },
        { key: "prazoDias", sortable: true, label: "Prazo (dias)", tdClass: "text-center", },
        { key: "valorFrete", sortable: true, label: "Valor Frete", tdClass: "text-center", },
      ],
      objTarifacao: {
        cep: "",
        peso: 0,
        altura: 0,
        largura: 0,
        comprimento: 0,
        valorDeclarado: 0,
        valorNotaFiscal: 0,
        adicionais: [""],
				tipoObjeto: 0,
      }
    }
  },
  // #endregion

	// #region triggers
  created() {
    this.objTarifacao.tipoObjeto = this.VS_TIPO_VOLUME_CAIXA
  },
	// #endregion

  // #region metodos
  methods: {
    mascaraCep() {
      this.objTarifacao.cep = this.$vsFormataCep(this.objTarifacao.cep)
    },
    async consultaTarifacao() {
      let tar = this.objTarifacao
      let valorNFe = this.$vsConverteMoeda(tar.valorNotaFiscal)
      let valorDecl = this.$vsConverteMoeda(tar.valorDeclarado)
      tar.adicionais[0] = (tar.adicionais[0] !== false) ? tar.adicionais[0] : "" // temporario

			if (tar.tipoObjeto !== this.VS_TIPO_VOLUME_ENVELOPE) {
				if (!tar.altura || tar.altura < 1) {
					tar.altura = this.VS_DIMENSAO_MINIMA_ALTURA
				}
				if (!tar.largura || tar.largura < 1) {
					tar.largura = this.VS_DIMENSAO_MINIMA_LARGURA
				}
				if (!tar.comprimento || tar.comprimento < 1) {
					tar.comprimento = this.VS_DIMENSAO_MINIMA_COMPRIMENTO
				}
			}

      let req = {
        idContratante: this.$root.sessao.contratante.id,
        idFilial: this.$root.sessao.filial.id,
        cepOrigem: this.$vsLimpaFormatacao(this.$root.sessao.filial.endereco.cep),
        cepDestino: this.$vsLimpaFormatacao(this.objTarifacao.cep),
        peso: String(tar.peso),
        altura: String(tar.altura),
        largura: String(tar.largura),
        comprimento: String(tar.comprimento),
        valorDeclarado: (valorNFe > valorDecl) ? valorNFe.toFixed(2) : valorDecl.toFixed(2),
        adicionais: tar.adicionais,
				tipoObjeto: tar.tipoObjeto,
      }
      this.tarifacaoServicos = []

      let resp = await this.$vsApiRest("POST", "/tarifacao", req)
			
			if (resp.status === 200) {
        this.tarifacaoServicos = resp.data
      }
    },
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    mascaraInteiro(evt) {
      evt.target.value = this.$vsLimpaFormatacao(evt.target.value)
    },
		liberaDimensoes() {
			let tar = this.objTarifacao
			let envolpe = (tar.tipoObjeto === this.VS_TIPO_VOLUME_ENVELOPE)

			if (envolpe) {
				tar.altura = "0"
				tar.largura = "0"
				tar.comprimento = "0"
			}

			this.dimensoesLiberadas = !envolpe
		},
  },
  // #endregion
}
</script>
