<template>
  <div class="area-impressao" style="width: 800px;">

    <!-- resumo -->
    <div>
      <div class="mb-5">
        <div class="h1 mb-5 text-center">Caixa</div>
        <div class="h2 text-muted">Resumo</div>
      </div>
      <div class="bg-white p-4 h4 mb-5">

        <!-- cabecalho -->
        <div class="mb-4">
          <span>{{ filial.nome.toUpperCase() }}</span>
        </div>
        <div class="mb-4">
          <span>Colaborador:&nbsp;&nbsp;{{ operador.nome.toUpperCase() }}</span>
        </div>
        <div class="mb-4">
          <span class="float-right">R$ {{ $vsFormataMoeda(caixa.saldoAbertura) }}</span>
          <span>Abertura:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ $vsFormataData(caixa.dataAbertura, true) }}</span>
        </div>
        <div class="mb-4">
          <span class="float-right">R$ {{ $vsFormataMoeda(caixa.saldoPostagens) }}</span>
          <span>Postagens:</span>
        </div>
        <div class="mb-4">
          <span class="float-right">R$ {{ $vsFormataMoeda(caixa.saldoFechamento) }}</span>
          <span>Fechamento:&nbsp;&nbsp;&nbsp;{{ $vsFormataData(caixa.dataFechamento, true) }}</span>
        </div>
      </div>
    </div>
                  
    <!-- movimentacao -->
    <div class="nova-pagina">
      <div class="mb-5">
        <div class="h2 text-muted">Movimentação</div>
      </div>
      <div class="bg-white p-4 h4 mb-5">
        <table class="w-100">
          <tr>
            <td class="border-right pb-3 pr-2">Tipo</td>
            <td class="border-right pb-3 px-2">Data/hora</td>
            <td class="pb-3 px-2">Valor</td>
          </tr>
          <tr v-for="(mov, i) in caixa.movimentacoes" :key="i">
            <td class="border-right border-top pt-3">{{ mov.descricao }}</td>
            <td class="border-right border-top pt-3 px-2">{{ $vsFormataData(mov.dataCriacao, true) }}</td>
            <td class="border-top pt-3 pl-3 text-right text-nowrap">R$ {{ $vsFormataMoeda(mov.saldo) }}</td>
          </tr>
        </table>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: "vs-relatorio-caixa",
  props: {
    filial:   { type: Object, required: true },
    caixa:    { type: Object, required: true },
    operador: { type: Object, required: true },
  }
}
</script>
