<template>
  <form @submit.prevent="cadastrar()" method="post">
    <!-- #region acoes -->
    <b-form-row>
      <b-col v-if="isEdicao && exibeExclusao">
        <div class="float-right">
          <a @click.prevent v-b-modal="`apagaCadastro${obj.id}`" v-b-tooltip.hover title="Excluir cadastro" :data-vs-nome="$vsDataVsNome('lnkApagaCadastro')">
            <b-icon icon="trash" variant="danger" scale="1.2" />
          </a>
          <b-modal :id="`apagaCadastro${obj.id}`" title="Alerta">
            <div class="h5">
              <div class="mb-4">Deseja mesmo excluir o cadastro?</div>
              <div class="mb-3">CPF: {{ $vsFormataCpfCnpj(obj.cpfCnpj) }}</div>
              <div class="mb-3">Nome: {{ obj.nome }}</div>
						</div>
            <template #modal-footer="{ hide }">
              <div class="w-100">
                <b-button class="float-right ml-2" variant="danger" @click="apagar(); hide()" :data-vs-nome="$vsDataVsNome('btnExcluiCadastro')">Excluir</b-button>
                <b-button class="float-right" variant="secondary" @click="hide()" :data-vs-nome="$vsDataVsNome('btnFechaModal')">Cancelar</b-button>
              </div>
            </template>
          </b-modal>
        </div>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <!-- #region dados basicos -->
    <b-form-row>
			<b-col md="2">
        <b-form-group class="form-input-cinza" :label="labelCpfCnpj" label-cols="12">
          <b-form-input v-if="temPesquisaCpfCnpj" type="text" :maxlength="lenCpfCnpjFormatado" size="sm" v-model="obj.cpfCnpj" @keyup="mascaraCpfCnpj()" @blur="eventoPesquisaPai()" autocomplete="on" :disabled="bloqueiaEdicao()" :data-vs-nome="$vsDataVsNome('txtCpfCnpj')"/>
					<b-form-input v-else type="text" :maxlength="lenCpfCnpjFormatado" size="sm" v-model="obj.cpfCnpj" @keyup="mascaraCpfCnpj()" autocomplete="on" :disabled="bloqueiaEdicao()" :data-vs-nome="$vsDataVsNome('txtCpfCnpj')"/>
        </b-form-group>
      </b-col>

      <b-col md="10">
        <b-form-group class="form-input-cinza" label="Nome" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.nome" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtNome')"/>
        </b-form-group>
      </b-col>

      <b-col md="5" v-if="exibeFoto">
        <b-form-group class="form-input-cinza" label="Foto" label-cols="12">
          <b-form-file v-model="obj.fotoUpload" size="sm" placeholder="Opcional" accept="image/jpeg, image/png" :data-vs-nome="$vsDataVsNome('fleFoto')"/>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Email" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.email" autocomplete="on" :disabled="bloqueiaEdicaoEmail()" :data-vs-nome="$vsDataVsNome('txtEmail')"/>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group class="form-input-cinza" label="Telefone" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.telefone" @keyup="mascaraTelefone()" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtTelefone')"/>
        </b-form-group>
      </b-col>

      <b-col md="2" v-if="exibeSenha">
        <b-form-group class="form-input-cinza" label="Senha" label-cols="12">
          <b-input-group>
            <b-form-input :type="verSenha ? 'text' : 'password'" size="sm" v-model="obj.senha" :data-vs-nome="$vsDataVsNome('txtSenha')"/>
            <b-icon :id="`iconeSenha${obj.id}`" class="olho-senha" scale="1.2" :icon="!verSenha ? 'eye' : 'eye-slash'" @click="verSenha = !verSenha" />
          </b-input-group>
          <b-tooltip :target="`iconeSenha${obj.id}`" triggers="hover">Mostrar/Ocultar senha</b-tooltip>
        </b-form-group>
      </b-col>

      <b-col md="3" v-if="exibePerfil">
        <b-form-group class="form-input-cinza" label="Cargo" label-cols="12">
          <b-form-select v-model="obj.perfil" :options="arrayPerfis" size="sm" :data-vs-nome="$vsDataVsNome('cmbCargo')"/>
        </b-form-group>
      </b-col>

      <b-col md="12" v-if="mostraPontoAtendimento()">
        <b-form-group v-if="isPF" class="form-input-cinza" label="Ponto(s) de atendimento" label-cols="12" v-slot="{ ariaDescribedby }">
          <b-form-checkbox-group v-model="meusVinculos" size="lg" name="vinculo" :aria-describedby="ariaDescribedby" stacked switches style="column-count: 2;">
            <b-form-checkbox v-for="(fil, i) in arrayFiliais" :key="i" :value="fil.id" @change="removeVinculo(fil.id)" class="pr-4 mb-3" :data-vs-nome="$vsDataVsNome('chkPontoAtendimento')">
              <span style="cursor: pointer">{{ fil.nome }}</span>
            </b-form-checkbox>
          </b-form-checkbox-group>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <!-- #region endereco -->
    <b-form-row v-if="exibeEndereco">
      <b-col md="2">
        <b-form-group class="form-input-cinza" label="Cep" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.endereco.cep" @keyup="mascaraCep()" maxlength="9" autocomplete="on" @blur="buscaCep()" :data-vs-nome="$vsDataVsNome('txtEnderecoCep')"/>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group class="form-input-cinza" label="Endereço" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.endereco.logradouro" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtEnderecoLogradouro')"/>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group class="form-input-cinza" label="Número" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.endereco.numero" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtEnderecoNumero')"/>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group class="form-input-cinza" label="Complemento" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.endereco.complemento" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtEnderecoComplemento')"/>
        </b-form-group>
      </b-col>

			<b-col md="4">
        <b-form-group class="form-input-cinza" label="Bairro" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.endereco.bairro" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtEnderecoBairro')"/>
        </b-form-group>
      </b-col>

      <b-col md="4">
        <b-form-group class="form-input-cinza" label="Cidade" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.endereco.cidade" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtEnderecoCidade')"/>
        </b-form-group>
      </b-col>

      <b-col md="1">
        <b-form-group class="form-input-cinza" label="UF" label-cols="12">
          <b-form-input type="text" size="sm" maxlength="2" v-model="obj.endereco.uf" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtEnderecoUF')"/>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <b-form-row>
      <b-col md="12" v-if="exibeMensagem">
        <b-form-group class="form-input-cinza" label="Mensagem do recibo" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.mensagemCustomizada" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtMensagemRecibo')"/>
        </b-form-group>
      </b-col>
    </b-form-row>

    <!-- #region perfil vipp (uso externo) -->
    <b-form-row v-if="exibePerfilVipp">
      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Usuário Vipp" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.perfilVipp.usuario" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtUsuarioVipp')"/>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Senha Vipp" label-cols="12">
          <b-input-group>
            <b-form-input :type="verSenhaVipp ? 'text' : 'password'" size="sm" v-model="obj.perfilVipp.token" :data-vs-nome="$vsDataVsNome('txtSenhaVipp')"/>
            <b-icon :id="`iconeSenhaVipp${obj.id}`" class="olho-senha" scale="1.2" :icon="!verSenhaVipp ? 'eye' : 'eye-slash'" @click="verSenhaVipp = !verSenhaVipp" />
          </b-input-group>
          <b-tooltip :target=" `iconeSenhaVipp${obj.id}` " triggers="hover">Mostrar/Ocultar senha</b-tooltip>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="IdPerfil Vipp" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.perfilVipp.idPerfil" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtIdPerfilVipp')"/>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

		<!-- #region contrato/cartao romaneio -->
    <b-form-row v-if="obj.perfil === VS_PERFIL_CONTRATANTE">
      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Contrato" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.contratosRomaneio[0].numeroContrato" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtContratoRomaneio')"/>
        </b-form-group>
      </b-col>

      <b-col md="3">
        <b-form-group class="form-input-cinza" label="Cartão" label-cols="12">
          <b-form-input type="text" size="sm" v-model="obj.contratosRomaneio[0].numeroCartao" autocomplete="on" :data-vs-nome="$vsDataVsNome('txtCartaoRomaneio')"/>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <!-- #region acoes -->
    <b-form-row>
      <b-col md="3">
        <button type="submit" class="btn-azul" :data-vs-nome="$vsDataVsNome('btnSalvar')">Salvar</button>
      </b-col>
			<b-col md="1" v-if="isFilial"/>
			<b-col md="7" v-if="isFilial" class="px-3 border border-danger text-danger d-flex align-items-center">
				Data Vencimento à contar da data de criação (dias corridos)&nbsp;&nbsp;
				<b-form-select class="numPerPage" v-model="obj.diasCorridosVencimentoFatura" :options="comboDiasCorridosFatura" :data-vs-nome="$vsDataVsNome('cmbDiasCorridosFatura')"/>
			</b-col>
    </b-form-row>
    <!-- #endregion -->
  </form>
</template>

<script>
export default {
  // #region configs
  name: "vs-form-usuario",
  props: {
    obj: { type: Object, required: true },
    arrayPerfis: { type: Array, default: () => [] },
    arrayFiliais: { type: Array, default: () => [] },
    isEdicao: { type: Boolean, default: false },
    isPF: { type: Boolean, default: false },
    isPJ: { type: Boolean, default: false },
    exibeFoto: { type: Boolean, default: false },
    exibePerfil: { type: Boolean, default: false },
    exibeEndereco: { type: Boolean, default: false },
    exibeSenha: { type: Boolean, default: false },
    exibeMensagem: { type: Boolean, default: false },
    exibePerfilVipp: { type: Boolean, default: false },
    exibeExclusao: { type: Boolean, default: false },
		isFilial: { type: Boolean, default: false },
		emailObrigatorio: { type: Boolean, default: false },
		telefoneObrigatorio: { type: Boolean, default: false },
		isAutoCadastro: { type: Boolean, default: false },
		telaExterna: { type: Boolean, default: false },
		preCadastro: { type: Boolean, default: false },
		isEdicaoToken: { type: Boolean, default: false },
		temPesquisaCpfCnpj: { type: Boolean, default: false },
  },
  // #endregion

  // #region dados
  data() {
    return {
      verSenha: false,
      verSenhaVipp: false,
      labelCpfCnpj: (this.isPF) ? "CPF" : (this.isPJ) ? "CNPJ" : "CPF/CNPJ",
      lenCpfCnpjFormatado: (this.isPF) ? 14 : 18,
      lenCpfCnpj: (this.isPF) ? 11 : 14,
      enderecos: [],
      meusVinculos: [],
			comboDiasCorridosFatura: [],
    }
  },
  // #endregion

  // #region triggers
  beforeUpdate() {
    this.ajustaFiliais()
  },
  created() {
    this.ajustaFiliais()
		this.ajustaCadastro()
  },
	mounted() {
		if (this.isFilial) {
			this.defineComboVencimentoFatura()
		}
	},
  // #endregion

  // #region metodos
  methods: {
		eventoPesquisaPai() {
			this.$emit("eventoPesquisaCpfCnpj")
		},
		ajustaFiliais() {
      let fils = this.arrayFiliais

      this.meusVinculos = []

      // ajusta opcoes de arrayFiliais
      for (let i = 0; i < fils.length; i++) {

        // ajusta vinculos
        if (this.isEdicao) {
          for (let j = 0; j < this.obj.vinculos.length; j++) {
            if (this.obj.vinculos[j].idUsuario === fils[i].id) {
              this.meusVinculos.push(this.obj.vinculos[j].idUsuario)
            }
          }
        }
      }
    },
		ajustaCadastro() {
			this.obj.cpfCnpj = this.$vsFormataCpfCnpj(this.obj.cpfCnpj)
			this.obj.telefone = this.$vsFormataTelefone(this.obj.telefone)
			this.obj.fotoUpload = null

			// ajusta endereco
			this.enderecos = [
				{ value: this.VS_TIPO_ENDERECO_RUA, text: "Rua" },
				{ value: this.VS_TIPO_ENDERECO_AVENIDA, text: "Avenida" },
				{ value: this.VS_TIPO_ENDERECO_VIELA, text: "Viela" },
				{ value: this.VS_TIPO_ENDERECO_RODOVIA, text: "Rodovia" },
				{ value: this.VS_TIPO_ENDERECO_ALAMEDA, text: "Alameda" },
			]

			if (!this.obj.endereco) {
				this.obj.endereco = {
					tipo: this.VS_TIPO_ENDERECO_RUA,
					logradouro: "",
					numero: "",
					bairro: "",
					cidade: "",
					cep: "",
					uf: "",
					pais: "Brasil",
					apelido: "",
					complemento: "",
					isComercial: false,
				}
				this.obj.endereco.tipo = this.enderecos[0].value
				this.obj.endereco.isComercial = this.isPJ
				this.obj.endereco.cep = this.$vsFormataCep(this.obj.endereco.cep)
			}
			if (!this.obj.endereco.complemento) {
				this.obj.endereco.complemento = ""
			}
			if (!this.obj.perfilVipp) {
				this.obj.perfilVipp = {
					usuario: "",
					token: "",
					idPerfil: "",
				}
			}
			if (!this.obj.contratosRomaneio) {
				this.obj.contratosRomaneio = []
			}
			if (this.obj.perfil === this.VS_PERFIL_CONTRATANTE && this.obj.contratosRomaneio.length === 0) {
				this.obj.contratosRomaneio = [{
					numeroContrato: "",
					numeroCartao: "",
				}]
			}
		},
		defineComboVencimentoFatura() {
			this.comboDiasCorridosFatura = Array.from({length: 90}, (v, k) => k+1)

			if (!this.obj.diasCorridosVencimentoFatura || this.obj.diasCorridosVencimentoFatura === 0) {
				this.obj.diasCorridosVencimentoFatura = 7 // sugerido/predefinido
			}
		},
    async buscaCep() {
			let end = this.obj.endereco
      let cep = this.$vsLimpaFormatacao(end.cep)

      if (cep.length !== 8) {
        return
      }

      let resp = await this.$vsApiRest("GET", `/tarifacao/?cep=${cep}`)

      end.numero = ""
      end.complemento = ""
			end.logradouro = ""
      end.bairro = ""
      end.cidade = ""
      end.uf = ""

      if (resp.status === 200) {
				let endDB = resp.data
        end.logradouro = endDB.logradouro
        end.bairro = endDB.bairro
        end.cidade = endDB.cidade
        end.uf = endDB.uf
      }
    },
    mascaraCpfCnpj() {
      this.obj.cpfCnpj = this.$vsFormataCpfCnpj(this.obj.cpfCnpj)
    },
    mascaraTelefone() {
      this.obj.telefone = this.$vsFormataTelefone(this.obj.telefone)
    },
    mascaraCep() {
      this.obj.endereco.cep = this.$vsFormataCep(this.obj.endereco.cep)
    },
    async cadastrar() {
      if (!this.validacoes()) {
        return
      }
			if (!(await this.cpfCnpjLiberadoInsert())) {
				return
			}

      let form = new FormData() // post tradicional por causa da foto...

      if (this.isEdicao) {
        form.append("id", this.obj.id)
      }

      // dados basicos
      form.append("nome", this.obj.nome)
      form.append("cpfCnpj", this.$vsLimpaFormatacao(this.obj.cpfCnpj))
      form.append("email", this.obj.email)
      form.append("telefone", this.$vsLimpaFormatacao(this.obj.telefone))
      form.append("isAtivo", this.obj.isAtivo)
      form.append("isExcluido", this.obj.isExcluido)
      form.append("senha", this.obj.senha)
      form.append("foto", this.obj.fotoUpload)
      form.append("mensagemCustomizada", this.obj.mensagemCustomizada)
      form.append("perfil", this.obj.perfil)
			form.append("origemCadastro", (this.isAutoCadastro)
				? this.VS_ORIGEM_CADASTRO_AUTOCADASTRO
				: this.VS_ORIGEM_CADASTRO_INTERNO)

			if (this.obj.perfil === this.VS_PERFIL_FILIAL) {
				form.append("diasCorridosVencimentoFatura", this.obj.diasCorridosVencimentoFatura)
			}
			form.append("telaExterna", this.telaExterna)
			form.append("preCadastro", this.preCadastro)

      // perfilVipp
      form.append("perfilVipp.usuario", this.obj.perfilVipp.usuario)
      form.append("perfilVipp.token", this.obj.perfilVipp.token)
      form.append("perfilVipp.idPerfil", this.obj.perfilVipp.idPerfil)

      // vinculos/relacionamentos
			if (![this.VS_PERFIL_CONTRATANTE, this.VS_PERFIL_CLIENTE].includes(this.obj.perfil)) {
				this.obj.vinculos = []

				for (let i = 0; i < this.meusVinculos.length; i++) { // escolhidos na tela
					this.obj.vinculos.push({
						idUsuario: this.meusVinculos[i],
						isSuperior: true,
					})
				}
			}

			this.defineVinculoSuperior() // padrao

      for (let i = 0; i < this.obj.vinculos.length; i++) {
        form.append(`vinculos[${i}].idUsuario`, this.obj.vinculos[i].idUsuario)
        form.append(`vinculos[${i}].isSuperior`, this.obj.vinculos[i].isSuperior)
      }

      // endereco
      form.append("endereco.tipo", this.obj.endereco.tipo)
      form.append("endereco.logradouro", this.obj.endereco.logradouro)
      form.append("endereco.numero", this.obj.endereco.numero)
      form.append("endereco.bairro", this.obj.endereco.bairro)
      form.append("endereco.cidade", this.obj.endereco.cidade)
      form.append("endereco.cep", this.$vsLimpaFormatacao(this.obj.endereco.cep))
      form.append("endereco.uf", this.obj.endereco.uf)
      form.append("endereco.pais", this.obj.endereco.pais)
      form.append("endereco.complemento", this.obj.endereco.complemento)
      form.append("endereco.isComercial", this.obj.endereco.isComercial)

			// contratos de romaneio
			for (let i = 0; i < this.obj.contratosRomaneio.length; i++) {
				form.append(`contratosRomaneio[${i}].numeroContrato`, this.obj.contratosRomaneio[i].numeroContrato.trim())
				form.append(`contratosRomaneio[${i}].numeroCartao`, this.obj.contratosRomaneio[i].numeroCartao.trim())
			}

      let resp = await this.$vsApiRest("POST", "/usuarios", form, { "Content-Type": "multipart/form-data" })

      if (resp.status === 200) {
        this.$vsNotificacao("Cadastro efetuado com sucesso", "success")
        this.obj.primeiroNome = this.obj.nome.split(" ")[0]

        if (!this.obj.edicao) {
          this.limparForm()
        }

				let sessao = this.$root.sessao

				if (sessao && sessao.id === this.obj.id) {
					this.$vsSalvaSessao(sessao)
				}

				if (this.isEdicaoToken) {
					this.obj.id = resp.data.msg
				}
				this.eventoObjetoPai()
      }
    },
    async apagar() {
      let resp = await this.$vsApiRest("DELETE", `/usuarios/${this.obj.id}`)

      if (resp.status === 200) {
        this.$vsNotificacao(resp.data.msg, "success")
        this.eventoObjetoPai()
      }
    },
    limparForm() {
      this.obj.nome = ""
      this.obj.fotoUpload = null
      this.obj.telefone = ""
      this.obj.cpfCnpj = ""
      this.obj.email = ""
      this.obj.mensagemCustomizada = ""

      if (this.exibeSenha) {
        this.obj.senha = ""
      }

      this.obj.isAtivo = true
      this.obj.isExcluido = false
      this.obj.verSenha = false
      this.obj.verSenhaVipp = false

      if (this.exibeEndereco) {
        this.obj.endereco = {
          tipo: this.enderecos[0].value,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          apelido: "",
          complemento: "",
          isComercial: this.isPJ,
        }
      }

      if (this.exibePerfilVipp) {
        this.obj.perfilVipp = {
          usuario: "",
          token: "",
          idPerfil: "",
        }
      }

      this.obj.vinculos = []
      this.meusVinculos = []
    },
    validacoes() {
      let ok = true
			let erros = [`Erros encontrados:`]

      if (!this.$vsNomeValido(this.obj.nome)) {
        ok = false
        erros.push("Nome inválido, evite números e caracteres especiais")
      }
      let cpfCnpj = this.$vsLimpaFormatacao(this.obj.cpfCnpj)
      if ((this.isPJ && cpfCnpj.length !== this.lenCpfCnpj) || !this.$vsCpfCnpjValido(cpfCnpj)) {
        ok = false
        erros.push(`${this.labelCpfCnpj} inválido`)
      }
      if ((this.emailObrigatorio || this.obj.email) && !this.$vsEmailValido(this.obj.email)) {
        ok = false
        erros.push("E-mail inválido")
      }
      if ((this.telefoneObrigatorio || this.obj.telefone) && !this.$vsTelefoneValido(this.$vsLimpaFormatacao(this.obj.telefone))) {
        ok = false
        erros.push("Telefone inválido")
      }
			if (this.exibeSenha) {
				let senhaInvalida = !this.$vsSenhaValida(this.obj.senha, this.obj.senha)
				
				if ((this.obj.edicao && this.obj.senha.length && senhaInvalida) || (!this.obj.edicao && senhaInvalida)) {
					ok = false
					erros.push("Senha inválida (deve conter ao menos 8 caracteres, uma letra e um número)")
				}
			}
      if (this.exibePerfil && !this.arrayPerfis.find(el => el.value == this.obj.perfil)) {
        ok = false
        erros.push("Falta definir o perfil do usuário")
      }
      if (this.obj.fotoUpload) {
        if (((this.obj.fotoUpload.size / 1024) / 1024) > 2) {
          ok = false
          erros.push("Foto deve ter no máximo 2MB")
        }
        if (this.obj.fotoUpload.type !== "image/jpeg" && this.obj.fotoUpload.type !== "image/png") {
          ok = false
          erros.push("Formato de foto deve ser png ou jpeg/jpg")
        }
      }
      if (this.exibeEndereco) {
        if (this.$vsLimpaFormatacao(this.obj.endereco.cep).length !== 8) {
          ok = false
          erros.push("Cep inválido")
        }
        if (this.obj.endereco.tipo.length === 0) {
          ok = false
          erros.push("Tipo de logradouro inválido")
        }
        if (this.obj.endereco.logradouro.trim().length === 0) {
          ok = false
          erros.push("Logradouro inválido")
        }
        if (this.obj.endereco.numero.trim().length === 0) {
          ok = false
          erros.push("Número inválido, caso não exista por gentileza informe s/n")
        }
        if (this.obj.endereco.bairro.trim().length === 0) {
          ok = false
          erros.push("Bairro inválido")
        }
        if (this.obj.endereco.cidade.trim().length === 0) {
          ok = false
          erros.push("Cidade inválida")
        }
				if (this.obj.endereco.uf.trim().length !== 2) {
          ok = false
          erros.push("UF inválido")
        }
        if (this.obj.endereco.pais.trim().length === 0) {
          ok = false
          erros.push("País inválido")
        }
      }
      /*if (this.exibePerfilVipp) {
        if (!this.obj.perfilVipp.usuario.trim().length) {
          ok = false
          erros.push("Usuário Vipp inválido")
        }
        if (!this.obj.perfilVipp.token.trim().length) {
          ok = false
          erros.push("Senha Vipp inválida")
        }
        if (this.obj.perfil === this.VS_PERFIL_FILIAL && !this.obj.perfilVipp.idPerfil.trim().length) {
          ok = false
          erros.push("IdPerfil Vipp inválido")
        }
      }*/

			// erros numa unica notificacao
      if (!ok) {
        const html = this.$createElement
        const nodes = []
        erros.forEach((el, i) => {
          if (i === 0) {
            nodes.push(el)
          } else {
            nodes.push(`- ${el}`)
          }
          nodes.push(html("br"))
        })
        this.$vsNotificacao([html('p', nodes)], "warning")
      }

      return ok
    },
    defineVinculoSuperior() {
			let sessao = this.$root.sessao

			if (!sessao || (sessao.perfil === this.VS_PERFIL_CLIENTE)) {
				return
			}

      let idContratante = (sessao.perfil === this.VS_PERFIL_CONTRATANTE)
				? sessao.id
				: sessao.contratante.id
			let idFilial = null
			
      if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				idFilial = sessao.id
			} else if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				idFilial = sessao.filial.id
			}
        
      // contratante do usuario logado
      this.obj.vinculos.push({
        idUsuario: idContratante,
        isSuperior: true,
      })

			// filial do usuario logado
			if (idFilial) {
				this.obj.vinculos.push({
					idUsuario: idFilial,
					isSuperior: true,
				})
			}

      // usuario logado
      this.obj.vinculos.push({
        idUsuario: sessao.id,
        isSuperior: true,
      })
    },
    eventoObjetoPai() {
      this.$emit("evento")
    },
    async removeVinculo(idVinculo) {
      let vinculos = this.meusVinculos

      if (vinculos.includes(idVinculo)) {
        return
      }

			let req = {
          idUsuario: this.obj.id,
          idVinculo: idVinculo,
      }
      await this.$vsApiRest("PATCH", `/usuarios/vinculos/exclusao`, req)
    },
    mostraPontoAtendimento() {
      let sessao = this.$root.sessao

			if (!sessao) {
				return false
			}

			let total = this.arrayFiliais.length
      let idFilial = null

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				return true
			}
      if (sessao.perfil === this.VS_PERFIL_FILIAL) {
        idFilial = sessao.id
      }
      if (sessao.perfil === this.VS_PERFIL_GERENTE) {
        idFilial = sessao.filial.id
      }
      return (total > 1) || (total === 1 && idFilial && this.arrayFiliais[0].id !== idFilial)
    },
		bloqueiaEdicao() {
			let sessao = this.$root.sessao
			return this.isEdicao || this.isEdicaoToken || (sessao && sessao.id === this.obj.id)
		},
		bloqueiaEdicaoEmail() {
			let sessao = this.$root.sessao
			let obj = this.obj
			return (obj.perfil === this.VS_PERFIL_CLIENTE && obj.cadastroPivot) || this.isEdicao || this.isEdicaoToken || (sessao && sessao.id === obj.id)
		},
		async cpfCnpjLiberadoInsert() {
			let ok = true
			let obj = this.obj
			let sessao = this.$root.sessao

			if (!this.isEdicao && sessao && obj.perfil === this.VS_PERFIL_CLIENTE) {
				let query = `idContratante=${sessao.id}`

				if (sessao.perfil !== this.VS_PERFIL_CONTRATANTE) {
					query = `idContratante=${sessao.contratante.id}&idFilial=${(sessao.perfil === this.VS_PERFIL_FILIAL) ? sessao.id : sessao.filial.id}`
				}

				query += `&cpfCnpj=${this.$vsLimpaFormatacao(obj.cpfCnpj)}&cadastroPivot=true`

				let resp = await this.$vsApiRest("GET", `/usuarios/clientes/?${query}`)

				if (resp.status === 200 && resp.data.length) {
					this.$vsNotificacao(`CPF/CNPJ: ${this.$vsFormataCpfCnpj(obj.cpfCnpj)} já cadastrado`, "warning")
					ok = false
				}
			}
			return ok
		},
  }
  // #endregion
}
</script>
