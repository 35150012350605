<template>
  <b-row>
    <b-col class="p-0 text-center">
      <div class="cabecalho cor-azul text-nowrap d-flex justify-content-between">
        <!-- #region esquerda -->
          <div style="cursor: pointer;" @click="$root.mostraMenu = !$root.mostraMenu">
            <div style="font-size: 2em">
              <b-icon icon="list" scale="1.6" />
            </div>
            <div class="pl-1">Menu</div>
          </div>
          <!-- #endregion -->

        <!-- #region centro -->
        <div>
          <div class="h1"><span class="cor-azul">post</span><span class="cor-laranja">air</span></div>
          <span class="h4 font-weight-bold" v-if="isPerfilOperacional()">{{ $root.sessao.filial.nome }}</span>
					<span class="h4 font-weight-bold" v-else>&nbsp;</span>
        </div>
        <!-- #endregion -->

        <!-- #region direita -->
        <div class="text-dark d-flex justify-content-between">
					<div class="text-right">
						<span class="h4 font-weight-bold d-none d-md-inline">{{ $root.sessao.primeiroNome }}&nbsp;&nbsp;</span><br>
						<span class="h5 d-none d-md-inline">{{ $root.sessao.cargo }}&nbsp;&nbsp;</span>
					</div>
					<div>
						<b-img v-if="![VS_PERFIL_CONTRATANTE, VS_PERFIL_CLIENTE].includes($root.sessao.perfil)" rounded="circle" alt="Foto do usuário" :src="$vsDefineFoto($root.sessao.fotoBase64)" width="70" height="70" />

						<!-- #region perfil para editar -->
						<b-dropdown v-else ref="perfilDropDown" variant="link" toggle-class="text-decoration-none" no-caret right>
							<template #button-content>
								<b-img rounded="circle" alt="Foto do usuário" :src="$vsDefineFoto($root.sessao.fotoBase64)" width="70" height="70" />
							</template>
							<b-dropdown-form class="text-center text-nowrap">
								<b-button type="button" variant="success" to="/meus-dados" class="text-white" @click="$refs.perfilDropDown.hide()">Meus dados</b-button>
							</b-dropdown-form>
							<b-dropdown-divider />
							<b-dropdown-form class="text-center">
								<router-link to="/logout" style="font-size: large; color: #000">
									<b-icon icon="power" scale="1.2" />&nbsp;&nbsp;Sair
								</router-link>
							</b-dropdown-form>
						</b-dropdown>
						<!-- #endregion -->
					</div>
        </div>
        <!-- #endregion -->
      </div>
    </b-col>
  </b-row>
</template>

<script>
export default {
	// #region configs
  name: "vs-cabecalho",
	// #endregion

	// #region metodos
  methods: {
    isPerfilOperacional() {
      let operacionais = [this.VS_PERFIL_GERENTE, this.VS_PERFIL_OPERADOR]
      return this.$root.sessao.filial && operacionais.includes(this.$root.sessao.perfil)
    },
  }
	// #endregion
}
</script>
