<template>
  <b-row>
    <b-col class="d-none d-lg-block nuvem-direita"></b-col>
    <b-col class="text-center h5">
      <div class="d-none d-md-block mt-5 pt-4"></div>
      <div class="d-none d-md-block mt-5"></div>
      <div class="d-none d-block mt-5"></div>

      <img class="animacao-logo" src="@/assets/img/logo.png">

      <!-- #region formulario com efeito -->
      <b-form class="mt-4 mx-3 animacao-login" @submit.prevent="entrar">
        <b-row align-h="center">
          <b-col md="7" lg="12">
						<div class="mb-4 rounded p-2" v-if="mostraErro" style="color: #856404; background-color: #fff3cd;">
							Seu cadastro ainda não foi validado, <a class="text-info" @click.prevent="reenviaEmail()">clique aqui</a> para iniciar o processo de ativação
						</div>
            <b-form-group>
              <b-form-input class="form-input-cinza" type="text" v-model="form.email" placeholder="Email" />
            </b-form-group>
          </b-col>

          <b-col md="7" lg="12">
            <b-form-group>
              <b-input-group>
                <b-form-input class="form-input-cinza" :type="tipoSenha" v-model="form.senha" placeholder="Senha" />
                <b-icon id="iconeSenha" class="olho-senha" scale="1.5" :icon="iconeSenha" @click="mostrarSenha()" />
              </b-input-group>
              <b-tooltip target="iconeSenha" triggers="hover">
                Mostrar/Ocultar senha
              </b-tooltip>
            </b-form-group>
          </b-col>

          <b-col md="7" lg="12" class="text-left mb-4">
            <b-form-checkbox size="lg" v-model="form.isConectado" @change="salvarLogin()" switch>
              <span style="cursor: pointer;">Continuar conectado</span>
            </b-form-checkbox>
          </b-col>

          <b-col md="7" lg="12" class="mb-4">
            <button text-variant="none" type="submit" class="btn-azul">Entrar</button>
          </b-col>

          <b-col md="7" lg="12">
            <a @click.prevent="emailEsqueciSenha()">Esqueci a senha</a>
          </b-col>
					<b-col md="7" lg="12" class="mt-4">
            <router-link class="text-info" to="/cliente">Cliente, conclua seu cadastro aqui</router-link>
          </b-col>
					<b-col v-if="$vsAmbienteTeste() || $vsAmbienteDesenvolvimento()" md="7" lg="12" class="mt-5">
            <b-form-checkbox size="lg" v-model="$root.baseProducaoSimulada" switch>
              <span style="cursor: pointer;">Simular base da produção <small>(ambiente de desenvolvimento/testes)</small></span>
            </b-form-checkbox>
          </b-col>
        </b-row>
      </b-form>
			<!-- #endregion -->
    </b-col>
    <b-col class="d-none d-lg-block nuvem-esquerda"></b-col>
  </b-row>
</template>

<script>
export default {
	// #region configs
  name: "login",
  path: "/",
	// #endregion

	// #region dados
  data() {
    return {
      form: {
        email: "",
        senha: "",
        isConectado: false,
      },
      verSenha: false,
      tipoSenha: "password",
      iconeSenha: "eye",
			mostraErro: false,
    }
  },
	// #endregion

	// #region triggers
  mounted() {
    let form = this.form
    let login = this.$vsRecuperaLogin()

    if (login) {
      form.email = login.email
      form.senha = login.senha
      form.isConectado = login.isConectado
    }
  },
	// #endregion

	// #region metodos
  methods: {
    entrar() {
      if (!this.validacoes()) {
        return
      }
      this.logar()
    },
		async logar() {
			let resp = await this.$vsApiRest("POST", "/login", this.form)
			this.mostraErro = (resp.status === 401 && resp.data.preCadastro)
			
			if (resp.status === 200) {
				this.$vsSalvaLogin(this.form)
				this.$vsLogin(resp.data)
			}
    },
    validacoes() {
      let ok = true
      let form = this.form

      if (!this.$vsEmailValido(form.email)) {
        ok = false
        this.$vsNotificacao("E-mail inválido", "warning")
      }
      if (!this.$vsSenhaValida(form.senha)) {
        ok = false
        this.$vsNotificacao("Senha inválida (deve conter ao menos 8 caracteres, uma letra minúscula e um número)", "warning")
      }
      return ok
    },
    mostrarSenha() {
      this.verSenha = !this.verSenha
      this.iconeSenha = this.verSenha ? "eye-slash" : "eye"
      this.tipoSenha = this.verSenha ? "text" : "password"
    },
    salvarLogin() {
      this.$vsSalvaLogin(this.form)
    },
		async reenviaEmail() {
			let form = this.form

			this.mostraErro = false

			if (!form.email.trim().length) {
				this.$vsNotificacao("Informe o email", "warning")
				return
			}

			let query = `email=${form.email.trim()}`
			let resp = await this.$vsApiRest("GET", `/usuarios/email-ativacao-cadastro?${query}`, this.form)

			if (resp.status === 200) {
				this.$vsNotificacao("Acesse o email informado para finalizar e ativar seu cadastro", "info")
			}
		},
		async emailEsqueciSenha() {
			let email = this.form.email.trim()

			if (email.length === 0) {
				this.$vsNotificacao("Informe o email para prosseguir", "warning")
				return
			}
			if (!this.$vsEmailValido(email)) {
				this.$vsNotificacao("Email inválido", "warning")
				return
			}

			let query = `email=${email}`
			let resp = await this.$vsApiRest("GET", `/usuarios/email-esqueci-senha/?${query}`)
			
			if (resp.status === 200) {
				this.$vsNotificacao("Alteração de senha solicitada com sucesso, acesse o email informado para finalizar o processo", "info")
			}
		},
  },
	// #endregion
}
</script>
