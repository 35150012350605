<template>
  <div class="text-center">
		<div class="titulo">
			<h1>Nova senha</h1>
		</div>

    <div class="h5 d-flex justify-content-center">
      <!-- #region formulario com efeito -->
      <b-form class="mt-4 mx-3" @submit.prevent="novaSenha">
        <b-row align-h="center">
          <b-col md="7" lg="12">
            <b-form-group>
              <b-input-group>
                <b-form-input class="form-input-cinza" :type="tipoSenha" v-model="form.senha" placeholder="Senha" />
                <b-icon id="iconeSenha" class="olho-senha" scale="1.5" :icon="iconeSenha" @click="mostrarSenha()" />
              </b-input-group>
              <b-tooltip target="iconeSenha" triggers="hover">
                Mostrar/Ocultar senha
              </b-tooltip>
            </b-form-group>
          </b-col>

					<b-col md="7" lg="12">
            <b-form-group>
              <b-input-group>
                <b-form-input class="form-input-cinza" :type="tipoSenha" v-model="form.confirmacaoSenha" placeholder="Senha" />
                <b-icon id="iconeSenha" class="olho-senha" scale="1.5" :icon="iconeSenha" @click="mostrarSenha()" />
              </b-input-group>
              <b-tooltip target="iconeSenha" triggers="hover">
                Mostrar/Ocultar senha
              </b-tooltip>
            </b-form-group>
          </b-col>

					<b-col md="7" lg="12" class="mb-4">
            <button text-variant="none" type="submit" class="btn-azul">Entrar</button>
          </b-col>
        </b-row>
      </b-form>
			<!-- #endregion -->
		</div>
  </div>
</template>

<script>
export default {
  // #region configs
  name: "nova-senha",
  path: "/nova-senha",
  requireAuth: false,
  // #endregion

  // #region dados
  data() {
    return {
      form: {
				token: this.$route.query.token,
				senha: "",
				confirmacaoSenha: "",
			},
			verSenha: false,
      tipoSenha: "password",
      iconeSenha: "eye",
    }
  },
  // #endregion

  // #region metodos
  methods: {
		mostrarSenha() {
      this.verSenha = !this.verSenha
      this.iconeSenha = this.verSenha ? "eye-slash" : "eye"
      this.tipoSenha = this.verSenha ? "text" : "password"
    },
		async novaSenha() {
			let form = this.form

			if (!this.$vsSenhaValida(form.senha)) {
				this.$vsNotificacao("Senha inválida (deve conter ao menos 8 caracteres, uma letra minúscula e um número)", "warning")
				return
			}
			if (form.senha !== form.confirmacaoSenha) {
				this.$vsNotificacao("Senhas não conferem", "warning")
				return
			}

			let resp = await this.$vsApiRest("PATCH", "/usuarios/alteracao-senha", form)
        
			if (resp.status === 200) {
				this.$vsNotificacao("Senha alterada com sucesso, faça seu login", "info")
				this.$router.push("/")
      }
		},
  }
  // #endregion
}
</script>
