<template>
  <div>
		<div class="titulo">
			<h1>Cliente</h1>
			<h4>Ativação de Cadastro de cliente</h4>
		</div>

    <div class="h5">
			
		</div>
  </div>
</template>

<script>
export default {
  // #region configs
  name: "cadastro-ativacao",
  path: "/cadastro-ativacao",
  requireAuth: false,
  // #endregion

	// #region triggers
	mounted() {
		this.ativacao()
	},
	// #endregion

  // #region metodos
  methods: {
		async ativacao() {
			let resp = await this.$vsApiRest("PATCH", `/usuarios/ativacao-cadastro/${this.$route.query.token}`)
			
			if (resp.status === 200) {
				this.$vsNotificacao("Cadastro ativado com sucesso, faça seu login", "success")
				this.$router.push("/")
			}
		},
  }
  // #endregion
}
</script>
