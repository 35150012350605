<template>
  <div>
    <h1 class="titulo">Gerador de senhas</h1>

    <form @submit.prevent="crifraSenha()">
			<!-- #region dados basicos -->
      <b-form-row>
        <b-col md="4">
          <b-form-group style="color: transparent;" label="Senha" label-cols="12" label-size="lg">
              <b-input-group>
                <b-form-input class="form-input-cinza" :type="tipoSenha" v-model="senha" placeholder="Senha" />
                <b-icon id="iconeSenha" class="olho-senha" scale="1.5" :icon="iconeSenha" @click="mostrarSenha()" />
              </b-input-group>
              <b-tooltip target="iconeSenha" triggers="hover">
                Mostrar/Ocultar senha
              </b-tooltip>
          </b-form-group>
        </b-col>
			</b-form-row>
			<!-- #endregion -->
      
			<!-- #region opcoes -->
			<b-form-row>
				<b-col md="2">
          <b-button class="btn-azul" type="submit">Criptografar</b-button>
        </b-col>
			</b-form-row>
			<!-- #endregion -->
    </form>

		<div v-if="senhaCifrada.length" class="mt-5 h5">
			Senha cifrada:
			<a class="text-secondary font-weight-bold" @click.prevent="copiaClipboard()" v-b-tooltip.hover.top="'Copiar para o clipboard'">
				<b-icon icon="clipboard" />&nbsp;{{ senhaCifrada }}
			</a>
		</div>
  </div>
</template>

<script>
export default {
  // #region configs
  name: "gerador-senha",
  path: "/gerador-senha",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
      senha: "",
			senhaCifrada: "",
			verSenha: false,
      tipoSenha: "password",
      iconeSenha: "eye",
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_SUPERADMIN])
  },
  // #endregion

  // #region metodos
  methods: {
		mostrarSenha() {
      this.verSenha = !this.verSenha
      this.iconeSenha = this.verSenha ? "eye-slash" : "eye"
      this.tipoSenha = this.verSenha ? "text" : "password"
    },
    async crifraSenha() {
			this.senhaCifrada = ""

			if (!this.validacoes()) {
        return
      }

			let req = {
				senha: this.senha.trim()
			}
      let resp = await this.$vsApiRest("POST", "/cifra-senha", req)
      
			if (resp.status === 200) {
        this.senhaCifrada = resp.data
      }
    },
    validacoes() {
      let ok = true

      if (!this.$vsSenhaValida(this.senha)) {
        ok = false
        this.$vsNotificacao("Senha inválida (deve conter ao menos 8 caracteres, uma letra minúscula e um número)", "warning")
      }
      return ok
    },
		copiaClipboard() {
			navigator.clipboard.writeText(this.senhaCifrada)
			this.$vsNotificacao("Senha copiada para a área de transferência (clipboard)", "success")
		},
  },
  // #endregion
}
</script>