<template>
  <div>
		<!-- #region modais -->
    <!-- #region mais de uma filial -->
    <b-modal title="Qual seu ponto de atendimento hoje?"
		  :visible="exibeModal"  
			hide-header-close 
			no-close-on-backdrop 
			no-close-on-esc 
			no-close>
			<!-- #region dados -->
			<div class="h5 modal-compactado">
				<b-form-radio class="mb-2" v-for="obj in filiais" :key="obj.id" name="filial" v-model="filialEcolhida" :value="obj" size="lg">
					<span style="cursor: pointer;">{{ obj.nome }}</span>
				</b-form-radio>
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <span v-if="exibeErro" class="text-danger">Escolha seu ponto de atendimento</span>
          <b-button type="button" class="float-right ml-2" variant="info" @click="defineFilial(hide);">Escolher
          </b-button>
        </div>
      </template>
			<!-- #endregion -->
    </b-modal>
    <!-- #endregion -->

		<!-- #region mensagens -->
		<b-modal v-for="(obj, i) in mensagens"
		  :key="obj.id"
			:id="`modalMensagem${i}`"
			title="Mensagem"
			size="lg" 
			hide-header-close 
			no-close-on-esc 
			no-close-on-backdrop
			visible>
			<!-- #region dados -->
			<div class="h5 modal-compactado" style="min-height: 300px !important;">
				<div class="mb-5 font-weight-bold">
					<b-icon v-if="obj.tipo == 1" icon="exclamation-octagon-fill" class="text-info"/>
					<b-icon v-if="obj.tipo == 2" icon="exclamation-octagon-fill" class="text-warning"/>
					<b-icon v-if="obj.tipo == 3" icon="exclamation-octagon-fill" class="text-danger"/>
					{{ obj.titulo }}
				</div>
				<div class="mb-4" style="white-space: pre-line;">{{ obj.corpo }}</div>
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="float-right">
					<b-button type="button" variant="primary" @click="marcaMensagemLida(i, obj)">
						<span class="font-weight-bold">OK</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
  </div>
</template>

<script>
export default {
  // #region configs
  name: "home",
  path: "/home",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
			OPT_TODOS: 0,
      filiais: [],
      filialEcolhida: null,
      exibeModal: false,
      exibeErro: false,
			mensagens: [],
    }
  },
  // #endregion

  // #region triggers
  created() {
		this.baixaMensagens()
  },
  // #endregion

  // #region metodos
  methods: {
		async baixaMensagens() {
			let sessao = this.$root.sessao
			let hoje = new Date().toISOString().substring(0, 10)
			let query = `idUsuario=${sessao.id}&perfil=${sessao.perfil}`

			query += `&dataInicial=${this.$vsConverteDataUTC(`${hoje}T00:00:00`)}`
			query += `&dataFinal=${this.$vsConverteDataUTC(`${hoje}T23:59:59`)}`

			let resp = await this.$vsApiRest("GET", `/mensagens/nao-lidas?${query}`)
        
			if (resp.status === 200) {
        this.mensagens = resp.data
				this.validacoes()
      }
		},
		async marcaMensagemLida(index, obj) {
			let req = {
				idMensagem: obj.id,
				idUsuario: this.$root.sessao.id,
			}

			let resp = await this.$vsApiRest("POST", "/mensagens/lida", req)
      
			if (resp.status === 200) {
        this.$bvModal.hide(`modalMensagem${index}`)
				this.mensagens.splice(index, 1)
				this.validacoes()
      }
		},
		validacoes() {
			if (this.mensagens.length) {
				return
			}

			let ok = true
			let sessao = this.$root.sessao
			let isOperacional = [this.VS_PERFIL_GERENTE, this.VS_PERFIL_OPERADOR].includes(sessao.perfil)

			// configuracoes
			if (isOperacional) {
				this.filiais = sessao.usuariosVinculados.filter(el => el.isAtivo && !el.isExcluido && el.perfil === this.VS_PERFIL_FILIAL)

				if (this.filiais.length === 1) {
					sessao.filial = this.filiais[0]
				}
			}
			if (sessao.perfil !== this.VS_PERFIL_CONTRATANTE && sessao.perfil !== this.VS_PERFIL_CLIENTE) {
				sessao.contratante = sessao.usuariosVinculados.find(el => el.perfil === this.VS_PERFIL_CONTRATANTE)

				// validacoes
				if (!sessao.contratante || (isOperacional && this.filiais.length === 0)) {
					this.$vsNotificacao("Você precisa estar vinculado(a) a um contratante e uma filial, por gentileza contate seu superior para corrigir seu cadastro", "warning")
					this.$vsLogout()
					ok = false
				}
				if (isOperacional && !sessao.filial && this.filiais.length > 1) {
					this.exibeModal = true
					ok = false
				}
			}
			if (ok) {
				this.$vsSalvaSessao(sessao)
				this.definePaginaInicial()
			}
		},
    defineFilial(fecharModal) {
      if (!this.filialEcolhida) {
        this.exibeErro = true
        return
      }
      fecharModal()
      this.$root.sessao.filial = this.filialEcolhida
      this.$vsSalvaSessao(this.$root.sessao)
      this.definePaginaInicial()
    },
    definePaginaInicial() {
      let home = "/home"
      let sessao = this.$root.sessao

      // redireciona uma unica vez e evita 
      // conflito de roteamento/redundancia
      if (!sessao.home) {
        switch (sessao.perfil) {

          case this.VS_PERFIL_SUPERADMIN:
            home = "/transportadoras"
            break

          case this.VS_PERFIL_CONTRATANTE:
          case this.VS_PERFIL_FILIAL:
          case this.VS_PERFIL_GERENTE:
            home = "/relatorio-movimentacoes"
            break

          case this.VS_PERFIL_OPERADOR:
            home = "/atendimento"
            break

					case this.VS_PERFIL_CLIENTE:
            home = "/meus-envios"
            break
        }

        sessao.home = home
        this.$vsSalvaSessao(sessao)

        if (home !== "/home") {
          this.$router.push(home)
        }
      }
    },
  },
  // #endregion
}
</script>
