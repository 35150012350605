<template>
  <div>
    <!-- #region titulo -->
    <h1 class="titulo d-flex justify-content-start align-items-baseline">
			<span class="col-6">
				Atendimento&nbsp;
				<span class="h4">{{ nomePagador() }}</span>&nbsp;
				<button class="btn-verde" style="width: 150px; height: 40px;" @click.prevent v-b-toggle.resumoPostagens>Exibir Resumo</button>
				<button v-if="!config.atendimentoVencido" class="btn-desabilitado" style="width: 200px; height: 40px;" @click.prevent="verificaAtendimento()">Limpar Atendimento</button>
			</span>

			<!-- #region pre-postagem -->
			<span class="pl-5 d-flex justify-content-start">
				<span>
					<b-input-group>
						<b-form-input ref="campoEtiquetaPrePostagem" type="text" v-model="config.etiquetaPrePostagem"autocomplete="off" placeholder="Pré Postagem" :disabled="criterioBloqueioEdicao()"/>
						<template #append>
							<b-button variant="primary" type="button" @click="buscaPrePostagem()" :disabled="criterioBloqueioEdicao()">
								<b-icon scale="1" icon="search" v-b-tooltip.hover title="Pesquisar" />
							</b-button>
						</template>
					</b-input-group>
				</span>
				<span v-if="volumePrePostado" class="pl-4 h5">
					<div class="">Serviço: {{ volumePrePostado.servicoDescricao }} Prazo: {{ volumePrePostado.prazoDias }} dias</div>
					<div class="text-danger font-weight-bold">Valor estimado R$ {{ $vsFormataMoeda(volumePrePostado.valorFrete) }}</div>
				</span>
			</span>
			<!-- #endregion -->
    </h1>
    <!-- #endregion -->

    <!-- #region resumo -->
    <b-sidebar id="resumoPostagens" backdrop-variant="transparent" right backdrop no-header-close class="menu-lateral">
      <div class="px-3">
        <!-- #region cabecalho -->
        <div class="h3 text-center">Resumo</div>
        <div class="mb-2">
          <span>Atendimento:</span>
          <span class="badge badge-info float-right">{{ calculaOperacoesAtivas() }}</span>
        </div>
        <div class="h4 font-weight-bold">
          <span>Total</span>
          <span class="float-right">R$ {{ $vsFormataMoeda(calculaTotalFrete()) }}</span>
        </div>
        <div>--------------------------</div>
        <!-- #endregion -->

        <!-- #region corpo/postagens/operacoes -->
        <div class="mt-2 h5" v-for="(post, i) in postagem.operacoes" :key="i">
          <div v-if="!post.isCancelada && post.volumes.filter(el => !el.isCancelado).length">
            <div v-if="i > 0">--------------------------</div>
            <!-- #region remetente/destinatario -->
            <div class="mb-3">
              <div class="mb-0">
                <span>Operação #{{ post.indiceResumo }}</span>
                <span v-if="!config.atendimentoVencido" class="float-right">
                  <a @click.prevent="cancelaOperacao(i)" v-b-tooltip.hover title="Excluir operação">
                    <b-icon icon="trash" variant="danger" />
                  </a>
                </span>
              </div>
              <div>De: {{ post.remetente.nome }}</div>
              <div>Cep: {{ $vsFormataCep(post.remetente.endereco.cep) }}</div>
              <div>Para: {{ post.destinatario.nome }}</div>
              <div>Cep: {{ $vsFormataCep(post.destinatario.endereco.cep) }}</div>
              <div>Volumes: {{ calculaVolumesAtivos(post.volumes) }}</div>
            </div>
            <!-- #endregion -->

            <!-- #region volumes -->
            <div v-for="(vol, j) in post.volumes" :key="j" class="mb-4">
              <div v-if="!vol.isCancelado">
                <div>
                  <span>
                    <a @click.prevent="mostraDeclaracaoConteudo(postagem, vol)" v-b-tooltip.hover title="Declaração de conteúdo">
                      <b-icon icon="file-text" variant="secondary" />
                    </a>
										<a v-if="exibeAR(vol)" class="text-info" @click.prevent="imprimeAR(postagem, vol)" v-b-tooltip.hover title="Imprimir Aviso de Recebimento">
                      AR
                    </a>
                    Volume #{{ vol.indiceResumo }}
                  </span>
                  <span v-if="!config.atendimentoVencido" class="float-right">
                    <a @click.prevent="cancelaVolume(i, j)" v-b-tooltip.hover title="Excluir volume">
                      <b-icon icon="trash" variant="danger" />
                    </a>
                  </span>
                </div>
                <div>
									Rótulo:
									<a class="text-black" @click.prevent="imprimeEtiqueta(vol.etiqueta)" v-b-tooltip.hover title="Imprimir Rótulo">
										<b-icon icon="printer" variant="info"/> {{ vol.etiqueta }}
                  </a>
								</div>
                <div>
                  <span>Modal: {{ vol.servicoDescricao }}</span>
                  <span class="text-danger font-weight-bold" style="cursor: pointer;" v-if="vol.observacaoFrete.length" v-b-tooltip.hover :title="vol.observacaoFrete">!</span>
                </div>
                <div>Peso: <span class="float-right">{{ vol.peso }}g</span></div>
                <div>
                  Dimensões:
                  <span class="float-right">
                    {{ vol.altura }} X {{ vol.largura }} X {{ vol.comprimento }}cm
                  </span>
                </div>
                <div>
                  <span>Frete:</span>
                  <span class="float-right">R$ {{ $vsFormataMoeda(vol.valorFrete) }}</span>
                </div>
              </div>
            </div>
            <!-- #endregion -->
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region rodape -->
        <div>--------------------------</div>
        <div class="mb-4 h4 font-weight-bold">
          <span>Total</span>
          <span class="float-right">R$ {{ $vsFormataMoeda(calculaTotalFrete()) }}</span>
        </div>
        <!-- #endregion -->
      </div>
    </b-sidebar>
    <!-- #endregion -->

    <!-- #region atendimento -->
    <div class="accordion" role="tablist">
      <!-- #region cliente/pagador -->
      <div class="mb-3 bg-white p-2">
        <div class="mb-3 h4" v-b-toggle.boxPagador @click.prevent="gravaSaveState('boxPagador')" v-b-tooltip.hover
          title="Mostrar/Ocultar cadastro">
          <span class="h4" v-if="pagador.cadastroPivot" style="color: #007F7F;">Pagador Fixo</span>
					<span class="h4" v-else>Pagador</span>
        </div>
        <b-collapse id="boxPagador" visible accordion="sanfonaFluxo" role="tabpanel">
          <!-- #region dados basicos -->
          <vs-form-atendimento-pessoa 
						:config="config" 
						:obj="pagador" 
						:buscarCliente="true" 
						:objPostagem="postagem" />
          <!-- #endregion -->

          <!-- #region opcao remetente -->
          <div v-if="!config.atendimentoVencido && pagador.isAtivo" class="mb-3">
            <b-form-checkbox class="form-input-cinza" size="lg" v-model="config.isRemetente" @change="defineRemetente(true)" :disabled="criterioBloqueioEdicao()" switch>
              <span style="cursor: pointer">este pagador será o remetente da postagem</span>
            </b-form-checkbox>
          </div>
          <!-- #endregion -->

          <!-- #region contratos -->
          <b-row class="mb-3" v-if="!config.atendimentoVencido && pagador.isAtivo && pagador.contratos.length">
            <!-- #region encontrados contratante -->
            <b-col md="4" v-if="filtraContratosContratante().length" class="border-right borda-laranja">
							<div class="h5 cor-laranja font-weight-bold mb-3">Situação contratual Contratante</div>

							<div class="mb-3" v-for="cont in filtraContratosContratante()" :key="cont.id">
								<b-form-radio name="contrato" v-model="pagador.contratoSelecionado" size="lg" :value="cont" :disabled="desativaOpcaoContrato(cont)" @change="selecionaContrato(cont)">
									<span style="cursor: pointer;">
										{{ $vsListaContratos(cont.tipo).text }}
										<span :class="$vsListaContratosStatus(cont.status).class">
											<b-icon :icon="$vsListaContratosStatus(cont.status).icon" scale="1.3" /> {{ $vsListaContratosStatus(cont.status).text }}
										</span>
										<div>Vigência: {{ $vsFormataData(cont.dataInicio) }} à {{ $vsFormataData(cont.dataTermino) }}</div>
										<div v-if="cont.tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
											<div>
												Saldo limite: <span :class="defineCorSaldo(cont.saldoLimite)">R$ {{ $vsFormataMoeda(cont.saldoLimite) }}</span>
											</div>
											<div>
												Saldo utilizado: <span :class="defineCorSaldo(cont.saldoUtilizado)">R$ {{ $vsFormataMoeda(cont.saldoUtilizado) }}</span>
											</div>
										</div>
									</span>
								</b-form-radio>
							</div>
            </b-col>
            <!-- #endregion -->

						<!-- #region encontrados filial logada -->
            <b-col md="4" v-if="filtraContratosFilial().length" class="border-right borda-laranja">
							<div class="h5 cor-laranja font-weight-bold mb-3">Situação contratual Filial</div>

							<div class="mb-3" v-for="cont in filtraContratosFilial()" :key="cont.id">
								<b-form-radio name="contrato" v-model="pagador.contratoSelecionado" size="lg" :value="cont" :disabled="desativaOpcaoContrato(cont)" @change="selecionaContrato(cont)">
									<span style="cursor: pointer;">
										{{ $vsListaContratos(cont.tipo).text }}
										<span :class="$vsListaContratosStatus(cont.status).class">
											<b-icon :icon="$vsListaContratosStatus(cont.status).icon" scale="1.3" /> {{ $vsListaContratosStatus(cont.status).text }}
										</span>
										<div>Vigência: {{ $vsFormataData(cont.dataInicio) }} à {{ $vsFormataData(cont.dataTermino) }}</div>
										<div v-if="cont.tipo !== VS_CONTRATO_TIPO_FIDELIDADE">
											<div>
												Saldo limite: <span :class="defineCorSaldo(cont.saldoLimite)">R$ {{ $vsFormataMoeda(cont.saldoLimite) }}</span>
											</div>
											<div>
												Saldo utilizado: <span :class="defineCorSaldo(cont.saldoUtilizado)">R$ {{ $vsFormataMoeda(cont.saldoUtilizado) }}</span>
											</div>
										</div>
									</span>
								</b-form-radio>
							</div>
            </b-col>
            <!-- #endregion -->

            <!-- #region default -->
            <b-col md="4" class="mb-3">
						  <div class="h5 font-weight-bold mb-3">&nbsp;</div>
              <b-form-radio name="contrato" v-model="pagador.contratoSelecionado" :value="null" size="lg" @change="selecionaContrato(null)">
                <span style="cursor: pointer;">À vista</span>
              </b-form-radio>
            </b-col>
            <!-- #endregion -->
          </b-row>
          <!-- #endregion -->

          <!-- #region acoes -->
          <b-row v-if="!config.atendimentoVencido">
            <b-col md="6" />
            <b-col md="2">
              <button v-if="pagador.isAtivo" class="btn-azul" @click.prevent="cadastraPagador()">Avançar</button>
              <span v-else class="text-danger mb-5">Cliente/pagador está inativo no sistema e não poderá prosseguir com a postagem</span>
            </b-col>
          </b-row>
          <!-- #endregion -->
        </b-collapse>
      </div>
      <!-- #endregion -->

      <!-- #region remetente -->
      <div v-if="!config.isRemetente" class="mb-3 bg-white p-2">
        <div class="mb-3" v-b-toggle.boxRemetente @click.prevent="gravaSaveState('boxRemetente')" v-b-tooltip.hover title="Mostrar/Ocultar">
          <span class="h4">Remetente</span>
        </div>
        <b-collapse id="boxRemetente" accordion="sanfonaFluxo" role="tabpanel">
          <!-- #region dados basicos -->
          <vs-form-atendimento-pessoa 
						:config="config" 
						:obj="operacaoAtual().remetente" 
						:buscarRemetente="true"
						:objPostagem="postagem" />
          <!-- #endregion -->

          <!-- #region acoes -->
          <b-row v-if="!config.atendimentoVencido">
            <b-col md="2">
              <button type="button" class="btn-azul" @click="voltar('boxPagador')">Voltar</button>
            </b-col>
            <b-col md="4" />
            <b-col md="2">
              <button type="button" class="btn-azul" @click="cadastraRemetente()">Avançar</button>
            </b-col>
          </b-row>
          <!-- #endregion -->
        </b-collapse>
      </div>
      <!-- #endregion -->

      <!-- #region destinatario -->
      <div class="mb-3 bg-white p-2">
        <div class="mb-3" v-b-toggle.boxDestinatario @click.prevent="gravaSaveState('boxDestinatario')"
          v-b-tooltip.hover title="Mostrar/Ocultar">
          <span class="h4">Destinatário</span>
        </div>
        <b-collapse id="boxDestinatario" accordion="sanfonaFluxo" role="tabpanel">
          <!-- #region dados basicos com cubagem -->
          <vs-form-atendimento-pessoa 
						:config="config" 
						:obj="operacaoAtual().destinatario" 
						:exibeCubagem="true"
            :objRemetente="operacaoAtual().remetente" 
						:objPostagem="postagem" 
						:objPagador="pagador"
            :objVolume="volume"
						:objVolumePrePostado="volumePrePostado"/>
          <!-- #endregion -->

          <!-- #region acoes -->
          <b-row v-if="!config.atendimentoVencido">
            <b-col md="2">
              <button type="button" class="btn-azul" @click="voltar('boxRemetente')">Voltar</button>
            </b-col>
            <b-col md="2" />
            <b-col md="2">
							<button v-if="liberaNovaPostagem()" type="button" class="btn-verde" @click="novaOperacao()">
								<span v-if="config.isPrePostagem">Nova leitura</span>
								<span v-else>Novo destinatário</span>
							</button>
              <button v-else type="button" class="btn-desabilitado">
								<span v-if="config.isPrePostagem">Nova leitura</span>
								<span v-else>Novo destinatário</span>
							</button>
            </b-col>
            <b-col md="2" />
            <b-col md="2">
              <button type="button" class="btn-azul" @click="avancar('boxServicos')">Avançar</button>
            </b-col>
          </b-row>
          <!-- #endregion -->
        </b-collapse>
      </div>
      <!-- #endregion -->

      <!-- #region produtos/servicos (operacoes de balcao) -->
      <div class="mb-3 bg-white p-2">
        <div class="mb-3" v-b-toggle.boxServicos @click.prevent="gravaSaveState('boxServicos')" v-b-tooltip.hover title="Mostrar/Ocultar">
          <span class="h4">Produtos/Serviços</span>
        </div>
        <b-collapse id="boxServicos" accordion="sanfonaFluxo" role="tabpanel">
          <!-- #region dados basicos -->
          <form @submit.prevent="novaOperacaoBalcao()">
            <b-row class="mt-4">
              <b-col md="3">
                <b-form-group class="form-input-cinza" label="Cód. Operação" label-size="" label-cols-md="12">
									<model-select ref="campoCodigoOperacao" v-if="!config.atendimentoVencido" v-model="operacaoBalcao.codigo" :options="cadOperacoesBalcao" @input="defineOperacaoBalcao()"/>
									<b-select ref="campoCodigoOperacao" v-else disabled />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group class="form-input-cinza" label="Valor unitário" label-size="" label-cols-md="12">
                  <b-form-input v-if="!config.atendimentoVencido && operacaoBalcao.tipoValor === VS_OPERACAO_BALCAO_TIPO_VALOR_LIVRE" class="text-right" type="text" v-model="produtoServico.valorUnitario" @keyup="mascaraMoeda" />
                  <b-form-input v-else class="text-right" type="text" :value="$vsFormataMoeda(operacaoBalcao.valorMonetario)" disabled />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group class="form-input-cinza" label="Quantidade" label-size="" label-cols-md="12">
                  <b-form-input class="text-right" v-if="!config.atendimentoVencido && (operacaoBalcao.usaQuantidade || operacaoBalcao.categoria === VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO)" type="text" v-model="produtoServico.quantidade" @keyup="mascaraInteiro" />
                  <b-form-input class="text-right" v-else type="text" :value="produtoServico.quantidade" disabled />
                </b-form-group>
              </b-col>

              <b-col md="3">
                <b-form-group class="form-input-cinza" label="Nº encomenda" label-size="" label-cols-md="12">
                  <b-form-input ref="campoNrEncomenda" v-if="!config.atendimentoVencido && operacaoBalcao.usaNumeroEncomenda" type="text" v-model="produtoServico.numeroEncomenda" />
                  <b-form-input ref="campoNrEncomenda" v-else type="text" disabled />
                </b-form-group>
              </b-col>

              <b-col v-if="!config.atendimentoVencido" md="2">
                <button type="submit" class="btn-laranja">Adicionar</button>
              </b-col>
            </b-row>
          </form>
          <!-- #endregion -->

          <!-- #region listagem -->
          <b-row>
            <b-col>
              <b-table class="text-nowrap h5 mt-4" 
								id="tabelaOperacoes"
                :items="postagem.produtosServicos" 
								:fields="cabTabelaOperacoesBalcao"
								small 
								responsive 
								hover>
                <!--===================================================================================
                  mapa de colunas (atua em campos sem tratamento) 
                  se precisar debugar ou visualizar os dados para ajustes basta descomentar...

                  obj.index = indice da linha
                  obj.field = nome do campo/coluna/chave json
                  obj.item  = objeto json bruto
                  obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
                ====================================================================================-->
                <!--<template #cell()="obj">
                    {{ obj.field }}
                    {{ obj.item }}
                  </template>-->

                <!--===================================================================================
                  celulas que precisam de tratamento, 
                  sobrescrevem mapa de colunas pela identificacao da chave
                  e nao precisam estar em ordem/sequencia
                ====================================================================================-->
                <template #cell(grupo)="obj">
                  {{ nomeGrupoOperacaoBalcao(obj.item) }}
                </template>

                <template #cell(categoria)="obj">
                  {{ nomeCategoriaOperacaoBalcao(obj.item) }}
                </template>

                <template #cell(codigo)="obj">
                  {{ codigoOperacaoBalcao(obj.item) }}
                </template>

                <template #cell(valorUnitario)="obj">
                  {{ $vsFormataMoeda(obj.value) }}
                </template>

                <template #cell(valorTotal)="obj">
                  {{ $vsFormataMoeda(obj.value) }}
                </template>

                <template #cell(opcoes)="obj">
                  <div v-if="!config.atendimentoVencido" class="pr-5">
                    <button type="button" class="btn-vermelho ml-3" style="width: 100px; height: 35px;"
                      @click="excluiOperacaoBalcao(obj.index)">Excluir</button>
                  </div>
                </template>
              </b-table>
            </b-col>
          </b-row>
          <!-- #endregion -->

          <!-- #region acoes -->
          <b-row v-if="!config.atendimentoVencido" class="mt-3">
            <b-col md="2">
              <button type="button" class="btn-azul" @click="voltar('boxDestinatario')">Voltar</button>
            </b-col>
            <b-col md="4" />
            <b-col md="2">
              <button type="button" class="btn-azul" @click="avancar('boxPagto')">Avançar</button>
            </b-col>
          </b-row>
          <!-- #endregion -->
        </b-collapse>
      </div>
      <!-- #endregion -->

      <!-- #region pagamento e finalizacao -->
      <div class="mb-3 bg-white p-2">
        <div class="mb-3" v-b-toggle.boxPagto @click.prevent="gravaSaveState('boxPagto')" v-b-tooltip.hover title="Mostrar/Ocultar">
          <span class="h4">Pagamento</span>
        </div>
        <b-collapse id="boxPagto" accordion="sanfonaFluxo" role="tabpanel">
          <b-row class="h5">
              <b-col md="5" class="mt-3">
                  <!-- #region com contrato -->
                  <div v-if="pagador.contratoSelecionado" class="mr-3">
                      <div>{{ $vsListaContratos(pagador.contratoSelecionado.tipo).text }} (contrato)</div>
                      <div class="small mt-3 mb-2">
                          <span>Vigência:</span>
                          <span class="float-right">
                              {{ $vsFormataData(pagador.contratoSelecionado.dataInicio) }} à {{ $vsFormataData(pagador.contratoSelecionado.dataTermino) }}
                          </span>
                      </div>

                      <!-- #region fidelidade -->
                      <div class="mt-4" v-if="pagador.contratoSelecionado.tipo === VS_CONTRATO_TIPO_FIDELIDADE">
                          <div v-for="pag in $vsListaPagamentos(0, false, true)" :key="pag.value" class="mb-2">
                              <b-form-radio name="pagamento" size="lg" inline v-model="postagem.formaPagamento.metodo" :value="pag.value">
                                  <span class="text-nowrap" style="cursor: pointer;">&nbsp;<b-icon :icon="pag.icon" scale="1.3" />&nbsp;&nbsp;{{ pag.text }}</span>
                              </b-form-radio>
                          </div>
                      </div>
                      <!-- #endregion -->

                      <!-- #region outros -->
                      <div v-else>
                          <div class="small mb-2">
                              Saldo limite:
                              <span class="float-right">
                                  <span :class="defineCorSaldo(pagador.contratoSelecionado.saldoLimite)">
                                      R$ {{ $vsFormataMoeda(pagador.contratoSelecionado.saldoLimite) }}
                                  </span>
                              </span>
                          </div>
                          <div class="small">
                              Saldo utilizado:
                              <span class="float-right">
                                  <span :class="defineCorSaldo(pagador.contratoSelecionado.saldoUtilizado)">
                                      R$ {{ $vsFormataMoeda(pagador.contratoSelecionado.saldoUtilizado) }}
                                  </span>
                              </span>
                          </div>
                      </div>
                      <!-- #endregion -->
                  </div>
                  <!-- #endregion -->

                  <!-- #region sem contrato -->
                  <div v-else class="mr-3">
                      <div>À vista</div>
                      <div class="mt-4 pl-1">
                          <div v-for="pag in $vsListaPagamentos(0, false, false)" :key="pag.value" class="mb-2 text-nowrap">
                              <b-form-radio name="pagamento" size="lg" inline v-model="postagem.formaPagamento.metodo" :value="pag.value">
                                  <span class="text-nowrap" style="cursor: pointer;">&nbsp;<b-icon :icon="pag.icon" scale="1.3" />&nbsp;&nbsp;{{ pag.text }}</span>
                              </b-form-radio>
                          </div>
                      </div>
                  </div>
                  <!-- #endregion -->

                  <!-- #region Desconto para pagto a vista -->
                  <div v-if="!pagador.contratoSelecionado" class="mt-4 mb-3 mr-3">
                      <b-form-row>
                          <b-col md="8">
                              <b-form-group class="form-input-cinza" label="Desconto" label-cols="12" label-size="lg">
                                  <model-select v-model="operacaoPercentual" :options="comboPercentual" @input="aplicaOperacaoPercentual()" />
                              </b-form-group>
                          </b-col>
                          <b-col md="4">
                              <b-form-group class="form-input-cinza" label="% de desconto" label-cols="12" label-size="lg">
                                  <b-form-input class="text-right" type="text" v-model="valorOperacaoPercentual" @keyup="mascaraMoeda($event); calculaTotalAPagar()" :disabled="liberaDescontoPercentual()" />
                              </b-form-group>
                          </b-col>
                      </b-form-row>
                  </div>
                  <!-- #endregion -->

                  <!-- #region totais -->
                  <span v-if="calculaTotalAPagar()"/>
                  <div class="mt-4 mb-3 mr-3">
                      <span>Frete</span>
                      <span class="float-right">R$ {{ $vsFormataMoeda(calculaTotalFrete()) }}</span>
                  </div>
                  <div class="mb-3 mr-3">
                      <span>Produtos/Serviços</span>
                      <span class="float-right">R$ {{ $vsFormataMoeda(calculaTotalOperacoesBalcao()) }}</span>
                  </div>
                  <div class="mb-2 mr-3 h4 font-weight-bold">
                      <span>Total</span>
                      <span class="float-right">R$ {{ $vsFormataMoeda(postagem.formaPagamento.valorBruto) }}</span>
                  </div>
                  <div v-if="postagem.formaPagamento.valorDescontoPercentual" class="mb-2 mr-3 h4 font-weight-bold text-danger">
                      <span>Desconto ({{ $vsFormataMoeda(postagem.formaPagamento.valorDescontoPercentual) }}%)</span>
                      <span class="float-right">R$ -{{ $vsFormataMoeda(postagem.formaPagamento.valorDescontoMonetario) }}</span>
                  </div>
                  <div v-if="postagem.formaPagamento.valorDescontoPercentual" class="mb-2 mr-3 h4 font-weight-bold">
                      <span>Total com desconto</span>
                      <span class="float-right">R$ {{ $vsFormataMoeda(postagem.formaPagamento.valorTotal) }}</span>
                  </div>
                  <!-- #endregion -->

                  <!-- #region calculo de troco -->
                  <div v-if="postagem.formaPagamento.metodo === VS_PAGAMENTO_DINHEIRO">
                      <div class="mb-1 pt-1">
                          <span>Valor pago</span>
                          <span class="float-right w-50">
                              <b-form-input class="text-right" type="text" size="lg" v-model="config.valorPagtoDinheiro" @keyup="mascaraMoeda" />
                          </span>
                      </div>
                      <div class="mb-1">&nbsp;</div>
                      <div class="mr-3 h4 font-weight-bold">
                          <span>Troco</span>
                          <span class="float-right">R$ {{ $vsFormataMoeda(calculaTroco()) }}</span>
                      </div>
                  </div>
                  <!-- #endregion -->
              </b-col>
          </b-row>

          <!-- #region acoes-->
          <b-row>
            <b-col md="2">
              <button v-if="!config.atendimentoVencido" type="button" class="btn-azul" @click="voltar('boxServicos')">Voltar</button>
            </b-col>
            <b-col md="4" />
            <b-col md="3">
              <button v-if="pagador.isAtivo && config.isLiberada" type="button" class="btn-azul" @click="finalizar()">Finalizar Atendimento</button>
              <span v-else-if="!pagador.isAtivo" class="text-danger">Cliente/pagador está inativo no sistema e não poderá prosseguir com a postagem</span>
              <span v-else-if="!config.isLiberada" class="text-danger">Total ultrapassa o limite do contrato escolhido</span>
            </b-col>
          </b-row>
          <!-- #endregion -->
        </b-collapse>
      </div>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->

    <!-- #region recibo oculto (usado apenas para impressao) -->
    <div class="d-none">
      <vs-recibo-postagem v-if="recibo.filial"
				id="impressaoRecibo" 
				:filial="recibo.filial" 
				:operador="recibo.operador"
        :postagem="recibo.postagem"
				:pagador="recibo.pagador" />
     </div>
    <!-- #endregion -->

    <!-- #region modais -->
		<!-- #region abertura caixa -->
    <b-modal id="modalCaixa" title="É necessário informar a abertura de caixa">
      <div class="h5">
        <span>Saldo atual</span>
        <span class="float-right">
          <b-form-input class="text-right" type="text" v-model="caixa.saldoAbertura" size="lg" @keyup="mascaraMoeda" />
        </span>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="abreCaixa()">Confirmar</b-button>
          <b-button type="button" class="float-right ml-2" variant="secondary" @click="hide()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
		<!-- #endregion -->

		<!-- #region cancelamento atendimento -->
    <b-modal id="modalCancelamento" title="Cancelamento">
      <div class="h5">
        <div class="mb-3 text-danger font-weight-bold">Existem Operações em aberto</div>
        <div class="mb-3">Deseja continuar com o cancelamento do atendimento?</div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="cancelaAtendimento()">Confirmar</b-button>
          <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
		<!-- #endregion -->

		<!-- #region declaracao conteudo -->
    <b-modal id="modalDeclaracaoConteudo" :title="`Formulário Declaração de Conteúdo para o rótulo ${declaracaoConteudo.etiqueta}`" size="lg">
      <div class="h5">
        <!-- #region cadastro -->
        <div class="mb-3">Máximo {{ VS_MAX_ITENS_DECLARACAO_CONTEUDO }} itens</div>
        <form @submit.prevent="gravaDeclaracaoConteudo()">
          <b-form-row>
            <b-col md="5">
              <b-form-group class="form-input-cinza" label="Descrição" label-size="" label-cols-md="12">
                <b-form-input ref="descricaoDeclaracaoConteudo" type="text" v-model="declaracaoConteudoItem.descricao" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Qtd" label-size="" label-cols-md="12">
                <b-form-input type="text" class="text-right" v-model="declaracaoConteudoItem.quantidade" @keyup="mascaraInteiro" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Valor R$" label-size="" label-cols-md="12">
                <b-form-input type="text" class="text-right" v-model="declaracaoConteudoItem.valor" @keyup="mascaraMoeda" />
              </b-form-group>
            </b-col>

            <b-col md="2" class="">
                <b-form-group class="text-white" label="." label-cols-md="12">
                    <b-button type="submit" class="btn-laranja p-0 m-0" size="lg" style="height: 40px;" :disabled="(declaracaoConteudo.itens.length >= VS_MAX_ITENS_DECLARACAO_CONTEUDO)">Gravar</b-button>
                </b-form-group>
            </b-col>
          </b-form-row>
        </form>
        <!-- #endregion -->

        <!-- #region listagem/exclusao -->
        <b-row class="mb-3 d-flex justify-content-between" v-for="(item, i) in declaracaoConteudo.itens" :key="i">
          <b-col>{{ item.descricao }}</b-col>
          <b-col class="text-right">{{ item.quantidade }}</b-col>
          <b-col class="text-right">
            R$ {{ $vsFormataMoeda(item.valor) }}&nbsp;
            <a @click.prevent="excluiDeclaracaoConteudo(i)" v-b-tooltip.hover title="Excluir item">
              <b-icon icon="trash" variant="danger" />
            </a>
          </b-col>
        </b-row>
        <b-row class="d-flex justify-content-between font-weight-bold h4">
          <b-col>Total itens ({{ declaracaoConteudo.itens.length }})</b-col>
          <b-col class="text-right">{{ calculaQtdDeclaracaoConteudo() }}</b-col>
          <b-col class="text-right">R$ {{ $vsFormataMoeda(calculaTotalDeclaracaoConteudo()) }}&nbsp;</b-col>
        </b-row>
        <!-- #endregion -->
      </div>
      <template #modal-footer="{ hide }">
				<!-- #region opcoes -->
        <div class="w-100 d-flex justify-content-between">
          <b-button v-if="declaracaoConteudo.itens.length" type="button" variant="primary" class="text-nowrap" @click="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_A4)">
            <span class="font-weight-bold">Imprimir A4</span>
          </b-button>
          <b-button v-else type="button" variant="primary" class="text-nowrap" disabled>
            <span class="font-weight-bold">Imprimir A4</span>
          </b-button>

          <b-button v-if="declaracaoConteudo.itens.length" type="button" variant="success" class="text-nowrap" @click="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_10x15)">
            <span class="font-weight-bold">Imprimir 10x15</span>
          </b-button>
          <b-button v-else type="button" variant="success" class="text-nowrap" disabled>
            <span class="font-weight-bold">Imprimir 10x15</span>
          </b-button>

          <b-button v-if="declaracaoConteudo.itens.length" type="button" variant="secondary" @click="excluiDeclaracaoConteudo()">
            <span class="font-weight-bold">Limpar</span>
          </b-button>
          <b-button v-else type="button" variant="secondary" disabled>
            <span class="font-weight-bold">Limpar</span>
          </b-button>

          <b-button type="button" variant="danger" @click="hide()">
            <span class="font-weight-bold">Fechar</span>
          </b-button>
        </div>
				<!-- #endregion -->
      </template>
    </b-modal>
		<!-- #endregion -->
    
		<!-- #region conovite para vinculo -->
		<b-modal id="modalConvite" title="Convite para vínculo"
			hide-header-close 
			no-close-on-esc 
			no-close-on-backdrop>
      <div class="h5" v-if="pagador">
        <div class="mb-3">Pagador: {{ pagador.nome }}</div>
        <div class="mb-3">CPF/CNPJ: {{ $vsFormataCpfCnpj(pagador.cpfCnpj) }}</div>
				<div class="mb-3">Email: {{ pagador.email }}</div>
				<div class="mb-5">Ponto de atendimento: {{ $root.sessao.filial.nome }}</div>
				<div class="mb-3">Deseja enviar um convite para vincular este cliente a este ponto de atendimento?</div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="enviaConvite()">Confirmar</b-button>
          <b-button type="button" class="float-right ml-2" variant="secondary" @click="hide();novoAtendimento()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
		<!-- #endregion -->
		
		<!-- #region atendimento vencido -->
		<b-modal id="modalAtendimentoVencido" title="Informativo" 
			hide-header-close	
			no-close-on-esc 
			no-close-on-backdrop>
      <div class="h5">
        Data de abertura do atendimento menor que a data atual, por favor finalize o atendimento
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="hide()">OK</b-button>
        </div>
      </template>
    </b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
  </div>
</template>

<script>
import VsFormAtendimentoPessoa from "../templates/vs-form-atendimento-pessoa.vue"
import VsReciboPostagem from "../templates/vs-recibo-postagem.vue"
import { ModelSelect } from "vue-search-select"

export default {
  // #region configs
  name: "atendimento",
  path: "/atendimento",
  requireAuth: true,
  components: { VsFormAtendimentoPessoa, VsReciboPostagem, ModelSelect },
  // #endregion

  // #region dados
  data() {
    return {
      config: null,
      cabTabelaOperacoesBalcao: [
        { key: "grupo", sortable: true, label: "Categoria", tdClass: "text-left", },
        { key: "categoria", sortable: true, label: "Tipo", tdClass: "text-left", },
        { key: "codigo", sortable: true, label: "Código", tdClass: "text-left", },
        { key: "descricao", sortable: true, label: "Descrição", tdClass: "text-left", },
        { key: "valorUnitario", sortable: true, label: "Valor Unitário", tdClass: "text-right", },
        { key: "quantidade", sortable: false, label: "Quantidade", tdClass: "text-right", },
        { key: "valorTotal", sortable: true, label: "Valor Total", tdClass: "text-right", },
        { key: "numeroEncomenda", sortable: false, label: "Nº encomenda", tdClass: "text-left", },
        { key: "opcoes", sortable: false, label: "Opções", tdClass: "text-left", },
      ],
      cadOperacoesBalcaoGrupos: [],
      cadOperacoesBalcao: [],
      pagador: null,
      postagem: null,
      operacaoBalcao: null,
      produtoServico: null,
      volume: null,
			volumePrePostado: null,
      caixa: {
        id: null,
        idContratante: this.$root.sessao.contratante.id,
        idFilial: this.$root.sessao.filial.id,
        idOperador: this.$root.sessao.id,
        saldoAbertura: 0,
        gerencia: false,
      },
      declaracaoConteudo: {
        id: null,
        idAtendimento: null,
        idContratante: null,
        idFilial: null,
        idOperador: null,
        idTransportadora: null,
        etiqueta: "",
        itens: [],
        valorTotal: 0,
      },
      declaracaoConteudoItem: {
        descricao: "",
        quantidade: 1,
        valor: 0
      },
			recibo: {
				filial: null,
				operador: null,
				postagem: null,
				pagador: null,
			},
			operacaoPercentual: null,
			valorOperacaoPercentual: 0,
			comboPercentual: [],
    }
  },
  // #endregion

  // #region watches
  watch: {
	"config.definiuServico"(ok) {
      if (ok) {
        this.config.definiuServico = false
		let pag = this.pagador
		let opr = this.operacaoAtual()

		if (!pag.id) {
		    this.$vsNotificacao("Defina o pagador, preencha o cadastro e clique em avançar", "warning")
			return
		}
		let pagOK = this.validaCadastro(pag)
		let remOK = this.validaCadastro(opr.remetente)
		let desOK = this.validaCadastro(opr.destinatario, false, true)
				
		if (pagOK && remOK && desOK) {
        	this.novoVolume()
      	}
      }
    },
    "pagador.contratoSelecionado"(obj) {
        this.operacaoPercentual = null
        this.valorOperacaoPercentual = 0
        this.calculaTotalAPagar()
    },
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([
			this.VS_PERFIL_GERENTE, 
			this.VS_PERFIL_OPERADOR
		])
    this.configs()
  },
  mounted() {
    this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
		async bootstrap() {
			await this.verificaCaixa()
			await this.buscaOperacoesBalcaoGrupos()
    	await this.buscaOperacoesBalcao()
			await this.iniciaPostagem()
			await this.validaAtendimentoVencido()
		},
		configs() {
			this.pagador = this.formBasico("Pagador")
      this.postagem = this.formPostagem()
      this.operacaoBalcao = this.formOperacaoBalcao()
      this.produtoServico = this.formProdutoServico()
      this.volume = this.formVolume()
      this.config = this.formConfig()
            this.volumePrePostado = null

            this.operacaoPercentual = null
            this.valorOperacaoPercentual = 0
		},
    formConfig() {
      return {
        isLiberada: true,
        isRemetente: true,
        tiposContrato: [],
        valorPagtoDinheiro: 0,
        definiuServico: false,
        tarifacaoServicos: [],
        idServicoTravado: null,
        idTransportadoraTravada: null,
				etiquetaPrePostagem: "",
				isPrePostagem: false,
				qtdOperacoes: 1,
				atendimentoVencido: false,
      }
    },
    formPostagem() {
      return {
        id: null,
        isFinalizada: false,
	    dataAbertura: new Date().toISOString(), 
        dataCriacao: new Date().toISOString(),
        idContratante: this.$root.sessao.contratante.id,
        idFilial: this.$root.sessao.filial.id,
        idOperador: this.$root.sessao.id,
        idPagador: null,
        idCaixa: null,
        operacoes: [this.formOperacao()],
        produtosServicos: [],
        formaPagamento: {
          metodo: 0,
          valorBruto: 0,
          valorTotal: 0,
          valorDescontoMonetario: 0,
          valorDescontoPercentual: 0,
          contratoCopia: null,
          operacaoDescontoPercentualCopia: null,
        },
        comprovante: 0,
      }
    },
    formBasico(tipoCad) {
      return {
        contratoSelecionado: null,
        tipoForm: tipoCad,
        cpfCnpj: "",
        nome: "",
				nomeFantasia: "",
        email: "",
				perfil: null,
        telefone: "",
				aosCuidados: "",
        foto: null,
        cargo: null,
        isAtivo: true,
        isExcluido: false,
        endereco: {
          tipo: this.VS_TIPO_ENDERECO_RUA,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          complemento: "",
          isComercial: true
        },
        contratos: [],
        vinculos: [],
				origemCadastro: 0,
				cadastroPivot: false,
				preCadastro: false
      }
    },
    formOperacao() {
      return {
        id: null,
        isCancelada: false,
        idUsuarioCancelamento: null,
        motivoCancelamento: "",
        remetente: this.formBasico("Remetente"),
        destinatario: this.formBasico("Destinatário"),
        volumes: [],
        idPagador: null,
        isRemetentePagador: false,
      }
    },
    formVolume() {
      return {
        id: null,
        isCancelado: false,
        idUsuarioCancelamento: null,
				idPrePostagem: null,
				idVolumePrePostado: null,
        motivoCancelamento: "",
        tipoObjeto: this.VS_TIPO_VOLUME_CAIXA,
        peso: 0,
        altura: 0,
        largura: 0,
        comprimento: 0,
        valorDeclarado: 0,
        valorNotaFiscal: 0,
        numeroNotaFiscal: "",
        chaveNotaFiscal: "",
        servicoDescricao: "",
        servicoCodigo: "",
        valorFreteTransportadora: 0,
        valorFreteContratanteBase: 0,
        valorFreteContratante: 0,
        valorFreteTarifacao: 0,
        valorFreteFilialBase: 0,
        valorFreteFilial: 0,
        valorFrete: 0,
        prazoDias: 0,
        etiqueta: "",
        codigoPostagemExterna: "",
        codigoEntregaTransportadora: "",
        servicoRedirecionado: null,
        observacaoFrete: "",
        observacoes: [""],
        adicionais: [""],
        idTransportadora: null,
        idServico: null,
				dimensoesLiberadas: true,
      }
    },
    formOperacaoBalcao() {
      return {
        id: null,
        codigo: null,
        descricao: null,
        categoria: null,
        tipoValor: null,
        valorMonetario: 0,
        usaNumeroEncomenda: false,
        usaQuantidade: false,
        usaDesconto: false,
        quantidade: 0,
      }
    },
    formProdutoServico() {
      return {
        idOperacaoBalcao: null,
        descricao: "",
        valorUnitario: 0,
        quantidade: 0,
        valorTotal: 0,
        numeroEncomenda: "",
      }
    },
    async novoAtendimento() {
        this.configs()
      await this.iniciaPostagem()
			this.abreSanfonaBox("boxPagador")
    },
    validaCadastro(obj, validaCpf = false, validaVolume = false) {
      let ok = true
      let erros = [`Erros na etapa do ${obj.tipoForm}:`]

      // obrigatorios
      if (!this.$vsNomeValido(obj.nome)) {
        erros.push("Nome inválido, não pode estar em branco nem conter caracteres especiais")
        ok = false
      }
      if (validaCpf && !this.$vsCpfCnpjValido(this.$vsLimpaFormatacao(obj.cpfCnpj))) {
        ok = false
        erros.push("CPF/CNPJ inválido")
      }

      // opcionais
      if (obj.email && !this.$vsEmailValido(obj.email)) {
        ok = false
        erros.push("Email inválido")
      }
      if (obj.telefone && !this.$vsTelefoneValido(this.$vsLimpaFormatacao(obj.telefone))) {
        ok = false
        erros.push("Telefone inválido")
      }

      // obrigatorios
      let end = obj.endereco
      if (this.$vsLimpaFormatacao(end.cep).length !== 8) {
        ok = false
        erros.push("Cep inválido")
      }
      if (end.uf.trim().length !== 2) {
        ok = false
        erros.push("UF inválida")
      }
      if (end.logradouro.trim().length === 0) {
        ok = false
        erros.push("Logradouro inválido")
      }
      if (end.numero.trim().length === 0) {
        ok = false
        erros.push("Número inválido, caso não exista informe s/n")
      }
      if (end.bairro.trim().length === 0) {
        ok = false
        erros.push("Bairro inválido")
      }
      if (end.cidade.trim().length === 0) {
        ok = false
        erros.push("Cidade inválida")
      }
      if (end.pais.trim().length === 0) {
        ok = false
        erros.push("País inválido")
      }

      // tela destinatario
      if (validaVolume) {
        let vol = this.volume
        let valorNFe = this.$vsConverteMoeda(vol.valorNotaFiscal)
        let valorDecl = this.$vsConverteMoeda(vol.valorDeclarado)

        // opcionais
        if (vol.chaveNotaFiscal.length) {
          if (!/[\d]{44}/.test(vol.chaveNotaFiscal)) {
            ok = false
            erros.push("Chave da Nota fiscal inválida, deve conter apenas números e possuir 44 dígitos")
          }
          if (!vol.numeroNotaFiscal.length) {
            ok = false
            erros.push("Ao informar a chave da Nota fiscal, o número da nota é necessário")
          }
        }
        if (vol.numeroNotaFiscal.length) {
          if (!/[\d]/.test(vol.numeroNotaFiscal)) {
            ok = false
            erros.push("Número da Nota fiscal inválido, deve conter apenas números")
          }
          if (valorNFe <= 0) {
            ok = false
            erros.push("Ao informar o número da Nota fiscal, o valor da nota é necessário")
          }
        } else if (valorNFe > 0) {
          ok = false
          erros.push("Ao informar o valor da Nota fiscal, o número da nota  é necessário")
        }
        if (valorNFe > 0 && valorDecl > valorNFe) {
          ok = false
          erros.push("Valor declarado não pode ser maior que o valor da nota fiscal")
        }

        // obrigatorios
        if (!vol.peso || vol.peso < 1) {
          ok = false
          erros.push("Peso inválido")
        }
				/*if (vol.tipoObjeto !== this.VS_TIPO_VOLUME_ENVELOPE) {
					if (!vol.altura || vol.altura < 1) {
						ok = false
						erros.push("Altura inválida")
					}
					if (!vol.largura || vol.largura < 1) {
						ok = false
						erros.push("Largura inválida")
					}
					if (!vol.comprimento || vol.comprimento < 1) {
						ok = false
						erros.push("Comprimento inválido")
					}
				}
        if (ok && vol.servicoCodigo.length === 0) {
          ok = false
          erros.push("Escolha uma modalidade de envio")
        }*/
      }

      // erros numa unica notificacao
      if (!ok) {
        const html = this.$createElement
        const nodes = []
        erros.forEach((el, i) => {
          if (i === 0) {
            nodes.push(el)
          } else {
            nodes.push(`- ${el}`)
          }
          nodes.push(html("br"))
        })
        this.$vsNotificacao([html('p', nodes)], "warning")
      }
      return ok
    },
    voltar(rota) {
      // pequeno desvio
      if (this.config.isRemetente && rota === "boxRemetente") {
        rota = "boxPagador"
      }
      this.gravaSaveState(rota)
      this.abreSanfonaBox(rota)
    },
    avancar(rota) {
      this.gravaSaveState(rota)
      this.abreSanfonaBox(rota)
    },
    cadastraPessoa(obj) {
      // cadastra remetente e/ou destinatario para consultas posteriores
      let pessoa = this.clonagem(obj)
      pessoa.vinculos = [
        { idUsuario: this.$root.sessao.contratante.id, isSuperior: true },
        { idUsuario: this.$root.sessao.filial.id, isSuperior: true },
      ]
      this.$vsApiRest("POST", "/pessoas", [pessoa])
    },
    async cadastraPagador() {
      let pag = this.pagador
      if (!this.validaCadastro(pag)) {
        return
      }

      // dados complementares
      pag.isAtivo = true
			pag.origemCadastro = this.VS_ORIGEM_CADASTRO_ATENDIMENTO
      pag.perfil = this.VS_PERFIL_CLIENTE
      pag.endereco.cep = this.$vsLimpaFormatacao(pag.endereco.cep)

      if (!pag.vinculos) {
        pag.vinculos = []
      }

			pag.vinculos.push({ idUsuario: this.$root.sessao.contratante.id, isSuperior: true, })
			pag.vinculos.push({ idUsuario: this.$root.sessao.filial.id, isSuperior: true, })

      let resp = await this.$vsApiRest("POST", "/usuarios", pag)
      
			if (resp.status === 200) {
        pag.id = resp.data.msg
        pag.endereco.cep = this.$vsFormataCep(pag.endereco.cep)
        this.postagem.idPagador = pag.id
        this.defineRemetente()
      }
    },
    defineRemetente(click = false) {
      let proximaEtapa = "boxRemetente"

      if (click) {
        // limpa form
        this.limpaFormRemetente()
      }
      if (this.config.isRemetente) {
        let rem = this.operacaoAtual().remetente
        let pag = this.pagador
        // preenche form
        rem.nome = pag.nome
        rem.cpfCnpj = pag.cpfCnpj
        rem.email = pag.email
        rem.telefone = pag.telefone
        rem.endereco.cep = pag.endereco.cep
        rem.endereco.logradouro = pag.endereco.logradouro
        rem.endereco.numero = pag.endereco.numero
        rem.endereco.bairro = pag.endereco.bairro
        rem.endereco.complemento = pag.endereco.complemento
        rem.endereco.cidade = pag.endereco.cidade
        rem.endereco.uf = pag.endereco.uf
        proximaEtapa = "boxDestinatario"

        if (!click) {
          this.cadastraPessoa(rem) // background
        }
      }

      this.gravaSaveState(proximaEtapa)

      if (!click) {
        this.abreSanfonaBox(proximaEtapa)
      }
    },
    cadastraRemetente() {
      let rem = this.operacaoAtual().remetente
      if (this.validaCadastro(rem)) {
        let proximaEtapa = "boxDestinatario"
        this.cadastraPessoa(rem) // background
        this.avancar(proximaEtapa)
      }
    },
    async finalizar() {
      if (!this.validaAtendimento()) {
        return
      }

      // validacoes
      let cont = this.pagador.contratoSelecionado
      let temContrato = (cont !== null)
      let temContratoFidelidade = (temContrato && cont.tipo === this.VS_CONTRATO_TIPO_FIDELIDADE)
      let temContratoPosPago = (temContrato && cont.tipo === this.VS_CONTRATO_TIPO_POSPAGO)
      let pagto = this.postagem.formaPagamento

      if (pagto.metodo === 0 && (!temContrato || temContratoFidelidade)) {
        this.$vsNotificacao("Escolha o método de pagamento", "warning")
        return
      }
      if (temContratoPosPago) {
        pagto.metodo = this.VS_PAGAMENTO_SALDO
      }
      if (!this.validaTroco()) {
        return
      }
      await this.verificaCaixa(true)
    },
    async imprimeRecibo() {
			let query = `idPostagem=${this.postagem.id}`
			let recibo = this.recibo
			let resp = await this.$vsApiRest("GET", `/postagens/recibo/?${query}`)

			if (resp.status === 200) {
				recibo.postagem = resp.data.postagem
				recibo.filial = resp.data.filial
				recibo.operador = resp.data.operador
				recibo.pagador = resp.data.pagador

				const observer = new MutationObserver((mutations, obs) => {
					let impressao = document.querySelector("#impressaoRecibo")

					if (impressao) {
						let text = this.$vsConverteHtmlTexto(impressao.innerHTML)
						this.$vsDownload(new TextEncoder().encode(text), `recibo-postair-${recibo.postagem.comprovante}.vst`, "text/plain")
						this.verificaConvite()
						obs.disconnect()
						return
					}
				})
				observer.observe(document, { childList: true,	subtree: true	})
			}
    },
    imprimeEtiqueta(etiqueta) {
      this.$vsImprimeEtiqueta(etiqueta)
    },
    desativaOpcaoContrato(obj) {
      return (obj.status !== this.VS_CONTRATO_STATUS_ATIVO)
    },
    selecionaContrato(obj) {
      this.pagador.contratoSelecionado = obj
    },
    calculaTotalFrete() {
      let total = 0

      this.postagem.operacoes.forEach(p => {
        if (!p.isCancelada) {
          p.volumes.forEach(v => {
            if (!v.isCancelado) {
              total += v.valorFrete
            }
          })
        }
      })
      return total
    },
    calculaTotalOperacoesBalcao() {
      let total = 0

      this.postagem.produtosServicos.forEach(p => {
        total += p.valorTotal
      })
      return total
    },
    calculaTotalAPagar() {
      let total = this.calculaTotalFrete() + this.calculaTotalOperacoesBalcao()
      let post = this.postagem
      let cont = this.pagador.contratoSelecionado
      let temContrato = (cont !== null)
      let aVista = !temContrato
      let temContratoFidelidade = (temContrato && cont.tipo === this.VS_CONTRATO_TIPO_FIDELIDADE)
      let pagto = post.formaPagamento
      let oprPercentual = this.operacaoPercentual
      let desconto = 0
      let valPercentual = this.$vsConverteMoeda(this.valorOperacaoPercentual)
      
      total = parseFloat(total.toFixed(2))

      if (valPercentual > this.VS_OPERACAO_BALCAO_DESCONTO_PERCENTUAL_MAXIMO) {
          valPercentual = 0
          this.valorOperacaoPercentual = valPercentual
      }
      if (valPercentual > 0) {
          valPercentual *= -1
      }
      if (oprPercentual != null) {
          oprPercentual = this.comboPercentual.find(el => el.value === oprPercentual)
      }

      desconto = (valPercentual / 100) * total
        
      pagto.contratoCopia = cont
      pagto.valorBruto = total
      pagto.valorTotal = total + desconto
      pagto.valorDescontoMonetario = Math.abs(desconto)
      pagto.valorDescontoPercentual = valPercentual
      pagto.operacaoDescontoPercentualCopia = oprPercentual
        
      this.config.isLiberada = (
        aVista ||
        temContratoFidelidade ||
        (temContrato && pagto.valorTotal <= (cont.saldoLimite - cont.saldoUtilizado))
      )

      return pagto.valorTotal
    },
    async iniciaPostagem() {
      let post = this.postagem
			let resp = await this.$vsApiRest("POST", "/postagens/inicio", post)

      if (resp.status === 200) {
				this.postagem = resp.data
				await this.recuperaSaveState()
      }
    },
		async buscaPostagemAtual() {
      let post = this.postagem
			let resp = await this.$vsApiRest("POST", "/postagens/inicio", post)
      
			if (resp.status === 200) {
        post.id = resp.data.id
				this.gravaSaveState("boxDestinatario")
      }
    },
    async finalizaPostagem() {
      let post = this.postagem
      post.idPagador = this.pagador.id
      post.idCaixa = this.caixa.id

      let req = {
        idPostagem: post.id,
        idPagador: post.idPagador,
        idCaixa: post.idCaixa,
		produtosServicos: post.produtosServicos,
        formaPagamento: post.formaPagamento,
      }

	  let resp = await this.$vsApiRest("PATCH", "/postagens/finalizacao", req)

      if (resp.status === 200) {
        this.$vsNotificacao("Postagem efetuada com sucesso", "success")
        this.imprimeRecibo()
      }
    },
      mascaraMoeda(evt) {
          if (evt.key == "Enter") {
              return
          }
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    async abreCaixa() {
      let saldo = this.$vsConverteMoeda(this.caixa.saldoAbertura)

      if (isNaN(saldo) || saldo < 0) {
        this.$vsNotificacao("Saldo inválido", "warning")
        return
      }
      this.caixa.saldoAbertura = saldo
      this.caixa.gerencia = (this.$root.sessao.perfil === this.VS_PERFIL_GERENTE)

      let resp = await this.$vsApiRest("POST", `/caixas`, this.caixa)

      if (resp.status === 200) {
        this.caixa.id = resp.data.id
        this.$bvModal.hide("modalCaixa")
        this.finalizar()
      }
    },
    async verificaCaixa(finalizacao = false) {
			let obj = this.caixa
			let resp = await this.$vsApiRest("GET", `/caixas/operador/${obj.idFilial}/${obj.idOperador}`)
        
			if (resp.status === 404) {
        this.$bvModal.show("modalCaixa")
        return
      }
			if (resp.status === 200 && finalizacao) {
        this.caixa.id = resp.data.id
        this.finalizaPostagem()
      }
    },
    calculaTroco() {
      let valor = this.$vsConverteMoeda(this.config.valorPagtoDinheiro)
      return valor - this.postagem.formaPagamento.valorTotal
    },
    validaTroco() {
			let pagto = this.postagem.formaPagamento

      if (pagto.metodo !== this.VS_PAGAMENTO_DINHEIRO) {
        return true
      }

      let total = pagto.valorTotal
      let valorPago = this.$vsConverteMoeda(this.config.valorPagtoDinheiro)

      if (valorPago < total) {
        this.$vsNotificacao("Valor pago não pode ser menor que o total", "warning")
        return false
      }
      return true
    },
    novaOperacao() {
      let cfg = this.config
      let oprAnterior = this.operacaoAtual()
      let oprNova = this.formOperacao()

			if (cfg.isPrePostagem) {
				this.$refs.campoEtiquetaPrePostagem.$el.focus()
			} else {
				oprNova.remetente = this.clonagem(oprAnterior.remetente)
			}
      this.postagem.operacoes.push(oprNova)

			cfg.idServicoTravado = null
      cfg.idTransportadoraTravada = null
			cfg.qtdOperacoes++

      this.gravaSaveState("boxDestinatario")
    },
    async novoVolume() {
      let opr = this.operacaoAtual()
      if (!this.validaCadastro(opr.destinatario, false, true)) {
        return
      }
      let post = this.postagem
      let cfg = this.config
      let vol = this.clonagem(this.volume)
			let prevol = this.volumePrePostado

      opr.idPagador = null
      opr.isRemetentePagador = false

      if (cfg.isRemetente) {
        opr.idPagador = post.idPagador
        opr.isRemetentePagador = true
      }

      vol.valorDeclarado = this.$vsConverteMoeda(vol.valorDeclarado)
      vol.valorNotaFiscal = this.$vsConverteMoeda(vol.valorNotaFiscal)

			if (vol.tipoObjeto !== this.VS_TIPO_VOLUME_ENVELOPE) {
				if (!vol.altura || vol.altura < 1) {
					vol.altura = this.VS_DIMENSAO_MINIMA_ALTURA
				}
				if (!vol.largura || vol.largura < 1) {
					vol.largura = this.VS_DIMENSAO_MINIMA_LARGURA
				}
				if (!vol.comprimento || vol.comprimento < 1) {
					vol.comprimento = this.VS_DIMENSAO_MINIMA_COMPRIMENTO
				}
			}

      let req = {
        idPostagem: post.id,
        idOperacao: opr.id,
        remetente: opr.remetente,
        destinatario: opr.destinatario,
        volume: vol,
        idPagador: opr.idPagador,
        isRemetentePagador: opr.isRemetentePagador,
				isAfericao: (cfg.isPrePostagem && prevol.idServico === vol.idServico),
      }
			let resp = await this.$vsApiRest("PATCH", "/postagens/remessa/", req)

      if (resp.status === 200) {
				let volDB = resp.data.volume
        let dimensoesAjustadas = (
          volDB.altura != vol.altura ||
          volDB.largura != vol.largura ||
          volDB.comprimento != vol.comprimento
        )

        vol.id = volDB.id
        vol.altura = volDB.altura
        vol.largura = volDB.largura
        vol.comprimento = volDB.comprimento
        vol.etiqueta = volDB.etiqueta
        vol.codigoPostagemExterna = volDB.codigoPostagemExterna
        vol.codigoEntregaTransportadora = volDB.codigoEntregaTransportadora
        vol.servicoRedirecionado = volDB.servicoRedirecionado
        vol.isCancelado = false
				vol.idPrePostagem = null

        opr.id = resp.data.idOperacao
        opr.isCancelada = false
        opr.volumes.push(vol)

        cfg.tarifacaoServicos = []
        cfg.idServicoTravado = vol.idServico
        cfg.idTransportadoraTravada = vol.idTransportadora
				cfg.isPrePostagem = false
				cfg.etiquetaPrePostagem = ""

				this.volumePrePostado = null

        this.$vsNotificacao(`Volume ${vol.etiqueta} adicionado, veja no resumo`, "success")

        if (dimensoesAjustadas) {
          this.$vsNotificacao(`Peso/dimensões ajustados para o mínimo, veja no resumo`, "info")
        }
        this.volume = this.formVolume()
        this.imprimeEtiqueta(vol.etiqueta)
        this.gravaSaveState("boxDestinatario")
      }
    },
    async cancelaAtendimento() {
      let post = this.postagem
      let req = {
        idAtendimento: post.id,
        idResponsavel: post.idOperador,
        motivo: "Postagem cancelada no atendimento",
				cancelamentoOrigem: this.VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_ATENDIMENTO,
      }
			let resp = await this.$vsApiRest("PATCH", "/postagens/cancelamento", req)
      
			if (resp.status === 200) {
        this.$bvModal.hide("modalCancelamento")
        this.novoAtendimento()
      }
    },
    async cancelaOperacao(index) {
      let post = this.postagem
      let opr = post.operacoes[index]

      if (opr.isCancelada) {
        return
      }

      opr.motivoCancelamento = "Postagem cancelada no atendimento"
      opr.idUsuarioCancelamento = post.idOperador

      let req = {
        idAtendimento: post.id,
        idPostagemOuItem: opr.id,
        idResponsavel: opr.idUsuarioCancelamento,
        motivo: opr.motivoCancelamento,
				cancelamentoOrigem: this.VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_ATENDIMENTO,
      }
			let resp = await this.$vsApiRest("PATCH", "/postagens/cancelamento", req)
      
			if (resp.status === 200) {
        opr.isCancelada = resp.data // esconde no resumo

        if (opr.isCancelada) {
          // forca cencelamento de todos os volumes (localmente)
          for (let i = 0; i < opr.volumes.length; i++) {
            this.cancelaVolume(index, i, true)
          }
          this.$vsNotificacao(`Postagem removida`, "warning")
        }
				this.buscaPostagemAtual()
      }
    },
    async cancelaVolume(indexOperacao, index, cancelamentoLocal = false) {
      let cfg = this.config
      let post = this.postagem
      let opr = post.operacoes[indexOperacao]
      let vol = opr.volumes[index]
      let vm = this

      if (vol.isCancelado) {
        return
      }

      vol.motivoCancelamento = "Volume cancelado no atendimento"
      vol.idUsuarioCancelamento = post.idOperador

      let configuraRemocao = function (cancela = true) {
        vol.isCancelado = cancela // esconde no resumo

        if (vol.isCancelado) {
          let cancelados = opr.volumes.filter(el => el.isCancelado).length

          if (cancelados === opr.volumes.length) {
            cfg.idServicoTravado = null
            cfg.idTransportadoraTravada = null
            opr.isCancelada = true
          }
          vm.$vsNotificacao(`Volume ${vol.etiqueta} removido`, "warning")
        }
      }

      if (cancelamentoLocal) {
        configuraRemocao()
        return
      }

      let req = {
        idAtendimento: post.id,
        idPostagemOuItem: vol.id,
        idResponsavel: vol.idUsuarioCancelamento,
        motivo: vol.motivoCancelamento,
				cancelamentoOrigem: this.VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_ATENDIMENTO,
      }
			let resp = await this.$vsApiRest("PATCH", "/postagens/cancelamento", req)

      if (resp.status === 200) {
        configuraRemocao(resp.data)
				this.buscaPostagemAtual()
      }
    },
    calculaOperacoesAtivas() {
      let ativos = this.postagem.operacoes.filter(el => !el.isCancelada && el.volumes.filter(el2 => !el2.isCancelado).length)
      ativos.forEach((el, i) => el.indiceResumo = i + 1)
      return ativos.length
    },
    calculaVolumesAtivos(lista) {
      let ativos = lista.filter(el => !el.isCancelado)
      ativos.forEach((el, i) => el.indiceResumo = i + 1)
      return ativos.length
    },
    liberaNovaPostagem() {
      let oprs = this.postagem.operacoes
      let volumesAtivos = 0

      if (oprs.length) {
        volumesAtivos = this.operacaoAtual().volumes.filter(el => !el.isCancelado).length
      }
      return this.config.isLiberada && (volumesAtivos > 0)
    },
    validaAtendimento() {
      let pag = this.pagador

      if (!pag.id) {
        this.$vsNotificacao("Defina o pagador, preencha o cadastro e clique em avançar", "warning")
        return false
      }
			if (!this.validaCadastro(pag)) {
        return
      }

      let post = this.postagem
      let volumesAtivos = post.operacoes.filter(opr => !opr.isCancelada && opr.volumes.filter(vol => !vol.isCancelado).length > 0).length
      let servicosAdicionados = post.produtosServicos.length
      let ok = (volumesAtivos || servicosAdicionados)

      if (!ok) {
        this.$vsNotificacao("Defina o frete ou adicione um produto/serviço", "warning")
      }
      return ok
    },
    operacaoAtual() {
      let oprs = this.postagem.operacoes

			if (!oprs.length) {
				oprs.push(this.formOperacao())
			}
      return oprs[oprs.length - 1]
    },
    clonagem(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    abreSanfonaBox(nome) {
      this.$root.$emit("bv::toggle::collapse", nome)
    },
    async buscaOperacoesBalcaoGrupos() {
      let post = this.postagem
      let query = `idContratante=${post.idContratante}&idFilial=${post.idFilial}&ativo=true&excluido=false`
			let resp = await this.$vsApiRest("GET", `/operacoes/balcao/grupos/?${query}`)

      if (resp.status === 200) {
        this.cadOperacoesBalcaoGrupos = resp.data
      }
    },
    async buscaOperacoesBalcao() {
      let post = this.postagem
      let query = `idContratante=${post.idContratante}&idFilial=${post.idFilial}&ativo=true&excluido=false`
			let resp = await this.$vsApiRest("GET", `/operacoes/balcao/?${query}`)

      if (resp.status === 200) {
	    let grps = this.cadOperacoesBalcaoGrupos

				resp.data.forEach(el => {
					let grupo = grps.find(el2 => el2.id === el.idGrupo)

					if (grupo) {
						el.value = el.codigo
						el.text = `${el.codigo} - ${el.descricao} (${grupo.descricao})`
					}
				})
        this.defineComboOperacoesBalcao(resp.data)
		this.defineComboOperacoesPercentualBalcao(resp.data)
      }
    },
	defineComboOperacoesBalcao(lista) {
		this.cadOperacoesBalcao = lista.filter(el => !el.usaPercentual)
	},
	defineComboOperacoesPercentualBalcao(lista) {
        this.comboPercentual = lista.filter(el => el.usaPercentual && el.usaDesconto)
        this.comboPercentual.unshift({ value: null, text: "0 - Sem desconto" })
    },
    recuperaSaveState() {
			let post = this.postagem

			if (!post.saveState) {
				return
			}

			let checkPoint = post.saveState
			let cfg = JSON.parse(checkPoint.jsonConfigNavegador)

			this.pagador = JSON.parse(checkPoint.jsonPagador)
			this.config = cfg

			if (checkPoint.jsonVolumePrePostado) {
				this.volumePrePostado = JSON.parse(checkPoint.jsonVolumePrePostado)
			}

			if (cfg.qtdOperacoes !== post.operacoes.length) {
				post.operacoes.push(this.formOperacao())
				cfg.qtdOperacoes = post.operacoes.length
			}

			let opr = this.operacaoAtual()
			let rem = JSON.parse(checkPoint.jsonRemetente)
			let des = JSON.parse(checkPoint.jsonDestinatario)
			let vol = JSON.parse(checkPoint.jsonVolume)
			let prods = JSON.parse(checkPoint.jsonProdutosServicos)

			if (rem) {
				opr.remetente = rem
			}
			if (des) {
				opr.destinatario = des
			}
			if (vol) {
				this.volume = vol
			}
			if (prods) {
				this.postagem.produtosServicos = prods
			}
      if (checkPoint.etapaAtual !== "boxPagador") {
      	this.abreSanfonaBox(checkPoint.etapaAtual)
      }
    },
    async gravaSaveState(etapaAtual) {
	  let opr = this.operacaoAtual()
      let req = {
		etapaAtual: etapaAtual,
        jsonConfigNavegador: JSON.stringify(this.config),
        jsonPagador: JSON.stringify(this.pagador),
				jsonRemetente: JSON.stringify(opr.remetente),
				jsonDestinatario: JSON.stringify(opr.destinatario),
				jsonVolume: JSON.stringify(this.volume),
				jsonVolumePrePostado: JSON.stringify(this.volumePrePostado),
				jsonProdutosServicos: JSON.stringify(this.postagem.produtosServicos),
      }
      await this.$vsApiRest("POST", `/postagens/save-state/${this.postagem.id}`, req)
    },
    excluiOperacaoBalcao(index) {
      this.postagem.produtosServicos.splice(index, 1)
      this.gravaSaveState("boxServicos")
    },
    montaProdutosServicos() {
      let lista = []
      let oprs = this.cadOperacoesBalcao
      let grps = this.cadOperacoesBalcaoGrupos

      oprs.forEach(el => {
        let grupo = grps.find(el2 => el2.id === el.idGrupo)

        if (grupo) {
          lista.push({ value: el.codigo, text: `${el.codigo} - ${el.descricao} (${grupo.descricao})` })
        }
      })

      return lista
    },
    defineOperacaoBalcao() {
      let oprCombo = this.cadOperacoesBalcao.find(el => el.codigo === parseInt(this.operacaoBalcao.codigo))
      let prod = this.produtoServico

      if (oprCombo) {
        this.operacaoBalcao = this.clonagem(oprCombo)
        prod.quantidade = this.operacaoBalcao.quantidade
      }
      prod.numeroEncomenda = ""
    },
    mascaraInteiro(evt) {
      evt.target.value = this.$vsLimpaFormatacao(evt.target.value)
    },
    async validaOperacaoBalcao() {
      let opr = this.operacaoBalcao
      let prod = this.produtoServico
      let oprCombo = this.cadOperacoesBalcao.find(el => el.codigo === parseInt(opr.codigo))

      if (!oprCombo) {
        this.$vsNotificacao("Código de operação inválido", "warning")
        return false
      }

      var valor = this.$vsConverteMoeda(prod.valorUnitario)
      if (opr.tipoValor === this.VS_OPERACAO_BALCAO_TIPO_VALOR_LIVRE) {
        if (!opr.usaDesconto && valor <= 0) {
          this.$vsNotificacao("Esta operação não aceita valor unitário zero ou negativo", "warning")
          return false
        }
        if (opr.usaDesconto && valor >= 0) {
          this.$vsNotificacao("Informe o valor unitário negativo para o desconto", "warning")
          return false
        }
      }

      var qtd = parseInt(prod.quantidade)
      if ((opr.usaQuantidade || opr.categoria === this.VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO) && !qtd) {
        this.$vsNotificacao("Informe a quantidade", "warning")
        return false
      }

      if (opr.usaNumeroEncomenda) {
        let etiqueta = prod.numeroEncomenda.trim()
				let validacaoCorreios = false

        if (etiqueta.length === 0) {
          this.$vsNotificacao("Informe o Nº encomenda", "warning")
          return false
        }
				if (etiqueta.length === this.VS_ETIQUETA_CORREIO_LEN) {
					etiqueta = etiqueta.toUpperCase()
					validacaoCorreios = true
				}

        let nrEncomendaExiste = this.postagem.produtosServicos.find(el => el.numeroEncomenda === etiqueta)
        if (nrEncomendaExiste) {
          this.$vsNotificacao("Nº encomenda já informado", "warning")
          return false
        }

        if (validacaoCorreios && !this.$vsValidaEtiquetaCorreio(etiqueta)) {
          this.$vsNotificacao("Nº encomenda inválido", "warning")
          return false
        }

				var resp =  await this.$vsApiRest("GET", `/postagens/etiqueta/existe?etiqueta=${etiqueta}`)
        if (resp.status !== 200) {
					return false
				}
      }
      return true
    },
    limpaOperacaoBalcao() {
      let opr = this.operacaoBalcao
      let prod = this.produtoServico
      let campoNrEncomenda = this.$refs.campoNrEncomenda.$el
			let campoCodigoOperacao = this.$refs.campoCodigoOperacao.$el

			prod.valorUnitario = 0
      prod.numeroEncomenda = ""
      prod.quantidade = 0

      if (opr.usaNumeroEncomenda) {
        prod.quantidade = opr.quantidade
        campoNrEncomenda.focus()
        return
      }

      opr.codigo = null
      opr.usaQuantidade = false
      opr.valorMonetario = 0
			
			campoCodigoOperacao.children[1].focus()
    },
    async novaOperacaoBalcao() {
      let resp = await this.validaOperacaoBalcao()

      if (!resp) {
        return
      }
			if (!this.validaCadastro(this.pagador)) {
				return
			}

			let tabela = this.postagem.produtosServicos
      let opr = this.operacaoBalcao
      let grupo = this.cadOperacoesBalcaoGrupos.find(el => el.id === opr.idGrupo)
      let prod = this.clonagem(this.produtoServico)

      prod.idOperacaoBalcao = opr.id
      prod.codigo = opr.codigo
      prod.categoria = opr.categoria
      prod.grupo = grupo.descricao || ""
      prod.descricao = opr.descricao
      prod.numeroEncomenda = prod.numeroEncomenda.trim()
      prod.valorUnitario = (opr.tipoValor === this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO)
        ? opr.valorMonetario
        : this.$vsConverteMoeda(prod.valorUnitario)
      prod.valorTotal = prod.valorUnitario * prod.quantidade

      if (prod.quantidade === 0) {
         prod.valorTotal = prod.valorUnitario
      }
			if (prod.numeroEncomenda.length === this.VS_ETIQUETA_CORREIO_LEN) {
				prod.numeroEncomenda = prod.numeroEncomenda.toUpperCase()
			}

      tabela.push(prod)

      this.limpaOperacaoBalcao()
      this.gravaSaveState("boxServicos")
    },
    defineCorSaldo(valor) {
      if (valor < 0) {
        return "text-danger font-weight-bold"
      }
      if (valor > 0) {
        return "text-success font-weight-bold"
      }
      return "text-dark font-weight-bold"
    },
    limpaFormPagador() {
      let pag = this.pagador
      // limpa form pagador
      pag.nome = ""
      pag.cpfCnpj = ""
      pag.email = ""
      pag.telefone = ""
      pag.endereco.cep = ""
      pag.endereco.logradouro = ""
      pag.endereco.bairro = ""
      pag.endereco.numero = ""
      pag.endereco.complemento = ""
      pag.endereco.cidade = ""
      pag.endereco.uf = ""
      pag.contratos = ""
    },
    limpaFormRemetente() {
      let rem = this.operacaoAtual().remetente
      // limpa form Remetente
      rem.nome = ""
      rem.cpfCnpj = ""
      rem.email = ""
      rem.telefone = ""
      rem.endereco.cep = ""
      rem.endereco.logradouro = ""
      rem.endereco.numero = ""
      rem.endereco.bairro = ""
      rem.endereco.complemento = ""
      rem.endereco.cidade = ""
      rem.endereco.uf = ""
    },
    limpaFormDestinatario() {
      let dest = this.operacaoAtual().destinatario
      // limpa form Destinatario
      dest.nome = ""
      dest.cpfCnpj = ""
      dest.email = ""
      dest.telefone = ""
      dest.endereco.cep = ""
      dest.endereco.logradouro = ""
      dest.endereco.numero = ""
      dest.endereco.bairro = ""
      dest.endereco.complemento = ""
      dest.endereco.cidade = ""
      dest.endereco.uf = ""
			dest.aosCuidados = ""
      this.config.tarifacaoServicos = []
    },
		async verificaAtendimento() {
			let resp = await this.$vsApiRest("GET", `/postagens/?id=${this.postagem.id}`)
      
			if (resp.status === 200) {
        let post = resp.data[0]

				if (post.isCancelada) {
					this.novoAtendimento()
				}else if (post.isFinalizada) {
					this.$vsNotificacao(`Atendimento ${post.comprovante} já finalizado em ${this.$vsFormataData(post.dataCriacao)}`, "warning")
					this.novoAtendimento()
				} else {
					this.limpaAtendimento()
				}
      }
    },
    limpaAtendimento() {
      let operacoes = this.postagem.operacoes.filter(el => !el.isCancelada)
      if (operacoes.length > 0) {
        this.$bvModal.show("modalCancelamento")
      } else {
        this.cancelaAtendimento()
      }
    },
    nomeCategoriaOperacaoBalcao(obj) {
      try {
        let oprCombo = this.cadOperacoesBalcao.find(el => el.id === obj.idOperacaoBalcao)
        return this.$vsListaOperacaoBalcaoCategorias(oprCombo.categoria).text
      } catch {
        return ""
      }
    },
    codigoOperacaoBalcao(obj) {
      try {
        let oprCombo = this.cadOperacoesBalcao.find(el => el.id === obj.idOperacaoBalcao)
        return oprCombo.codigo
      } catch {
        return ""
      }
    },
    nomeGrupoOperacaoBalcao(obj) {
      try {
        let oprCombo = this.cadOperacoesBalcao.find(el => el.id === obj.idOperacaoBalcao)
        let grupo = this.cadOperacoesBalcaoGrupos.find(el => el.id === oprCombo.idGrupo)
        return grupo.descricao
      } catch {
        return ""
      }
    },
    async mostraDeclaracaoConteudo(postagem, volume) {
      let declaracao = this.declaracaoConteudo

      declaracao.id = null
      declaracao.idAtendimento = postagem.id
      declaracao.idContratante = postagem.idContratante
      declaracao.idFilial = postagem.idFilial
      declaracao.idOperador = postagem.idOperador
      declaracao.idTransportadora = volume.idTransportadora
      declaracao.etiqueta = volume.etiqueta

      await this.limpaDeclaracaoConteudo(true)
      this.$bvModal.show("modalDeclaracaoConteudo")

      let query = `idPostagem=${declaracao.idAtendimento}&etiqueta=${declaracao.etiqueta}`
			let resp = await this.$vsApiRest("GET", `/postagens/declaracao-conteudo/?${query}`)

      if (resp.status === 200 && resp.data.length) {
        let declaracaoDB = resp.data[0]
        declaracao.id = declaracaoDB.id
        declaracao.itens = declaracaoDB.itens
      }
    },
    async validaDeclaracaoConteudo() {
      let form = this.declaracaoConteudoItem
      let descricao = form.descricao.trim()
      let ok = true
        
      if (!descricao.length) {
        this.$vsNotificacao("Informe a descrição", "warning")
        ok = false
      }
      if (form.quantidade <= 0) {
        this.$vsNotificacao("Informe a quantidade", "warning")
        ok = false
      }

      return ok
    },
    async gravaDeclaracaoConteudo() {
      if (!await this.validaDeclaracaoConteudo()) {
        return
      }

      let form = this.declaracaoConteudoItem
      let declaracao = this.declaracaoConteudo
        
      form.descricao = form.descricao.trim()
			form.quantidade = parseInt(form.quantidade)
      form.valor = this.$vsConverteMoeda(form.valor)
			
      declaracao.itens.push(JSON.parse(JSON.stringify(form)))

      let resp = await this.$vsApiRest("POST", "/postagens/declaracao-conteudo", declaracao)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Declaração ou item gravado com sucesso", "success")
        declaracao.id = resp.data.id
        await this.limpaDeclaracaoConteudo()
        this.$refs.descricaoDeclaracaoConteudo.$el.focus()
      }
    },
		async gravaDeclaracaoConteudoPrePostagem() {
			let vol = this.volume
			let query = `idPostagem=${vol.idPrePostagem}&etiqueta=${vol.etiqueta}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/declaracao-conteudo/?${query}`)

			if (resp.status === 200 && resp.data.length) {
				let post = this.postagem
                let decl = resp.data[0]

                if (decl.itens.length == 0) {
                    return
                }
				
				decl.id = null
				decl.idAtendimento = post.id
      	        decl.idContratante = post.idContratante
      	        decl.idFilial = post.idFilial
      	        decl.idOperador = post.idOperador
      	        decl.idTransportadora = vol.idTransportadora

				resp = await this.$vsApiRest("POST", "/postagens/declaracao-conteudo", decl)

				if (resp.status === 200) {
					this.declaracaoConteudo = resp.data
				}
			}
    },
    calculaQtdDeclaracaoConteudo() {
      let form = this.declaracaoConteudo
      return form.itens.reduce((total, obj) => total + obj.quantidade, 0)
    },
    calculaTotalDeclaracaoConteudo() {
      let form = this.declaracaoConteudo
      return form.itens.reduce((total, obj) => total + obj.valor, 0)
    },
    async excluiDeclaracaoConteudo(index) {
      let declaracao = this.declaracaoConteudo
      let req = {
        id: declaracao.id,
        indiceItem: index || 0,
        excluirTudo: (index === undefined),
      }
			let resp = await this.$vsApiRest("DELETE", "/postagens/declaracao-conteudo", req)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Declaração ou item apagado com sucesso", "success")
        if (req.excluirTudo) {
          await this.limpaDeclaracaoConteudo(true)
        } else {
          declaracao.itens.splice(index, 1)
        }
      }
      
    },
    async limpaDeclaracaoConteudo(tudo = false) {
      let form = this.declaracaoConteudoItem
      let declaracao = this.declaracaoConteudo
        
      form.descricao = ""
      form.quantidade = 1
      form.valor = 0

      if (tudo) {
        declaracao.itens = []
        }
    },
    async imprimeDeclaracaoConteudo(tipo) {
      let declaracao = this.declaracaoConteudo
      let query = `idPostagem=${declaracao.idAtendimento}&etiqueta=${declaracao.etiqueta}&tipoRelatorio=${tipo}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/declaracao-conteudo/?${query}`, {}, {}, "arraybuffer")
        
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `declaracao-conteudo-${new Date().getTime()}.pdf`)
      }
    },
		exibeAR(vol) {
			return vol.adicionais.includes("AR")
		},
		async imprimeAR(postagem, vol) {
			let query = `idAtendimento=${postagem.id}&etiqueta=${vol.etiqueta}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/ar/?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `aviso-recebimento-${new Date().getTime()}.pdf`)
      }
		},
		filtraContratosContratante() {
			let id = this.$root.sessao.contratante.id
			return this.pagador.contratos.filter(el => el.idContratante === id && el.idFilial === id)
		},
		filtraContratosFilial() {
			return this.pagador.contratos.filter(el => el.idFilial === this.$root.sessao.filial.id)
		},
		async verificaConvite() {
			let pagador = this.pagador
			let cpfCnpj = this.$vsLimpaFormatacao(pagador.cpfCnpj.trim())
			let email = pagador.email.trim()

			if (email.length === 0 || cpfCnpj.length === 0) {
				this.novoAtendimento()
				return
			}

			let sessao = this.$root.sessao
			let query = `idConvidante=${sessao.filial.id}&cpfCnpjConvidado=${cpfCnpj}&emailConvidado=${email}`
			let resp = await this.$vsApiRest("GET", `/usuarios/convites/?${query}`)
      
			if (resp.status === 200) {
        if (resp.data.length === 0) {
					this.$bvModal.show("modalConvite")
				}	else if (resp.data.length && resp.data[0].situacao === this.VS_STATUS_USUARIO_CONVITE_AGUARDANDO && new Date(resp.data[0].dataExpiracaoToken) < new Date((new Date()).toISOString())) {
					this.$bvModal.show("modalConvite")
				} else {
					this.novoAtendimento()
				}
      }
		},
		async enviaConvite() {
			let pagador = this.pagador
			let sessao = this.$root.sessao
			let req = {
				idConvidante: sessao.filial.id,
				idConvidado: pagador.id,
			}
			let resp = await this.$vsApiRest("POST", "/usuarios/convites", req)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Convite enviado com sucesso", "info")
				this.$bvModal.hide("modalConvite")
				this.novoAtendimento()
      }
		},
		async buscaPrePostagem() {
			let post = this.postagem
			let cfg = this.config
			let vol = this.volume
			let etiqueta = cfg.etiquetaPrePostagem.trim()

			if (etiqueta.length === 0) {
				this.$vsNotificacao("Informe a etiqueta da Pré Postagem", "warning")
				return
			}

			vol.idPrePostagem = null
			cfg.isPrePostagem = false
			this.volumePrePostado = null

			let query = `idFilial=${this.$root.sessao.filial.id}&etiqueta=${etiqueta}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/afericao/?${query}`)

			if (resp.status === 200) {
				let prepost = resp.data
				let pag = this.pagador

				if (pag.id && pag.id !== prepost.idPagador) {
					this.$vsNotificacao("Pagador diferente, finalize o atendimento atual para continuar", "warning")
					return
				}

				resp = await this.$vsApiRest("GET", `/usuarios/?ids=${prepost.idPagador}`)

				if (resp.status === 200) {
					let prepag = resp.data[0]
					let precfg = JSON.parse(prepost.saveState.jsonConfigNavegador)
					let prerem = prepost.operacoes[0].remetente
					let predes = prepost.operacoes[0].destinatario
					let prevol = prepost.operacoes[0].volumes[0]

					let opr = this.operacaoAtual()
					let rem = opr.remetente
					let des = opr.destinatario
					
					// #region config
					cfg.isRemetente = precfg.isRemetente
					cfg.isPrePostagem = true
					// #endregion

					// #region pagador
					pag.id = prepag.id
					pag.nome = prepag.nome
					pag.nomeFantasia = prepag.nomeFantasia
					pag.cpfCnpj = this.$vsFormataCpfCnpj(prepag.cpfCnpj)
					pag.email = prepag.email
					pag.telefone = this.$vsFormataTelefone(prepag.telefone)
					pag.endereco.cep = this.$vsFormataCep(prepag.endereco.cep)
					pag.endereco.logradouro = prepag.endereco.logradouro
					pag.endereco.numero = prepag.endereco.numero
					pag.endereco.bairro = prepag.endereco.bairro
					pag.endereco.complemento = prepag.endereco.complemento
					pag.endereco.cidade = prepag.endereco.cidade
					pag.endereco.uf = prepag.endereco.uf
					pag.isAtivo = prepag.isAtivo
					pag.contratos = prepag.contratos
					pag.vinculos = prepag.vinculos
					pag.origemCadastro = prepag.origemCadastro
					pag.cadastroPivot = prepag.cadastroPivot
					pag.preCadastro = prepag.preCadastro
					// #endregion
					
					// #region postagem
					post.idPagador = pag.id
					// #endregion

					// #region remetente
					rem.id = prerem.id
					rem.nome = prerem.nome
					rem.nomeFantasia = prerem.nomeFantasia
					rem.cpfCnpj = this.$vsFormataCpfCnpj(prerem.cpfCnpj)
					rem.email = prerem.email
					rem.telefone = this.$vsFormataTelefone(prerem.telefone)
					rem.endereco.cep = this.$vsFormataCep(prerem.endereco.cep)
					rem.endereco.logradouro = prerem.endereco.logradouro
					rem.endereco.numero = prerem.endereco.numero
					rem.endereco.bairro = prerem.endereco.bairro
					rem.endereco.complemento = prerem.endereco.complemento
					rem.endereco.cidade = prerem.endereco.cidade
					rem.endereco.uf = prerem.endereco.uf
					// #endregion
					
					// #region destinatario
					des.id = predes.id
					des.nome = predes.nome
					des.nomeFantasia = predes.nomeFantasia
					des.aosCuidados = predes.aosCuidados
					des.cpfCnpj = this.$vsFormataCpfCnpj(predes.cpfCnpj)
					des.email = predes.email
					des.telefone = this.$vsFormataTelefone(predes.telefone)
					des.endereco.cep = this.$vsFormataCep(predes.endereco.cep)
					des.endereco.logradouro = predes.endereco.logradouro
					des.endereco.numero = predes.endereco.numero
					des.endereco.bairro = predes.endereco.bairro
					des.endereco.complemento = predes.endereco.complemento
					des.endereco.cidade = predes.endereco.cidade
					des.endereco.uf = predes.endereco.uf
					// #endregion
					
					// #region volume
					vol.idPrePostagem = prepost.id
					vol.idVolumePrePostado = prevol.id
					vol.idServico = prevol.idServico
					vol.idTransportadora = prevol.idTransportadora
					
					vol.chaveNotaFiscal = prevol.chaveNotaFiscal
					vol.numeroNotaFiscal = prevol.numeroNotaFiscal
					vol.valorNotaFiscal = this.$vsFormataMoeda(prevol.valorNotaFiscal)
					vol.valorDeclarado = this.$vsFormataMoeda(prevol.valorDeclarado)

					vol.tipoObjeto = prevol.tipoObjeto
					vol.altura = prevol.altura
					vol.largura = prevol.largura
					vol.comprimento = prevol.comprimento
					vol.peso = prevol.peso
					vol.prazoDias = prevol.prazoDias
					vol.observacoes = prevol.observacoes
					vol.adicionais = prevol.adicionais

					vol.etiqueta = prevol.etiqueta
					vol.etiquetaVolumePrePostado = prevol.etiqueta

					vol.codigoEntregaTransportadora = prevol.codigoEntregaTransportadora
					vol.codigoPostagemExterna = prevol.codigoPostagemExterna
					
					vol.servicoCodigo = prevol.servicoCodigo
					vol.servicoCodigoFilial = prevol.servicoCodigoFilial
					vol.servicoCodigoTransportadora = prevol.servicoCodigoTransportadora
					
					vol.servicoDescricao = prevol.servicoDescricao
					vol.servicoDescricaoCustomizada = prevol.servicoDescricaoCustomizada
					vol.servicoDescricaoFilial = prevol.servicoDescricaoFilial
					vol.servicoDescricaoTransportadora = prevol.servicoDescricaoTransportadora

					vol.servicoNivelContrato = prevol.servicoNivelContrato
					vol.servicoNivelContratoFilial = prevol.servicoNivelContratoFilial
					vol.servicoNivelContratoTransportadora = prevol.servicoNivelContratoTransportadora

					vol.valorFrete = prevol.valorFrete
					vol.valorFreteBase = prevol.valorFreteBase
					vol.valorFreteContratante = prevol.valorFreteContratante
					vol.valorFreteContratanteBase = prevol.valorFreteContratanteBase
					vol.valorFreteFilial = prevol.valorFreteFilial
					vol.valorFreteFilialBase = prevol.valorFreteFilialBase
					vol.valorFreteTarifacao = prevol.valorFreteTarifacao
					vol.valorFreteTransportadora = prevol.valorFreteTransportadora

					this.volumePrePostado = prevol
					this.liberaDimensoes()
					// #endregion

					// #region declaracao-conteudo
					await this.gravaDeclaracaoConteudoPrePostagem()
					// #endregion

					if (!post.saveState || post.saveState.etapaAtual !== "boxDestinatario") {
						this.avancar("boxDestinatario")
					}
				}
			}
		},
		criterioBloqueioEdicao() {
			let post = this.postagem
			let oprs = post.operacoes.filter(el => !el.isCancelado)
			let qtdVolsOpr = (oprs.length > 0) ? oprs[oprs.length - 1].volumes.filter(el => !el.isCancelado).length : 0

			return (this.config.atendimentoVencido || qtdVolsOpr > 0)
		},
		async validaAtendimentoVencido() {
			let cfg = this.config
			let post = this.postagem
			let oprs = post.operacoes.filter(el => !el.isCancelado)
			let prods = post.produtosServicos
			let qtdVolsTotal = oprs.filter(el => el.volumes.length).map(el => el.volumes).filter(el => !el.isCancelado).length
			let hoje = new Date()
			let dataAbertura = new Date(post.dataAbertura)

			hoje.setHours(0,0,0,0)
			dataAbertura.setHours(0,0,0,0)
			
			if (dataAbertura < hoje && (prods.length > 0 || qtdVolsTotal > 0)) {
				cfg.atendimentoVencido = true
				this.$bvModal.show("modalAtendimentoVencido")
				this.avancar("boxPagto")
			}
		},
		nomePagador() {
			let nome = this.pagador.nome.toUpperCase()
			let len = 20

			return (nome.length > len) ? `${nome.substring(0, len)}...` : nome
		},
		liberaDimensoes() {
			let vol = this.volume
			let envolpe = (vol.tipoObjeto === this.VS_TIPO_VOLUME_ENVELOPE)

			if (envolpe) {
				vol.altura = "0"
				vol.largura = "0"
				vol.comprimento = "0"
			}

			vol.dimensoesLiberadas = !envolpe
		},
		aplicaOperacaoPercentual() {
            let opr = this.comboPercentual.find(el => el.value == this.operacaoPercentual)
            
            this.valorOperacaoPercentual = 0

            if (opr.tipoValor === this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO) {
                this.valorOperacaoPercentual = this.$vsFormataMoeda(opr.valorMonetario)
            }
			this.calculaTotalAPagar()
      },
      liberaDescontoPercentual() {
          let opr = this.comboPercentual.find(el => el.value == this.operacaoPercentual)
          return !(opr && opr.tipoValor === this.VS_OPERACAO_BALCAO_TIPO_VALOR_LIVRE)
      },
  },
  // #endregion
}
</script>