<template>
  <div v-if="eventos.length">
    <!-- #region descricao -->
    <div class="text-left text-uppercase h4">
      {{ codigoDescricao }}
    </div>
    <!-- #endregion -->

    <!-- #region listagem -->
    <div class="bg-white mt-4 text-left p-4">
      <div class="mb-5" v-for="(evt, i) in eventos" :key="i">
        <div>
          <span class="float-right small text-muted">
            {{ $vsFormataData(evt.dataHora, true) }}
          </span>
          <span v-if="i === 0" class="cor-azul small font-weight-bold">
            <b-icon :icon="iconeEvento(evt)" scale="1.6" />
            &nbsp;&nbsp;&nbsp;{{ evt.evento }}
          </span>
          <span v-else class="small font-weight-bold">
            <b-icon :icon="iconeEvento(evt)" scale="1.6" />
            &nbsp;&nbsp;&nbsp;{{ evt.evento }}
          </span>
        </div>
        <div class="m-5 small">
          <span v-if="evt.localDestino.length">
            De {{ evt.localEvento }}<br>Para {{ evt.localDestino }}
          </span>
          <span v-else>{{ evt.localEvento }}</span>
        </div>
      </div>
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
export default {
  // #region configs
  name: "vs-rastreio-encomenda",
  props: {
    etiqueta: { type: String, required: true },
		isPrePostagem: { type: Boolean, default: false },
  },
  // #endregion

  // #region dados
  data() {
    return {
      codigoDescricao: "",
      eventos: [],
    }
  },
  // #endregion

  // #region triggers
  mounted() {
    this.rastrear()
  },
  // #endregion

  // #region metodos
  methods: {
    async rastrear() {
			this.eventos = []
      this.codigoDescricao = ""
			
			let etiqueta = this.etiqueta.trim().toUpperCase()

			if (etiqueta.length === 0) {
				return
			}

			let url = this.isPrePostagem 
						? `/pre-postagens/rastreio/${etiqueta}`
						: `/postagens/rastreio/${etiqueta}`
      let resp = await this.$vsApiRest("GET", url)

			if (resp.status === 200) {
        this.codigoDescricao = etiqueta
       	this.eventos = resp.data
      }
    },
    iconeEvento(obj) {
      let status = obj.evento.toLowerCase()

      if (obj.primeiroStatus) {
        return "box" // default inicial
      }
      if (status.includes("postado")) {
        return "envelope" // inicio
      }
      if (status.includes("entregue")) {
        return "gift" // fim
      }
      return "truck" // em transito
    },
  }
  // #endregion
}
</script>
