<template>
  <div>
    <!-- #region titulo -->
    <h4 class="titulo cor-azul text-center">Pré Postagem</h4>
    <!-- #endregion -->

    <!-- #region atendimento -->
		<!-- #region etapa1 - remetente/destinatario -->
    <div :key="'remetenteDestinatario'" v-if="config.etapaAtual == 'remetenteDestinatario'">
      <!-- #region remetente -->
      <div class="mb-3">
				<div class="mb-3 text-danger font-weight-bold" v-if="!filiais.length">Você não possui nenhum ponto de atendimento vinculado</div>
				<div class="mb-3">
					<b-form-checkbox class="form-input-cinza" size="lg" v-model="config.isRemetente" @change="defineRemetente()" switch :data-vs-nome="$vsDataVsNome('chkPagadorIsRemetente')">
            <span style="cursor: pointer">Sou o próprio remetente</span>
          </b-form-checkbox>
				</div>
        <fieldset v-if="!config.isRemetente">
          <legend class="mb-0">
						<h3 class="font-weight-bold">
							Remetente
							<button class="btn-cinza" style="width: 150px; height: 40px;" @click.prevent="limpaDados(operacaoAtual().remetente)" :data-vs-nome="$vsDataVsNome('btnLimpaDadosRemetente')">Limpar dados</button>
						</h3>
					</legend>
					<h6 class="mt-0 mb-4">Escolha um contato ou insira os dados manualmente</h6>

					<!-- #region formulario -->
          <vs-form-pre-postagem-pessoa 
						:obj="operacaoAtual().remetente" 
						:buscarRemetente="true"
						idBoxSanfona="remetente"
						:pessoas="pessoas" />
          <!-- #endregion -->
				</fieldset>
      </div>
      <!-- #endregion -->

      <!-- #region destinatario -->
      <div class="mb-3">
				<fieldset>
          <legend class="mb-0">
						<h3 class="font-weight-bold">
							Destinatário
							<button class="btn-cinza" style="width: 150px; height: 40px;" @click.prevent="limpaDados(operacaoAtual().destinatario)" :data-vs-nome="$vsDataVsNome('btnLimpaDadosDestinatario')">Limpar dados</button>
						</h3>
					</legend>
					<h6 class="mt-0 mb-4">Escolha um contato ou insira os dados manualmente</h6>

					<!-- #region formulario -->
          <vs-form-pre-postagem-pessoa 
						:obj="operacaoAtual().destinatario"  
						:buscarDestinatario="true"
						:isDestinatario="true" 
						idBoxSanfona="destinatario"
						:pessoas="pessoas" />
          <!-- #endregion -->
				</fieldset>
      </div>
      <!-- #endregion -->

			<!-- #region acoes -->
			<b-row>
        <b-col md="2">
          <button v-if="filiais.length" type="button" class="btn-azul" @click="cadastraRemetenteDestinatario()" :data-vs-nome="$vsDataVsNome('btnProximo')">Próximo</button>
					<button v-else type="button" class="btn-desabilitado">Próximo</button>
        </b-col>
      </b-row>
      <!-- #endregion -->
		</div>
		<!-- #endregion -->

		<!-- #region etapa2 - dimensoes -->
		<div :key="'dimensoes'" v-if="config.etapaAtual == 'dimensoes'">
			<fieldset class="mb-3">
          <legend class="mb-0"><h3 class="font-weight-bold">Dimensões</h3></legend>
					<h6 class="mt-0 mb-4">Estime as dimensões do seu pacote. Quanto mais precisa for sua medição, melhor será estimado o preço final.</h6>
			</fieldset>

			<!-- #region cubagem -->
			<div class="mb-3">
				<form>
					<!-- #region tipo, dimensoes e peso -->
					<b-form-row>
						<b-col md="3">
							<b-form-group class="form-input-cinza" label="Tipo de embalagem" label-cols="12">
								<b-form-select :options="$vsListaTiposObjetos()" v-model="volume.tipoObjeto" @change="liberaDimensoes()" :data-vs-nome="$vsDataVsNome('cmbVolumeTipoObjeto')"/>
							</b-form-group>
						</b-col>

						<b-col cols="6" md="2">
							<b-form-group class="form-input-cinza" label="Altura (cm)" label-cols="12">
								<b-form-input v-if="config.dimensoesLiberadas" class="text-right" type="text" v-model="volume.altura" @keyup="mascaraInteiro" :data-vs-nome="$vsDataVsNome('txtVolumeAltura')"/>
								<b-form-input v-else class="text-right" type="text" :value="volume.altura" disabled />
							</b-form-group>
						</b-col>

						<b-col cols="6" md="2">
							<b-form-group class="form-input-cinza" label="Largura (cm)" label-cols="12">
								<b-form-input v-if="config.dimensoesLiberadas" class="text-right" type="text" v-model="volume.largura" @keyup="mascaraInteiro" :data-vs-nome="$vsDataVsNome('txtVolumeLargura')"/>
								<b-form-input v-else class="text-right" type="text" :value="volume.largura" disabled />
							</b-form-group>
						</b-col>

						<b-col cols="6" md="2">
							<b-form-group class="form-input-cinza" label="Comprimento (cm)" label-cols="12">
								<b-form-input v-if="config.dimensoesLiberadas" class="text-right" type="text" v-model="volume.comprimento" @keyup="mascaraInteiro" :data-vs-nome="$vsDataVsNome('txtVolumeComprimento')"/>
								<b-form-input v-else class="text-right" type="text" :value="volume.comprimento" disabled />
							</b-form-group>
						</b-col>

						<b-col cols="6" md="2">
							<b-form-group class="form-input-cinza" label="Peso (gramas)" label-cols="12">
								<b-form-input class="text-right" type="text" v-model="volume.peso" @keyup="mascaraInteiro" :data-vs-nome="$vsDataVsNome('txtVolumePeso')"/>
							</b-form-group>
						</b-col>
					</b-form-row>
					<!-- #endregion -->

					<!-- #region nota fiscal, declarado e obs -->
					<div class="abre-fecha d-flex justify-content-between">
						<b-button type="button" pill variant="outline-dark" @click.prevent="mostraDeclaracaoConteudo()" :data-vs-nome="$vsDataVsNome('btnDeclaracaoConteudo')">
							Declaração Conteúdo
						</b-button>
						<a v-b-toggle.boxNotaFiscal	v-b-tooltip.hover title="Mostrar/Ocultar" :data-vs-nome="$vsDataVsNome('lnkExibeBoxNotaFiscal')">
							Mais dados&nbsp;&nbsp;
							<b-icon class="when-opened" icon="dash-square-dotted" scale="1.2" />
							<b-icon class="when-closed" icon="plus-square-dotted" scale="1.2" />
						</a>
					</div>

					<b-collapse id="boxNotaFiscal" accordion="sanfonaFluxo" role="tabpanel">
						<b-form-row>
							<b-col cols="12" md="6">
								<b-form-group class="form-input-cinza" label="Chave nota" label-cols="12">
									<b-form-input type="text" v-model="volume.chaveNotaFiscal" maxlength="44" @blur="defineNumeroNotaFiscal()" :data-vs-nome="$vsDataVsNome('txtChaveNotaFiscal')"/>
								</b-form-group>
							</b-col>

							<b-col cols="12" md="6">
								<b-form-group class="form-input-cinza" label="Nº Nota Fiscal" label-cols="12">
									<b-form-input type="text" v-model="volume.numeroNotaFiscal" :data-vs-nome="$vsDataVsNome('txtNumeroNotaFiscal')"/>
								</b-form-group>
							</b-col>

							<b-col cols="6" md="2">
								<b-form-group class="form-input-cinza" label="Valor Nota Fiscal" label-cols="12">
									<b-form-input class="text-right" type="text" v-model="volume.valorNotaFiscal" @keyup="mascaraMoeda" :data-vs-nome="$vsDataVsNome('txtValorNotaFiscal')"/>
								</b-form-group>
							</b-col>

							<b-col cols="6" md="2">
								<b-form-group class="form-input-cinza" label="Valor Declarado" label-cols="12">
									<b-form-input class="text-right" type="text" v-model="volume.valorDeclarado" @keyup="mascaraMoeda" :data-vs-nome="$vsDataVsNome('txtValorDeclarado')"/>
								</b-form-group>
							</b-col>

							<b-col cols="12" md="8">
								<b-form-group class="form-input-cinza" label="Observação" label-cols="12">
									<b-form-input type="text" v-model="volume.observacoes[0]" :data-vs-nome="$vsDataVsNome('txtObservacao')"/>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-collapse>
					<!-- #endregion -->
				</form>
    	</div>
			<div class="my-3 small text-center" style="color: #808080; opacity: 0.7;">
				Se as dimensões não forem informadas, serão utilizadas dimensões mínimas
			</div>
    	<!-- #endregion -->

			<!-- #region acoes-->
			<div>
				<b-row>
          <b-col md="2" cols="5">
            <button type="button" class="btn-azul" @click="voltar('remetenteDestinatario')" :data-vs-nome="$vsDataVsNome('btnVoltar')">Voltar</button>
          </b-col>
					<b-col md="8" cols="2"/>
          <b-col md="2" cols="5">
            <button type="button" class="btn-azul" @click="defineDimensoes()" :data-vs-nome="$vsDataVsNome('btnProximo')">Próximo</button>
          </b-col>
        </b-row>
      </div>
			<!-- #endregion -->
    </div>
		<!-- #endregion -->
	
		<!-- #region etapa3 - modalidade/servico -->
		<div :key="'modalidadeServico'" v-if="config.etapaAtual == 'modalidadeServico'">
			<div class="mb-3 font-weight-bold h5">Minha loja</div>
			<div class="mb-3" v-if="filialPadrao">
				<b-button variant="outline-dark" type="button" @click.prevent="avancar('pontoAtendimento')" :data-vs-nome="$vsDataVsNome('btnPontoAtendimento')">
					<b-img class="mr-3" rounded="circle" left alt="Foto do usuário" :src="$vsDefineFoto(filialPadrao.fotoBase64)" width="70" height="70" />
					<div class="text-left">
						<div class="h5 font-weight-bold">{{ filialPadrao.nome }}</div>
						<h6 class="small">
							<b-icon icon="geo-alt" /> X km | {{ filialPadrao.endereco.logradouro }}, {{ filialPadrao.endereco.numero }} -
							{{ filialPadrao.endereco.cidade }}<br>
							<b-icon icon="telephone" /> {{ $vsFormataTelefone(filialPadrao.telefone) }}
						</h6>
					</div>
				</b-button>
			</div>
			<fieldset>
          <legend class="mb-0"><h3 class="font-weight-bold">Modalidade</h3></legend>
					<h6 class="mt-0 mb-4">Escolha qual modalidade de envio você deseja postar</h6>
			</fieldset>

			<div>
				<form>
					<!-- #region adicionais -->
					<b-form-row class="mb-3">
						<b-col md="2">
							<b-form-checkbox size="lg" v-model="volume.adicionais[0]" value="AR" @change="defineAdicionais()" switch :data-vs-nome="$vsDataVsNome('chkAdicionalAR')">
								<span class="font-weight-bold" style="cursor: pointer">AR</span>
							</b-form-checkbox>
						</b-col>
					</b-form-row>
					<!-- #endregion -->

					<!-- #region tabela de frete -->
					<div v-if="tarifacaoServicos.length" class="mb-3 text-danger font-weight-bold">
						* Valores e prazos são estimativas
					</div>
					<b-form-row class="mb-2">
						<b-col cols="6" md="3" v-for="(tar, i) in tarifacaoServicos" :key="i" class="mb-2">
							<button type="button" class="box-azul text-center p-2" @click="defineServico(tar)" v-b-tooltip.hover title="Escolher esta modalidade" :data-vs-nome="$vsDataVsNome('btnModalidadeServico')">
								<span class="font-weight-bold h5">{{ tar.servicoDescricao }}</span><br>
								<span>R$ {{ $vsFormataMoeda(tar.valorFrete) }}</span><br>
								<span>{{ tar.prazoDias }} dias úteis</span><br>
								<span>{{ tar.observacaoFrete }}</span><br>
							</button>
						</b-col>
					</b-form-row>
					<!-- #endregion -->
				</form>
			</div>

			<!-- #region acoes-->
			<div>
				<b-row>
          <b-col md="2">
            <button type="button" class="btn-azul" @click="voltar('dimensoes')" :data-vs-nome="$vsDataVsNome('btnVoltar')">Voltar</button>
          </b-col>
        </b-row>
      </div>
			<!-- #endregion -->
    </div>
		<!-- #endregion -->

		<!-- #region etapa3 estendida - ponto de atendimento -->
		<div :key="'pontoAtendimento'" v-if="config.etapaAtual == 'pontoAtendimento'">
			<div>
				<fieldset>
          <legend class="mb-0"><h3 class="font-weight-bold">Ponto de Atendimento</h3></legend>
					<h6 class="mt-0 mb-3">Pesquise por um ponto de atendimento vinculado</h6>

					<b-form-group class="form-input-cinza" label="" label-cols="12" label-size="lg">
						<model-select v-model="filialPadrao" :options="filiais" :data-vs-nome="$vsDataVsNome('cmbFilialPadrao')"/>
					</b-form-group>
				</fieldset>
			</div>

			<div class="mb-3 d-flex justify-content-start">
					<b-img class="mr-3" rounded="circle" left alt="Foto do usuário" :src="$vsDefineFoto(filialPadrao.fotoBase64)" width="70" height="70" />
					<div>
						<div class="h5 font-weight-bold">{{ filialPadrao.nome }}</div>
						<h6 class="small">
							<b-icon icon="geo-alt" /> X km | {{ filialPadrao.endereco.logradouro }}, {{ filialPadrao.endereco.numero }} -
							{{ filialPadrao.endereco.cidade }}<br>
							<b-icon icon="telephone" /> {{ $vsFormataTelefone(filialPadrao.telefone) }}
						</h6>
					</div>
				</div>

				<!-- #region acoes-->
				<b-row>
					<b-col md="2">
						<button type="button" class="btn-azul" @click="definePontoAtendimento()" :data-vs-nome="$vsDataVsNome('btnPontoAtendimento')">Confirmar</button>
					</b-col>
				</b-row>
				<!-- #endregion -->
    </div>
		<!-- #endregion -->

		<!-- #region etapa4 - resumo e finalizacao -->
		<div :key="'resumoFinalizacao'" v-if="config.etapaAtual == 'resumoFinalizacao'">
			<fieldset>
          <legend class="mb-0">
						<h3 class="font-weight-bold">Resumo pré-Postagem</h3>
					</legend>
			</fieldset>
			
      <!-- #region resumo -->
      <div class="mb-3 bg-white p-2">
        <b-row class="h5">
            <b-col>
              <div class="mt-4 mb-3">
								<div class="mb-3" v-for="(opr, i) in postagem.operacoes.filter(el => !el.isCancelada)" :key="i">
									<!-- #region servico -->
									<b-form-row class="mb-3">
										<b-col md="3" v-if="exibeDeclaracaoConteudo() || exibeAR(opr.volumes[0])">
											<div class="mb-3" v-if="exibeDeclaracaoConteudo()">
												<a class="text-primary" @click.prevent="mostraDeclaracaoConteudo(false)" v-b-tooltip.hover.left="'Imprimir Declaração de conteúdo'" :data-vs-nome="$vsDataVsNome('lnkDeclaracaoConteudo')">
													<b-icon icon="printer" variant="info" /> Declaração de Conteúdo
												</a>
											</div>
											<div class="mb-3" v-if="exibeAR(opr.volumes[0])">
												<a class="text-primary" @click.prevent="imprimeAR(opr.volumes[0])" v-b-tooltip.hover.left="'Imprimir Aviso de Recebimento'" :data-vs-nome="$vsDataVsNome('lnkAdicionalAR')">
													<b-icon icon="printer" variant="info" /> Aviso de Recebimento
												</a>
											</div>
										</b-col>
										<b-col>
											<span>{{ opr.volumes[0].servicoDescricao }}</span>
											<span class="float-right">
												R$ {{ $vsFormataMoeda(opr.volumes[0].valorFrete) }}&nbsp;
												<a @click.prevent="limpaAtendimento()" v-b-tooltip.hover title="Excluir" :data-vs-nome="$vsDataVsNome('btnLimpaAtendimento')">
													<b-icon icon="trash" variant="danger" />
												</a>
											</span>
										</b-col>
									</b-form-row>
									<!-- #endregion -->
									
									<!-- #region dados destinatario -->
									<div class="mb-2">{{ opr.destinatario.nome }}</div>
									<div class="mb-2">
										{{ opr.destinatario.endereco.logradouro }},
										{{ opr.destinatario.endereco.numero }},
										{{ opr.destinatario.endereco.bairro }}
									</div>
									<div class="mb-4">
										{{ $vsFormataCep(opr.destinatario.endereco.cep) }},
										{{ opr.destinatario.endereco.cidade }},
										{{ opr.destinatario.endereco.uf }}
									</div>
									<!-- #endregion -->

									<!-- #region dados de postagem -->
									<div class="mb-2">
										Rótulo:
										<a class="text-black" @click.prevent="imprimeEtiqueta(opr.volumes[0].etiqueta)" v-b-tooltip.hover.left="'Imprimir'" :data-vs-nome="$vsDataVsNome('lnkImprimeEtiqueta')">
											<b-icon icon="printer" class="text-info" />&nbsp;{{ opr.volumes[0].etiqueta }}
										</a>
									</div>
									<div class="mb-2" v-if="opr.volumes[0].adicionais[0].length">Adicionais: {{ opr.volumes[0].adicionais[0] }}</div>
									<div class="mb-2" v-if="opr.volumes[0].numeroNotaFiscal.length">NF: {{ opr.volumes[0].numeroNotaFiscal }}</div>
									<div class="mb-2">
										Peso:
										<span class="float-right">{{ opr.volumes[0].peso }}g</span>
									</div>
									<div class="mb-2">
										Dimensões: 
										<span class="float-right">
											{{ $vsFormataNumero(opr.volumes[0].altura) }} x
											{{ $vsFormataNumero(opr.volumes[0].largura) }} x
											{{ $vsFormataNumero(opr.volumes[0].comprimento) }}cm
										</span>
									</div>
									<!-- #endregion -->
								</div>
              </div>
              <div class="mb-2 h4 font-weight-bold">
                <span class="text-danger">Valor Estimado</span>
                <span class="float-right">R$ {{ $vsFormataMoeda(calculaTotalAPagar()) }}</span>
              </div>
            </b-col>
          </b-row>
      </div>
      <!-- #endregion -->

			<!-- #region acoes-->
			<b-row>
        <b-col md="3">
          <button type="button" class="btn-azul" @click="novoAtendimento()" :data-vs-nome="$vsDataVsNome('btnNovoAtendimento')">Nova Pré Postagem</button>
        </b-col>
      </b-row>
      <!-- #endregion -->
    </div>
		<!-- #endregion -->
    <!-- #endregion -->

    <!-- #region modais -->
		<!-- #region cancelamento atendimento -->
    <b-modal id="modalCancelamento" title="Cancelamento">
      <div class="h5">
        <div class="mb-3 text-danger font-weight-bold">Existem Operações em aberto</div>
        <div class="mb-3">Deseja continuar com o cancelamento da pré-postagem?</div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="cancelaAtendimento()" :data-vs-nome="$vsDataVsNome('btnCancelaAtendimento')">Confirmar</b-button>
          <b-button class="float-right" variant="secondary" @click="hide()" :data-vs-nome="$vsDataVsNome('btnFechaModal')">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
		<!-- #endregion -->

		<!-- #region declaracao conteudo -->
    <b-modal id="modalDeclaracaoConteudo" title="Declaração de Conteúdo" size="lg">
      <div class="h5 text-nowrap">
		<div v-if="declaracaoConteudo.etiqueta.length" class="mb-3">Rótulo: {{ declaracaoConteudo.etiqueta }}</div>
        <div class="mb-3">Máximo {{ VS_MAX_ITENS_DECLARACAO_CONTEUDO }} itens</div>
        <!-- #region cadastro -->
        <form v-if="declaracaoConteudo.cadastro" @submit.prevent="gravaDeclaracaoConteudo(true)">
          <b-form-row>
            <b-col md="5">
              <b-form-group class="form-input-cinza" label="Descrição" label-cols="12">
                <b-form-input ref="descricaoDeclaracaoConteudo" type="text" v-model="declaracaoConteudoItem.descricao" :data-vs-nome="$vsDataVsNome('txtDescricaoDeclaracaoConteudoItem')"/>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Qtd" label-cols="12">
                <b-form-input type="text" class="text-right" v-model="declaracaoConteudoItem.quantidade" @keyup="mascaraInteiro" :data-vs-nome="$vsDataVsNome('txtQtdDeclaracaoConteudoItem')"/>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Valor R$" label-cols="12">
                <b-form-input type="text" class="text-right" v-model="declaracaoConteudoItem.valor" @keyup="mascaraMoeda" :data-vs-nome="$vsDataVsNome('txtValorDeclaracaoConteudoItem')"/>
              </b-form-group>
            </b-col>

            <b-col md="2">
							<b-form-group class="text-white" label="." label-cols="12">
              	<b-button type="submit" class="btn-laranja p-0 m-0" size="lg" style="height: 40px;" :data-vs-nome="$vsDataVsNome('btnGravaDeclaracaoConteudoItem')" :disabled="(declaracaoConteudo.itens.length >= VS_MAX_ITENS_DECLARACAO_CONTEUDO)">Gravar</b-button>
							</b-form-group>
            </b-col>
          </b-form-row>
        </form>
        <!-- #endregion -->

        <!-- #region listagem/exclusao -->
				<div class="pr-3 pb-2">
					<b-row class="mb-3 d-flex justify-content-between" v-for="(item, i) in declaracaoConteudo.itens" :key="i">
						<b-col>{{ item.descricao }}</b-col>
						<b-col class="text-right">{{ item.quantidade }}</b-col>
						<b-col class="text-right">
							R$ {{ $vsFormataMoeda(item.valor) }}&nbsp;
							<a @click.prevent="excluiDeclaracaoConteudo(i)" :data-vs-nome="$vsDataVsNome('lnkApagaDeclaracaoConteudoItem')">
								<b-icon icon="trash" variant="danger" />
							</a>
						</b-col>
					</b-row>
				</div>
        <b-row v-if="declaracaoConteudo.itens.length" class="mt-3 d-flex justify-content-between font-weight-bold h4">
          <b-col>Total ({{ declaracaoConteudo.itens.length }})</b-col>
          <b-col class="text-right">{{ calculaQtdDeclaracaoConteudo() }}</b-col>
          <b-col class="text-right">R$ {{ $vsFormataMoeda(calculaTotalDeclaracaoConteudo()) }}&nbsp;</b-col>
        </b-row>
        <!-- #endregion -->
      </div>
      <template #modal-footer="{ hide }">
				<!-- #region opcoes -->
        <div class="w-100 d-flex justify-content-between">
					<b-button v-if="declaracaoConteudo.itens.length && !declaracaoConteudo.cadastro" size="sm" type="button" variant="primary" class="text-nowrap" @click="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_A4)" :data-vs-nome="$vsDataVsNome('btnImprimeDeclaracaoConteudoA4')">
						<span class="font-weight-bold">PDF A4</span>
					</b-button>

					<b-button v-if="declaracaoConteudo.itens.length && !declaracaoConteudo.cadastro" size="sm" type="button" variant="success" class="text-nowrap" @click="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_10x15)" :data-vs-nome="$vsDataVsNome('btnImprimeDeclaracaoConteudo10x15')">
						<span class="font-weight-bold">PDF 10x15</span>
					</b-button>

          <b-button v-if="declaracaoConteudo.itens.length" size="sm" type="button" variant="secondary" @click="excluiDeclaracaoConteudo()" :data-vs-nome="$vsDataVsNome('btnApagaDeclaracaoConteudo')">
            <span class="font-weight-bold">Limpar</span>
          </b-button>

          <b-button size="sm" type="button" variant="danger" @click="hide()" :data-vs-nome="$vsDataVsNome('btnFechaModal')">
            <span class="font-weight-bold">Fechar</span>
          </b-button>
        </div>
				<!-- #endregion -->
      </template>
    </b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
  </div>
</template>

<script>
import VsFormPrePostagemPessoa from "../templates/vs-form-pre-postagem-pessoa.vue"
import { ModelSelect } from "vue-search-select"

export default {
  // #region configs
  name: "pre-postagem",
  path: "/pre-postagem",
  requireAuth: true,
  components: { VsFormPrePostagemPessoa, ModelSelect, },
  // #endregion

  // #region dados
  data() {
    return {
			filiais: [],
			filialPadrao: null,
      config: null,
      pagador: null,
      postagem: null,
      volume: null,
      declaracaoConteudo: null,
      declaracaoConteudoItem: null,
			tarifacaoServicos: [],
			pessoas: [],
    }
  },
  // #endregion

	// #region watches
	watch: {
  	"config.etapaAtual"(nome) {
			if (nome === "dimensoes") {
				this.defineFilialPadrao()
			}
      if (nome === "modalidadeServico") {
				this.consultaTarifacao()
      }
    },
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_CLIENTE])
		this.configs()
  },
	mounted() {
		this.bootstrap()
	},
  // #endregion

  // #region metodos
  methods: {
		configs() {
			this.pagador = this.$root.sessao
      this.postagem = this.formPostagem()
      this.volume = this.formVolume()
      this.config = this.formConfig()
			this.declaracaoConteudo = this.formDeclaracaoConteudo()
			this.declaracaoConteudoItem = this.formDeclaracaoConteudoItem()
		},
		async bootstrap() {
			await this.listaPessoas()
			await this.listaFiliais()

			if (this.filiais.length) {
				await this.defineFilialPadrao()
    		await this.iniciaPostagem()
			}
		},
		async listaPessoas() {
			let query = `idsAdmins=${this.$root.sessao.id}`
      let resp = await this.$vsApiRest("GET", `/pessoas/pre-postagem/?${query}`)

			if (resp.status === 200) {
				this.pessoas = resp.data
			}
		},
		async listaFiliais() {
			let sessao = this.$root.sessao
			let query = `idPagador=${sessao.id}&ativo=true`
			let resp = await this.$vsApiRest("GET", `/usuarios/filiais/?${query}`)
        
			if (resp.status === 200) {
        let vinculosAtivos = sessao.vinculos.filter(el => el.isAtivo).map((el) => { return el.idUsuario })
				let fils = resp.data.filter(el => vinculosAtivos.includes(el.id))

				fils.forEach(el => {
					el.text = el.nome
					el.value = el.id
				})
				this.filiais = fils
      }
		},
		async defineFilialPadrao() {
			let sessao = this.$root.sessao
			let padrao = sessao.vinculos.find(el => el.isPadrao)
			let fils = this.filiais

			if (fils.length === 0) {
				return
			}
			if (padrao) {
				this.filialPadrao = fils.find(el => el.id === padrao.idUsuario)
			} 
			if (!this.filialPadrao) {
				this.filialPadrao = fils[0]
			}
			await this.defineContratante()
		},
		async defineContratante() {
			let fil = this.filialPadrao
			let contratante = fil.vinculos.find(el => el.isAtivo && el.isSuperior)

			if (!contratante) {
				return
			}
			let query = `idContratante=${contratante.idUsuario}`
			let resp = await this.$vsApiRest("GET", `/usuarios/contratantes?${query}`)
      
			if (resp.status === 200 && resp.data.length) {
				let post = this.postagem
				post.idContratante = resp.data[0].id
				post.idFilial = fil.id
				await this.$vsApiRest("POST", "/pre-postagens/inicio", post)
      }
		},
    formConfig() {
      return {
				etapaAtual: "remetenteDestinatario",
        isLiberada: true,
        isRemetente: true,
        tiposContrato: [],
        valorPagtoDinheiro: 0,
        definiuServico: false,
        tarifacaoServicos: [],
        idServicoTravado: null,
        idTransportadoraTravada: null,
				pontoAtendimento: null,
				dimensoesLiberadas: true,
      }
    },
    formPostagem() {
			let sessao = this.$root.sessao

      return {
        id: null,
        isFinalizada: false,
        dataCriacao: new Date().toISOString(),
        idContratante: null,
        idFilial: null,
        idOperador: sessao.id,
        idPagador: sessao.id,
        idCaixa: null,
        operacoes: [this.formOperacao()],
        produtosServicos: [],
        formaPagamento: {
          metodo: 0,
          valorTotal: 0,
          contratoCopia: null,
        },
        comprovante: 0,
      }
    },
    formBasico(tipoCad) {
      return {
        tipoForm: tipoCad,
        cpfCnpj: "",
        nome: "",
				nomeFantasia: "",
        email: "",
				perfil: null,
        telefone: "",
				aosCuidados: "",
        foto: null,
        cargo: null,
        isAtivo: true,
        isExcluido: false,
        endereco: {
          tipo: this.VS_TIPO_ENDERECO_RUA,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          complemento: "",
          isComercial: true,
					vazio: false,
        },
        contratos: [],
        vinculos: [],
				origemCadastro: 0,
				cadastroPivot: false,
				preCadastro: false
      }
    },
    formOperacao() {
      return {
        id: null,
        isCancelada: false,
        idUsuarioCancelamento: null,
        motivoCancelamento: "",
        remetente: this.formBasico("Remetente"),
        destinatario: this.formBasico("Destinatário"),
        volumes: [],
        idPagador: null,
        isRemetentePagador: false,
      }
    },
    formVolume() {
      return {
        id: null,
        isCancelado: false,
        idUsuarioCancelamento: null,
        motivoCancelamento: "",
        tipoObjeto: this.VS_TIPO_VOLUME_CAIXA,
        peso: 0,
        altura: 0,
        largura: 0,
        comprimento: 0,
        valorDeclarado: 0,
        valorNotaFiscal: 0,
        numeroNotaFiscal: "",
        chaveNotaFiscal: "",
        servicoDescricao: "",
        servicoCodigo: "",
        valorFreteTransportadora: 0,
        valorFreteContratanteBase: 0,
        valorFreteContratante: 0,
        valorFreteTarifacao: 0,
        valorFreteFilialBase: 0,
        valorFreteFilial: 0,
        valorFrete: 0,
        prazoDias: 0,
        etiqueta: "",
        codigoPostagemExterna: "",
        codigoEntregaTransportadora: "",
        servicoRedirecionado: null,
        observacaoFrete: "",
        observacoes: [""],
        adicionais: [""],
        idTransportadora: null,
        idServico: null,
      }
    },
		formDeclaracaoConteudo() {
			return {
				id: null,
        idAtendimento: null,
        idContratante: null,
        idFilial: null,
        idOperador: null,
				idTransportadora: null,
        etiqueta: "",
        itens: [],
        valorTotal: 0,
				cadastro: true,
			}
		},
		formDeclaracaoConteudoItem() {
			return {
        descricao: "",
        quantidade: 1,
        valor: 0
      }
		},
    async novoAtendimento() {
			this.configs()
      await this.iniciaPostagem()
    },
    validaPessoa(obj) {
      let ok = true
      let erros = [`Erros na etapa do ${obj.tipoForm}:`]

      // obrigatorios
      if (!this.$vsNomeValido(obj.nome)) {
        erros.push("Nome / Razão Social inválido, não pode estar em branco nem conter caracteres especiais")
        ok = false
      }
			if (obj.nomeFantasia && !this.$vsNomeValido(obj.nomeFantasia)) {
        erros.push("Apelido / Nome Fantasia inválido, evite caracteres especiais")
        ok = false
      }
      
      // opcionais
			if (obj.cpfCnpj && !this.$vsCpfCnpjValido(this.$vsLimpaFormatacao(obj.cpfCnpj))) {
        ok = false
        erros.push("CPF/CNPJ inválido")
      }
      if (obj.email && !this.$vsEmailValido(obj.email)) {
        ok = false
        erros.push("Email inválido")
      }
      if (obj.telefone && !this.$vsTelefoneValido(this.$vsLimpaFormatacao(obj.telefone))) {
        ok = false
        erros.push("Telefone inválido")
      }

      // obrigatorios
      let end = obj.endereco
      if (end.vazio || this.$vsLimpaFormatacao(end.cep).length !== 8) {
        ok = false
        erros.push("Cep inválido")
      }
      if (end.uf.length !== 2) {
        ok = false
        erros.push("UF inválida")
      }
      if (end.logradouro.length === 0) {
        ok = false
        erros.push("Logradouro inválido")
      }
      if (end.numero.length === 0) {
        ok = false
        erros.push("Número inválido, caso não exista informe s/n")
      }
      if (end.bairro.length === 0) {
        ok = false
        erros.push("Bairro inválido")
      }
      if (end.cidade.length === 0) {
        ok = false
        erros.push("Cidade inválida")
      }
      if (end.pais.length === 0) {
        ok = false
        erros.push("País inválido")
      }

      // erros numa unica notificacao
      if (!ok) {
        const html = this.$createElement
        const nodes = []
        erros.forEach((el, i) => {
          if (i === 0) {
            nodes.push(el)
          } else {
            nodes.push(`- ${el}`)
          }
          nodes.push(html("br"))
        })
        this.$vsNotificacao([html('p', nodes)], "warning")
      }
      return ok
    },
		validaVolume() {
      let ok = true
      let erros = [`Erros na etapa das Dimensões:`]

      // tela dimensoes
      let vol = this.volume
      let valorNFe = this.$vsConverteMoeda(vol.valorNotaFiscal)
      let valorDecl = this.$vsConverteMoeda(vol.valorDeclarado)

      // opcionais
      if (vol.chaveNotaFiscal.length) {
        if (!/[\d]{44}/.test(vol.chaveNotaFiscal)) {
          ok = false
          erros.push("Chave da Nota fiscal inválida, deve conter apenas números e possuir 44 dígitos")
        }
        if (!vol.numeroNotaFiscal.length) {
          ok = false
          erros.push("Ao informar a chave da Nota fiscal, o número da nota é necessário")
        }
      }
      if (vol.numeroNotaFiscal.length) {
        if (!/[\d]/.test(vol.numeroNotaFiscal)) {
          ok = false
          erros.push("Número da Nota fiscal inválido, deve conter apenas números")
        }
        if (valorNFe <= 0) {
          ok = false
          erros.push("Ao informar o número da Nota fiscal, o valor da nota é necessário")
        }
      } else if (valorNFe > 0) {
        ok = false
        erros.push("Ao informar o valor da Nota fiscal, o número da nota  é necessário")
      }
      if (valorNFe > 0 && valorDecl > valorNFe) {
        ok = false
        erros.push("Valor declarado não pode ser maior que o valor da nota fiscal")
      }

      // obrigatorios
      if (!vol.peso || vol.peso < 1) {
        ok = false
        erros.push("Peso inválido")
      }
			/*if (vol.tipoObjeto !== this.VS_TIPO_VOLUME_ENVELOPE) {
				if (!vol.altura || vol.altura < 1) {
					ok = false
					erros.push("Altura inválida")
				}
				if (!vol.largura || vol.largura < 1) {
					ok = false
					erros.push("Largura inválida")
				}
				if (!vol.comprimento || vol.comprimento < 1) {
					ok = false
					erros.push("Comprimento inválido")
				}
			}*/

      // erros numa unica notificacao
      if (!ok) {
        const html = this.$createElement
        const nodes = []
        erros.forEach((el, i) => {
          if (i === 0) {
            nodes.push(el)
          } else {
            nodes.push(`- ${el}`)
          }
          nodes.push(html("br"))
        })
        this.$vsNotificacao([html('p', nodes)], "warning")
      }
      return ok
    },
		validaModalidade() {
      let ok = true
      let erros = [`Erros na etapa da Modalidade:`]

      // tela dimensoes
      let vol = this.volume

      if (vol.servicoCodigo.length === 0) {
        ok = false
        erros.push("Escolha uma modalidade de envio")
      }

      // erros numa unica notificacao
      if (!ok) {
        const html = this.$createElement
        const nodes = []
        erros.forEach((el, i) => {
          if (i === 0) {
            nodes.push(el)
          } else {
            nodes.push(`- ${el}`)
          }
          nodes.push(html("br"))
        })
        this.$vsNotificacao([html('p', nodes)], "warning")
      }
      return ok
    },
    async voltar(rota) {
			this.config.etapaAtual = rota
      await this.gravaSaveState(rota)
    },
    async avancar(rota) {
			this.config.etapaAtual = rota

			if (rota !== "resumoFinalizacao") {
				await this.gravaSaveState(rota)
			}
    },
    async cadastraPessoas(objs) {
      // cadastra remetente e/ou destinatario para consultas posteriores
			let id = this.$root.sessao.id
      let lista = []

			objs.forEach((obj) => {
				let pessoa = this.clonagem(obj)
				
				pessoa.vinculos = [
					{ idUsuario: id, isSuperior: true },
				]
				if (!this.pessoas.find(el => el.cpfCnpj === this.$vsLimpaFormatacao(pessoa.cpfCnpj))) {
					this.pessoas.push(pessoa)
				}
				lista.push(pessoa)
			})
      await this.$vsApiRest("POST", "/pessoas/pre-postagem", lista)
			await this.listaPessoas()
    },
    defineRemetente() {
			this.limpaFormRemetente()
      
      if (this.config.isRemetente) {
        let rem = this.operacaoAtual().remetente
        let pag = this.pagador

        rem.nome = pag.nome
        rem.cpfCnpj = this.$vsFormataCpfCnpj(pag.cpfCnpj)
        rem.email = pag.email
        rem.telefone = this.$vsFormataTelefone(pag.telefone)
        rem.endereco.cep = this.$vsFormataCep(pag.endereco.cep)
        rem.endereco.logradouro = pag.endereco.logradouro
        rem.endereco.numero = pag.endereco.numero
        rem.endereco.bairro = pag.endereco.bairro
        rem.endereco.complemento = pag.endereco.complemento
        rem.endereco.cidade = pag.endereco.cidade
        rem.endereco.uf = pag.endereco.uf
      }
    },
    cadastraRemetenteDestinatario() {
			let opr = this.operacaoAtual()
      let rem = opr.remetente
			let des = opr.destinatario

      if (this.validaPessoa(rem) && this.validaPessoa(des)) {
        this.cadastraPessoas([rem, des]) // background
        this.avancar("dimensoes")
      }
    },
    async imprimeRecibo() {
			let post = this.postagem
			let query = `idAtendimento=${post.id}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/impressao/recibo/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `recibo-postair-${post.comprovante}.pdf`)
			}
    },
    imprimeEtiqueta(etiqueta) {
      this.$vsImprimeEtiquetaPrePostagem(etiqueta)
    },
    calculaTotalFrete() {
      let total = 0

      this.postagem.operacoes.forEach(p => {
        if (!p.isCancelada) {
          p.volumes.forEach(v => {
            if (!v.isCancelado) {
              total += v.valorFrete
            }
          })
        }
      })
      return total
    },
    calculaTotalAPagar() {
      let total = this.calculaTotalFrete()
      let post = this.postagem
      let pagto = post.formaPagamento

      total = parseFloat(total.toFixed(2))

      if (total < 0) {
        total = 0
      }
      pagto.valorTotal = total

      return total
    },
    async iniciaPostagem() {
			let resp = await this.$vsApiRest("POST", "/pre-postagens/inicio", this.postagem)
      
			if (resp.status === 200) {
				let post = resp.data

				let declaracao = this.declaracaoConteudo
				declaracao.idAtendimento = post.id
				declaracao.idContratante = post.idContratante
				declaracao.idFilial = post.idFilial
				declaracao.idOperador = post.idOperador

				this.postagem = post

				this.defineRemetente()
      	this.recuperaSaveState()
      }
    },
    async finalizaPostagem() {
      let post = this.postagem
      post.idPagador = this.pagador.id

      let req = {
        idPostagem: post.id,
        idPagador: post.idPagador,
        idCaixa: null,
        formaPagamento: post.formaPagamento,
        produtosServicos: post.produtosServicos,
      }
			let resp = await this.$vsApiRest("PATCH", "/pre-postagens/finalizacao", req)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Pré Postagem efetuada com sucesso", "success")
				await this.defineFilialPadrao()
      }
    },
      mascaraMoeda(evt) {
          if (evt.key == "Enter") {
              return
          }
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    novaOperacao() {
      let cfg = this.config
      let oprAnterior = this.operacaoAtual()
      let oprNova = this.formOperacao()

      cfg.idServicoTravado = null
      cfg.idTransportadoraTravada = null
			cfg.dimensoesLiberadas = true

      oprNova.remetente = this.clonagem(oprAnterior.remetente)
			oprNova.destinatario = this.clonagem(oprAnterior.destinatario)

      this.postagem.operacoes.push(oprNova)

      this.voltar("remetenteDestinatario")
    },
    async novoVolume() {
			let opr = this.operacaoAtual()
      let post = this.postagem
      let cfg = this.config
      let vol = opr.volumes[0]
			let declr = this.declaracaoConteudo

      opr.idPagador = null
      opr.isRemetentePagador = false

      if (cfg.isRemetente) {
        opr.idPagador = post.idPagador
        opr.isRemetentePagador = true
      }

      vol.valorDeclarado = this.$vsConverteMoeda(vol.valorDeclarado)
      vol.valorNotaFiscal = this.$vsConverteMoeda(vol.valorNotaFiscal)

      let req = {
        idPostagem: post.id,
        idOperacao: opr.id,
        remetente: opr.remetente,
        destinatario: opr.destinatario,
        volume: vol,
        idPagador: opr.idPagador,
        isRemetentePagador: opr.isRemetentePagador,
      }
			let resp = await this.$vsApiRest("PATCH", "/pre-postagens/remessa/", req)

      if (resp.status === 200) {
        let volDB = resp.data.volume
        let dimensoesAjustadas = (
          volDB.altura != vol.altura ||
          volDB.largura != vol.largura ||
          volDB.comprimento != vol.comprimento
        )

        vol.id = volDB.id
        vol.altura = volDB.altura
        vol.largura = volDB.largura
        vol.comprimento = volDB.comprimento
        vol.etiqueta = volDB.etiqueta
        vol.codigoPostagemExterna = volDB.codigoPostagemExterna
        vol.codigoEntregaTransportadora = volDB.codigoEntregaTransportadora
        vol.servicoRedirecionado = volDB.servicoRedirecionado
        vol.isCancelado = false

        opr.id = resp.data.idOperacao
        opr.isCancelada = false
				opr.volumes[0] = vol

        this.tarifacaoServicos = []
        cfg.idServicoTravado = vol.idServico
        cfg.idTransportadoraTravada = vol.idTransportadora

				declr.etiqueta = vol.etiqueta
				declr.idTransportadora = vol.idTransportadora
				declr.idAtendimento = post.id
      	declr.idContratante = post.idContratante
      	declr.idFilial = post.idFilial
      	declr.idOperador = post.idOperador

        this.$vsNotificacao(`Volume ${vol.etiqueta} adicionado, veja no resumo`, "success")

        if (dimensoesAjustadas) {
          this.$vsNotificacao(`Peso/dimensões ajustados para o mínimo, veja no resumo`, "info")
        }
				await this.gravaDeclaracaoConteudo()
        this.imprimeEtiqueta(vol.etiqueta)
				await this.finalizaPostagem()
        this.avancar("resumoFinalizacao")
      }
    },
    async cancelaAtendimento() {
      let post = this.postagem
      let req = {
        idAtendimento: post.id,
        idResponsavel: post.idOperador,
        motivo: "Postagem cancelada na pré-postagem",
				cancelamentoOrigem: this.VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_PREPOSTAGEM,
      }
      let resp = await this.$vsApiRest("PATCH", "/pre-postagens/cancelamento", req)

      if (resp.status === 200) {
        this.$bvModal.hide("modalCancelamento")
      	this.novoAtendimento()
      }
    },
    operacaoAtual() {
      let oprs = this.postagem.operacoes

			if (!oprs.length) {
				oprs.push(this.formOperacao())
			}
      return oprs[oprs.length - 1]
    },
    clonagem(obj) {
      return JSON.parse(JSON.stringify(obj))
    },
    recuperaSaveState() {
			let post = this.postagem

			if (!post.saveState) {
				return
			}

			let checkPoint = post.saveState
			this.pagador = JSON.parse(checkPoint.jsonPagador)
			this.config = JSON.parse(checkPoint.jsonConfigNavegador)
			this.filialPadrao = this.config.pontoAtendimento

			let opr = this.operacaoAtual()
			let rem = JSON.parse(checkPoint.jsonRemetente)
			let des = JSON.parse(checkPoint.jsonDestinatario)
			let vol = JSON.parse(checkPoint.jsonVolume)

			if (rem) {
				opr.remetente = rem
			}
			if (des) {
				opr.destinatario = des
			}
			if (vol) {
				this.volume = vol
				this.liberaDimensoes()
			}
    },
    async gravaSaveState(etapaAtual) {
			let cfg = this.config
			cfg.pontoAtendimento = this.filialPadrao

			let opr = this.operacaoAtual()
			let req = {
				etapaAtual: etapaAtual,
				jsonConfigNavegador: JSON.stringify(cfg),
				jsonPagador: JSON.stringify(this.pagador),
				jsonRemetente: JSON.stringify(opr.remetente),
				jsonDestinatario: JSON.stringify(opr.destinatario),
				jsonVolume: JSON.stringify(this.volume),
			}
      await this.$vsApiRest("POST", `/pre-postagens/save-state/${this.postagem.id}`, req)
    },
    mascaraInteiro(evt) {
      evt.target.value = this.$vsLimpaFormatacao(evt.target.value)
    },
    limpaFormRemetente() {
      let rem = this.operacaoAtual().remetente
      rem.nome = ""
			rem.nomeFantasia = ""
      rem.cpfCnpj = ""
      rem.email = ""
      rem.telefone = ""
      rem.endereco.cep = ""
      rem.endereco.logradouro = ""
      rem.endereco.numero = ""
      rem.endereco.bairro = ""
      rem.endereco.complemento = ""
      rem.endereco.cidade = ""
      rem.endereco.uf = ""
    },
    limpaAtendimento() {
      let operacoes = this.postagem.operacoes.filter(el => !el.isCancelada)
      if (operacoes.length > 0) {
        this.$bvModal.show("modalCancelamento")
      } else {
        this.cancelaAtendimento()
      }
    },
    async mostraDeclaracaoConteudo(cadastro=true) {
			let declaracao = this.declaracaoConteudo

			if (cadastro) {
				let postagem = this.postagem
				let volume = this.volume

				declaracao.idAtendimento = postagem.id
				declaracao.idContratante = postagem.idContratante
				declaracao.idFilial = postagem.idFilial
				declaracao.idOperador = postagem.idOperador
				declaracao.idTransportadora = volume.idTransportadora
				declaracao.etiqueta = volume.etiqueta
			}

			declaracao.cadastro = cadastro

      await this.limpaDeclaracaoConteudo(true)
      this.$bvModal.show("modalDeclaracaoConteudo")

      let query = `idPostagem=${declaracao.idAtendimento}&etiqueta=${declaracao.etiqueta}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/declaracao-conteudo/?${query}`)
      
			if (resp.status === 200) {
        if (resp.data.length) {
          let declaracaoDB = resp.data[0]
          declaracao.id = declaracaoDB.id
          declaracao.itens = declaracaoDB.itens
        }
				if (cadastro) {
					this.$refs.descricaoDeclaracaoConteudo.$el.focus()
				}
      }
    },
    async validaDeclaracaoConteudo() {
      let form = this.declaracaoConteudoItem
      let descricao = form.descricao.trim()
      let ok = true

      if (!descricao.length) {
        this.$vsNotificacao("Informe a descrição", "warning")
        ok = false
      }
      if (form.quantidade <= 0) {
        this.$vsNotificacao("Informe a quantidade", "warning")
        ok = false
      }
      return ok
    },
    async gravaDeclaracaoConteudo(novoItem=false) {
			let postagem = this.postagem
      let form = this.declaracaoConteudoItem
      let declaracao = this.declaracaoConteudo

			if (novoItem) {
				if (!await this.validaDeclaracaoConteudo()) {
					return
				}
				form.descricao = form.descricao.trim()
				form.quantidade = parseInt(form.quantidade)
				form.valor = this.$vsConverteMoeda(form.valor)
				declaracao.itens.push(JSON.parse(JSON.stringify(form)))
			}
			
			declaracao.idAtendimento = postagem.id
      declaracao.idContratante = postagem.idContratante
      declaracao.idFilial = postagem.idFilial
      declaracao.idOperador = postagem.idOperador

      let resp = await this.$vsApiRest("POST", "/pre-postagens/declaracao-conteudo", declaracao)
      
			if (resp.status === 200) {
        declaracao.id = resp.data.id

				if (novoItem) {
					this.$vsNotificacao("Declaração ou item gravado com sucesso", "success")
					await this.limpaDeclaracaoConteudo()
					this.$refs.descricaoDeclaracaoConteudo.$el.focus()
				}
      }
    },
    calculaQtdDeclaracaoConteudo() {
      let form = this.declaracaoConteudo
      return form.itens.reduce((total, obj) => total + obj.quantidade, 0)
    },
    calculaTotalDeclaracaoConteudo() {
      let form = this.declaracaoConteudo
      return form.itens.reduce((total, obj) => total + obj.valor, 0)
    },
    async excluiDeclaracaoConteudo(index) {
      let declaracao = this.declaracaoConteudo
      let req = {
        id: declaracao.id,
        indiceItem: index || 0,
        excluirTudo: (index === undefined),
      }
			let resp = await this.$vsApiRest("DELETE", "/pre-postagens/declaracao-conteudo", req)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Declaração ou item apagado com sucesso", "success")

        if (req.excluirTudo) {
          await this.limpaDeclaracaoConteudo(true)
        } else {
          declaracao.itens.splice(index, 1)
        }
      }
    },
    async limpaDeclaracaoConteudo(tudo = false) {
      let form = this.declaracaoConteudoItem
      let declaracao = this.declaracaoConteudo

      form.descricao = ""
      form.quantidade = 1
      form.valor = 0

      if (tudo) {
        declaracao.itens = []
      }
    },
    async imprimeDeclaracaoConteudo(tipo) {
      let declaracao = this.declaracaoConteudo
      let query = `idPostagem=${declaracao.idAtendimento}&etiqueta=${declaracao.etiqueta}&tipoRelatorio=${tipo}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/impressao/declaracao-conteudo/?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `declaracao-conteudo-${new Date().getTime()}.pdf`)
      }
    },
		exibeAR(vol) {
			return vol.adicionais.includes("AR")
		},
		async imprimeAR(vol) {
			let postagem = this.postagem
			let query = `idAtendimento=${postagem.id}&etiqueta=${vol.etiqueta}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/impressao/ar/?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `aviso-recebimento-${new Date().getTime()}.pdf`)
      }
		},
		async definePontoAtendimento() {
			await this.defineContratante()
			this.avancar('modalidadeServico')
		},
		defineServico(obj) {
      let vol = this.operacaoAtual().volumes[0]

      vol.idServico = obj.idServico
      vol.idTransportadora = obj.transportadora.id

      vol.servicoCodigo = String(obj.servicoCodigo)
      vol.servicoDescricao = obj.servicoDescricao

      vol.valorFreteTransportadora = obj.valorFreteTransportadora
      vol.valorFreteContratanteBase = obj.valorFreteContratanteBase
      vol.valorFreteContratante = obj.valorFreteContratante
      vol.valorFreteTarifacao = obj.valorFreteTarifacao
      vol.valorFreteFilialBase = obj.valorFreteFilialBase
      vol.valorFreteFilial = obj.valorFreteFilial
      vol.valorFrete = obj.valorFrete

      vol.prazoDias = obj.prazoDias
      vol.observacaoFrete = obj.observacaoFrete

      this.novoVolume()
    },
    async consultaTarifacao() {
      let vol = this.volume
      let cfg = this.config
			let fil = this.filialPadrao
			let des = this.operacaoAtual().destinatario
			let valorNFe = this.$vsConverteMoeda(vol.valorNotaFiscal)
      let valorDecl = this.$vsConverteMoeda(vol.valorDeclarado)

      vol.adicionais[0] = (vol.adicionais[0] !== false) ? vol.adicionais[0] : "" // temporario

      let req = {
        idContratante: this.postagem.idContratante,
        idFilial: fil.id,
        idTransportadora: cfg.idTransportadoraTravada,
        idServico: cfg.idServicoTravado,
        cepOrigem: this.$vsLimpaFormatacao(fil.endereco.cep),
        cepDestino: this.$vsLimpaFormatacao(des.endereco.cep),
        peso: String(vol.peso),
        altura: String(vol.altura),
        largura: String(vol.largura),
        comprimento: String(vol.comprimento),
        valorDeclarado: (valorNFe > valorDecl) ? valorNFe.toFixed(2) : valorDecl.toFixed(2),
        adicionais: vol.adicionais,
				tipoObjeto: vol.tipoObjeto,
      }
      this.tarifacaoServicos = []

      let resp = await this.$vsApiRest("POST", "/tarifacao", req)
      
			if (resp.status === 200) {
        for (let i = 0; i < resp.data.length; i++) {
          let srv = resp.data[i]
          let valor = this.valorTotal(srv.valorFrete)
          srv.observacaoFrete = ""
          srv.opcoes = true

          if (valor < 0) {
            valor = 0
            srv.observacaoFrete = "Desconto maior do que o valor do frete!"
          }
          srv.valorFrete = valor
        }
        this.tarifacaoServicos = resp.data
      }
    },
    defineNumeroNotaFiscal() {
      let chave = String(this.volume.chaveNotaFiscal)
      this.volume.numeroNotaFiscal = (chave.length === 44) ? chave.substring(25, 34) : ""
    },
    valorTotal(valorFrete) {
      let contrato = this.pagador.contratoSelecionado
      valorFrete = parseFloat(valorFrete)

      if (contrato && contrato.taxas[0]) {
        let taxa = contrato.taxas[0]
        let totalAcrescimo = (valorFrete * (Math.abs(taxa.percentualAcrescimo) / 100)) + Math.abs(taxa.valorAcrescimo)
        let totalDesconto = (valorFrete * (Math.abs(taxa.percentualDesconto) / 100)) + Math.abs(taxa.valorDesconto)

        valorFrete = parseFloat(parseFloat(valorFrete + totalAcrescimo - totalDesconto).toFixed(2))
      }
      return valorFrete
    },
		liberaDimensoes() {
			let cfg = this.config
			let vol = this.volume
			let envolpe = (vol.tipoObjeto === this.VS_TIPO_VOLUME_ENVELOPE)

			if (envolpe) {
				vol.altura = "0"
				vol.largura = "0"
				vol.comprimento = "0"
			}
			cfg.dimensoesLiberadas = !envolpe
		},
		defineVolume() {
			let opr = this.operacaoAtual()
			let vol = this.clonagem(this.volume)

			vol.adicionais[0] = (vol.adicionais[0] !== false) ? vol.adicionais[0] : "" // temporario

			if (vol.tipoObjeto !== this.VS_TIPO_VOLUME_ENVELOPE) {
				if (!vol.altura || vol.altura < 1) {
					vol.altura = this.VS_DIMENSAO_MINIMA_ALTURA
				}
				if (!vol.largura || vol.largura < 1) {
					vol.largura = this.VS_DIMENSAO_MINIMA_LARGURA
				}
				if (!vol.comprimento || vol.comprimento < 1) {
					vol.comprimento = this.VS_DIMENSAO_MINIMA_COMPRIMENTO
				}
			}
			if (opr.volumes.length === 0) {
				opr.volumes.push(vol)
			} else {
				opr.volumes[0] = vol
			}
		},
		defineDimensoes() {
			if (this.validaVolume()) {
				this.defineVolume()
				this.avancar("modalidadeServico")
			}
		},
		defineAdicionais() {
			this.defineVolume()
			this.consultaTarifacao()
		},
		defineModalidade() {
			if (this.validaModalidade()) {
				this.avancar("resumoFinalizacao")
			}
		},
		exibeDeclaracaoConteudo() {
			return this.declaracaoConteudo.itens.length
		},
		limpaDados(obj) {
			obj.id = null
			obj.cpfCnpj = ""
			obj.nome = ""
      obj.email = ""
			obj.aosCuidados = ""
			obj.nomeFantasia = ""
      obj.telefone = ""
      obj.contratos = []
      obj.vinculos = []
      obj.contratoSelecionado = null

			obj.endereco.cep = ""
			obj.endereco.logradouro = ""
			obj.endereco.numero = ""
			obj.endereco.bairro = ""
			obj.endereco.cidade = ""
			obj.endereco.uf = ""
			obj.endereco.complemento = ""
		},
  },
  // #endregion
}
</script>