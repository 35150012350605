<template>
  <div>
		<div class="titulo">
			<h1>Meus Dados</h1>
		</div>

    <div class="h5">
      <!-- #region cadastro -->
      <vs-form-usuario 
				:obj="formCliente" 
				:arrayPerfis="formCliente.perfis" 
				:exibeEndereco="true"
        :exibeFoto="true" 
				:exibeSenha="true"
				:emailObrigatorio="true"
				:telefoneObrigatorio="false"
				:isAutoCadastro="true"
				:telaExterna="!tokenValidado"
				:preCadastro="true"
				:isEdicaoToken="tokenValidado"
				v-on:evento="confirmacao" />
      <!-- #endregion -->
		</div>
  </div>
</template>

<script>
import VsFormUsuario from "../templates/vs-form-usuario.vue"

export default {
  // #region configs
  name: "cliente",
  path: "/cliente",
  requireAuth: false,
  components: { VsFormUsuario },
  // #endregion

  // #region dados
  data() {
    return {
      formCliente: {
        nome: "",
        fotoUpload: null,
        telefone: "",
        cpfCnpj: "",
        email: "",
        senha: "",
        isAtivo: true,
        isExcluido: false,
        verSenha: false,
        mensagemCustomizada: "",
        perfil: 0,
        perfis: null,
        cadastros: [],
        vinculos: [],
        endereco: {
          tipo: 0,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          apelido: "",
          complemento: "",
          isComercial: false,
        },
      },
      tokenValidado: false,
			convite: null,
    }
  },
  // #endregion

  // #region triggers
  created() {
    let form = this.formCliente
		form.perfil = this.VS_PERFIL_CLIENTE
		form.perfis = [{ value: this.VS_PERFIL_CLIENTE, text: "Cliente" },]
		form.endereco.tipo = this.VS_TIPO_ENDERECO_RUA
  },
	mounted() {
		this.validaToken()
	},
  // #endregion

  // #region metodos
  methods: {
		async validaToken() {
			let token = this.$route.query.token

			if (!token) {
				return
			}

			// #region convite
			let resp = await this.$vsApiRest("GET", `/usuarios/convites/?token=${this.$route.query.token}`)
			
			if (resp.status !== 200 || resp.data.length === 0) {
				return
			}
			
			let convite = resp.data[0]

			if (new Date(convite.dataExpiracaoToken) < new Date((new Date()).toISOString())) {
				this.$vsNotificacao(`Não foi possível carregar seu cadastro pois o token expirou em ${this.$vsFormataData(convite.dataExpiracaoToken, true)}`, "warning")
				return
			}
			// #endregion
			
			// #region cadastro
			resp = await this.$vsApiRest("GET", `/usuarios/clientes?cpfCnpj=${convite.cpfCnpjConvidado}&email=${convite.emailConvidado}`)

			if (resp.status === 200 && resp.data.length > 0) {
				// #region desvio cadastro pivot (caso email convite tenha falhado)
				let cadastroPivot = resp.data.find(el => el.cadastroPivot)

				if (cadastroPivot) {
					this.$vsNotificacao("Identificamos que você já possui cadastro, por gentileza faça seu login", "info")
					this.$router.push("/")
					return
				}
				// #endregion

				// #region preenche form
				let cadastro = resp.data.find(el => el.id === convite.idConvidado)
				let form = this.formCliente
					
				form.nome = cadastro.nome
				form.email = cadastro.email
				form.cpfCnpj = this.$vsFormataCpfCnpj(cadastro.cpfCnpj)
				form.telefone = this.$vsFormataTelefone(cadastro.telefone)
					
				form.endereco.cep = this.$vsFormataCep(cadastro.endereco.cep)
				form.endereco.logradouro = cadastro.endereco.logradouro
				form.endereco.numero = cadastro.endereco.numero
				form.endereco.bairro = cadastro.endereco.bairro
				form.endereco.complemento = cadastro.endereco.complemento
				form.endereco.cidade = cadastro.endereco.cidade
				form.endereco.uf = cadastro.endereco.uf
				// #endregion

				this.tokenValidado = true
				this.convite = convite
			}
			// #endregion
		},
		async confirmacao() {
			let form = this.formCliente
			form.isAtivo = false

			if (this.convite) {
				let req = {
					id: this.convite.id,
					idConvidado: form.id,
					situacao: this.VS_STATUS_USUARIO_CONVITE_ACEITO,
				}
      	await this.$vsApiRest("PATCH", `/usuarios/convites`, req)
			}

			this.$vsNotificacao("Acesse o email informado para finalizar e ativar seu cadastro", "info")
			this.$router.push("/")
		},
  }
  // #endregion
}
</script>
