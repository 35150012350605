<template>
	<div>
		<h1 class="titulo">Relatório de movimentações</h1>

		<b-tabs content-class="pt-4 px-2">
			<!-- #region atendimentos -->
			<b-tab title="Atendimentos" title-item-class="h5" active @click="listaAtendimentos()">
				<!-- #region filtro -->
				<form @submit.prevent="listaAtendimentos()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.atendimento.dataInicial"
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.atendimento.dataFinal" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Atendimento" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.atendimento.comprovante" autocomplete="on"	@keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2" v-if="$root.sessao.perfil !== VS_PERFIL_OPERADOR && comboEquipe.length">
								<b-form-group class="form-input-cinza" label="Responsável" label-cols="12" label-size="lg">
									<model-select v-model="filtro.atendimento.responsavel.id" :options="comboEquipe" @input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Nº Encomenda" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.atendimento.etiqueta" autocomplete="on" @keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Situação" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.atendimento.ativo" :options="comboSituacao" @change="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="4">
								<b-form-group class="form-input-cinza" label="Pagador" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.atendimento.pagador.nome" autocomplete="on" @keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="4">
								<b-form-group class="form-input-cinza" label="Destinatário" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.atendimento.destinatario.nome" autocomplete="on" @keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Cep Destinatário" label-cols="12" label-size="lg" content-cols-md="">
									<b-form-input type="text" v-model="filtro.atendimento.destinatario.endereco.cep" autocomplete="on" @keyup="limpaTela(); mascaraCep($event)" maxlength="9" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

				<!-- #region listagem -->
				<div v-if="mostraAtendimento">
					<div class="">
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimeAtendimentosCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
					</div>

					<b-table id="tabelaAtendimentos" class="text-nowrap h5"
						:items="atendimentos.dados"
						:fields="cabecalhoTabelaAtendimento" 
						:per-page="paginador.totalPagina" 
						:current-page="paginador.paginaTabela"
						:sort-by.sync="paginador.ordenacaoCampo"
      			:sort-desc.sync="paginador.ordenacaoDesc"
						@sort-changed="ordenaBusca($event, listaAtendimentos)"
						small 
						hover 
						responsive>
						<!-- #region celulas customizadas/formatadas -->
						<template #cell(detalhes)="obj">
							<a @click.prevent="lazyLoadDetalhesAtendimento(obj)" v-b-tooltip.hover.right="'Mostrar/ocultar detalhes'">
								<b-icon v-if="obj.detailsShowing" icon="dash-square-dotted" />
                <b-icon v-else icon="plus-square-dotted" />
							</a>
						</template>

						<template #cell(dataHora)="obj">
							{{ $vsFormataData(obj.value, true) }}
						</template>

						<template #cell(comprovante)="obj">
							<a v-if="liberaImprimirRecibo(obj.item)" class="text-black"  @click.prevent="imprimeReciboTXT(obj.item)"	v-b-tooltip.hover.left="'Imprimir cupom/recibo'">
								<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}
							</a>
							<span v-else v-b-tooltip.hover.left="'Atendimento ou encomenda cancelada'">
								<b-icon icon="exclamation-octagon-fill" class="text-danger"/>&nbsp;{{ obj.value }}
							</span>
							<a v-if="liberaCancelarAtendimento(obj.item)" class="text-danger" @click.prevent="mostraModalCancelamento(obj.item, true)">
								<b-icon icon="trash" />
							</a>
						</template>

						<template #cell(atendimentoCancelado)="obj">
							<span v-if="obj.value" v-b-tooltip.hover.left="obj.item.motivoCancelamento">Cancelado</span>
							<span v-else>Ativo</span>
						</template>

						<template #cell(valorTotal)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->

						<!-- #region linha detalhes -->
						<template #row-details="obj">
							<div class="px-4" style="display: grid;">
								<b-table v-if="idDetalheAberto === obj.item.idPostagem" 
									class="text-nowrap h5" :items="atendimentoDetalhes"
									:fields="cabecalhoTabelaAtendimentoDetalhe" small hover responsive borderless>

									<template #cell(remetenteCep)="obj">
										{{ $vsFormataCep(obj.value) }}
									</template>

									<template #cell(destinatarioCep)="obj">
										{{ $vsFormataCep(obj.value) }}
									</template>

									<template #cell(numeroEncomenda)="obj">
										<div v-if="obj.value.length">
											<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
												<b-icon icon="exclamation-octagon-fill" class="text-warning"/>&nbsp;{{ obj.value }}
											</span>
											<span class="text-nowrap" v-else-if="liberaImprimirEtiqueta(obj.item)">	
												<a class="text-black" @click.prevent="imprimeEtiqueta(obj.value)" v-b-tooltip.hover.left="'Imprimir rótulo/etiqueta'">
													<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}&nbsp;
												</a>
												<a class="text-primary" @click.prevent="rastrearEtiqueta(obj.value)" v-b-tooltip.hover.left="'Rastrear rótulo/etiqueta'">
													<b-icon icon="search" />&nbsp;
												</a>
												<a v-if="obj.item.temDeclaracaoConteudo" @click.prevent="mostraDeclaracaoConteudo(obj.item)" v-b-tooltip.hover.left="'Imprimir Declaração de conteúdo'">
													<b-icon icon="file-text" variant="secondary" />&nbsp;
												</a>
												<a v-if="exibeAR(obj.item)" class="text-info" @click.prevent="imprimeAR(obj.item)" v-b-tooltip.hover.left="'Imprimir Aviso de Recebimento'">
													AR
												</a>
											</span>
											<span v-else v-b-tooltip.hover.left="'Encomenda cancelada'">
												<b-icon icon="exclamation-octagon-fill" class="text-danger"/>&nbsp;{{ obj.value }}
											</span>
										</div>
									</template>

									<template #cell(servicoDescricao)="obj">
										<div class="d-flex justify-content-between">
											<span>{{ obj.value }}</span>
											<span class="pl-3" v-if="liberaCancelarEncomenda(obj.item)">
												<a class="text-danger" @click.prevent="mostraModalCancelamento(obj.item)">
													<b-icon icon="trash" />
												</a>
											</span>
										</div>
									</template>

									<template #cell(itemCancelado)="obj">
										<span v-if="obj.value" v-b-tooltip.hover.left="obj.item.motivoCancelamento">Cancelado</span>
										<span v-else>Ativo</span>
									</template>

									<template #cell(valorUnitario)="obj">
										{{ $vsFormataMoeda(obj.value) }}
									</template>

									<template #cell(valorTotal)="obj">
										{{ $vsFormataMoeda(obj.value) }}
									</template>
								</b-table>
							</div>
						</template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<b-row class="h5">
						<b-col cols="7">
							<span style="padding-left: 5px;padding-right: 20px;">
								<b-form-select class="numPerPage" v-model="paginador.totalPagina" :options="paginador.comboPaginacao" style="width: 100px;" @change="mudaPagina(1, listaAtendimentos)" />
							</span>
							<span>Total de Registros: {{ $vsFormataNumero(atendimentos.totalRegistros) }}</span>
						</b-col>
						<b-col class="font-weight-bold text-right">
							Qtd. Total de objetos: {{ $vsFormataNumero(atendimentos.totalizadores[0]) }}<br>
							Total das Postagens: R$ {{ $vsFormataMoeda(atendimentos.totalizadores[1]) }}
						</b-col>
					</b-row>
					<b-pagination 
						class="mt-3 d-flex justify-content-center" 
						aria-controls="tabelaAtendimentos"
						v-model="paginador.paginaAtual"
						:limit="3"
						:total-rows="atendimentos.totalRegistros"
						:per-page="paginador.totalPagina"
						@change="mudaPagina($event, listaAtendimentos)"/>
					<!-- #endregion -->
				</div>
				<!-- #endregion -->
			</b-tab>
			<!-- #endregion -->

			<!-- #region fechamento de caixa -->
			<b-tab v-if="![VS_PERFIL_OPERADOR].includes($root.sessao.perfil)" title="Fechamento de Caixa" title-item-class="h5" @click="listaFechamentos()">
				<!-- #region filtro -->
				<b-form-group label="Filtrar por:" label-size="lg" label-cols="12">
					<b-form-row>
						<b-col md="2">
							<b-form-group class="form-input-cinza" label="Data" label-cols="12" label-size="lg">
								<b-form-datepicker locale="pt-BR"
												   v-model="filtro.fechamento.data"
												   placeholder="dd/mm/aaaa"
												   :date-format-options="formatoData"
												   @input="listaFechamentos()" />
							</b-form-group>
						</b-col>
						<b-col md="5">
							<b-form-group class="form-input-cinza" :label="(telaContratante) ? 'Filial' : 'Colaborador' " label-cols="12" label-size="lg">
								<model-select v-model="filtro.fechamento.optCombo" :options="comboEquipe" @input="listaFechamentos()" />
							</b-form-group>
						</b-col>
					</b-form-row>
				</b-form-group>
				<!-- #endregion -->
				
				<!-- #region relatorio -->
				<div ref="impressaoFechamento" v-if="mostraFechamento" class="area-impressao" style="width: 700px;">
					<!-- #region resumo -->
					<div class="mb-5">
						<div class="float-right d-screen-block d-print-none">
							<button class="btn-azul px-5" @click.prevent="imprimeFechamento()" size="lg">Imprimir</button>
						</div>
						<div class="h1 d-none d-print-block mb-5 text-center">Fechamento de caixa</div>
						<div class="h2 text-muted">Resumo</div>
					</div>
					<div class="bg-white p-4 h4 mb-5">
						<!-- #region cabecalho -->
						<div class="mb-4">
							<span v-if="telaContratante">{{ comboEquipe.find(el => el.value === filtro.fechamento.optCombo).text.toUpperCase() }}</span>
							<span v-else-if="$root.sessao.perfil === VS_PERFIL_FILIAL">{{ $root.sessao.nome.toUpperCase() }}</span>
							<span v-else>{{ $root.sessao.filial.nome.toUpperCase() }}</span>
							<span class="float-right">{{ filtro.fechamento.data.split("-").reverse().join("/") }}</span>
						</div>
						<div class="mb-4" v-if="!telaContratante && filtro.fechamento.optCombo !== OPT_TODOS">
							<span>Colaborador:&nbsp;&nbsp;{{ comboEquipe.find(el => el.value === filtro.fechamento.optCombo).text.toUpperCase() }}</span>
						</div>
						<div class="mb-4">
							<span>Atendimentos:</span>
							<span class="float-right">{{ fechamento.quantidadeAtendimentos }}</span>
						</div>
						<!-- #endregion -->

						<!-- #region caixas (filial e gerente) -->
						<div v-if="!telaContratante && filtro.fechamento.optCombo !== OPT_TODOS">
							<div v-for="(cx, i) in fechamento.caixas" :key="i">
								<div class="mb-4">Caixa {{ i + 1 }}</div>
								<div class="mb-4">
									<span>Saldo inicial:&nbsp;&nbsp;&nbsp;{{ $vsFormataData(cx.dataAbertura, true) }}</span>
									<span class="float-right">{{ $vsFormataMoeda(cx.saldoAbertura) }}</span>
								</div>
								<div class="mb-4">
									<span>Saldo final:&nbsp;&nbsp;&nbsp;&nbsp;{{ $vsFormataData(cx.dataFechamento, true) }}</span>
									<span class="float-right">{{ $vsFormataMoeda(cx.saldoFechamento) }}</span>
								</div>
							</div>
						</div>
						<!-- #endregion -->

						<!-- #region entradas -->
						<div class="mt-5 mb-5">
							<div class="cor-azul h3">
								<b-icon icon="plus-circle" scale="0.8" />&nbsp;Entradas
							</div>
							<div v-for="entrada in agrupaEntradas()"	:key="entrada.value" class="mb-3">
								<span class="float-right">{{ $vsFormataMoeda(entrada.total) }}</span>
								<span>{{ entrada.text }}</span>
							</div>
							<div class="mb-3">
								<span class="float-right">{{ $vsFormataMoeda(fechamento.valorTotalEntradas) }}</span>
								<span>TOTAL</span>
							</div>
						</div>
						<!-- #endregion -->

						<!-- #region saidas -->
						<div>
							<div class="cor-laranja h3">
								<b-icon icon="dash-circle" scale="0.8" />&nbsp;Saídas
							</div>
							<div class="mb-3">
								<span class="float-right">{{ $vsFormataMoeda(fechamento.valorTotalSaidas) }}</span>
								<span>{{ $vsListaPagamentos(VS_PAGAMENTO_DINHEIRO).text }}</span>
							</div>
							<div class="mb-3">
								<span class="float-right">{{ $vsFormataMoeda(fechamento.valorTotalCancelamentos) }}</span>
								<span>Cancelados no atendimento</span>
							</div>
							<div class="mb-3">
								<span class="float-right">{{ $vsFormataMoeda(fechamento.valorTotalEstornos) }}</span>
								<span>Estornos</span>
							</div>
							<div class="mb-3">
								<span class="float-right">{{ $vsFormataMoeda(fechamento.valorTotalDescontos) }}</span>
								<span>Descontos</span>
							</div>
							<div class="mb-3">
								<span class="float-right">{{ $vsFormataMoeda(fechamento.valorTotalSaidas + fechamento.valorTotalCancelamentos + fechamento.valorTotalEstornos + fechamento.valorTotalDescontos) }}</span>
								<span>TOTAL</span>
							</div>
						</div>
						<!-- #endregion -->
					</div>
					<!-- #endregion -->

					<!-- #region movimentacao agrupada por equipe (filial/operador) -->
					<div class="nova-pagina" v-if="filtro.fechamento.optCombo === OPT_TODOS">
						<div class="mb-5">
							<span v-if="telaContratante" class="h2 text-muted">
								Movimentação por filial
							</span>
							<span v-else class="h2 text-muted">Movimentação por colaborador</span>
						</div>
						<div class="bg-white p-4 h4 mb-5">
							<table class="w-100">
								<tr>
									<td class="border-right pb-3 pr-2">
										<span v-if="telaContratante">Filial</span>
										<span v-else>Colaborador</span>
									</td>
									<td class="border-right pb-3 px-2 text-center">Quantidade</td>
									<td class="border-right pb-3 px-2 text-center">Valor total</td>
									<td class="border-right pb-3 px-2 text-center">Quantidade desconto</td>
									<td class="pb-3 px-2 text-center">Valor total desconto</td>
								</tr>
								<tr v-for="(mov, i) in fechamento.equipe" :key="i">
									<td class="border-right border-top pt-3">{{ mov.descricao }}</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ mov.quantidade }}</td>
									<td class="border-right border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(mov.valorTotal) }}</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ mov.quantidadeDesconto }}</td>
									<td class="border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(mov.valorTotalDesconto) }}</td>
								</tr>
								<tr>
									<td class="border-right border-top pt-3">TOTAL</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ fechamento.quantidadeTotalEquipe }}</td>
									<td class="border-right border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(fechamento.valorTotalEquipe) }}</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ fechamento.quantidadeTotalDesconto }}</td>
									<td class="border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(fechamento.valorTotalDescontos) }}</td>
								</tr>
							</table>
						</div>
					</div>
					<!-- #endregion -->

					<!-- #region movimentacao agrupada por servico -->
					<div class="nova-pagina">
						<div class="mb-5">
							<div class="h2 text-muted">Movimentação por frete</div>
						</div>
						<div class="bg-white p-4 h4 mb-5">
							<table class="w-100">
								<tr>
									<td class="border-right pb-3 pr-2">Produto/modalidade</td>
									<td class="border-right pb-3 px-2 text-center">Quantidade</td>
									<td class="pb-3 px-2 text-center">Valor total</td>
								</tr>
								<tr v-for="(mov, i) in fechamento.servicos" :key="i">
									<td class="border-right border-top pt-3">{{ mov.descricao }}</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ mov.quantidade }}</td>
									<td class="border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(mov.valorTotal) }}</td>
								</tr>
								<tr>
									<td class="border-right border-top pt-3">SUB-TOTAL</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ fechamento.quantidadeTotalServicos }}</td>
									<td class="border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(fechamento.valorTotalServicos) }}</td>
								</tr>
							</table>
						</div>
					</div>
					<!-- #endregion -->

					<!-- #region movimentacao agrupada por Operacao -->
					<div class="nova-pagina">
						<div class="mb-5">
							<div class="h2 text-muted">Movimentação por produto/serviço</div>
						</div>
						<div class="bg-white p-4 h4 mb-5">
							<table class="w-100">
								<tr>
									<td class="border-right pb-3 pr-2">Produto/modalidade</td>
									<td class="border-right pb-3 px-2 text-center">Quantidade</td>
									<td class="pb-3 px-2 text-center">Valor total</td>
								</tr>
								<tr v-for="(mov, i) in fechamento.operacoes"	:key="i">
									<td class="border-right border-top pt-3">{{ mov.descricao }}</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ mov.quantidade }}</td>
									<td class="border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(mov.valorTotal) }}</td>
								</tr>
								<tr>
									<td class="border-right border-top pt-3">SUB-TOTAL</td>
									<td class="border-right border-top pt-3 px-2 text-right">{{ fechamento.quantidadeTotalOperacoes }}</td>
									<td class="border-top pt-3 pl-3 text-right text-nowrap">{{ $vsFormataMoeda(fechamento.valorTotalOperacoes) }}</td>
								</tr>
							</table>
						</div>
					</div>
					<!-- #endregion -->
				</div>
				<!-- #endregion -->
			</b-tab>
			<!-- #endregion -->
		
			<!-- #region postagens -->
			<b-tab v-if="![VS_PERFIL_OPERADOR].includes($root.sessao.perfil)" title="Postagens" title-item-class="h5" @click="buscaModalidades(); listaPostagens()">
				<!-- #region filtro -->
				<form @submit.prevent="listaPostagens()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.postagem.dataInicial" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.postagem.dataFinal" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Atendimento" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.postagem.comprovante" autocomplete="on"	@keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Responsável" label-cols="12" label-size="lg">
									<model-select v-model="filtro.postagem.responsavel.id" :options="comboEquipe" @input="limpaTela(); mudaCategoria(); buscaModalidades()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Nº Encomenda" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.postagem.etiqueta" autocomplete="on" @keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Status" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.postagem.status" :options="comboStatusPostagem" @change="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Pagador" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.postagem.pagador.nome" autocomplete="on" @keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Destinatário" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.postagem.destinatario.nome" autocomplete="on"	@keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Cep Destinatário" label-cols="12" label-size="lg"	content-cols-md="">
									<b-form-input type="text" v-model="filtro.postagem.destinatario.endereco.cep" autocomplete="on"	@keyup="limpaTela(); mascaraCep($event)" maxlength="9" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Categoria" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.postagem.categoria" :options="comboCategoria" @change="limpaTela(); buscaModalidades();" />
								</b-form-group>
							</b-col>
							<b-col md="3">
								<b-form-group class="form-input-cinza" label="Modalidade" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.postagem.modalidade" :options="comboModalidade" @change="limpaTela(); mudaCategoria()" />
								</b-form-group>
							</b-col>
							<b-col md="1">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

				<!-- #region listagem -->
				<div v-if="mostraPostagem">
					<div class="">
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimePostagensCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
					</div>

					<b-table id="tabelaPostagens" class="text-nowrap h5" 
						:items="atendimentos"
						:fields="cabecalhoTabelaPostagem" 
						:per-page="paginador.totalPagina" 
						:current-page="paginador.paginaAtual" 
						small 
						hover 
						responsive 
						no-footer-sorting 
						foot-clone>
						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataHora)="obj">
							{{ $vsFormataData(obj.value, true) }}
						</template>

						<template #cell(comprovante)="obj">
							<a v-if="liberaImprimirRecibo(obj.item)" class="text-black"  @click.prevent="imprimeReciboTXT(obj.item)"	v-b-tooltip.hover.left="'Imprimir cupom/recibo'">
								<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}
							</a>
							<span v-else v-b-tooltip.hover.left="'Atendimento ou encomenda cancelada'">
								<b-icon icon="exclamation-octagon-fill" class="text-danger"/>&nbsp;{{ obj.value }}
							</span>
							<a v-if="liberaCancelarAtendimento(obj.item)" class="text-danger" @click.prevent="mostraModalCancelamento(obj.item, true)">
								<b-icon icon="trash" />
							</a>
						</template>

						<template #cell(itemPostado)="obj">
							<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
								<b-icon icon="exclamation-octagon-fill" variant="warning" />&nbsp;Não rastreável
							</span>
							<span v-else-if="obj.value">
								<b-icon icon="gift-fill" variant="success" />&nbsp;Postado
							</span>
							<span v-else>
								<b-icon icon="exclamation-octagon-fill" variant="danger" />&nbsp;Não postado
							</span>
						</template>

						<template #cell(remetenteCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(destinatarioCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(numeroEncomenda)="obj">
							<div v-if="obj.value.length">
								<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
									<b-icon icon="exclamation-octagon-fill" class="text-warning"/>&nbsp;{{ obj.value }}
								</span>
								<span class="text-nowrap" v-else-if="liberaImprimirEtiqueta(obj.item)">	
									<a class="text-black" @click.prevent="imprimeEtiqueta(obj.value)" v-b-tooltip.hover.left="'Imprimir rótulo/etiqueta'">
										<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}&nbsp;
									</a>
									<a class="text-primary" @click.prevent="rastrearEtiqueta(obj.value)" v-b-tooltip.hover.left="'Rastrear rótulo/etiqueta'">
										<b-icon icon="search" />&nbsp;
									</a>
									<a v-if="obj.item.temDeclaracaoConteudo" @click.prevent="mostraDeclaracaoConteudo(obj.item)" v-b-tooltip.hover.left="'Imprimir Declaração de conteúdo'">
										<b-icon icon="file-text" variant="secondary" />&nbsp;
									</a>
									<a v-if="exibeAR(obj.item)" class="text-info" @click.prevent="imprimeAR(obj.item)" v-b-tooltip.hover.left="'Imprimir Aviso de Recebimento'">
										AR
									</a>
								</span>
								<span v-else v-b-tooltip.hover.left="'Encomenda cancelada'">
									<b-icon icon="exclamation-octagon-fill" class="text-danger"/>&nbsp;{{ obj.value }}
								</span>
							</div>
						</template>

						<template #cell(servicoDescricao)="obj">
							<div class="d-flex justify-content-between">
								<span>{{ obj.value }}</span>
								<span class="pl-3" v-if="liberaCancelarEncomenda(obj.item)">
									<a class="text-danger" @click.prevent="mostraModalCancelamento(obj.item)">
										<b-icon icon="trash" />
									</a>
								</span>
							</div>
						</template>

						<template #cell(itemCancelado)="obj">
							<span v-if="obj.value" v-b-tooltip.hover.left="obj.item.motivoCancelamento">Cancelado</span>
							<span v-else>Ativo</span>
						</template>

						<template #cell(valorUnitario)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(valorTotal)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(dataHora)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(valorUnitario)><span class="float-right">R$ {{ $vsFormataMoeda(totalUnitarioAtendimento()) }}</span></template>
						<template #foot(quantidade)><span class="float-right">{{ $vsFormataNumero(totalQuantidadeAtendimento()) }}</span></template>
						<template #foot(valorTotal)><span class="float-right">R$ {{ $vsFormataMoeda(totalValorAtendimento()) }}</span></template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" v-model="paginador.totalPagina" :options="paginador.comboPaginacao" style="width: 100px;" />
						</span>
						<span>Total de Registros: {{ $vsFormataNumero(atendimentos.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="atendimentos.length"
							:per-page="paginador.totalPagina" aria-controls="tabelaPostagens" v-model="paginador.paginaAtual" />
					</div>
					<!-- #endregion -->
				</div>
				<!-- #endregion -->
			</b-tab>
			<!-- #endregion -->

			<!-- #region pre-postagens -->
			<b-tab v-if="$root.sessao.perfil !== VS_PERFIL_OPERADOR" title="Pré Postagens" title-item-class="h5" @click="listaPrePostagens()">
				<!-- #region filtro -->
				<form @submit.prevent="listaPrePostagens()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.prePostagem.dataInicial"
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.prePostagem.dataFinal" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col cols="1" align-self="center" class="pt-4 text-center">
								<a v-b-toggle.exibeFiltro @click.prevent class="h4" v-b-tooltip.hover title="Mostrar/ocultar filtros">
									<span class="cor-azul text-nowrap"><b-icon icon="plus" scale="1" variant="primary" />Filtros</span>
								</a>
							</b-col>
							<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
						<b-form-row>
							<!-- #region filtro ocultavel -->
							<b-collapse id="exibeFiltro" class="mt-3">
								<b-form-row>
									<b-col md="3" v-if="comboEquipePrePostagem.length">
										<b-form-group class="form-input-cinza" :label="cabecalhoTabelaPrePostagem[1].label" label-cols="12" label-size="lg">
											<model-select v-model="filtro.prePostagem.responsavel.id" :options="comboEquipePrePostagem" @input="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Nº Encomenda" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.prePostagem.etiqueta" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Remetente" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.prePostagem.remetente.nome" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Destinatário" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.prePostagem.destinatario.nome" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Cep Destinatário" label-cols="12" label-size="lg" content-cols-md="">
											<b-form-input type="text" v-model="filtro.prePostagem.destinatario.endereco.cep" autocomplete="on" @keyup="limpaTela(); mascaraCep($event)" maxlength="9" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Situação" label-cols="12" label-size="lg">
											<b-form-select v-model="filtro.prePostagem.ativo" :options="comboSituacao" @change="limpaTela()" />
										</b-form-group>
									</b-col>
								</b-form-row>
							</b-collapse>
							<!-- #endregion -->
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

				<!-- #region listagem -->
				<div v-if="mostraPostagem">
					<div class="">
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimePrePostagensCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
					</div>

					<b-table id="tabelaPrePostagens" class="text-nowrap h5"
						:items="atendimentos"
						:fields="cabecalhoTabelaPrePostagem" 
						:per-page="paginador.totalPagina" 
						:current-page="paginador.paginaAtual" 
						small 
						hover 
						responsive>
						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataHora)="obj">
							{{ $vsFormataData(obj.value, true) }}
						</template>

						<template #cell(valorTotal)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(remetenteCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(destinatarioCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(numeroEncomenda)="obj">
							<div v-if="obj.value.length">
								<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
									<b-icon icon="exclamation-octagon-fill" class="text-warning"/>&nbsp;{{ obj.value }}
								</span>
								<span class="text-nowrap" v-else-if="liberaImprimirEtiqueta(obj.item)">	
									<a class="text-black" @click.prevent="imprimeEtiquetaPrePostagem(obj.value)" v-b-tooltip.hover.left="'Imprimir rótulo/etiqueta'">
										<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}&nbsp;
									</a>
								</span>
								<span v-else v-b-tooltip.hover.left="'Encomenda cancelada'">
									<b-icon icon="x" class="text-danger" scale="2"/>&nbsp;{{ obj.value }}
								</span>
							</div>
						</template>

						<template #cell(servicoDescricao)="obj">
							<div class="d-flex justify-content-between">
								<span>{{ obj.value }}</span>
							</div>
						</template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<b-row class="h5">
						<b-col cols="7">
							<span style="padding-left: 5px;padding-right: 20px;">
								<b-form-select class="numPerPage" v-model="paginador.totalPagina" :options="paginador.comboPaginacao" style="width: 100px;" />
							</span>
							<span>Total de Registros: {{ $vsFormataNumero(atendimentos.length) }}</span>
						</b-col>
						<b-col class="font-weight-bold text-right">
							Qtd. Total de objetos: {{ $vsFormataNumero(totalQuantidadeAtendimento()) }}<br>
							Total Estimado: R$ {{ $vsFormataMoeda(totalValorAtendimento()) }}
						</b-col>
					</b-row>
					<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="atendimentos.length"
							:per-page="paginador.totalPagina" aria-controls="tabelaPrePostagens" v-model="paginador.paginaAtual" />
					<!-- #endregion -->
				</div>
				<!-- #endregion -->
			</b-tab>
			<!-- #endregion -->

			<!-- #region cobrança -->
			<b-tab v-if="[VS_PERFIL_FILIAL, VS_PERFIL_GERENTE].includes($root.sessao.perfil)" title="Cobrança" title-item-class="h5" @click="buscaModalidades(true); listaCobrancas()">
				<!-- #region filtro -->
				<form @submit.prevent="listaCobrancas()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.cobranca.dataInicial" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.cobranca.dataFinal" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<!--<b-col md="2">
								<b-form-group class="form-input-cinza" label="Atendimento" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.cobranca.comprovante" autocomplete="on"	@keyup="limpaTela()" />
								</b-form-group>
							</b-col>-->
							<!--<b-col md="3" v-if="$root.sessao.perfil !== VS_PERFIL_FILIAL">
								<b-form-group class="form-input-cinza" label="Unidade" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.cobranca.responsavel.id" :options="comboFilialCobranca" @change="limpaTela()" />
								</b-form-group>
							</b-col>-->
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Nº Encomenda" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.cobranca.etiqueta" autocomplete="on" @keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Status" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.cobranca.status" :options="comboStatusPostagem" @change="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Pagador" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.cobranca.pagador.nome" autocomplete="on" @keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Destinatário" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.cobranca.destinatario.nome" autocomplete="on"	@keyup="limpaTela()" />
								</b-form-group>
							</b-col>
							<!--<b-col md="2">
								<b-form-group class="form-input-cinza" label="Cep Destinatário" label-cols="12" label-size="lg"	content-cols-md="">
									<b-form-input type="text" v-model="filtro.cobranca.destinatario.endereco.cep" autocomplete="on"	@keyup="limpaTela(); mascaraCep($event)" maxlength="9" />
								</b-form-group>
							</b-col>-->
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Categoria" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.cobranca.categoria" :options="comboCategoria" @change="limpaTela(); buscaModalidades(true);" />
								</b-form-group>
							</b-col>
							<b-col md="3">
								<b-form-group class="form-input-cinza" label="Modalidade" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.cobranca.modalidade" :options="comboModalidade" @change="limpaTela(); mudaCategoria(true)" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Situação" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.cobranca.ativo" :options="comboSituacao" @change="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="1">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

				<!-- #region listagem -->
				<div v-if="mostraCobranca">
					<div class="">
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimeCobrancasCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimeCobrancasPDF()" class="btn-vermelho" v-b-tooltip.hover title="Imprimir PDF">Exportar PDF</b-button>
						</span>
					</div>

					<b-table id="tabelaCobrancas" class="text-nowrap h5" 
						:items="atendimentos"
						:fields="cabecalhoTabelaCobranca" 
						:per-page="paginador.totalPagina" 
						:current-page="paginador.paginaAtual" 
						small 
						hover 
						responsive 
						no-footer-sorting 
						foot-clone>
						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataHora)="obj">
							{{ $vsFormataData(obj.value, true) }}
						</template>

						<template #cell(comprovante)="obj">
							<a v-if="liberaImprimirRecibo(obj.item)" class="text-black"  @click.prevent="imprimeReciboTXT(obj.item)"	v-b-tooltip.hover.left="'Imprimir cupom/recibo'">
								<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}
							</a>
							<span v-else v-b-tooltip.hover.left="'Atendimento ou encomenda cancelada'">
								<b-icon icon="exclamation-octagon-fill" class="text-danger"/>&nbsp;{{ obj.value }}
							</span>
							<a v-if="liberaCancelarAtendimento(obj.item)" class="text-danger" @click.prevent="mostraModalCancelamento(obj.item, true)">
								<b-icon icon="trash" />
							</a>
						</template>

						<template #cell(itemPostado)="obj">
							<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
								<b-icon icon="exclamation-octagon-fill" variant="warning" />&nbsp;Não rastreável
							</span>
							<span v-else-if="obj.value">
								<b-icon icon="gift-fill" variant="success" />&nbsp;Postado
							</span>
							<span v-else>
								<b-icon icon="exclamation-octagon-fill" variant="danger" />&nbsp;Não postado
							</span>
						</template>

						<template #cell(remetenteCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(destinatarioCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(numeroEncomenda)="obj">
							<div v-if="obj.value.length">
								<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
									<b-icon icon="exclamation-octagon-fill" class="text-warning"/>&nbsp;{{ obj.value }}
								</span>
								<span class="text-nowrap" v-else-if="liberaImprimirEtiqueta(obj.item)">	
									<a class="text-black" @click.prevent="imprimeEtiqueta(obj.value)" v-b-tooltip.hover.left="'Imprimir rótulo/etiqueta'">
										<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}&nbsp;
									</a>
									<a class="text-primary" @click.prevent="rastrearEtiqueta(obj.value)" v-b-tooltip.hover.left="'Rastrear rótulo/etiqueta'">
										<b-icon icon="search" />&nbsp;
									</a>
									<a v-if="obj.item.temDeclaracaoConteudo" @click.prevent="mostraDeclaracaoConteudo(obj.item)" v-b-tooltip.hover.left="'Imprimir Declaração de conteúdo'">
										<b-icon icon="file-text" variant="secondary" />&nbsp;
									</a>
									<a v-if="exibeAR(obj.item)" class="text-info" @click.prevent="imprimeAR(obj.item)" v-b-tooltip.hover.left="'Imprimir Aviso de Recebimento'">
										AR
									</a>
								</span>
								<span v-else v-b-tooltip.hover.left="'Encomenda cancelada'">
									<b-icon icon="exclamation-octagon-fill" class="text-danger"/>&nbsp;{{ obj.value }}
								</span>
							</div>
						</template>

						<template #cell(servicoDescricao)="obj">
							<div class="d-flex justify-content-between">
								<span>{{ obj.value }}</span>
								<span class="pl-3" v-if="liberaCancelarEncomenda(obj.item)">
									<a class="text-danger" @click.prevent="mostraModalCancelamento(obj.item)">
										<b-icon icon="trash" />
									</a>
								</span>
							</div>
						</template>

						<template #cell(itemCancelado)="obj">
							<span v-if="obj.value" v-b-tooltip.hover.left="obj.item.motivoCancelamento">Cancelado</span>
							<span v-else>Ativo</span>
						</template>

						<template #cell(valorUnitario)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(valorTotal)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(dataHora)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(valorUnitario)><span class="float-right">R$ {{ $vsFormataMoeda(totalUnitarioAtendimento()) }}</span></template>
						<template #foot(quantidade)><span class="float-right">{{ $vsFormataNumero(totalQuantidadeAtendimento()) }}</span></template>
						<template #foot(valorTotal)><span class="float-right">R$ {{ $vsFormataMoeda(totalValorAtendimento()) }}</span></template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" v-model="paginador.totalPagina" :options="paginador.comboPaginacao" style="width: 100px;" />
						</span>
						<span>Total de Registros: {{ $vsFormataNumero(atendimentos.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="atendimentos.length"
							:per-page="paginador.totalPagina" aria-controls="tabelaCobrancas" v-model="paginador.paginaAtual" />
					</div>
					<!-- #endregion -->
				</div>
				<!-- #endregion -->
			</b-tab>
			<!-- #endregion -->
		</b-tabs>

		<!-- #region recibo oculto (usado apenas para impressao) -->
		<div class="d-none">
			<vs-recibo-postagem v-if="recibo.filial" 
			  id="impressaoRecibo" 
				:filial="recibo.filial"
				:operador="recibo.operador" 
				:postagem="recibo.postagem"
				:pagador="recibo.pagador" />
		</div>
		<!-- #endregion -->

		<!-- #region modais -->
		<!-- #region cancelamento -->
		<b-modal v-if="$root.sessao.perfil === VS_PERFIL_GERENTE" id="modalCancelamento" title="Cancelamento" size="lg">
			<div class="h5">
				<!-- #region dados basicos -->
				<div v-if="cancelamento.item" class="mb-5">
					<div>
						<div class="mb-3">
							Atendimento: {{ cancelamento.item.comprovante }}
							<span class="float-right">Data: {{ $vsFormataData(cancelamento.item.dataHora, true) }}</span>
						</div>
						<div class="mb-3">Responsável: {{ cancelamento.item.responsavelNome }}</div>
						<div class="mb-3" v-if="cancelamento.item.remetenteNome">Remetente: {{ cancelamento.item.remetenteNome }}</div>
						<div class="mb-3" v-if="cancelamento.item.remetenteCep">CEP: {{ $vsFormataCep(cancelamento.item.remetenteCep) }}</div>
						<div class="mb-3" v-if="cancelamento.item.destinatarioNome">Destinatário: {{ cancelamento.item.destinatarioNome }}</div>
						<div class="mb-3" v-if="cancelamento.item.destinatarioCep">CEP: {{ $vsFormataCep(cancelamento.item.destinatarioCep) }}</div>
					</div>
					<div v-if="cancelamento.atendimento">
						<div class="mb-3 text-danger font-weight-bold">Obs: Essa ação não poderá ser desfeita e todo o conteúdo será cancelado</div>
						<div class="mb-3">Deseja continuar com o cancelamento do atendimento?</div>
					</div>
					<div v-else>
						<div class="mb-3">Serviço/Modalidade: {{ cancelamento.item.servicoDescricao }}</div>
						<div class="mb-3">Nº encomenda: {{ cancelamento.item.numeroEncomenda }}</div>
						<div class="mb-3 cor-laranja font-weight-bold">Obs: Essa ação não poderá ser desfeita e o item será cancelado</div>
						<div class="mb-3">Deseja continuar com o cancelamento do item?</div>
					</div>
				</div>
				<!-- #endregion -->

				<!-- #region motivo e autenticacao -->
				<div>
					<b-form-group label="Motivo" label-align-md="right" label-size="" label-cols-md="2">
						<b-form-input type="text" v-model="cancelamento.motivo" />
					</b-form-group>
				</div>
				<div>
					<b-form-group label="Senha" label-align-md="right" label-size="" label-cols-md="2" content-cols-md="5">
						<b-form-input type="password" v-model="cancelamento.senha" />
					</b-form-group>
				</div>
				<!-- #endregion -->
			</div>
			<template #modal-footer="{ hide }">
				<div class="w-100">
					<b-button class="float-right ml-2" variant="info"	@click.prevent="confirmaCancelamento()">Confirmar</b-button>
					<b-button class="float-right" variant="secondary" @click.prevent="hide()">Cancelar</b-button>
				</div>
			</template>
		</b-modal>
		<!-- #endregion -->

		<!-- #region rastreio -->
		<b-modal id="modalRastreio" title="Rastreamento" size="lg">
			<div class="h5 modal-compactado">
				<vs-rastreio-encomenda :key=" indiceRastreio " :etiqueta=" etiquetaRastreio " />
			</div>
			<template #modal-footer="{ hide }">
				<div class="w-100">
					<b-button class="float-right" variant="secondary" @click.prevent="hide()">Cancelar</b-button>
				</div>
			</template>
		</b-modal>
		<!-- #endregion -->

		<!-- #region declaracao conteudo -->
		<b-modal id="modalDeclaracaoConteudo"	:title="`Declaração de Conteúdo para o rótulo ${declaracaoConteudo.etiqueta}`" size="lg">
			<div v-if="declaracaoConteudo.itens.length" class="h5">
				<!-- #region listagem -->
				<b-row class="mb-3 d-flex justify-content-between font-weight-bold">
					<b-col>Descrição</b-col>
					<b-col>Qtd.</b-col>
					<b-col>Valor</b-col>
				</b-row>
				<b-row class="mb-3 d-flex justify-content-between" v-for="(item, i) in declaracaoConteudo.itens" :key="i">
					<b-col>{{ item.descricao }}</b-col>
					<b-col class="text-right">{{ item.quantidade }}</b-col>
					<b-col class="text-right">R$ {{ $vsFormataMoeda(item.valor) }}</b-col>
				</b-row>
				<b-row class="d-flex justify-content-between font-weight-bold h4">
					<b-col>Total itens</b-col>
					<b-col class="text-right">{{ calculaQtdDeclaracaoConteudo() }}</b-col>
					<b-col class="text-right">R$ {{ $vsFormataMoeda(calculaTotalDeclaracaoConteudo()) }}</b-col>
				</b-row>
				<!-- #endregion -->
			</div>
			<div v-else class="h5">Nenhum item declarado</div>

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="w-100 d-flex justify-content-between">
					<b-button v-if="declaracaoConteudo.itens.length" variant="primary" class="text-nowrap" @click.prevent="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_A4)">
						<span class="font-weight-bold">Imprimir A4</span>
					</b-button>
					<b-button v-else type="button" variant="primary" class="text-nowrap" disabled>
						<span class="font-weight-bold">Imprimir A4</span>
					</b-button>

					<b-button v-if="declaracaoConteudo.itens.length" variant="success" class="text-nowrap" @click.prevent="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_10x15)">
						<span class="font-weight-bold">Imprimir 10x15</span>
					</b-button>
					<b-button v-else type="button" variant="success" class="text-nowrap" disabled>
						<span class="font-weight-bold">Imprimir 10x15</span>
					</b-button>

					<b-button type="button" variant="danger" @click="hide()">
						<span class="font-weight-bold">Fechar</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
	</div>
</template>

<script>
import VsReciboPostagem from "../templates/vs-recibo-postagem.vue"
import VsRastreioEncomenda from "../templates/vs-rastreio-encomenda.vue"
import { ModelSelect } from "vue-search-select"

export default {
	// #region configs
	name: "relatorio-movimentacoes",
	path: "/relatorio-movimentacoes",
	requireAuth: true,
	components: { VsReciboPostagem, VsRastreioEncomenda, ModelSelect },
	// #endregion

	// #region dados
	data() {
		return {
			OPT_TODOS: "0",
			//dataInicioLimite: new Date(new Date() - 31 * 86400000).toISOString().substring(0, 10), // d-31
			idDetalheAberto: null,
			mostraAtendimento: false,
			mostraFechamento: false,
			mostraPostagem: false,
			mostraCobranca: false,
			telaContratante: false,
			indiceRastreio: 0,
			etiquetaRastreio: "",
			recibo: {
				filial: null,
				operador: null,
				postagem: null,
				pagador: null,
			},
			declaracaoConteudo: {
				etiqueta: "",
				itens: [],
			},
			cadOperacoesBalcao: [],
			cancelamento: {
				atendimento: false,
				item: null,
				motivo: "",
				senha: null,
				produto: null,
			},
			filtro: {
				atendimento: {
					comprovante: null,
					etiqueta: null,
					dataInicial: new Date().toISOString().substring(0, 10), // hoje
					dataFinal: new Date().toISOString().substring(0, 10), // hoje
					ativo: null,
					responsavel: {
						id: null,
					},
					pagador: {
						nome: null,
					},
					remetente: {
						nome: null,
					},
					destinatario: {
						nome: null,
						endereco: {
							cep: null,
						}
					},
				},
				postagem: {
					comprovante: null,
					etiqueta: null,
					dataInicial: new Date().toISOString().substring(0, 10), // hoje
					dataFinal: new Date().toISOString().substring(0, 10), // hoje
					responsavel: {
						id: null,
					},
					pagador: {
						nome: null,
					},
					remetente: {
						nome: null,
					},
					destinatario: {
						nome: null,
						endereco: {
							cep: null,
						}
					},
					status: null,
					categoria: null,
					modalidade: null,
				},
				prePostagem: {
					comprovante: null,
					etiqueta: null,
					dataInicial: new Date(new Date() - 7 * 86400000).toISOString().substring(0, 10), // d-7
					dataFinal: new Date().toISOString().substring(0, 10), // hoje
					ativo: null,
					responsavel: {
						id: null,
					},
					pagador: {
						nome: null,
					},
					remetente: {
						nome: null,
					},
					destinatario: {
						nome: null,
						endereco: {
							cep: null,
						}
					},
				},
				cobranca: {
					//comprovante: null,
					etiqueta: null,
					dataInicial: new Date().toISOString().substring(0, 10), // hoje
					dataFinal: new Date().toISOString().substring(0, 10), // hoje
					responsavel: {
						id: null,
					},
					pagador: {
						nome: null,
					},
					destinatario: {
						nome: null,
						/*endereco: {
							cep: null,
						}*/
					},
					status: null,
					categoria: null,
					modalidade: null,
					ativo: null,
				},
				fechamento: {
					data: new Date().toISOString().substring(0, 10), // hoje
					optCombo: null,
				},
			},
			formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
			cabecalhoTabelaAtendimento: [
				{ key: "detalhes", label: "", sortable: false, },
				{ key: "dataHora", label: "Data", sortable: true,  },
				{ key: "comprovante", label: "Atendimento", sortable: true, },
				{ key: "responsavelNome", label: "Responsável", sortable: true, },
				{ key: "pagadorNome", label: "Pagador", sortable: true, },
				{ key: "atendimentoCancelado", label: "Situação", sortable: true,  },
				{ key: "quantidade", label: "Quantidade", sortable: true, tdClass: "text-right", },
				{ key: "valorTotal", label: "Valor Total", sortable: true, tdClass: "text-right", },
			],
			cabecalhoTabelaAtendimentoDetalhe: [
				{ key: "remetenteNome", label: "Remetente", sortable: true, },
				{ key: "remetenteCep", label: "Cep", sortable: true, },
				{ key: "destinatarioNome", label: "Destinatário", sortable: true, },
				{ key: "destinatarioCep", label: "Cep", sortable: true, },
				{ key: "numeroEncomenda", label: "Nº Encomenda", sortable: true, },
				{ key: "servicoDescricao", label: "Serviço/Modalidade", sortable: true, },
				{ key: "itemCancelado", label: "Situação", sortable: true, },
				{ key: "valorUnitario", label: "Unitário", sortable: true, tdClass: "text-right" },
				{ key: "quantidade", label: "Quantidade", sortable: true, tdClass: "text-right" },
				{ key: "valorTotal", label: "Total", sortable: true, tdClass: "text-right" },
			],
			cabecalhoTabelaPostagem: [
				{ key: "dataHora", label: "Data", sortable: true, },
				{ key: "comprovante", label: "Atendimento", sortable: true, },
				{ key: "itemPostado", label: "Status", sortable: true, },
				{ key: "responsavelNome", label: "Responsável", sortable: true, },
				{ key: "pagadorNome", label: "Pagador", sortable: true, },
				{ key: "remetenteNome", label: "Remetente", sortable: true, },
				{ key: "remetenteCep", label: "Cep", sortable: true, },
				{ key: "destinatarioNome", label: "Destinatário", sortable: true, },
				{ key: "destinatarioCep", label: "Cep", sortable: true, },
				{ key: "numeroEncomenda", label: "Nº Encomenda", sortable: true, },
				{ key: "servicoDescricao", label: "Serviço/Modalidade", sortable: true, },
				{ key: "itemCancelado", label: "Situação", sortable: true, },
				{ key: "valorUnitario", label: "Valor Unitário", sortable: true, tdClass: "text-right" },
				{ key: "quantidade", label: "Quantidade", sortable: true, tdClass: "text-right" },
				{ key: "valorTotal", label: "Valor Total", sortable: true, tdClass: "text-right" },
			],
			cabecalhoTabelaPrePostagem: [
				{ key: "dataHora", label: "Data", sortable: true,  },
				{ key: "responsavelNome", label: "Pagador", sortable: true, },
				{ key: "remetenteNome", label: "Remetente", sortable: true, },
				{ key: "remetenteCep", label: "Cep Rem.", sortable: true, },
				{ key: "numeroEncomenda", label: "Etiqueta", sortable: true, },
				{ key: "servicoDescricao", label: "Serviço/Modal", sortable: true, },
				{ key: "destinatarioNome", label: "Destinatário", sortable: true, },
				{ key: "destinatarioCep", label: "Cep Dest.", sortable: true, },
				{ key: "valorTotal", label: "Valor Estimado", sortable: true, tdClass: "text-right", },
			],
			cabecalhoTabelaCobranca: [
				{ key: "dataHora", label: "Data", sortable: true, },
				//{ key: "comprovante", label: "Atendimento", sortable: true, },
				{ key: "itemPostado", label: "Status", sortable: true, },
				//{ key: "responsavelNome", label: "Responsável", sortable: true, },
				{ key: "pagadorNome", label: "Pagador", sortable: true, },
				{ key: "remetenteNome", label: "Remetente", sortable: true, },
				//{ key: "remetenteCep", label: "Cep", sortable: true, },
				{ key: "destinatarioNome", label: "Destinatário", sortable: true, },
				//{ key: "destinatarioCep", label: "Cep", sortable: true, },
				{ key: "numeroEncomenda", label: "Nº Encomenda", sortable: true, },
				{ key: "servicoDescricao", label: "Serviço/Modalidade", sortable: true, },
				{ key: "itemCancelado", label: "Situação", sortable: true, },
				//{ key: "valorUnitario", label: "Valor Unitário", sortable: true, tdClass: "text-right" },
				//{ key: "quantidade", label: "Quantidade", sortable: true, tdClass: "text-right" },
				{ key: "valorTotal", label: "Valor Total", sortable: true, tdClass: "text-right" },
			],
			atendimentos: [],
			atendimentoDetalhes: [],
			comboEquipe: [],
			comboEquipePrePostagem: [],
			comboFilialCobranca: [],
			comboSituacao: [],
			comboStatusPostagem: [],
			comboCategoria: [],
			comboModalidade: [],
			fechamento: [],
			paginador: {
				paginaAtual: 1,
				paginaServidor: 1,
				paginaTabela: 1,
				totalPagina: 10,
				comboPaginacao: [10, 50, 100],
				ordenacaoDesc: false,
				ordenacaoCampo: "dataHora",
			},
		}
	},
	// #endregion

	// #region triggers
	created() {
		this.$vsDefineAcesso([
			this.VS_PERFIL_CONTRATANTE, 
			this.VS_PERFIL_FILIAL, 
			this.VS_PERFIL_GERENTE, 
			this.VS_PERFIL_OPERADOR,
		])
	},
	mounted() {
		this.bootstrap()
	},
	// #endregion

	// #region metodos
	methods: {
		async bootstrap() {
			let sessao = this.$root.sessao
			let filtro = this.filtro

			this.telaContratante = (sessao.perfil === this.VS_PERFIL_CONTRATANTE)

			filtro.atendimento.responsavel.id = this.OPT_TODOS
			filtro.atendimento.ativo = this.OPT_TODOS

			filtro.fechamento.optCombo = this.OPT_TODOS

			filtro.postagem.status = this.OPT_TODOS
			filtro.postagem.categoria = this.OPT_TODOS
			filtro.postagem.modalidade = this.OPT_TODOS
			filtro.postagem.responsavel.id = this.OPT_TODOS

			filtro.prePostagem.responsavel.id = this.OPT_TODOS
			filtro.prePostagem.ativo = this.OPT_TODOS

			filtro.cobranca.status = this.OPT_TODOS
			filtro.cobranca.categoria = this.OPT_TODOS
			filtro.cobranca.modalidade = this.OPT_TODOS
			filtro.cobranca.responsavel.id = this.OPT_TODOS
			filtro.cobranca.ativo = this.OPT_TODOS

			this.defineComboSituacao()
			this.defineComboCategoria()
			this.defineComboStatusPostagem()

			await this.buscaEquipe()
			await this.buscaEquipePrePostagem()
			await this.listaAtendimentos()

			if (this.telaContratante) {
				this.cabecalhoTabelaPrePostagem[1].label = "Ponto de atendimento"
			}
		},
		async buscaModalidades(cobranca = false) {
			let sessao = this.$root.sessao
			let filtro = (cobranca) ? this.filtro.cobranca : this.filtro.postagem
			let idContratante = (this.telaContratante) ? sessao.id : sessao.contratante.id
			let query = `idContratante=${idContratante}`

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE && filtro.responsavel.id !== this.OPT_TODOS) {
				query += `&idFilial=${filtro.responsavel.id}`
			}
			if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idFilial=${sessao.id}`
			} 
			if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `&idFilial=${sessao.filial.id}`
			}
			if (filtro.categoria !== this.OPT_TODOS) {
				query += `&categoriaPostagem=${filtro.categoria}`
			}

			filtro.modalidade = this.OPT_TODOS

			let resp = await this.$vsApiRest("GET", `/postagens/modalidade-servicos/?${query}`)

			if (resp.status === 200) {
				this.defineComboModalidades(resp.data)
			}
		},		
		async buscaEquipe() {
			let sessao = this.$root.sessao
			let idContratante = sessao.id
			let tipo = "filiais"
			let filtro = this.filtro.fechamento

			if (!this.telaContratante) {
				idContratante = sessao.contratante.id
				tipo = "equipe"
			}

			let query = ""

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				query = `idContratante=${idContratante}`
			} else if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `idContratante=${idContratante}&idFilial=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `idContratante=${idContratante}&idFilial=${sessao.filial.id}`
			}
            query += "&ativo=true&excluido=false"

			let resp = await this.$vsApiRest("GET", `/usuarios/${tipo}/?${query}`)

			if (resp.status === 200) {
				this.defineComboEquipe(resp.data)
			}
		},
		async buscaEquipePrePostagem() {
			let sessao = this.$root.sessao
			let idContratante = sessao.id
			let tipo = "filiais"

			if (!this.telaContratante) {
				idContratante = sessao.contratante.id
				tipo = "clientes"
			}

			let query = ""

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				query = `idContratante=${idContratante}`
			} else if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `idFilial=${sessao.id}&cadastroPivot=true`
			} else {
				query += `idFilial=${sessao.filial.id}&cadastroPivot=true`
			}

			let resp = await this.$vsApiRest("GET", `/usuarios/${tipo}/?${query}`)

			if (resp.status === 200) {
				this.defineComboEquipePrePostagem(resp.data)
			}
		},
		async buscaAtendimentoDetalhe(obj) {
			let atendimento = obj.item
			let sessao = this.$root.sessao
			let query = `idAtendimento=${atendimento.idPostagem}&perfil=${sessao.perfil}`

			this.idDetalheAberto = atendimento.idPostagem
			this.atendimentoDetalhes = []

			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-atendimento-detalhado/?${query}`)
			
			if (resp.status === 200) {
				this.atendimentoDetalhes = resp.data
				obj.toggleDetails()
			}			
		},
		defineComboEquipe(equipe) {
			equipe.forEach(el => {
				el.value = el.id
                el.text = el.nome
			})

			this.comboEquipe = equipe
			this.comboEquipe.unshift({ value: this.OPT_TODOS, text: "Todos" })
		},
		defineComboEquipePrePostagem(equipe) {
			if (equipe.length === 0) {
				return
			}
			equipe.forEach(el => {
				el.value = el.id
				el.text = el.nome
			})

			this.comboEquipePrePostagem = equipe
			this.comboEquipePrePostagem.unshift({ value: this.OPT_TODOS, text: "Todos" })
		},
		defineComboFilialCobranca(filiais) {
			filiais.forEach(el => {
				el.value = el.id
				el.text = el.nome
			})

			this.comboFilialCobranca = filiais
			this.comboFilialCobranca.unshift({ value: this.OPT_TODOS, text: "Todas" })
		},
		defineComboModalidades(modalidades) {
			modalidades.forEach(el => {
				el.value = `${el.categoriaEncomenda}|${el.codigo}|${el.descricao}`
				el.text = el.descricao
			})
			this.comboModalidade = modalidades
			this.comboModalidade.unshift({ value: this.OPT_TODOS, text: "Todos" })
		},
		defineComboSituacao() {
			this.comboSituacao.push({ value: this.OPT_TODOS, text: "Todos" })
			this.comboSituacao.push({ value: true, text: "Ativos" })
			this.comboSituacao.push({ value: false, text: "Cancelados" })
		},
		defineComboCategoria() {
			this.comboCategoria.push({ value: this.OPT_TODOS, text: "Todos" })
			this.comboCategoria.push({ value: this.VS_CATEGORIA_ENCOMENDA_POSTAGEM, text: "Postagens" })
			this.comboCategoria.push({ value: this.VS_CATEGORIA_ENCOMENDA_PRODUTO, text: "Produtos" })
			this.comboCategoria.push({ value: this.VS_CATEGORIA_ENCOMENDA_SERVICO, text: "Serviços" })
		},
		defineComboStatusPostagem() {
			this.comboStatusPostagem.push({ value: this.OPT_TODOS, text: "Todos" })
			this.comboStatusPostagem.push({ value: this.VS_STATUS_RASTREIO_POSTADO, text: "Postado" })
			this.comboStatusPostagem.push({ value: this.VS_STATUS_RASTREIO_NAOPOSTADO, text: "Não postado" })
			this.comboStatusPostagem.push({ value: this.VS_STATUS_RASTREIO_NAORASTREAVEL, text: "Não rastreável" })
		},
		montaQueryAtendimentos() {
			let sessao = this.$root.sessao
			let filtro = this.filtro.atendimento
			let idContratante = sessao.id

			if (!this.telaContratante) {
				idContratante = sessao.contratante.id
			}

			let query = `idContratante=${idContratante}`

			if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idFilial=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `&idFilial=${sessao.filial.id}`
			}else if (sessao.perfil === this.VS_PERFIL_OPERADOR) {
				query += `&idFilial=${sessao.filial.id}`
				query += `&idOperador=${sessao.id}`
			}

			query += `&perfil=${sessao.perfil}`

			if (filtro.responsavel.id !== this.OPT_TODOS) {
				let campoResponsavel = (this.telaContratante)	? "idFilial" : "idOperador"
				query += `&${campoResponsavel}=${filtro.responsavel.id}`
			}
			if (filtro.ativo !== this.OPT_TODOS) {
				query += `&ativo=${filtro.ativo}`
			}
			if (filtro.comprovante && filtro.comprovante.length > 0) {
				query += `&comprovante=${filtro.comprovante.trim()}`
			} else if (filtro.etiqueta && filtro.etiqueta.length > 0) {
				query += `&etiqueta=${filtro.etiqueta.trim()}`
			} else {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
				query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

				let pagador = filtro.pagador
				let remetente = filtro.remetente
				let destinatario = filtro.destinatario

				if (pagador && pagador.nome && pagador.nome.length > 0) {
					query += `&nomePagador=${pagador.nome.trim()}`
				}
				if (remetente && remetente.nome && remetente.nome.length > 0) {
					query += `&nomeRemetente=${remetente.nome.trim()}`
				}
				if (destinatario) {
					let endereco = destinatario.endereco
					if (destinatario.nome && destinatario.nome.length > 0) {
						query += `&nomeDestinatario=${destinatario.nome.trim()}`
					}
					if (endereco && endereco.cep && endereco.cep.length === 9) {
						query += `&cepDestinatario=${this.$vsLimpaFormatacao(endereco.cep)}`
					}
				}
			}
			return query
		},
		async listaAtendimentos(resetaPaginacaoEOrdenacao = true) {
			if (!this.validacoesFiltro(this.filtro.atendimento)) {
				return
			}

			let pag = this.paginador
			let ordem = pag.ordenacaoCampo.substring(0, 1).toUpperCase() + pag.ordenacaoCampo.substring(1)
			let query = this.montaQueryAtendimentos()

			query += `&pagina=${pag.paginaServidor}&ordenacaoDesc=${pag.ordenacaoDesc}&ordenacaoCampo=${ordem}`

			this.limpaTela(resetaPaginacaoEOrdenacao)

			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-atendimento/?${query}`)
			
			if (resp.status === 200) {
				this.atendimentos = resp.data
				this.mostraAtendimento = (this.atendimentos.totalRegistros > 0)
			}
		},
		async listaFechamentos() {
			this.limpaTela()

			let sessao = this.$root.sessao
			let filtro = this.filtro.fechamento
			let idContratante = (this.telaContratante) ? sessao.id : sessao.contratante.id
			let query = `idContratante=${idContratante}&perfil=${sessao.perfil}`

			if (this.telaContratante && filtro.optCombo != this.OPT_TODOS) {
				query += `&idFilial=${filtro.optCombo}`
			} else if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idFilial=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `&idFilial=${sessao.filial.id}`
			}
			if (filtro.data && filtro.data.length > 0) {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.data}T00:00:00`)}`
				query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.data}T23:59:59`)}`
			}
			if (!this.telaContratante && filtro.optCombo != this.OPT_TODOS) {
				query += `&idOperador=${filtro.optCombo}`
			}

			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-fechamento/?${query}`)
			
			if (resp.status === 200 && resp.data != null) {
				this.fechamento = resp.data
				this.mostraFechamento = (resp.data.quantidadeAtendimentos > 0)
			}
		},
		montaQueryPostagens() {
			let sessao = this.$root.sessao
			let filtro = this.filtro.postagem
			let idContratante = sessao.id

			if (!this.telaContratante) {
				idContratante = sessao.contratante.id
			}

			let query = `idContratante=${idContratante}`

			if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idFilial=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `&idFilial=${sessao.filial.id}`
			}

			query += `&perfil=${sessao.perfil}`

			if (filtro.responsavel.id !== this.OPT_TODOS) {
				let campoResponsavel = (this.telaContratante)	? "idFilial" : "idOperador"
				query += `&${campoResponsavel}=${filtro.responsavel.id}`
			}
			if (filtro.status !== this.OPT_TODOS) {
				query += `&statusRastreio=${filtro.status}`
			}
			if (filtro.modalidade !== this.OPT_TODOS) {
				var itemCombo = this.comboModalidade.find(el => el.value === filtro.modalidade)
				var coringa = itemCombo.value.split("|")
				query += `&modalidadeServico=${coringa[1]}`
			}
			if (filtro.categoria !== this.OPT_TODOS) {
				let temEtiqueta = (filtro.categoria === this.VS_CATEGORIA_ENCOMENDA_SERVICO)
				query += `&categoriaEncomenda=${filtro.categoria}`
			}
			if (filtro.comprovante && filtro.comprovante.length > 0) {
				query += `&comprovante=${filtro.comprovante.trim()}`
			} else if (filtro.etiqueta && filtro.etiqueta.length > 0) {
				query += `&etiqueta=${filtro.etiqueta.trim()}`
			} else {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
				query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

				let pagador = filtro.pagador
				let remetente = filtro.remetente
				let destinatario = filtro.destinatario

				if (pagador && pagador.nome && pagador.nome.length > 0) {
					query += `&nomePagador=${pagador.nome.trim()}`
				}
				if (remetente && remetente.nome && remetente.nome.length > 0) {
					query += `&nomeRemetente=${remetente.nome.trim()}`
				}
				if (destinatario) {
					let endereco = destinatario.endereco
					if (destinatario.nome && destinatario.nome.length > 0) {
						query += `&nomeDestinatario=${destinatario.nome.trim()}`
					}
					if (endereco && endereco.cep && endereco.cep.length === 9) {
						query += `&cepDestinatario=${this.$vsLimpaFormatacao(endereco.cep)}`
					}
				}
			}
			return query
		},
		async listaPostagens(resetaPaginacaoEOrdenacao = true) {
			if (!this.validacoesFiltro(this.filtro.postagem)) {
				return
			}

			let pag = this.paginador
			let ordem = pag.ordenacaoCampo.substring(0, 1).toUpperCase() + pag.ordenacaoCampo.substring(1)
			let query = this.montaQueryPostagens()

			query += `&pagina=${pag.paginaServidor}&ordenacaoDesc=${pag.ordenacaoDesc}&ordenacaoCampo=${ordem}`

			this.limpaTela(resetaPaginacaoEOrdenacao)

			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-postagem/?${query}`)
			
			if (resp.status === 200) {
				this.atendimentos = resp.data
				this.mostraPostagem = (resp.data.length > 0)
			}
		},
		montaQueryCobrancas() {
			let sessao = this.$root.sessao
			let filtro = this.filtro.cobranca
			let idContratante = sessao.id

			if (!this.telaContratante) {
				idContratante = sessao.contratante.id
			}

			let query = `idContratante=${idContratante}&perfil=${sessao.perfil}`

			if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idFilial=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `&idFilial=${sessao.filial.id}`
			}
			if (filtro.responsavel.id !== this.OPT_TODOS) {
				let campoResponsavel = (this.telaContratante)	? "idFilial" : "idOperador"
				query += `&${campoResponsavel}=${filtro.responsavel.id}`
			}
			if (filtro.ativo !== this.OPT_TODOS) {
				query += `&ativo=${filtro.ativo}`
			}
			if (filtro.status !== this.OPT_TODOS) {
				query += `&statusRastreio=${filtro.status}`
			}
			if (filtro.modalidade !== this.OPT_TODOS) {
				var itemCombo = this.comboModalidade.find(el => el.value === filtro.modalidade)
				var coringa = itemCombo.value.split("|")
				query += `&modalidadeServico=${coringa[1]}`
			}
			if (filtro.categoria !== this.OPT_TODOS) {
				let temEtiqueta = (filtro.categoria === this.VS_CATEGORIA_ENCOMENDA_SERVICO)
				query += `&categoriaEncomenda=${filtro.categoria}`
			}
			if (filtro.comprovante && filtro.comprovante.length > 0) {
				query += `&comprovante=${filtro.comprovante.trim()}`
			} else if (filtro.etiqueta && filtro.etiqueta.length > 0) {
				query += `&etiqueta=${filtro.etiqueta.trim()}`
			} else {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
				query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

				let pagador = filtro.pagador
				let destinatario = filtro.destinatario

				if (pagador && pagador.nome && pagador.nome.length > 0) {
					query += `&nomePagador=${pagador.nome.trim()}`
				}
				if (destinatario) {
					let endereco = destinatario.endereco
					if (destinatario.nome && destinatario.nome.length > 0) {
						query += `&nomeDestinatario=${destinatario.nome.trim()}`
					}
					if (endereco && endereco.cep && endereco.cep.length === 9) {
						query += `&cepDestinatario=${this.$vsLimpaFormatacao(endereco.cep)}`
					}
				}
			}
			return query
		},
		async listaCobrancas() {
			if (!this.validacoesFiltro(this.filtro.cobranca)) {
				return
			}

			let query = this.montaQueryCobrancas()

			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-postagem/?${query}`)
			
			if (resp.status === 200) {
				this.atendimentos = resp.data
				this.mostraCobranca = (resp.data.length > 0)
			}
		},
		async imprimeReciboTXT(obj) {
			let query = `idPostagem=${obj.idPostagem}`
			let recibo = this.recibo
			let resp = await this.$vsApiRest("GET", `/postagens/recibo/?${query}`)

			if (resp.status === 200) {
				recibo.postagem = resp.data.postagem
				recibo.filial = resp.data.filial
				recibo.operador = resp.data.operador
				recibo.pagador = resp.data.pagador

				const observer = new MutationObserver((mutations, obs) => {
					let impressao = document.querySelector("#impressaoRecibo")
					
					if (impressao) {						
						let text = this.$vsConverteHtmlTexto(impressao.innerHTML)
						this.$vsDownload(new TextEncoder().encode(text), `recibo-postair-${recibo.postagem.comprovante}.vst`, "text/plain")

						obs.disconnect()
						return
					}
				})
				observer.observe(document, {	childList: true, subtree: true })
			}
		},
		async imprimeReciboPDF(obj) {
			let query = `idAtendimento=${obj.idPostagem}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/recibo/?${query}`, {}, {}, "arraybuffer")
				
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `recibo-postair-${obj.comprovante}.pdf`)
			}
		},
		imprimeEtiqueta(etiqueta) {
			this.$vsImprimeEtiqueta(etiqueta)
		},
		imprimeFechamento() {
			this.$vsImprimeHTML(this.$refs.impressaoFechamento)
		},
		agrupaEntradas() {
			let entradas = this.$vsListaPagamentos(0, true, true)

			entradas.forEach(ent => {
				ent.total = 0

				this.fechamento.entradas.forEach(pag => {
					if (pag.metodo === ent.value) {
						ent.total += pag.valorTotal
					}
				})
			})
			return entradas
		},
		mostraModalCancelamento(obj, atendimento = false) {
			let cancel = this.cancelamento
			cancel.item = obj
			cancel.atendimento = atendimento
			cancel.motivo = ""
			cancel.senha = null
			this.$bvModal.show("modalCancelamento")
		},
		async confirmaCancelamento() {
			let cancel = this.cancelamento

			if (!cancel.motivo || !cancel.motivo.trim().length) {
				this.$vsNotificacao("Informe o motivo do cancelamento", "warning")
				return
			}

			let sessao = this.$root.sessao
			let resp = await this.$vsAutenticacao(sessao.email, cancel.senha, this.VS_PERFIL_GERENTE)
				
			if (!resp || resp.status !== 200) {
				return
			}

			let req = {
				idAtendimento: cancel.item.idPostagem,
				idResponsavel: sessao.id,
				motivo: cancel.motivo,
				cancelamentoOrigem: this.VS_ORIGEM_CANCELAMENTO_VOLUME_TELA_MOVIMENTACAO,
			}
			let msg = `Atendimento ${cancel.item.comprovante} cancelado`

			if (!cancel.atendimento) {
				req.idPostagemOuItem = cancel.item.idItem
				msg = `Serviço/Modalidade ${cancel.item.servicoDescricao} `

				if (cancel.item.numeroEncomenda.length) {
					msg += `(${cancel.item.numeroEncomenda}) `
				}
				msg += "cancelado"
			}

			this.$bvModal.hide("modalCancelamento")

			resp = await this.$vsApiRest("PATCH", "/postagens/cancelamento", req)

			if (resp.status === 200) {
				cancel.senha = null
				cancel.motivo = ""
				this.$vsNotificacao(msg, "warning")
				this.listaAtendimentos()
			}	
		},
		liberaImprimirRecibo(obj) {
			return !obj.atendimentoCancelado
		},
		liberaImprimirEtiqueta(obj) {
			return !obj.atendimentoCancelado && !obj.encomendaExterna && !obj.itemCancelado
		},
		liberaCancelarAtendimento(obj) {
			return (
				this.$root.sessao.perfil === this.VS_PERFIL_GERENTE 
				&& !obj.atendimentoCancelado 
				&& !obj.temRomaneio 
				&& !obj.faturadoContratante 
				&& !obj.faturadoFilial
			)
		},
		liberaCancelarEncomenda(obj) {
			return (
				this.$root.sessao.perfil === this.VS_PERFIL_GERENTE 
				&& !obj.atendimentoCancelado 
				&& !obj.temRomaneio 
				&& !obj.itemCancelado 
				&& !obj.faturadoContratante 
				&& !obj.faturadoFilial
			)
		},
		mascaraCep(evt) {
			evt.target.value = this.$vsFormataCep(evt.target.value)
		},
		calculaQtdDeclaracaoConteudo() {
			let form = this.declaracaoConteudo
			return form.itens.reduce((total, obj) => total + obj.quantidade, 0)
		},
		calculaTotalDeclaracaoConteudo() {
			let form = this.declaracaoConteudo
			return form.itens.reduce((total, obj) => total + obj.valor, 0)
		},
		async mostraDeclaracaoConteudo(obj) {
			let declaracao = this.declaracaoConteudo
			let query = `idPostagem=${obj.idPostagem}&etiqueta=${obj.numeroEncomenda}`

			this.$bvModal.show("modalDeclaracaoConteudo")

			declaracao.itens = []

			let resp = await this.$vsApiRest("GET", `/postagens/declaracao-conteudo/?${query}`)
			
			if (resp.status === 200 && resp.data.length) {
				declaracao.etiqueta = obj.numeroEncomenda
				declaracao.idAtendimento = obj.idPostagem
				declaracao.itens = resp.data[0].itens
			}
		},
		async imprimeDeclaracaoConteudo(tipo) {
			let declaracao = this.declaracaoConteudo
			let query = `idPostagem=${declaracao.idAtendimento}&etiqueta=${declaracao.etiqueta}&tipoRelatorio=${tipo}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/declaracao-conteudo/?${query}`, {}, {}, "arraybuffer")

			if (resp.status === 200) {
				this.$vsDownload(resp.data, `declaracao-conteudo-${new Date().getTime()}.pdf`)
			}
		},
		rastrearEtiqueta(etiqueta) {
			this.etiquetaRastreio = etiqueta
			this.indiceRastreio++
			this.$bvModal.show("modalRastreio")
		},
		limpaTela(resetaPaginacaoEOrdenacao = true) {
			this.mostraAtendimento = false
			this.mostraFechamento = false
			this.mostraPostagem = false
			this.mostraCobranca = false

			if (resetaPaginacaoEOrdenacao) {
				let pag = this.paginador
				pag.paginaAtual = 1
				pag.paginaServidor = 1
				pag.paginaTabela = 1
				pag.totalPagina = 10
				pag.ordenacaoDesc = false
				pag.ordenacaoCampo = "dataHora"
			}
		},
		exibeAR(obj) {
			return obj.adicionais.includes("AR")
		},
		async imprimeAR(obj) {
			let query = `idAtendimento=${obj.idPostagem}&etiqueta=${obj.numeroEncomenda}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/ar/?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `aviso-recebimento-${new Date().getTime()}.pdf`)
      }
		},
		totalUnitarioAtendimento() {
			return this.atendimentos.reduce((total, obj) => total += obj.valorUnitario, 0)
		},
		totalQuantidadeAtendimento() {
			return this.atendimentos.reduce((total, obj) => total += obj.quantidade, 0)
		},
		totalValorAtendimento() {
			return this.atendimentos.reduce((total, obj) => total += obj.valorTotal, 0)
		},
		lazyLoadDetalhesAtendimento(obj) {
			if (obj.detailsShowing) {
				obj.toggleDetails()
				return
			}
			this.atendimentos.dados.forEach((el, i) => {
				if (i !== obj.index) {
					this.$set(el, "_showDetails", false)
				}
			})
			this.buscaAtendimentoDetalhe(obj)
		},
		mudaCategoria(cobranca = false) {
			let filtro = (cobranca) ? this.filtro.cobranca : this.filtro.postagem

			if (filtro.modalidade !== this.OPT_TODOS) {
				var itemCombo = this.comboModalidade.find(el => el.value === filtro.modalidade)
				var coringa = itemCombo.value.split("|")
			
				filtro.categoria = coringa[0]
			} else {
				filtro.categoria = this.OPT_TODOS
			}
		},
		async imprimePostagensCSV() {
			let query = this.montaQueryPostagens()
			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-postagem/csv/?${query}`, {}, {}, "arraybuffer")

			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-postagens-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
			}
		},
		async imprimeAtendimentosCSV() {
			let query = this.montaQueryAtendimentos()
			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-atendimento-detalhado/csv/?${query}`, {}, {}, "arraybuffer")

			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-atendimentos-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
			}
		},
		async imprimeCobrancasCSV() {
			let query = this.montaQueryCobrancas()
			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-cobranca/csv/?${query}`, {}, {}, "arraybuffer")

			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-cobrancas-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
			}
		},
		async imprimeCobrancasPDF() {
			let query = this.montaQueryCobrancas()
			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-cobranca/pdf/?${query}`, {}, {}, "arraybuffer")

			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-cobrancas-${new Date().getTime()}.zip`)
			}
		},
		validacoesFiltro(form, prazoDias = 45) {
			let dtInicial = new Date(`${form.dataInicial}T00:00:00`)
			let dtFinal = new Date(`${form.dataFinal}T23:59:59`)
			let dtLimite = new Date(dtFinal - prazoDias * 86400000) // d-dias
			let ok = true

			if (dtFinal < dtInicial) {
				this.$vsNotificacao("Data final não pode ser menor que a data inicial", "warning")
				ok = false
			}
			if (dtInicial < dtLimite) {
				this.$vsNotificacao(`Intervalo entre as datas maior que ${prazoDias} dias, faça a correção`, "warning")
				ok = false
			}
			return ok
		},
		montaQueryPrePostagens() {
			let sessao = this.$root.sessao
			let filtro = this.filtro.prePostagem

			let query = `perfil=${sessao.perfil}`

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				query += `&idContratante=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idFilial=${sessao.id}`
			} else {
				query += `&idFilial=${sessao.filial.id}`
			}
			if (filtro.responsavel.id !== this.OPT_TODOS) {
				if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
					query += `&idFilial=${filtro.responsavel.id}`
				} else {
					query += `&idPagador=${filtro.responsavel.id}`
				}
			}
			if (filtro.ativo !== this.OPT_TODOS) {
				query += `&ativo=${filtro.ativo}`
			}
			if (filtro.comprovante && filtro.comprovante.length > 0) {
				query += `&comprovante=${filtro.comprovante.trim()}`
			} else if (filtro.etiqueta && filtro.etiqueta.length > 0) {
				query += `&etiqueta=${filtro.etiqueta.trim()}`
			} else {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
				query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

				let pagador = filtro.pagador
				let remetente = filtro.remetente
				let destinatario = filtro.destinatario

				if (pagador && pagador.nome && pagador.nome.length > 0) {
					query += `&nomePagador=${pagador.nome.trim()}`
				}
				if (remetente && remetente.nome && remetente.nome.length > 0) {
					query += `&nomeRemetente=${remetente.nome.trim()}`
				}
				if (destinatario) {
					let endereco = destinatario.endereco
					if (destinatario.nome && destinatario.nome.length > 0) {
						query += `&nomeDestinatario=${destinatario.nome.trim()}`
					}
					if (endereco && endereco.cep && endereco.cep.length === 9) {
						query += `&cepDestinatario=${this.$vsLimpaFormatacao(endereco.cep)}`
					}
				}
			}
			return query
		},
		async listaPrePostagens() {
			if (!this.validacoesFiltro(this.filtro.prePostagem, 20)) {
				return
			}

			let query = this.montaQueryPrePostagens()

			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/pre-postagens/relatorio/movimentacao-postagem/?${query}`)
				
			if (resp.status === 200) {
				this.atendimentos = resp.data
				this.mostraPostagem = (resp.data.length > 0)
			}			
		},
		imprimeEtiquetaPrePostagem(etiqueta) {
			this.$vsImprimeEtiquetaPrePostagem(etiqueta)
		},
		async imprimePrePostagensCSV() {
			let query = this.montaQueryPrePostagens()
			let resp = await this.$vsApiRest("GET", `/pre-postagens/relatorio/movimentacao-postagem-pagador/csv/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-pre-postagens-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
			}
		},
		mudaPagina(pagina, funcaoListagem) {
			let atd = this.atendimentos
			let pag = this.paginador
			let proximaPagina = Math.ceil(pagina / (this.VS_LIMITE_PAGINACAO / pag.totalPagina))
			let ultimaPagina = Math.ceil(atd.totalRegistros / pag.totalPagina)

			if (pagina > pag.paginaAtual) {
				pag.paginaTabela++
			} else if (pagina < pag.paginaAtual) {
				pag.paginaTabela--
			}
			if (pagina === 1 || proximaPagina > pag.paginaServidor) {
				pag.paginaTabela = 1
			} else if (proximaPagina < pag.paginaServidor) {
				pag.paginaTabela =  this.VS_LIMITE_PAGINACAO / pag.totalPagina
			}
			if (pagina === ultimaPagina) {
				let registrosUltimaPagina = atd.totalRegistros - (this.VS_LIMITE_PAGINACAO * (atd.totalPaginas - 1))
				pag.paginaTabela = Math.ceil(registrosUltimaPagina / pag.totalPagina)
			}
			if (atd.totalRegistros > this.VS_LIMITE_PAGINACAO && pag.paginaServidor !== proximaPagina) {
				pag.paginaServidor = proximaPagina
				pag.paginaAtual = pagina
				
				funcaoListagem(false)
			}
		},
		ordenaBusca(evt, funcaoListagem) {
			let pag = this.paginador
			pag.ordenacaoDesc = evt.sortDesc
			pag.ordenacaoCampo = evt.sortBy
			pag.paginaAtual = 1
			pag.paginaServidor = 1
			pag.paginaTabela = 1
			
			funcaoListagem(false)
		},
	},
	// #endregion
}
</script>
