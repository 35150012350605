<template>
	<div>
		<h1 class="titulo">Meus Envios</h1>

		<b-tabs content-class="pt-4 px-2">
			<!-- #region postagens -->
			<b-tab title="Postagens" title-item-class="h5" @click="listaPostagens()">
				<!-- #region filtro -->
				<form @submit.prevent="listaPostagens()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.postagem.dataInicial"
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.postagem.dataFinal" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col cols="1" align-self="center" class="pt-4 text-center">
								<a v-b-toggle.exibeFiltro @click.prevent class="h4" v-b-tooltip.hover title="Mostrar/ocultar filtros">
									<span class="cor-azul text-nowrap"><b-icon icon="plus" scale="1" variant="primary" />Filtros</span>
								</a>
							</b-col>
							<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
						<b-form-row>
							<!-- #region filtro ocultavel -->
							<b-collapse id="exibeFiltro" class="mt-3">
								<b-form-row>
									<b-col md="3" v-if="comboEquipe.length">
										<b-form-group class="form-input-cinza" label="Ponto de Atendimento" label-cols="12" label-size="lg">
											<model-select v-model="filtro.postagem.responsavel.id" :options="comboEquipe" @input="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Nº Encomenda" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.postagem.etiqueta" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Remetente" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.postagem.remetente.nome" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Destinatário" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.postagem.destinatario.nome" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Cep Destinatário" label-cols="12" label-size="lg" content-cols-md="">
											<b-form-input type="text" v-model="filtro.postagem.destinatario.endereco.cep" autocomplete="on" @keyup="limpaTela(); mascaraCep($event)" maxlength="9" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Situação" label-cols="12" label-size="lg">
											<b-form-select v-model="filtro.postagem.ativo" :options="comboSituacao" @change="limpaTela()" />
										</b-form-group>
									</b-col>
								</b-form-row>
							</b-collapse>
							<!-- #endregion -->
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

				<!-- #region listagem -->
				<div v-if="mostraPostagem">
					<div class="">
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimePostagensCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimePostagensPDF()" class="btn-vermelho" v-b-tooltip.hover title="Imprimir PDF">Exportar PDF</b-button>
						</span>
					</div>

					<b-table id="tabelaPostagens" class="text-nowrap h5"
						:items="lista"
						:fields="cabecalhoTabelaPostagem" 
						:per-page="totalPagina" 
						:current-page="paginaAtual" 
						small 
						hover 
						responsive 
						no-footer-sorting >
						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataHora)="obj">
							<a v-if="liberaImprimirRecibo(obj.item)" class="text-black"  @click.prevent="imprimeReciboPDF(obj.item)"	v-b-tooltip.hover.left="'Imprimir cupom/recibo'">
								<b-icon icon="printer" class="text-info" />&nbsp;{{ $vsFormataData(obj.value, true) }}
							</a>
							<span v-else v-b-tooltip.hover.left="'Atendimento ou encomenda cancelada'">
								<b-icon icon="x" class="text-danger" scale="2"/>&nbsp;{{ $vsFormataData(obj.value, true) }}
							</span>&nbsp;&nbsp;							
						</template>

						<template #cell(remetenteCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(destinatarioCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(numeroEncomenda)="obj">
							<div v-if="obj.value.length">
								<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
									<b-icon icon="exclamation-octagon-fill" class="text-warning"/>&nbsp;{{ obj.value }}
								</span>
								<span v-else-if="liberaRastrearEtiqueta(obj.item)" class="text-nowrap">	
									<a class="text-black" @click.prevent="rastrearEtiqueta(obj.value)" v-b-tooltip.hover.left="'Rastrear rótulo/etiqueta'">
										<b-icon icon="search" class="text-primary" />&nbsp;{{ obj.value }}
									</a>
								</span>
								<span v-else v-b-tooltip.hover.left="'Encomenda cancelada'">
									<b-icon icon="x" class="text-danger" scale="2"/>&nbsp;{{ obj.value }}
								</span>
							</div>
						</template>

						<template #cell(servicoDescricao)="obj">
							<div class="d-flex justify-content-between">
								<span>{{ obj.value }}</span>
							</div>
						</template>

						<template #cell(itemCancelado)="obj">
							<span v-if="obj.value" v-b-tooltip.hover.left="obj.item.motivoCancelamento">Cancelado</span>
							<span v-else>Ativo</span>
						</template>

						<template #cell(valorDeclarado)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(valorUnitario)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(valorTotal)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<b-row class="h5">
						<b-col cols="7">
							<span style="padding-left: 5px;padding-right: 20px;">
								<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
							</span>
							<span>Total de Registros: {{ $vsFormataNumero(lista.length) }}</span>
						</b-col>
						<b-col class="font-weight-bold text-right">
							Qtd. Total de objetos: {{ $vsFormataNumero(totalQuantidadePostagem()) }}<br>
							Total das Postagens: R$ {{ $vsFormataMoeda(totalValorPostagem()) }}
						</b-col>
					</b-row>
					<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="lista.length"
							:per-page="totalPagina" aria-controls="tabelaPostagens" v-model="paginaAtual" />
					<!-- #endregion -->
				</div>
				<!-- #endregion -->
			</b-tab>
			<!-- #endregion -->

			<!-- #region pre-postagens -->
			<b-tab title="Pré Postagens" title-item-class="h5" @click="listaPrePostagens()">
				<!-- #region filtro -->
				<form @submit.prevent="listaPrePostagens()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.prePostagem.dataInicial"
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="filtro.prePostagem.dataFinal" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData" 
										@input="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col cols="1" align-self="center" class="pt-4 text-center">
								<a v-b-toggle.exibeFiltro @click.prevent class="h4" v-b-tooltip.hover title="Mostrar/ocultar filtros">
									<span class="cor-azul text-nowrap"><b-icon icon="plus" scale="1" variant="primary" />Filtros</span>
								</a>
							</b-col>
							<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
						<b-form-row>
							<!-- #region filtro ocultavel -->
							<b-collapse id="exibeFiltro" class="mt-3">
								<b-form-row>
									<b-col md="3" v-if="comboEquipe.length">
										<b-form-group class="form-input-cinza" label="Ponto de Atendimento" label-cols="12" label-size="lg">
											<model-select v-model="filtro.prePostagem.responsavel.id" :options="comboEquipe" @input="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Nº Encomenda" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.prePostagem.etiqueta" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Remetente" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.prePostagem.remetente.nome" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Destinatário" label-cols="12" label-size="lg">
											<b-form-input type="text" v-model="filtro.prePostagem.destinatario.nome" autocomplete="on" @keyup="limpaTela()" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Cep Destinatário" label-cols="12" label-size="lg" content-cols-md="">
											<b-form-input type="text" v-model="filtro.prePostagem.destinatario.endereco.cep" autocomplete="on" @keyup="limpaTela(); mascaraCep($event)" maxlength="9" />
										</b-form-group>
									</b-col>
									<b-col md="2">
										<b-form-group class="form-input-cinza" label="Situação" label-cols="12" label-size="lg">
											<b-form-select v-model="filtro.prePostagem.ativo" :options="comboSituacao" @change="limpaTela()" />
										</b-form-group>
									</b-col>
								</b-form-row>
							</b-collapse>
							<!-- #endregion -->
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

				<!-- #region listagem -->
				<div v-if="mostraPostagem">
					<div class="">
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimePrePostagensCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
					</div>

					<b-table id="tabelaPrePostagens" class="text-nowrap h5"
						:items="lista"
						:fields="cabecalhoTabelaPrePostagem" 
						:per-page="totalPagina" 
						:current-page="paginaAtual" 
						small 
						hover 
						responsive 
						no-footer-sorting >
						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataHora)="obj">
							{{ $vsFormataData(obj.value, true) }}
						</template>

						<template #cell(valorTotal)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(remetenteCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(destinatarioCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(numeroEncomenda)="obj">
							<div v-if="obj.value.length">
								<span v-if="obj.item.encomendaExterna" v-b-tooltip.hover.left="'Encomenda externa'">
									<b-icon icon="exclamation-octagon-fill" class="text-warning"/>&nbsp;{{ obj.value }}
								</span>
								<span class="text-nowrap" v-else-if="liberaImprimirEtiqueta(obj.item)">	
									<a class="text-black" @click.prevent="imprimeEtiquetaPrePostagem(obj.value)" v-b-tooltip.hover.left="'Imprimir rótulo/etiqueta'">
										<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}&nbsp;
									</a>
								</span>
								<span v-else v-b-tooltip.hover.left="'Encomenda cancelada'">
									<b-icon icon="x" class="text-danger" scale="2"/>&nbsp;{{ obj.value }}
								</span>
							</div>
						</template>

						<template #cell(servicoDescricao)="obj">
							<div class="d-flex justify-content-between">
								<span>{{ obj.value }}</span>
							</div>
						</template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<b-row class="h5">
						<b-col cols="7">
							<span style="padding-left: 5px;padding-right: 20px;">
								<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
							</span>
							<span>Total de Registros: {{ $vsFormataNumero(lista.length) }}</span>
						</b-col>
						<b-col class="font-weight-bold text-right">
							Qtd. Total de objetos: {{ $vsFormataNumero(totalQuantidadePostagem()) }}<br>
							Total Estimado: R$ {{ $vsFormataMoeda(totalValorPostagem()) }}
						</b-col>
					</b-row>
					<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="lista.length"
							:per-page="totalPagina" aria-controls="tabelaPrePostagens" v-model="paginaAtual" />
					<!-- #endregion -->
				</div>
				<!-- #endregion -->
			</b-tab>
			<!-- #endregion -->
		</b-tabs>

		<!-- #region modais -->
		<!-- #region rastreio -->
		<b-modal id="modalRastreio" title="Rastreamento" size="lg">
			<div class="h5 modal-compactado">
				<vs-rastreio-encomenda 
					:key="indiceRastreio" 
					:etiqueta="etiquetaRastreio" 
					:isPrePostagem="isPrePostagem"/>
			</div>
			<template #modal-footer="{ hide }">
				<div class="w-100">
					<b-button class="float-right" variant="secondary" @click.prevent="hide()">Cancelar</b-button>
				</div>
			</template>
		</b-modal>
		<!-- #endregion -->

		<!-- #region declaracao conteudo -->
		<b-modal id="modalDeclaracaoConteudo"	:title="`Declaração de Conteúdo para o rótulo ${declaracaoConteudo.etiqueta}`" size="lg">
			<div v-if="declaracaoConteudo.itens.length" class="h5 modal-compactado">
				<!-- #region listagem -->
				<b-row class="mb-3 d-flex justify-content-between font-weight-bold">
					<b-col>Descrição</b-col>
					<b-col>Qtd.</b-col>
					<b-col>Valor</b-col>
				</b-row>
				<b-row class="mb-3 d-flex justify-content-between" v-for="(item, i) in declaracaoConteudo.itens" :key="i">
					<b-col>{{ item.descricao }}</b-col>
					<b-col class="text-right">{{ item.quantidade }}</b-col>
					<b-col class="text-right">R$ {{ $vsFormataMoeda(item.valor) }}</b-col>
				</b-row>
				<b-row class="d-flex justify-content-between font-weight-bold h4">
					<b-col>Total itens</b-col>
					<b-col class="text-right">{{ calculaQtdDeclaracaoConteudo() }}</b-col>
					<b-col class="text-right">R$ {{ $vsFormataMoeda(calculaTotalDeclaracaoConteudo()) }}</b-col>
				</b-row>
				<!-- #endregion -->
			</div>
			<div v-else class="h5">Nenhum item declarado</div>

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="w-100 d-flex justify-content-between">
					<b-button v-if="declaracaoConteudo.itens.length" variant="primary" class="text-nowrap" @click.prevent="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_A4)">
						<span class="font-weight-bold">Imprimir A4</span>
					</b-button>
					<b-button v-else type="button" variant="primary" class="text-nowrap" disabled>
						<span class="font-weight-bold">Imprimir A4</span>
					</b-button>

					<b-button v-if="declaracaoConteudo.itens.length" variant="success" class="text-nowrap" @click.prevent="imprimeDeclaracaoConteudo(VS_RELATORIO_DECLARACAO_CONTEUDO_TIPO_FOLHA_10x15)">
						<span class="font-weight-bold">Imprimir 10x15</span>
					</b-button>
					<b-button v-else type="button" variant="success" class="text-nowrap" disabled>
						<span class="font-weight-bold">Imprimir 10x15</span>
					</b-button>

					<b-button type="button" variant="danger" @click="hide()">
						<span class="font-weight-bold">Fechar</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
	</div>
</template>

<script>
import VsRastreioEncomenda from "../templates/vs-rastreio-encomenda.vue"
import { ModelSelect } from "vue-search-select"

export default {
	// #region configs
	name: "meus-envios",
	path: "/meus-envios",
	requireAuth: true,
	components: { VsRastreioEncomenda, ModelSelect },
	// #endregion

	// #region dados
	data() {
		return {
			OPT_TODOS: "0",
			idDetalheAberto: null,
			mostraPostagem: false,
			indiceRastreio: 0,
			etiquetaRastreio: "",
			isPrePostagem: false,
			declaracaoConteudo: {
				etiqueta: "",
				itens: [],
			},
			cancelamento: {
				atendimento: false,
				item: null,
				motivo: "",
				senha: null,
				produto: null,
			},
			filtro: {
				prePostagem: {
					comprovante: null,
					etiqueta: null,
					dataInicial: new Date(new Date() - 7 * 86400000).toISOString().substring(0, 10), // d-7
					dataFinal: new Date().toISOString().substring(0, 10), // hoje
					ativo: null,
					responsavel: {
						id: null,
					},
					pagador: {
						nome: null,
					},
					remetente: {
						nome: null,
					},
					destinatario: {
						nome: null,
						endereco: {
							cep: null,
						}
					},
				},
				postagem: {
					comprovante: null,
					etiqueta: null,
					dataInicial: new Date(new Date() - 7 * 86400000).toISOString().substring(0, 10), // d-7
					dataFinal: new Date().toISOString().substring(0, 10), // hoje
					ativo: null,
					responsavel: {
						id: null,
					},
					pagador: {
						nome: null,
					},
					remetente: {
						nome: null,
					},
					destinatario: {
						nome: null,
						endereco: {
							cep: null,
						}
					},
				},
			},
			formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
			cabecalhoTabelaPostagem: [
				{ key: "dataHora", label: "Data", sortable: true,  },
				{ key: "responsavelNome", label: "Ponto de Atendimento", sortable: true, },
				{ key: "remetenteNome", label: "Remetente", sortable: true, },
				{ key: "remetenteCep", label: "Cep Rem.", sortable: true, },
				{ key: "numeroEncomenda", label: "Etiqueta", sortable: true, },
				{ key: "itemCancelado", label: "Situação", sortable: true, },
				{ key: "servicoDescricao", label: "Serviço/Modal", sortable: true, },
				{ key: "destinatarioNome", label: "Destinatário", sortable: true, },
				{ key: "destinatarioCep", label: "Cep Dest.", sortable: true, },
				{ key: "valorDeclarado", label: "Declarado", sortable: true, tdClass: "text-right" },
				{ key: "valorUnitario", label: "V.Unit.", sortable: true, tdClass: "text-right" },
				{ key: "quantidade", label: "QT", sortable: true, tdClass: "text-right", },
				{ key: "valorTotal", label: "Total", sortable: true, tdClass: "text-right", },
			],
			cabecalhoTabelaPrePostagem: [
				{ key: "dataHora", label: "Data", sortable: true,  },
				{ key: "responsavelNome", label: "Ponto de Atendimento", sortable: true, },
				{ key: "remetenteNome", label: "Remetente", sortable: true, },
				{ key: "remetenteCep", label: "Cep Rem.", sortable: true, },
				{ key: "numeroEncomenda", label: "Etiqueta", sortable: true, },
				{ key: "servicoDescricao", label: "Serviço/Modal", sortable: true, },
				{ key: "destinatarioNome", label: "Destinatário", sortable: true, },
				{ key: "destinatarioCep", label: "Cep Dest.", sortable: true, },
				{ key: "valorTotal", label: "Valor Estimado", sortable: true, tdClass: "text-right", },
			],
			lista: [],
			atendimentoDetalhes: [],
			comboEquipe: [],
			comboSituacao: [],
			comboStatusPostagem: [],
			comboCategoria: [],
			paginaAtual: 1,
			totalPagina: 100,
			comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
		}
	},
	// #endregion

	// #region triggers
	created() {
		this.$vsDefineAcesso([
			this.VS_PERFIL_CLIENTE,
		])
	},
	mounted() {
		this.bootstrap()
	},
	// #endregion

	// #region metodos
	methods: {
		async bootstrap() {
			let filtro = this.filtro

			filtro.prePostagem.responsavel.id = this.OPT_TODOS
			filtro.prePostagem.ativo = this.OPT_TODOS

			filtro.postagem.responsavel.id = this.OPT_TODOS
			filtro.postagem.ativo = this.OPT_TODOS

			this.defineComboSituacao()
			this.defineComboCategoria()
			this.defineComboStatusPostagem()

			await this.buscaEquipe()
			await this.listaPostagens()
		},
		async buscaEquipe() {
			let sessao = this.$root.sessao
			let idContratante = sessao.id
			let tipo = "filiais"
			let query = ""

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				query = `idContratante=${idContratante}`
			} else if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `idContratante=${idContratante}`
				query += `&idFilial=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query += `idContratante=${idContratante}`
				query += `&idFilial=${sessao.filial.id}`
			} else if (sessao.perfil === this.VS_PERFIL_CLIENTE) {
				query = `idPagador=${sessao.id}`
			}

			let resp = await this.$vsApiRest("GET", `/usuarios/${tipo}/?${query}`)
			
			if (resp.status === 200) {
				this.defineComboEquipe(resp.data)
			}
		},
		async buscaPrePostagemDetalhe(obj) {
			let atendimento = obj.item
			let sessao = this.$root.sessao
			let query = `idAtendimento=${atendimento.idPostagem}&perfil=${sessao.perfil}`

			this.idDetalheAberto = atendimento.idPostagem
			this.atendimentoDetalhes = []

			let resp = await this.$vsApiRest("GET", `/pre-postagens/relatorio/movimentacao-atendimento-detalhado/?${query}`)
			
			if (resp.status === 200) {
				this.atendimentoDetalhes = resp.data
				obj.toggleDetails()
			}
		},
		async buscaPostagemDetalhe(obj) {
			let atendimento = obj.item
			let sessao = this.$root.sessao
			let query = `idAtendimento=${atendimento.idPostagem}&perfil=${sessao.perfil}`

			this.idDetalheAberto = atendimento.idPostagem
			this.atendimentoDetalhes = []

			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-atendimento-detalhado/?${query}`)
			
			if (resp.status === 200) {
				this.atendimentoDetalhes = resp.data
				obj.toggleDetails()
			}
		},
		defineComboEquipe(equipe) {
			equipe.forEach(el => {
				el.value = el.id
				el.text = el.nome
			})
			this.comboEquipe = equipe
			this.comboEquipe.unshift({ value: this.OPT_TODOS, text: "Todos" })
		},
		defineComboSituacao() {
			this.comboSituacao.push({ value: this.OPT_TODOS, text: "Todos" })
			this.comboSituacao.push({ value: true, text: "Ativos" })
			this.comboSituacao.push({ value: false, text: "Cancelados" })
		},
		defineComboCategoria() {
			this.comboCategoria.push({ value: this.OPT_TODOS, text: "Todos" })
			this.comboCategoria.push({ value: this.VS_CATEGORIA_ENCOMENDA_POSTAGEM, text: "Postagem" })
			this.comboCategoria.push({ value: this.VS_CATEGORIA_ENCOMENDA_SERVICO, text: "Serviços" })
		},
		defineComboStatusPostagem() {
			this.comboStatusPostagem.push({ value: this.OPT_TODOS, text: "Todos" })
			this.comboStatusPostagem.push({ value: this.VS_STATUS_RASTREIO_POSTADO, text: "Postado" })
			this.comboStatusPostagem.push({ value: this.VS_STATUS_RASTREIO_NAOPOSTADO, text: "Não postado" })
			this.comboStatusPostagem.push({ value: this.VS_STATUS_RASTREIO_NAORASTREAVEL, text: "Não rastreável" })
		},
		montaQueryPrePostagens() {
			let sessao = this.$root.sessao
			let filtro = this.filtro.prePostagem

			let query = `idPagador=${sessao.id}&perfil=${sessao.perfil}`

			if (filtro.responsavel.id !== this.OPT_TODOS) {
				query += `&idFilial=${filtro.responsavel.id}`
			}
			if (filtro.ativo !== this.OPT_TODOS) {
				query += `&ativo=${filtro.ativo}`
			}
			if (filtro.comprovante && filtro.comprovante.length > 0) {
				query += `&comprovante=${filtro.comprovante.trim()}`
			} else if (filtro.etiqueta && filtro.etiqueta.length > 0) {
				query += `&etiqueta=${filtro.etiqueta.trim()}`
			} else {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
				query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

				let pagador = filtro.pagador
				let remetente = filtro.remetente
				let destinatario = filtro.destinatario

				if (pagador && pagador.nome && pagador.nome.length > 0) {
					query += `&nomePagador=${pagador.nome.trim()}`
				}
				if (remetente && remetente.nome && remetente.nome.length > 0) {
					query += `&nomeRemetente=${remetente.nome.trim()}`
				}
				if (destinatario) {
					let endereco = destinatario.endereco
					if (destinatario.nome && destinatario.nome.length > 0) {
						query += `&nomeDestinatario=${destinatario.nome.trim()}`
					}
					if (endereco && endereco.cep && endereco.cep.length === 9) {
						query += `&cepDestinatario=${this.$vsLimpaFormatacao(endereco.cep)}`
					}
				}
			}
			return query
		},
		async listaPrePostagens() {
			if (!this.validacoesFiltro(this.filtro.prePostagem)) {
				return
			}

			let query = this.montaQueryPrePostagens()

			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/pre-postagens/relatorio/movimentacao-postagem/?${query}`)
				
			if (resp.status === 200) {
				this.lista = resp.data
				this.mostraPostagem = (resp.data.length > 0)
			}			
		},
		montaQueryPostagens() {
			let sessao = this.$root.sessao
			let filtro = this.filtro.postagem

			let query = `idPagador=${sessao.id}&perfil=${sessao.perfil}`

			if (filtro.responsavel.id !== this.OPT_TODOS) {
				query += `&idFilial=${filtro.responsavel.id}`
			}
			if (filtro.ativo !== this.OPT_TODOS) {
				query += `&ativo=${filtro.ativo}`
			}
			if (filtro.comprovante && filtro.comprovante.length > 0) {
				query += `&comprovante=${filtro.comprovante.trim()}`
			} else if (filtro.etiqueta && filtro.etiqueta.length > 0) {
				query += `&etiqueta=${filtro.etiqueta.trim()}`
			} else {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
				query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

				let pagador = filtro.pagador
				let remetente = filtro.remetente
				let destinatario = filtro.destinatario

				if (pagador && pagador.nome && pagador.nome.length > 0) {
					query += `&nomePagador=${pagador.nome.trim()}`
				}
				if (remetente && remetente.nome && remetente.nome.length > 0) {
					query += `&nomeRemetente=${remetente.nome.trim()}`
				}
				if (destinatario) {
					let endereco = destinatario.endereco
					if (destinatario.nome && destinatario.nome.length > 0) {
						query += `&nomeDestinatario=${destinatario.nome.trim()}`
					}
					if (endereco && endereco.cep && endereco.cep.length === 9) {
						query += `&cepDestinatario=${this.$vsLimpaFormatacao(endereco.cep)}`
					}
				}
			}
			return query
		},
		async listaPostagens() {
			if (!this.validacoesFiltro(this.filtro.postagem)) {
				return
			}

			let query = this.montaQueryPostagens()

			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-postagem/?${query}`)
			
			if (resp.status === 200) {
				this.lista = resp.data
				this.mostraPostagem = (resp.data.length > 0)
			}
		},
		async imprimeReciboPDF(obj) {
			let query = `idAtendimento=${obj.idPostagem}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/recibo/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `recibo-postair-${obj.comprovante}.pdf`)
			}
		},
		async imprimeReciboPrePostagemPDF(obj) {
			let query = `idAtendimento=${obj.idPostagem}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/impressao/recibo/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `recibo-postair-${obj.comprovante}.pdf`)
			}
		},
		imprimeEtiqueta(etiqueta) {
			this.$vsImprimeEtiqueta(etiqueta)
		},
		imprimeEtiquetaPrePostagem(etiqueta) {
			this.$vsImprimeEtiquetaPrePostagem(etiqueta)
		},
		liberaImprimirRecibo(obj) {
			return !obj.itemCancelado
		},
		liberaRastrearEtiqueta(obj) {
			return !obj.atendimentoCancelado && !obj.encomendaExterna && !obj.itemCancelado
		},
		liberaImprimirEtiqueta(obj) {
			return !obj.atendimentoCancelado && !obj.encomendaExterna && !obj.itemCancelado
		},
		mascaraCep(evt) {
			evt.target.value = this.$vsFormataCep(evt.target.value)
		},
		calculaQtdDeclaracaoConteudo() {
			let form = this.declaracaoConteudo
			return form.itens.reduce((total, obj) => total + obj.quantidade, 0)
		},
		calculaTotalDeclaracaoConteudo() {
			let form = this.declaracaoConteudo
			return form.itens.reduce((total, obj) => total + obj.valor, 0)
		},
		async mostraDeclaracaoConteudo(obj) {
			let declaracao = this.declaracaoConteudo
			let query = `idPostagem=${obj.idPostagem}&etiqueta=${obj.numeroEncomenda}`

			this.$bvModal.show("modalDeclaracaoConteudo")

			declaracao.itens = []

			let resp = await this.$vsApiRest("GET", `/postagens/declaracao-conteudo/?${query}`)
			
			if (resp.status === 200 && resp.data.length) {
				declaracao.etiqueta = obj.numeroEncomenda
				declaracao.idAtendimento = obj.idPostagem
				declaracao.itens = resp.data[0].itens
			}
		},
		async mostraDeclaracaoConteudoPrePostagem(obj) {
			let declaracao = this.declaracaoConteudo
			let query = `idPostagem=${obj.idPostagem}&etiqueta=${obj.numeroEncomenda}`

			this.$bvModal.show("modalDeclaracaoConteudo")

			declaracao.itens = []

			let resp = await this.$vsApiRest("GET", `/pre-postagens/declaracao-conteudo/?${query}`)
				
			if (resp.status === 200 && resp.data.length) {
				declaracao.etiqueta = obj.numeroEncomenda
				declaracao.idAtendimento = obj.idPostagem
				declaracao.itens = resp.data[0].itens
			}
		},
		async imprimeDeclaracaoConteudo(tipo) {
			let declaracao = this.declaracaoConteudo
			let query = `idPostagem=${declaracao.idAtendimento}&etiqueta=${declaracao.etiqueta}&tipoRelatorio=${tipo}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/declaracao-conteudo/?${query}`, {}, {}, "arraybuffer")

			if (resp.status === 200) {
				this.$vsDownload(resp.data, `declaracao-conteudo-${new Date().getTime()}.pdf`)
			}
		},
		async imprimeDeclaracaoConteudoPrePostagem(tipo) {
			let declaracao = this.declaracaoConteudo
			let query = `idPostagem=${declaracao.idAtendimento}&etiqueta=${declaracao.etiqueta}&tipoRelatorio=${tipo}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/impressao/declaracao-conteudo/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `declaracao-conteudo-${new Date().getTime()}.pdf`)
			}
		},
		rastrearEtiqueta(etiqueta, isPrePostagem=false) {
			this.etiquetaRastreio = etiqueta
			this.indiceRastreio++
			this.isPrePostagem = isPrePostagem
			this.$bvModal.show("modalRastreio")
		},
		limpaTela() {
			this.mostraPostagem = false
			this.paginaAtual = 1
			this.totalPagina = 100
		},
		exibeAR(obj) {
			return obj.adicionais.includes("AR")
		},
		async imprimeAR(obj) {
			let query = `idAtendimento=${obj.idPostagem}&etiqueta=${obj.numeroEncomenda}`
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/ar/?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
       	this.$vsDownload(resp.data, `aviso-recebimento-${new Date().getTime()}.pdf`)
			}
		},
		async imprimeARPrePostagem(obj) {
			let query = `idAtendimento=${obj.idPostagem}&etiqueta=${obj.numeroEncomenda}`
			let resp = await this.$vsApiRest("GET", `/pre-postagens/impressao/ar/?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `aviso-recebimento-${new Date().getTime()}.pdf`)
      }
		},
		totalQuantidadePostagem() {
			return this.lista.reduce((total, obj) => total += obj.quantidade, 0)
		},
		totalValorPostagem() {
			return this.lista.reduce((total, obj) => total += obj.valorTotal, 0)
		},
		lazyLoadDetalhesPrePostagem(obj) {
			if (obj.detailsShowing) {
				obj.toggleDetails()
				return
			}
			this.lista.forEach((el, i) => {
				if (i !== obj.index) {
					this.$set(el, "_showDetails", false)
				}
			})
			this.buscaPrePostagemDetalhe(obj)
		},
		lazyLoadDetalhesPostagem(obj) {
			if (obj.detailsShowing) {
				obj.toggleDetails()
				return
			}
			this.lista.forEach((el, i) => {
				if (i !== obj.index) {
					this.$set(el, "_showDetails", false)
				}
			})
			this.buscaPostagemDetalhe(obj)
		},
		async imprimePrePostagensCSV() {
			let query = this.montaQueryPrePostagens()
			let resp = await this.$vsApiRest("GET", `/pre-postagens/relatorio/movimentacao-postagem-pagador/csv/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-pre-postagens-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
			}
		},
		async imprimePostagensCSV() {
			let query = this.montaQueryPostagens()
			let resp = await this.$vsApiRest("GET", `/postagens/relatorio/movimentacao-postagem-pagador/csv/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-postagens-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
			}
		},
		async imprimePostagensPDF() {
			let query = this.montaQueryPostagens()
			let resp = await this.$vsApiRest("GET", `/postagens/impressao/postagem-pagador/pdf/?${query}`, {}, {}, "arraybuffer")
			
			if (resp.status === 200) {
				this.$vsDownload(resp.data, `relatorio-postagens-${new Date().getTime()}.zip`)
			}
		},
		validacoesFiltro(form) {
			let dtInicial = new Date(`${form.dataInicial}T00:00:00`)
			let dtFinal = new Date(`${form.dataFinal}T23:59:59`)
			let dtLimite = new Date(dtFinal - 60 * 86400000) // d-60
			let ok = true

			if (dtFinal < dtInicial) {
				this.$vsNotificacao("Data final não pode ser menor que a data inicial", "warning")
				ok = false
			}
			if (dtInicial < dtLimite) {
				this.$vsNotificacao("Intervalo entre as datas maior que 60 dias, faça a correção", "warning")
				ok = false
			}
			return ok
		},
	},
	// #endregion
}
</script>
