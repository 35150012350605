<template>
  <div>
    <h1 class="titulo">Transportadoras</h1>

    <b-tabs class="pt-4" content-class="p-3 bg-white">
      <!-- #region aba cadastros -->
      <b-tab title="Cadastros" active @click="limpaTransportadora()">
        <!-- #region cadastro/edicao -->
        <form>
          <b-form-row>
            <!-- #region dados basicos -->
            <b-col md="12">
              <b-form-group style="color: transparent;" label="." label-cols="12" label-size="lg">
                <b-form-checkbox class="form-input-cinza" v-model="transportadora.isAtivo" size="lg" switch>
                  <span style="cursor: pointer; color: black;">Ativo/Inativo</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="CPF/CNPJ" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="transportadora.cpfCnpj" @keyup="mascaraCpfCnpj()" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group class="form-input-cinza" label="Razão Social" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="transportadora.nome" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group class="form-input-cinza" label="Nome Fantasia" label-size="lg" label-cols="12" content-cols-md="">
                <b-form-input type="text" size="" v-model="transportadora.nomeFantasia" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="form-input-cinza" label="E-mail" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="transportadora.email" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Telefone" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="transportadora.telefone" @keyup="mascaraTelefone()" autocomplete="on" />
              </b-form-group>
            </b-col>
            <!-- #endregion -->

            <!-- #region endereco -->
            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Cep" label-cols="12" label-size="lg">
                <b-form-input type="text" size="" v-model="transportadora.endereco.cep" @keyup="mascaraCep()" maxlength="9" autocomplete="on" @blur="buscaCep()" />
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group class="form-input-cinza" label="Endereço" label-cols="12" label-size="lg">
                <b-form-input type="text" size="" v-model="transportadora.endereco.logradouro" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Bairro" label-cols="12" label-size="lg">
                <b-form-input type="text" size="" v-model="transportadora.endereco.bairro" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Número" label-cols="12" label-size="lg">
                <b-form-input type="text" size="" v-model="transportadora.endereco.numero" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Complemento" label-cols="12" label-size="lg">
                <b-form-input type="text" size="" v-model="transportadora.endereco.complemento" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Cidade" label-cols="12" label-size="lg">
                <b-form-input type="text" size="" v-model="transportadora.endereco.cidade" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="1">
              <b-form-group class="form-input-cinza" label="UF" label-cols="12" label-size="lg">
                <b-form-input type="text" size="" maxlength="2" v-model="transportadora.endereco.uf"
                  autocomplete="on" />
              </b-form-group>
            </b-col>
            <!-- #endregion -->

            <!-- #region configs -->
            <b-col md="4">
              <b-form-group style="color: transparent;" label="." label-cols="12" label-size="lg">
                <b-form-checkbox class="form-input-cinza" v-model="transportadora.usaCodigoServicoNumerico" size="lg" switch>
                  <span style="cursor: pointer; color: black;">Serviço usa código numérico?</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group style="color: transparent;" label="." label-cols="12" label-size="lg">
                <b-form-checkbox class="form-input-cinza" v-model="transportadora.usaNivelContrato" size="lg" switch @change="inativaCodigoNivelContrato()">
                  <span style="cursor: pointer; color: black;">Usa nível de contrato?</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="5">
              <b-form-group style="color: transparent;" label="." label-cols="12" label-size="lg">
                <b-form-checkbox class="form-input-cinza" v-model="transportadora.usaCodigoNivelContratoNumerico" size="lg" switch @change="ativaNivelContrato()">
                  <span style="cursor: pointer; color: black;">Nível de contrato usa código numérico?</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>

            <b-col md="4">
              <b-form-group class="form-input-cinza" label="Classe API *" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="transportadora.classeApi" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col cols="12" class="mb-3">
              <div style="color: #808080;">* uso exclusivo pela equipe de desenvolvimento</div>
            </b-col>
            <!-- #endregion -->
          </b-form-row>

          <!-- #region acoes -->
          <b-form-row>
            <b-col md="2">
              <b-button class="btn-azul" @click.prevent="gravaTransportadora(true)">Gravar</b-button>
            </b-col>
            <b-col md="2">
              <b-button class="btn-cinza" @click.prevent="limpaTransportadora()">Cancelar</b-button>
            </b-col>
          </b-form-row>
          <!-- #endregion -->
        </form>
        <!-- #endregion -->

        <!-- #region listagem/exibicao -->
        <div v-if="transportadoras.length" class="h5 mt-4 mb-5">
          <b-table 
					  class="text-nowrap h5" 
						id="tabelaTransportadoras" 
						:items="transportadoras"
            :fields="camposTabelaTransportadoras" 
						:per-page="totalPaginaTransportadora"
            :current-page="paginaAtualTransportadora"
						small 
						responsive 
						hover>
            <!--===================================================================================
              celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

              obj.index = indice da linha
              obj.field = nome do campo/coluna/chave json
              obj.item  = objeto json bruto
              obj.value = o valor da chave json com tipo original mantido e sem tratamento

              para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
            ====================================================================================-->
            <!--<template #cell()="obj">
              {{ obj.field }}
              {{ obj.item }}
              {{ obj.value }}
            </template>-->

            <template #cell(cpfCnpj)="obj">
              {{ $vsFormataCpfCnpj(obj.value) }}
            </template>

            <template #cell(usaCodigoServicoNumerico)="obj">
              <span v-if="obj.value">Sim</span>
              <span v-else>Não</span>
            </template>

            <template #cell(usaNivelContrato)="obj">
              <span v-if="obj.value">Sim</span>
              <span v-else>Não</span>
            </template>

            <template #cell(usaCodigoNivelContratoNumerico)="obj">
              <span v-if="obj.value">Sim</span>
              <span v-else>Não</span>
            </template>

            <template #cell(isAtivo)="obj">
              <span v-if="obj.value">Sim</span>
              <span v-else>Não</span>
            </template>

            <template #cell(opcoes)="obj">
              <div class="pr-5">
                <button type="button" class="btn-laranja" style="width: 100px; height: 40px;" @click="editaTransportadora(obj.item)">Editar</button>
              </div>
            </template>
          </b-table>

          <div class="h5">
            <span style="padding-left: 5px;padding-right: 20px;">
              <b-form-select class="numPerPage" v-model="totalPaginaTransportadora" :options="comboPaginacao" style="width: 100px;" />
            </span>
            <span> Total de Registros: {{ $vsFormataNumero(transportadoras.length) }}</span>
            <b-pagination class="mt-3 d-flex justify-content-center" 
						  :total-rows="transportadoras.length"
              :per-page="totalPaginaTransportadora" 
							aria-controls="tabelaTransportadoras"
              v-model="paginaAtualTransportadora" />
          </div>
        </div>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->

      <!-- #region aba servicos -->
      <b-tab title="Serviços" @click="limpaTransportadora()">
        <!-- #region cadastro/edicao -->
        <form @submit.prevent="gravaServico()">
          <!-- #region dados -->
          <b-form-row>
            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Transportadora" label-size="lg" label-cols="12">
                <b-form-select v-model="idTransportadora" :options="transportadoras" @change="defineTransportadora()" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Serviço" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="formServico.codigoServico" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Descrição" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="formServico.descricaoServico" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="2" v-if="transportadora.usaNivelContrato">
              <b-form-group class="form-input-cinza" label="Nível de contrato" label-size="lg" label-cols="12">
                <b-form-input ref="inputNivelContrato" type="text" size="" v-model="formServico.codigoNivelContrato" autocomplete="on" />
              </b-form-group>
            </b-col>

            <b-col md="3" v-if="transportadora.usaNivelContrato">
              <b-form-group class="form-input-cinza" label="Descrição" label-size="lg" label-cols="12">
                <b-form-input type="text" size="" v-model="formServico.descricaoNivelContrato" autocomplete="on" />
              </b-form-group>
            </b-col>
          </b-form-row>
          <!-- #endregion -->

          <!-- #region acoes -->
          <b-form-row>
            <b-col md="2">
              <b-button type="submit" class="btn-azul">Gravar</b-button>
            </b-col>
            <b-col md="2">
              <b-button class="btn-cinza" @click.prevent="limpaServico()">Cancelar</b-button>
            </b-col>
          </b-form-row>
          <!-- #endregion -->
        </form>
        <!-- #endregion -->

        <!-- #region listagem/exibicao -->
        <div v-if="transportadora.servicos.length" class="mt-4">
          <b-table 
					  id="tabelaServicos" 
						class="h5 p-3 cor-fundo-padrao" 
						:items="transportadora.servicos"
            :fields="camposTabelaServicos" 
						:per-page="totalPaginaServicos" 
						:current-page="paginaAtualServicos" 
						small
            responsive 
						borderless>
            <!--===================================================================================
              mapa de colunas (atua em campos sem tratamento) 
              se precisar debugar ou visualizar os dados para ajustes basta descomentar...

              obj.index = indice da linha
              obj.field = nome do campo/coluna/chave json
              obj.item  = objeto json bruto
              obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
            ====================================================================================-->
            <!--<template #cell()="obj">
              {{ obj.field }}
              {{ obj.item }}
            </template>-->

            <!--===================================================================================
              celulas que precisam de tratamento, 
              sobrescrevem mapa de colunas pela identificacao da chave
              e nao precisam estar em ordem/sequencia
            ====================================================================================-->
            <template #cell(isAtivo)="obj">
              <b-form-checkbox class="form-input-cinza" v-model="obj.item.isAtivo" size="lg" switch @change="mudaStatusServico()">
                <span v-if="obj.value" style="cursor: pointer">Sim</span>
                <span v-else style="cursor: pointer">Não</span>
              </b-form-checkbox>
            </template>

            <template #cell(niveisContrato)="obj">
              <span v-for="(nivel, i) in obj.value" :key="nivel.codigo">
                <b-button pill variant="outline-danger" class="mr-2 mb-2 px-2 p-1" @click.prevent="excluiNivelContrato(obj.item, i)">
                  <span class="">{{ nivel.codigo }} - {{ nivel.descricao }}</span><b-icon icon="trash" scale="0.8" />
                </b-button>
              </span>
            </template>
          </b-table>

          <div class="h5">
            <span style="padding-left: 5px;padding-right: 20px;">
              <b-form-select class="numPerPage" v-model="totalPaginaServicos" :options="comboPaginacao" style="width: 100px;" />
            </span>
            <span> Total de Registros: {{ $vsFormataNumero(transportadora.servicos.length) }}</span>
            <b-pagination class="mt-3 d-flex justify-content-center" 
						  :total-rows="transportadora.servicos.length"
              :per-page="totalPaginaServicos" 
							aria-controls="tabelaServicos" 
							v-model="paginaAtualServicos" />
          </div>
        </div>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->
    </b-tabs>
  </div>
</template>

<script>
export default {
  // #region configs
  name: "transportadoras",
  path: "/transportadoras",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
      idTransportadora: null,
      transportadora: {
        id: null,
        cpfCnpj: "",
        nome: "",
        nomeFantasia: "",
        email: "",
        telefone: "",
        endereco: {
          tipo: 0,
          logradouro: "",
          numero: "",
          bairro: "",
          cidade: "",
          cep: "",
          uf: "",
          pais: "Brasil",
          apelido: "",
          complemento: "",
          isComercial: false,
        },
        classeApi: "",
        isAtivo: true,
        isExcluido: false,
        usaNivelContrato: false,
        usaCodigoNivelContratoNumerico: false,
        usaCodigoServicoNumerico: false,
        servicos: [],
      },
      formServico: {
        codigoServico: "",
        descricaoServico: "",
        codigoNivelContrato: "",
        descricaoNivelContrato: "",
      },
      camposTabelaTransportadoras: [
        { key: "cpfCnpj", sortable: true, label: "CPF/CNPJ", tdClass: "text-left", },
        { key: "nome", sortable: true, label: "Razão Social", tdClass: "text-left", },
        { key: "nomeFantasia", sortable: true, label: "Nome Fantasia", tdClass: "text-left", },
        { key: "email", sortable: true, label: "E-mail", tdClass: "text-left", },
        { key: "telefone", sortable: true, label: "Telefone", tdClass: "text-left", },
        { key: "endereco.cep", sortable: true, label: "Cep", tdClass: "text-left", },
        { key: "endereco.logradouro", sortable: true, label: "Endereço", tdClass: "text-left", },
        { key: "endereco.bairro", sortable: true, label: "Bairro", tdClass: "text-left", },
        { key: "endereco.numero", sortable: true, label: "Número", tdClass: "text-left", },
        { key: "endereco.complemento", sortable: true, label: "Complemento", tdClass: "text-left", },
        { key: "endereco.cidade", sortable: true, label: "Cidade", tdClass: "text-left", },
        { key: "endereco.uf", sortable: true, label: "UF", tdClass: "text-left", },
        { key: "usaCodigoServicoNumerico", sortable: true, label: "Serviço numérico", tdClass: "text-left", },
        { key: "usaNivelContrato", sortable: true, label: "Usa nível de contrato", tdClass: "text-left", },
        { key: "usaCodigoNivelContratoNumerico", sortable: true, label: "Nível de contrato numérico", tdClass: "text-left", },
        { key: "isAtivo", sortable: true, label: "Ativo", tdClass: "text-left", },
        { key: "opcoes", sortable: true, label: "Opções", tdClass: "text-left", },
      ],
      camposTabelaServicos: [
        { key: "codigo", sortable: true, label: "Código", tdClass: "text-left", },
        { key: "descricao", sortable: true, label: "Descrição", tdClass: "text-left text-nowrap", },
        { key: "isAtivo", sortable: true, label: "Ativo", tdClass: "text-left", },
        { key: "niveisContrato", sortable: true, label: "Níveis de contrato", tdClass: "text-left", },
      ],
      transportadoras: [],
      paginaAtualTransportadora: 1,
      paginaAtualServicos: 1,
      totalPaginaTransportadora: 100,
      totalPaginaServicos: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_SUPERADMIN])
    this.transportadora.endereco.tipo = this.VS_TIPO_ENDERECO_RUA
  },
  mounted() {
    this.listaTransportadoras()
  },
  // #endregion

  // #region metodos
  methods: {
    mascaraCpfCnpj() {
      let tra = this.transportadora
      tra.cpfCnpj = this.$vsFormataCpfCnpj(tra.cpfCnpj)
    },
    mascaraTelefone() {
      let tra = this.transportadora
      tra.telefone = this.$vsFormataTelefone(tra.telefone)
    },
    mascaraCep() {
      let end = this.transportadora.endereco
      end.cep = this.$vsFormataCep(end.cep)
    },
    async buscaCep() {
      let end = this.transportadora.endereco
      let cep = this.$vsLimpaFormatacao(end.cep)

      if (cep.length !== 8) {
        return
      }

      let resp = await this.$vsApiRest("GET", `/tarifacao/?cep=${cep}`)
      
			end.numero = ""
      end.complemento = ""
			end.logradouro = ""
      end.bairro = ""
      end.cidade = ""
      end.uf = ""

      if (resp.status === 200) {
        end.logradouro = resp.data.logradouro
        end.bairro = resp.data.bairro
        end.cidade = resp.data.cidade
        end.uf = resp.data.uf
      }
    },
    async listaTransportadoras() {
      let query = "excluido=false"
      let resp = await this.$vsApiRest("GET", `/transportadoras/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.text = el.nomeFantasia
          el.value = el.id
        })
        this.transportadoras = resp.data
      }
    },
    limpaTransportadora() {
      this.$vsLoadingStart(true, 200)

      let tra = this.transportadora
      tra.id = null
      tra.cpfCnpj = ""
      tra.nome = ""
      tra.nomeFantasia = ""
      tra.email = ""
      tra.telefone = ""
      tra.classeApi = ""
      tra.isAtivo = true
      tra.isExcluido = false
      tra.usaNivelContrato = false
      tra.usaCodigoNivelContratoNumerico = false
      tra.usaCodigoServicoNumerico = false
      tra.servicos = []

      let end = tra.endereco
      end.logradouro = ""
      end.numero = ""
      end.bairro = ""
      end.cidade = ""
      end.cep = ""
      end.uf = ""
      end.pais = "Brasil"
      end.apelido = ""
      end.complemento = ""
      end.isComercial = false

      this.limpaServico()
    },
    editaTransportadora(obj) {
      this.$vsLoadingStart(true, 200)

      let tra = this.transportadora
      tra.id = obj.id
      tra.cpfCnpj = this.$vsFormataCpfCnpj(obj.cpfCnpj)
      tra.nome = obj.nome
      tra.nomeFantasia = obj.nomeFantasia
      tra.email = obj.email
      tra.telefone = this.$vsFormataTelefone(obj.telefone)
      tra.classeApi = obj.classeApi
      tra.isAtivo = obj.isAtivo
      tra.isExcluido = obj.isExcluido
      tra.usaNivelContrato = obj.usaNivelContrato
      tra.usaCodigoNivelContratoNumerico = obj.usaCodigoNivelContratoNumerico
      tra.usaCodigoServicoNumerico = obj.usaCodigoServicoNumerico
      tra.servicos = obj.servicos

      if (obj.endereco) {
        let end = tra.endereco
        end.logradouro = obj.endereco.logradouro
        end.numero = obj.endereco.numero
        end.bairro = obj.endereco.bairro
        end.cidade = obj.endereco.cidade
        end.cep = this.$vsFormataCep(obj.endereco.cep)
        end.uf = obj.endereco.uf
        end.pais = obj.endereco.pais
        end.apelido = obj.endereco.apelido
        end.complemento = obj.endereco.complemento
        end.isComercial = obj.endereco.isComercial
      }
    },
    async gravaTransportadora(limpeza = false) {
      let tra = this.transportadora
      tra.cpfCnpj = this.$vsLimpaFormatacao(tra.cpfCnpj)
      tra.telefone = this.$vsLimpaFormatacao(tra.telefone)
      tra.endereco.cep = this.$vsLimpaFormatacao(tra.endereco.cep)

      let resp = await this.$vsApiRest("POST", "/transportadoras", tra)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Cadastro efetuado com sucesso", "success")

        if (limpeza) {
          this.limpaTransportadora()
          this.listaTransportadoras()
        }
      }
    },
    defineTransportadora() {
      let tra = this.transportadoras.find(el => el.id === this.idTransportadora)
      if (tra) {
        this.editaTransportadora(tra)
      }
    },
    limpaServico() {
      this.$vsLoadingStart(true, 200)

      let form = this.formServico
      form.codigoServico = ""
      form.descricaoServico = ""
      form.codigoNivelContrato = ""
      form.descricaoNivelContrato = ""

      this.idTransportadora = null
    },
    async gravaServico() {
      let transp = this.transportadora
      let form = this.formServico
      let codigoServico = form.codigoServico.trim()
      let descricaoServico = form.descricaoServico.trim()
      let codigoNivel = form.codigoNivelContrato.trim()
      let descricaoNivel = form.descricaoNivelContrato.trim()
      let ok = true
      let regexNumerico = /^\d+$/

      // validacoes
      if (!this.idTransportadora) {
        this.$vsNotificacao("Informe a transportadora", "warning")
        ok = false
      }
      if (!codigoServico.length) {
        this.$vsNotificacao("Informe o código do serviço", "warning")
        ok = false
      } else if (transp.usaCodigoServicoNumerico && !regexNumerico.test(codigoServico)) {
        this.$vsNotificacao("Código do serviço deve ser numérico", "warning")
        ok = false
      }
      if (!descricaoServico.length) {
        this.$vsNotificacao("Informe a descrição do serviço", "warning")
        ok = false
      }
      if (transp.usaNivelContrato) {
        if (!codigoNivel.length) {
          this.$vsNotificacao("Informe o código do nível de contrato", "warning")
          ok = false
        } else if (transp.usaCodigoNivelContratoNumerico && !regexNumerico.test(codigoNivel)) {
          this.$vsNotificacao("Código do nível de contrato deve ser numérico", "warning")
          ok = false
        }
        if (!descricaoNivel.length) {
          this.$vsNotificacao("Informe a descrição do nível de contrato", "warning")
          ok = false
        }
      }
      if (!ok) {
        return
      }

      // ajustes finais
      if (transp.usaCodigoServicoNumerico) {
        codigoServico = String(parseInt(codigoServico))
      }
      if (transp.usaCodigoNivelContratoNumerico) {
        codigoNivel = String(parseInt(codigoNivel))
      }

      // insere na grid
      let lista = transp.servicos
      let servico = lista.find(el => el.codigo === codigoServico)

      if (!servico) {
        servico = {
          codigo: codigoServico,
          descricao: descricaoServico,
          niveisContrato: []
        }
        lista.push(servico)
      }
      servico.descricao = descricaoServico

      if (transp.usaNivelContrato) {
        let nivel = servico.niveisContrato.find(el => el.codigo === codigoNivel)

        if (!nivel) {
          nivel = {
            codigo: codigoNivel,
            descricao: descricaoNivel,
          }
          servico.niveisContrato.push(nivel)
        }
        nivel.descricao = descricaoNivel

        servico.niveisContrato.sort(function (a, b) {
          return (a.codigo > b.codigo) ? 1 : -1
        })

        form.codigoNivelContrato = ""
        form.descricaoNivelContrato = ""
        this.$refs.inputNivelContrato.$el.focus()
      }

      await this.gravaTransportadora()
    },
    mudaStatusServico() {
      this.gravaTransportadora()
    },
    excluiNivelContrato(obj, index) {
      obj.niveisContrato.splice(index, 1)
      this.gravaTransportadora()
    },
    ativaNivelContrato() {
      let transp = this.transportadora

      if (transp.usaCodigoNivelContratoNumerico) {
        transp.usaNivelContrato = true
      }
    },
    inativaCodigoNivelContrato() {
      let transp = this.transportadora

      if (!transp.usaNivelContrato) {
        transp.usaCodigoNivelContratoNumerico = false
      }
    },
  },
  // #endregion
}
</script>