<template>
  <b-row>
    <b-col class="d-none d-lg-block nuvem-direita"></b-col>
    <b-col class="text-center h5">
      <div class="d-none d-md-block mt-5 pt-4"></div>
      <div class="d-none d-md-block mt-5"></div>
      <div class="d-none d-block mt-5"></div>

      <img class="animacao-logo" src="@/assets/img/logo.png">
			
      <div class="mt-5 h2">Site em manutenção...</div>
			<div class="mt-5">
				<a href="/" class="text-info">Tentar novamente</a>
			</div>
    </b-col>
    <b-col class="d-none d-lg-block nuvem-esquerda"></b-col>
  </b-row>
</template>

<script>
export default {
	// #region configs
  name: "manutencao",
  path: "/manutencao",
	// #endregion
}
</script>
