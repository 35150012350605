<template>
  <div>
		<div class="titulo">
			<h1>Convites</h1>
			<h4>Gestão de convites feitos por Ponto(s) de Atendimento</h4>
		</div>

		<!-- #region listagem/edicao -->
    <div class="h5">
      <b-table 
				class="text-nowrap h5" 
				:id="`tabelaConvites`" 
				:items="tabelaConvites"
        :fields="cabecalhoTabelaConvites" 
				:per-page="totalPagina" 
				:current-page="paginaAtual" 
				small 
				hover 
				responsive>

				<!-- #region celulas customizadas/formatadas -->
        <template #cell(dataCriacao)="obj">
					{{ $vsFormataData(obj.value) }}
				</template>

				<template #cell(idConvidante)="obj">
					{{ nomeConvidante(obj.item) }}
				</template>

				<template #cell(situacao)="obj">
					<span v-if="conviteVencido(obj.item)">Expirado</span>
					<span v-else>{{ descricaoStatus(obj.value) }}</span>
				</template>

				<template #cell(dataExpiracaoToken)="obj">
					{{ $vsFormataData(obj.value, true) }}
				</template>

				<template #cell(opcoes)="obj">
					<div v-if="liberaEventoConvite(obj.item)" class="pr-5">
						<b-button class="btn-verde" style="width: 100px; height: 35px; padding-bottom: 30px;" @click.prevent="defineStatusConvite(obj.item, VS_STATUS_USUARIO_CONVITE_ACEITO)">Aceitar</b-button>
						<b-button class="btn-vermelho" style="width: 100px; height: 35px; padding-bottom: 30px;" @click.prevent="mostraMdalConviteRecusado(obj.item)">Recusar</b-button>
					</div>
				</template>
				<!-- #endregion -->
      </b-table>

			<!-- #region paginacao -->
      <div v-if="tabelaConvites.length" class="mt-4">
        <span style="padding-left: 5px;padding-right: 20px;">
          <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
        </span>
        <span>Total de Regitros: {{ $vsFormataNumero(tabelaConvites.length) }}</span>
        <b-pagination 
					class="mt-3 d-flex justify-content-center" 
					:total-rows="tabelaConvites.length" 
					:per-page="totalPagina" 
					v-model="paginaAtual" 
					aria-controls="tabelaConvites" />
      </div>
			<!-- #endregion -->
		</div>
		<!-- #endregion -->
  
		<!-- #region modais -->
		<!-- #region recusa de convite -->
		<b-modal id="modalConviteRecusado" title="Recusar convite" size="lg">
			<div class="h5">
				<!-- #region dados basicos -->
				<div v-if="conviteRecusado" class="mb-5">
					<div class="mb-5">
						Convidante: {{ nomeConvidante(conviteRecusado) }}
						<span class="float-right">Data: {{ $vsFormataData(conviteRecusado.dataCriacao) }}</span>
					</div>
					<div>
						<div class="mb-3">Deseja mesmo recusar este convite?</div>
						<div class="mb-3 text-danger font-weight-bold">Obs: Essa ação não poderá ser desfeita</div>
					</div>
				</div>
				<!-- #endregion -->
			</div>
			<template #modal-footer="{ hide }">
				<div class="w-100">
					<b-button class="float-right ml-2" variant="info"	@click.prevent="confirmaConviteRecusado()">Confirmar</b-button>
					<b-button class="float-right" variant="secondary" @click.prevent="hide()">Cancelar</b-button>
				</div>
			</template>
		</b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
	</div>
</template>

<script>
export default {
  // #region configs
  name: "convites",
  path: "/convites",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
      filiais: [],
			conviteRecusado: null,
			cabecalhoTabelaConvites: [
				{ key: "dataCriacao", label: "Data", sortable: true,  },
				{ key: "idConvidante", label: "Ponto de atendimento", sortable: true, },
				{ key: "situacao", label: "Situação", sortable: true, },
				{ key: "dataExpiracaoToken", label: "Válido até", sortable: true, },
				{ key: "opcoes", label: "Opções", sortable: false,  },
			],
			tabelaConvites: [],
			paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([
			this.VS_PERFIL_CLIENTE,
		])
  },
	mounted() {
		this.bootstrap()
	},
  // #endregion

  // #region metodos
  methods: {
		async bootstrap() {
			await this.listaFiliais()
			await this.listaConvites()
		},
		async listaConvites() {
			let sessao = this.$root.sessao
			let query = `cpfCnpjConvidado=${sessao.cpfCnpj}&emailConvidado=${sessao.email}`
			let resp = await this.$vsApiRest("GET", `/usuarios/convites/?${query}`)

      if (resp.status === 200) {
        this.tabelaConvites = resp.data
      }
		},
		async listaFiliais() {
			let resp = await this.$vsApiRest("GET", `/usuarios/filiais/`)

			if (resp.status === 200) {
        this.filiais = resp.data
      }
		},
		nomeConvidante(obj) {
			return this.filiais.find(el => el.id === obj.idConvidante).nome
		},
		descricaoStatus(status) {
			switch (status) {
				case this.VS_STATUS_USUARIO_CONVITE_AGUARDANDO:
					return "Aguardando"
				case this.VS_STATUS_USUARIO_CONVITE_ACEITO:
					return "Aceito"
				case this.VS_STATUS_USUARIO_CONVITE_RECUSADO:
					return "Recusado"
				default:
					return ""
			}
		},
		mostraMdalConviteRecusado(obj) {
			this.conviteRecusado = obj
			this.$bvModal.show("modalConviteRecusado")
		},
		confirmaConviteRecusado() {
			this.$bvModal.hide("modalConviteRecusado")
			this.defineStatusConvite(this.conviteRecusado, this.VS_STATUS_USUARIO_CONVITE_RECUSADO)
		},
		async defineStatusConvite(obj, status) {
			let sessao = this.$root.sessao
      let req = {
				id: obj.id,
				idConvidado: sessao.id,
				situacao: status
			}
      let resp = await this.$vsApiRest("PATCH", `/usuarios/convites`, req)
      
			if (resp.status === 200) {
        let filial = this.filiais.find(el => el.id == obj.idConvidante)
				let statusDescricao = this.descricaoStatus(status).toLocaleLowerCase()

				this.$vsNotificacao(`Convite de ${filial.nome} ${statusDescricao} com sucesso`, "success")				
				this.$vsDefineVinculoUsuario(sessao, obj.idConvidante, (status === this.VS_STATUS_USUARIO_CONVITE_RECUSADO))
				await this.listaConvites()
      }
    },
		liberaEventoConvite(obj) {
			return (obj.situacao === this.VS_STATUS_USUARIO_CONVITE_AGUARDANDO) && (!this.conviteVencido(obj))
		},
		conviteVencido(obj) {
			return (obj.situacao === this.VS_STATUS_USUARIO_CONVITE_AGUARDANDO) && new Date(obj.dataExpiracaoToken) < new Date((new Date()).toISOString())
		}
  }
  // #endregion
}
</script>
