<template>
  <div>
		<div class="titulo">
			<h1>Ferramentas</h1>
			<h4>Downloads</h4>
		</div>

		<!-- #region cadastro (apenas admin) -->
		<form v-if="$root.sessao.perfil === VS_PERFIL_SUPERADMIN" class="mb-4" @submit.prevent="gravar()">
      <b-form-row>
				<b-col md="12">
          <b-form-group class="form-input-cinza" label="URL" label-size="" label-cols-md="12">
            <b-form-input type="text" v-model="form.url" />
          </b-form-group>
        </b-col>

				<b-col md="8">
          <b-form-group class="form-input-cinza" label="Descrição" label-size="" label-cols-md="12">
            <b-form-input type="text" v-model="form.descricao" />
          </b-form-group>
        </b-col>

				<b-col md="4">
          <b-form-group class="form-input-cinza" label="Ícone" label-size="" label-cols-md="12">
            <b-form-input type="text" v-model="form.icone" />
          </b-form-group>
					<div class="h6 text-secondary">
						Consulte a lista de ícones suportáveis
						<a class="text-info" href="https://bootstrap-vue.org/docs/icons#icons-1" target="_blank">aqui</a>
					</div>
        </b-col>
      </b-form-row>
      
      <b-form-row>
        <b-col md="2">
          <button type="submit" class="btn-azul">Gravar</button>
        </b-col>

				<b-col md="2">
          <button type="button" class="btn-cinza" @click="limpar()">Cancelar</button>
        </b-col>
      </b-form-row>
    </form>
    <!-- #endregion -->

		<!-- #region listagem/exclusao -->
		<div class="row h5 mb-4 px-3" v-for="link in lista" :key="link.id">
			<!-- #region link publico -->
			<div class="col text-nowrap">
				<a class="text-info" @click.prevent="baixar(link)" target="_blank">
					<b-icon :icon="link.icone" scale="1.2" />&nbsp;
					<span>{{ link.descricao }}</span>
				</a>
			</div>
			<!-- #endregion -->

			<!-- #region opcoes (apenas admin) -->
			<div v-if="$root.sessao.perfil === VS_PERFIL_SUPERADMIN" class="col">
				<a class="text-warning" @click.prevent="editar(link)">
					<b-icon icon="pencil" scale="1.2" />&nbsp;
					<span>Editar</span>
				</a>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
				<a class="text-danger" @click.prevent="apagar(link)">
					<b-icon icon="trash" scale="1.2" />&nbsp;
					<span>Excluir</span>
				</a>
			</div>
			<!-- #endregion -->
		</div>
		<!-- #endregion -->
  </div>
</template>

<script>
export default {
	// #region configs
  name: "ferramentas",
  path: "/ferramentas",
	requireAuth: true,
	// #endregion

	// #region dados
	data() {
		return {
			lista: [],
			form: {
				id: null,
				descricao: "",
				url: "",
				icone: "",
			},
		}
	},
	// #endregion

	// #region triggers
	mounted() {
		this.listar()
	},
	// #endregion

	// #region metodos
	methods: {
		async listar() {
			this.lista = []

			let resp = await this.$vsApiRest("GET", "/ferramentas")
        
			if (resp.status === 200) {
        this.lista = resp.data
      }
		},
		editar(obj) {
			let form = this.form
			form.id = obj.id
			form.descricao = obj.descricao
			form.url = obj. url
			form.icone = obj.icone
		},
		async gravar() {
			if (!this.validar()) {
				return
			}

			let form = this.form
			form.url = form.url.trim()
			form.descricao = form.descricao.trim()
			form.icone = form.icone.trim()

		 	let resp = await this.$vsApiRest("POST", "/ferramentas", form)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Link gravado com sucesso", "success")
				this.limpar()
        this.listar()
      }
		},
		async apagar(obj) {
			let resp = await this.$vsApiRest("DELETE", `/ferramentas/${obj.id}`)
        
			if (resp.status === 200) {
        this.$vsNotificacao("Link apagado com sucesso", "success")
				this.listar()
      }
		},
		limpar() {
			let form = this.form

			form.id = null
			form.descricao = ""
			form.url = ""
			form.icone = ""
		},
		validar() {
			let form = this.form
			let descricao = form.url.trim()
			let url = form.descricao.trim()
			let ok = true

			if (!url.length) {
				this.$vsNotificacao("Informe a URL", "warning")
				ok = false
			}
			if (!descricao.length) {
				this.$vsNotificacao("Informe a descrição", "warning")
				ok = false
			}
			return ok
		},
	 	async	baixar(obj) {
			let sessao = this.$root.sessao
			let query = `idDownload=${obj.id}`

			if (sessao.perfil === this.VS_PERFIL_CONTRATANTE) {
				query += `&idContratante=${sessao.id}`
			} else if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query += `&idContratante=${sessao.contratante.id}&idFilial=${sessao.id}`
			}	else {
				query += `&idContratante=${sessao.contratante.id}&idFilial=${sessao.filial.id}`
			}

			let resp = await this.$vsApiRest("GET", `/ferramentas/download/?${query}`, {}, {}, "blob")
      
			if (resp.status === 200) {
				this.$vsDownload(resp.data, obj.url.split("/").reverse()[0])
      }
		},
	},
	// #endregion
}
</script>
