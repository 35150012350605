<template>
  <form>
		<b-form-row>
			<!-- #region campos de busca -->
			<b-col md="2">
				<b-form-group class="form-input-cinza" label="CPF/CNPJ" label-cols="12">
					<b-form-input type="text" v-model="obj.cpfCnpj" @keyup="mascaraCpfCnpj()" autocomplete="off" placeholder="Opcional" @blur="buscaPessoaCPF($event)" :data-vs-nome="$vsDataVsNome('txtCpfCnpj')"/>
				</b-form-group>
			</b-col>

			<b-col md="6">
        <b-form-group class="form-input-cinza" label="Nome / Razão Social" label-cols="12">
					<model-select class="form-input-cinza"
						:options="listaCustomizada()"
						@input="buscaPessoaNome($event)"
						@searchchange="labelNomeSearchChange"
						@blur="labelNomeBlur"
						ref="comboNome"
						:data-vs-nome="$vsDataVsNome('cmbNome')"/>
        </b-form-group>
      </b-col>
			<!-- #endregion -->
		</b-form-row>
		
		<b-form-row>
			<!-- #region dados basicos -->
			<b-col md="3">
				<b-form-group class="form-input-cinza" label="Apelido / Nome Fantasia" label-cols="12">
					<b-form-input ref="nomeFantasia" type="text" v-model="obj.nomeFantasia" autocomplete="off" :data-vs-nome="$vsDataVsNome('txtNomeFantasia')"/>
				</b-form-group>
			</b-col>

			<b-col md="3" v-if="isDestinatario">
				<b-form-group class="form-input-cinza" label="Aos cuidados" label-cols="12">
					<b-form-input type="text" v-model="obj.aosCuidados" autocomplete="off" placeholder="Opcional" :data-vs-nome="$vsDataVsNome('txtAosCuidados')"/>
				</b-form-group>
			</b-col>

			<b-col md="3">
				<b-form-group class="form-input-cinza" label="Email" label-cols="12">
					<b-form-input type="text" v-model="obj.email" autocomplete="off" placeholder="Opcional" :data-vs-nome="$vsDataVsNome('txtEmail')"/>
				</b-form-group>
			</b-col>

			<b-col md="2">
				<b-form-group class="form-input-cinza" label="Telefone" label-cols="12">
					<b-form-input type="text" v-model="obj.telefone" @keyup="mascaraTelefone()" autocomplete="off" placeholder="Opcional" :data-vs-nome="$vsDataVsNome('txtTelefone')"/>
				</b-form-group>
			</b-col>
			<!-- #endregion -->

			<!-- #region endereco -->
			<b-col md="2">
				<b-form-group class="form-input-cinza" label="Cep" label-cols="12">
					<b-form-input type="text" v-model="obj.endereco.cep" @keyup="mascaraCep($event)" maxlength="9" autocomplete="off" @blur="buscaCep()" :data-vs-nome="$vsDataVsNome('txtEnderecoCep')"/>
				</b-form-group>
			</b-col>

			<b-col md="5">
				<b-form-group class="form-input-cinza" label="Endereço" label-cols="12">
					<b-form-input type="text" v-model="obj.endereco.logradouro" autocomplete="off" :data-vs-nome="$vsDataVsNome('txtEnderecoLogradouro')"/>
				</b-form-group>
			</b-col>

			<b-col md="2">
				<b-form-group class="form-input-cinza" label="Número" label-cols="12">
					<b-form-input type="text" v-model="obj.endereco.numero" autocomplete="off" :data-vs-nome="$vsDataVsNome('txtEnderecoNumero')"/>
				</b-form-group>
			</b-col>

			<b-col md="3">
				<b-form-group class="form-input-cinza" label="Complemento" label-cols-md="12">
					<b-form-input type="text" v-model="obj.endereco.complemento" autocomplete="off" :data-vs-nome="$vsDataVsNome('txtEnderecoComplemento')"/>
				</b-form-group>
			</b-col>

			<b-col md="3">
				<b-form-group class="form-input-cinza" label="Bairro" label-cols="12">
					<b-form-input type="text" v-model="obj.endereco.bairro" autocomplete="off" :data-vs-nome="$vsDataVsNome('txtEnderecoBairro')"/>
				</b-form-group>
			</b-col>

			<b-col md="2">
				<b-form-group class="form-input-cinza" label="Cidade" label-cols="12">
					<b-form-input type="text" v-model="obj.endereco.cidade" autocomplete="off" :disabled="bloqueiaEndereco" :data-vs-nome="$vsDataVsNome('txtEnderecoCidade')"/>
				</b-form-group>
			</b-col>

			<b-col md="2">
				<b-form-group class="form-input-cinza" label="UF" label-cols="12" content-cols-md="4">
					<b-form-input type="text" maxlength="2" v-model="obj.endereco.uf" autocomplete="off" :disabled="bloqueiaEndereco" :data-vs-nome="$vsDataVsNome('txtEnderecoUF')"/>
				</b-form-group>
			</b-col>
			<!-- #endregion -->
		</b-form-row>
	</form>
</template>

<script>
import { ModelSelect } from "vue-search-select"

export default {
  // #region configs
  name: "vs-form-pre-postagem-pessoa",
  props: {
    obj: { type: Object, required: true },
    buscarRemetente: { type: Boolean, default: false },
    buscarDestinatario: { type: Boolean, default: false },
    isDestinatario: { type: Boolean, default: false },
		idBoxSanfona: { type: String, default: "" },
		pessoas: { type: Array, default: () => [] }
  },
	components: { ModelSelect },
  // #endregion

  // #region dados
  data() {
    return {
			bloqueiaEndereco: true,
			cepInicial: "",
		}
  },
  // #endregion

	// #region triggers
	mounted() {
		this.initLabelNome()
	},
	beforeUpdate() {
		this.initLabelNome(true)
	},
	// #endregion

  // #region metodos
  methods: {
		initLabelNome() {
			let parent = this.$refs.comboNome.$el
			let label = parent.children[2]
			
			label.innerText = this.obj.nome
		},
		limpaCadastro(buscaNome = false) {
			let obj = this.obj
			obj.id = null

			if (buscaNome) {
				obj.cpfCnpj = ""
			} else {
				obj.nome = ""
			}
      obj.email = ""
			obj.aosCuidados = ""
			obj.nomeFantasia = ""
      obj.telefone = ""
      obj.contratos = []
      obj.vinculos = []
      obj.contratoSelecionado = null

			this.isEdicao = false
			this.limpaEndereco(true)
		},
    buscaPessoaCPF(evt) {
			let param = this.$vsLimpaFormatacao(evt.target.value)

			if (!param.length || !this.validaCpfCnpj(param)) {
				return
			}
			let pessoa = this.pessoas.find(el => el.cpfCnpj === param)
			let obj = this.obj

			if (pessoa) {
				this.definePessoa(pessoa)
			} else if (!obj.id) {
				this.limpaCadastro()
			}
    },
		buscaPessoaNome(param) {
			let pessoa = this.pessoas.find(el => el.id === param)

			if (pessoa) {
				this.definePessoa(pessoa)
			}
    },
    definePessoa(pessoa) {
      this.$vsLoadingStart(true, 100) // simula carregamento

      let obj = this.obj
			obj.id = pessoa.id
      obj.nome = pessoa.nome
			obj.nomeFantasia = pessoa.nomeFantasia
			obj.aosCuidados = pessoa.aosCuidados
      obj.cpfCnpj = this.$vsFormataCpfCnpj(pessoa.cpfCnpj)
      obj.email = pessoa.email
      obj.telefone = this.$vsFormataTelefone(pessoa.telefone)

			obj.endereco.cep = this.$vsFormataCep(pessoa.endereco.cep)
			obj.endereco.numero = pessoa.endereco.numero
      obj.endereco.complemento = pessoa.endereco.complemento
			obj.endereco.logradouro = pessoa.endereco.logradouro
      obj.endereco.bairro = pessoa.endereco.bairro
      obj.endereco.cidade = pessoa.endereco.cidade
      obj.endereco.uf = pessoa.endereco.uf

      this.cepInicial = pessoa.endereco.cep
			this.bloqueiaEndereco = true

			let parent = this.$refs.comboNome.$el
			let input = parent.children[1]
			
			input.value = this.obj.nome
			this.$refs.nomeFantasia.$el.focus()
    },
    async buscaCep() {
      let obj = this.obj
      let cep = this.$vsLimpaFormatacao(obj.endereco.cep)

      if (this.cepInicial == cep || !cep.length || !this.validaCep(cep)) {
        return
      }

      let resp = await this.$vsApiRest("GET", `/tarifacao/?cep=${cep}`)

      obj.endereco.numero = ""
      obj.endereco.complemento = ""

      if (resp.status === 200) {
        let end = resp.data

				this.bloqueiaEndereco = !end.vazio
				obj.endereco.vazio = end.vazio

				if (end.vazio) {
					this.$vsNotificacao("Cep inválido", "warning")
					this.limpaEndereco()
					return
				}
        obj.endereco.logradouro = end.logradouro
        obj.endereco.bairro = end.bairro
        obj.endereco.cidade = end.cidade
        obj.endereco.uf = end.uf
				this.cepInicial = cep
      }
    },
    mascaraCpfCnpj() {
      this.obj.cpfCnpj = this.$vsFormataCpfCnpj(this.obj.cpfCnpj)
    },
    mascaraTelefone() {
      this.obj.telefone = this.$vsFormataTelefone(this.obj.telefone)
    },
    mascaraCep(evt) {
			if (["backspace", "delete"].includes(evt.key.toLowerCase())) {
				this.limpaEndereco()
			}
      this.obj.endereco.cep = this.$vsFormataCep(this.obj.endereco.cep)
    },
		validaCpfCnpj(param) {
			let ok = true
			let cpfCnpj = this.$vsLimpaFormatacao(param)

			if (!this.$vsCpfCnpjValido(cpfCnpj)) {
        ok = false
        this.$vsNotificacao("CPF/CNPJ inválido", "warning")
      }
			return ok
		},
		validaCep(cep) {
			let ok = true
			let len = this.$vsLimpaFormatacao(cep).length

      if (len && len < 8) {
        ok = false
        this.$vsNotificacao("Cep inválido", "warning")
      }
			return ok
		},
		limpaEndereco(limpaCep = false) {
			let obj = this.obj

			obj.endereco.logradouro = ""
			obj.endereco.numero = ""
			obj.endereco.bairro = ""
			obj.endereco.cidade = ""
			obj.endereco.uf = ""
			obj.endereco.complemento = ""

			if (limpaCep) {
				obj.endereco.cep = ""
			}

			this.cepInicial = ""
			this.bloqueiaEndereco = false
		},
		listaCustomizada() {
			let lista = this.pessoas

			lista.forEach(el => {
				el.value = el.id
				el.text = `${el.nome} - ${this.$vsFormataCpfCnpj(el.cpfCnpj)} - ${this.$vsFormataCep(el.endereco.cep)} - ${el.endereco.logradouro}`
			})
			return lista
		},
		labelNomeBlur() {
			let parent = this.$refs.comboNome.$el
			let input = parent.children[1]

			if (input.value.length) {
				this.obj.nome = input.value
			}
		},
		labelNomeSearchChange(evt) {
			let parent = this.$refs.comboNome.$el
			let input = parent.children[1]
			let label = parent.children[2]
			
			this.obj.nome = input.value
			label.innerText = ""

			if (!evt.length) {
				label.innerText = this.obj.nome
				input.value = this.obj.nome
			}
		},
  },
  // #endregion
}
</script>
