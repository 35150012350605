<template>
  <div>
    <!-- #region titulo com acao para novo romaneio -->
    <h1 class="titulo">
      Romaneio
      <b-dropdown ref="novoRomaneio" variant="success" text="Novo romaneio" no-caret>
        <b-dropdown-form>
          <b-form-group label="Transportadora">
            <b-form-select :options="comboTransportadoras.filter(el => el.isAtivo)" v-model="idTransportadora" />
          </b-form-group>
          <b-form-group label="Descrição">
            <b-form-input v-model="descricaoRomaneio" />
          </b-form-group>
					<b-form-group label="Nome da Unidade Postadora">
            <b-form-input v-model="nomeUnidadePostadora" />
          </b-form-group>
					<b-form-group label="Número do Contrato">
            <b-form-input v-model="numeroContrato" />
          </b-form-group>
					<b-form-group label="Número do Cartão">
            <b-form-input v-model="numeroCartao" />
          </b-form-group>
          <b-button type="button" variant="primary" @click="novoRomaneio()">Confirmar</b-button>
        </b-dropdown-form>

        <!-- forca expansao lateral dos inputs acima -->
        <b-dropdown-text class="text-light text-nowrap">
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        </b-dropdown-text>
      </b-dropdown>
    </h1>
    <!-- #endregion -->

    <!-- #region campo de busca por codigo de barras ou digitacao (trigger com enter) -->
    <b-container fluid>
      <b-row>
        <b-col md="4" />
        <b-col>
          <b-form-group class="form-input-cinza" label="Leia o código de barras do objeto" label-align-md="center" label-size="lg" label-cols-md="12" content-cols-md="" label-class="font-weight-bold">
            <b-form-input ref="campoBusca" type="text" v-model="etiqueta" size="lg" @keydown.enter="adicionaObjeto()" autocomplete="off" placeholder="Código#ID" />
          </b-form-group>
        </b-col>
        <b-col md="4" />
      </b-row>
    </b-container>
    <!-- #endregion -->

    <!-- #region mensagens com destaque visual -->
    <div v-if="mensagem.length" :class="`p-4 text-center text-light font-weight-bold h1 ${corMensagem}`">
      {{ mensagem }}
    </div>
    <!-- #endregion -->

    <!-- #region linha do tempo dos romaneios -->
    <b-tabs class="mt-4 h5">
      <!-- #region etapa1: abertos -->
      <b-tab title="Abertos" class="bg-white px-4 py-5" @click="listaRomeiosAbertos()">
        <!-- informativo -->
        <div v-if="!totalAbertos">Nenhum romaneio encontrado</div>
				<div v-else>
					<!-- #region listagem -->
					<div class="abre-fecha accordion mb-5" role="tablist" v-for="(rel, i) in relatorio" :key="rel.id">
						<div class="mb-3">
							<!-- #region cabecalho -->
							<a class="float-right" v-b-toggle :href="`#boxRomaneio${rel.id}`" @click.prevent="lazyLoadSanfona(rel, true)">
								<b-icon class="when-opened" icon="chevron-up" scale="1.2" />
								<b-icon class="when-closed" icon="chevron-down" scale="1.2" />
							</a>

							<div class="mb-4 h4" v-b-toggle="`boxRomaneio${rel.id}`" @click.prevent="lazyLoadSanfona(rel, true)" v-b-tooltip.hover.top="'Mostrar/Ocultar romaneio'">
								<div>Romaneio {{ rel.codigo }} - {{ rel.descricao }} - {{ rel.transportadoraNome }}</div>
								<div>Nome da Unidade Postadora: {{ rel.nomeUnidadePostadora }}</div>
								<div>Número do Contrato: {{ rel.numeroContrato }} - Número do Cartão: {{ rel.numeroCartao }}</div>
								<div>Abertura: {{ $vsFormataData(rel.dataCriacao, true) }} por {{ rel.operadorAberturaNome }}</div>
								<div>Objetos: {{ $vsFormataNumero(rel.qtdItens) }}</div>
							</div>

							<div class="mb-4">
								<b-form-radio size="lg" name="romaneioAtivo" v-model="idRomaneio" :value="rel.id" @change="lazyLoadSanfona(rel, true)">
									<span class="h4">Selecionar para leitura</span>
								</b-form-radio>
							</div>
							<!-- #endregion -->

							<!-- #region corpo -->
							<b-collapse v-if="idSanfonaAberta === rel.id" :id="`boxRomaneio${rel.id}`" accordion="sanfonaFluxo" role="tabpanel" :visible="(rel.id === idRomaneio)">
								<!-- #region itens do romaneio (volumes/produtos/servicos) -->
								<div v-if="relatorioItens.length">
									<b-table 
									  :id="`tabelaRomaneiosAbertos${rel.id}`" 
										class="text-nowrap h5 p-3 cor-fundo-padrao"
										:items="relatorioItens" 
										:fields="camposRomaneios" 
										:per-page="totalPagina"
										:current-page="paginaAtual" 
										small 
										responsive 
										borderless>
										<!--===================================================================================
											mapa de colunas (atua em campos sem tratamento) 
											se precisar debugar ou visualizar os dados para ajustes basta descomentar...

											obj.index = indice da linha
											obj.field = nome do campo/coluna/chave json
											obj.item  = objeto json bruto
											obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
										====================================================================================-->
										<!--<template #cell()="obj">
												{{ obj.field }}
												{{ obj.item }}
											</template>-->

										<!--===================================================================================
											celulas que precisam de tratamento, 
											sobrescrevem mapa de colunas pela identificacao da chave
											e nao precisam estar em ordem/sequencia
										====================================================================================-->
										<template #cell(dataLeitura)="obj">
											{{ $vsFormataData(obj.value, true) }}
										</template>

										<template #cell(dataAtendimento)="obj">
											{{ $vsFormataData(obj.value, true) }}
										</template>

										<template #cell(codigoPostagem)="obj">
											<div class="d-flex justify-content-between">
												<span>{{ obj.value }}</span>
												<span class="pl-3">
													<a class="text-danger" @click.prevent="mostraModalCancelaRomaneio(rel, obj.item)">
														<b-icon icon="trash" />
													</a>
												</span>
											</div>
										</template>
									</b-table>

									<!-- #region paginacao -->
									<div class="h5">
										<span style="padding-left: 5px;padding-right: 20px;">
											<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
										</span>
										<span> Total de Regitros: {{ $vsFormataNumero(relatorioItens.length) }}</span>
										<b-pagination class="mt-3 d-flex justify-content-center" 
											:total-rows="relatorioItens.length"
											:per-page="totalPagina" 
											:aria-controls="`tabelaRomaneiosAbertos${rel.id}`"
											v-model="paginaAtual" />
									</div>
									<!-- #endregion -->
								</div>
								<!-- #endregion -->

								<!-- #region opcoes -->
								<b-container fluid class="mt-4">
									<b-row>
										<b-col md="4" class="mb-4" v-if="relatorioItens.length">
											<a class="cor-laranja font-weight-bold" @click.prevent="fechaRomaneio(rel)">
												<b-icon icon="truck" scale="1.2" />&nbsp;&nbsp;Fechar romaneio {{ rel.codigo }}
											</a>
										</b-col>
										<b-col md="4" class="mb-4" v-if="relatorioItens.length">
											<a class="cor-laranja font-weight-bold" @click.prevent="fechaRomaneio(rel, true)">
												<b-icon icon="truck" scale="1.2" />&nbsp;&nbsp;Fechar e imprimir romaneio {{ rel.codigo }}
											</a>
										</b-col>
										<b-col md="4">
											<a class="text-danger font-weight-bold" @click.prevent="mostraModalCancelaRomaneio(rel)">
												<b-icon icon="trash" /> Cancelar romaneio {{ rel.codigo }}
											</a>
										</b-col>
									</b-row>
								</b-container>
								<!-- #endregion -->
							</b-collapse>
							<!-- #endregion -->
						</div>
					</div>
					<!-- #endregion -->
				</div>
      </b-tab>
      <!-- #endregion -->

      <!-- #region etapa2: fechados -->
      <b-tab title="Fechados" class="bg-white px-4 py-5" @click="listaRomeiosFechados()">
        <!-- informativo -->
        <div v-if="!totalFechados">Nenhum romaneio encontrado</div>
				<div v-else>
        	<!-- #region listagem -->
					<div class="abre-fecha accordion" role="tablist" v-for="(rel, i) in relatorio" :key="rel.id">
						<div class="mb-3">
							<!-- #region cabecalho -->
							<div class="text-right mb-4">
								<a @click.prevent="imprimeRomaneio(rel)" v-b-tooltip.hover title="Imprimir romaneio">
									<b-icon icon="file-pdf" scale="1.8" variant="danger" />
								</a>
							</div>

							<a class="float-right" v-b-toggle :href="`#boxRomaneio${rel.id}`" @click.prevent="lazyLoadSanfona(rel)">
								<b-icon class="when-opened" icon="chevron-up" scale="1.2" />
								<b-icon class="when-closed" icon="chevron-down" scale="1.2" />
							</a>

							<div class="mb-4 h4" v-b-toggle="`boxRomaneio${rel.id}`" @click.prevent="lazyLoadSanfona(rel)" v-b-tooltip.hover.top="'Mostrar/Ocultar romaneio'">
								<div>Romaneio {{ rel.codigo }} - {{ rel.descricao }} - {{ rel.transportadoraNome }}</div>
								<div>Nome da Unidade Postadora: {{ rel.nomeUnidadePostadora }}</div>
								<div>Número do Contrato: {{ rel.numeroContrato }} - Número do Cartão: {{ rel.numeroCartao }}</div>
								<div>Abertura: {{ $vsFormataData(rel.dataCriacao, true) }} por {{ rel.operadorAberturaNome }}</div>
								<div>Fechamento: {{ $vsFormataData(rel.dataFechamento, true) }} por {{ rel.operadorFechamentoNome }}</div>
								<div>Objetos: {{ $vsFormataNumero(rel.qtdItens) }}</div>
							</div>
							<!-- #endregion -->

							<!-- #region corpo -->
							<b-collapse v-if="idSanfonaAberta === rel.id" :id="`boxRomaneio${rel.id}`" accordion="sanfonaFluxo" role="tabpanel">
								<!-- #region itens do romaneio (volumes/produtos/servicos) -->
								<div v-if="relatorioItens.length">
									<b-table 
									  :id="`tabelaRomaneiosFechados${rel.id}`" 
										class="text-nowrap h5 p-3 cor-fundo-padrao"
										:items="relatorioItens" 
										:fields="camposRomaneios" 
										:per-page="totalPagina"
										:current-page="paginaAtual" 
										small 
										responsive 
										borderless>
										<!--===================================================================================
											mapa de colunas (atua em campos sem tratamento) 
											se precisar debugar ou visualizar os dados para ajustes basta descomentar...

											obj.index = indice da linha
											obj.field = nome do campo/coluna/chave json
											obj.item  = objeto json bruto
											obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
										====================================================================================-->
										<!--<template #cell()="obj">
											{{ obj.field }}
											{{ obj.item }}
										</template>-->

										<!--===================================================================================
											celulas que precisam de tratamento, 
											sobrescrevem mapa de colunas pela identificacao da chave
											e nao precisam estar em ordem/sequencia
										====================================================================================-->
										<template #cell(dataLeitura)="obj">
											{{ $vsFormataData(obj.value, true) }}
										</template>

										<template #cell(dataAtendimento)="obj">
											{{ $vsFormataData(obj.value, true) }}
										</template>

										<template #cell(codigoPostagem)="obj">
											<div class="d-flex justify-content-between">
												<span>{{ obj.value }}</span>
												<span class="pl-3" v-if="$root.sessao.perfil === VS_PERFIL_GERENTE">
													<a class="text-danger" @click.prevent="mostraModalCancelaRomaneio(rel, obj.item)">
														<b-icon icon="trash" />
													</a>
												</span>
											</div>
										</template>
									</b-table>

									<!-- #region paginacao -->
									<div class="h5">
										<span style="padding-left: 5px;padding-right: 20px;">
											<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
										</span>
										<span> Total de Regitros: {{ $vsFormataNumero(relatorioItens.length) }}</span>
										<b-pagination class="mt-3 d-flex justify-content-center" 
										  :total-rows="relatorioItens.length"
											:per-page="totalPagina" 
											:aria-controls="`tabelaRomaneiosFechados${rel.id}`"
											v-model="paginaAtual" />
									</div>
									<!-- #endregion -->
								</div>
								<!-- #endregion -->

								<!-- #region opcoes -->
								<b-container fluid class="mt-4" v-if="relatorioItens.length && $root.sessao.perfil === VS_PERFIL_GERENTE">
									<b-row>
										<b-col class="mb-4">
											<a class="text-danger font-weight-bold" @click.prevent="mostraModalCancelaRomaneio(rel)">
												<b-icon icon="trash" /> Cancelar romaneio {{ rel.codigo }}
											</a>
										</b-col>
									</b-row>
								</b-container>
								<!-- #endregion -->
							</b-collapse>
							<!-- #endregion -->
						</div>
					</div>
					<!-- #endregion -->
				</div>
			</b-tab>
      <!-- #endregion -->

      <!-- #region etapa3: cancelados -->
      <b-tab title="Cancelados" class="bg-white px-4 py-5" @click="listaRomeiosCancelados()">
        <!-- informativo -->
        <div v-if="!totalCancelados">Nenhum romaneio encontrado</div>
        <div v-else-if="relatorio.length" class="mb-3">
          <!-- #region listagem: itens do romaneio (volumes/produtos/servicos) -->
          <b-table 
					  id="tabelaRomaneiosCancelados" 
						class="text-nowrap h5 p-3 cor-fundo-padrao"
            :items="relatorio" 
						:fields="camposRomaneiosCancelados"
            :per-page="totalPagina" 
						:current-page="paginaAtual" 
						small 
						responsive
            borderless>
            <!--===================================================================================
              mapa de colunas (atua em campos sem tratamento) 
              se precisar debugar ou visualizar os dados para ajustes basta descomentar...

              obj.index = indice da linha
              obj.field = nome do campo/coluna/chave json
              obj.item  = objeto json bruto
              obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
              ====================================================================================-->
            <!--<template #cell()="obj">
                {{ obj.field }}
                {{ obj.item }}
              </template>-->

            <!--===================================================================================
                celulas que precisam de tratamento, 
                sobrescrevem mapa de colunas pela identificacao da chave
                e nao precisam estar em ordem/sequencia
              ====================================================================================-->
						<template #cell(dataLeitura)="obj">
              {{ $vsFormataData(obj.value, true) }}
            </template>

            <template #cell(dataAtendimento)="obj">
              {{ $vsFormataData(obj.value, true) }}
            </template>

            <template #cell(dataCancelamento)="obj">
              {{ $vsFormataData(obj.value, true) }}
            </template>

            <template #cell(codigoPostagem)="obj">
              {{ obj.value }}
            </template>
          </b-table>
          <div class="h5">
            <span style="padding-left: 5px;padding-right: 20px;">
              <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
            </span>
            <span> Total de Regitros: {{ $vsFormataNumero(relatorio.length) }}</span>
            <b-pagination class="mt-3 d-flex justify-content-center" 
						  :total-rows="relatorio.length"
              :per-page="totalPagina" 
							aria-controls="tabelaRomaneiosCancelados"
              v-model="paginaAtual" />
          </div>
          <!-- #endregion -->
        </div>
      </b-tab>
      <!-- #endregion -->
    </b-tabs>
    <!-- #endregion -->

    <!-- #region modais -->
    <b-modal id="modalCancelamento" title="Cancelamento">
      <div class="h5">
        <!-- #region informativo -->
        <div class="mb-3">
          <div v-if="cancelamento.item">
            <div class="mb-3">Romaneio {{ cancelamento.index }}</div>
            <div class="mb-3">Código de postagem: {{ cancelamento.item.codigoPostagem }}</div>
            <div class="mb-3">O objeto estará disponível para uso em outro romaneio</div>
            <div class="mb-3">Deseja continuar com o cancelamento do objeto?</div>
          </div>
          <div v-else>
            <div class="mb-3">Romaneio {{ cancelamento.index }}, Objetos: {{ cancelamento.total }}</div>
            <div class="mb-3">Os objetos estarão disponíveis para uso em outros romaneios</div>
            <div class="mb-3">Deseja continuar com o cancelamento do romaneio?</div>
          </div>
        </div>
        <!-- #endregion -->

        <!-- #region motivo e autenticacao -->
        <div>
          <b-form-group label="Motivo" label-align-md="right" label-size="" label-cols-md="2">
            <b-form-input type="text" v-model="cancelamento.motivo" />
          </b-form-group>
        </div>
        <div>
          <b-form-group label="Senha" label-align-md="right" label-size="" label-cols-md="2" content-cols-md="5">
            <b-form-input type="password" v-model="cancelamento.senha" />
          </b-form-group>
        </div>
        <!-- #endregion -->
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button type="button" class="float-right ml-2" variant="info" @click="cancelaRomaneio()">Confirmar</b-button>
          <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
    <!-- #endregion -->
  </div>
</template>

<script>
export default {
  // #region configs
  name: "romaneio",
  path: "/romaneio",
  requireAuth: true,
  components: {},
  // #endregion

  // #region dados
  data() {
    return {
			idSanfonaAberta: null,
      mensagem: "",
      corMensagem: "bg-success",
      etiqueta: "",
      descricaoRomaneio: "",
			nomeUnidadePostadora: "",
			numeroContrato: "",
			numeroCartao: "",
      idTransportadora: null,
      idEncomenda: null,
      idRomaneio: null,
      comboTransportadoras: [],
      cancelamento: {
        index: 0,
        idRomaneio: null,
        item: null,
        total: 0,
        motivo: "",
        senha: "",
				fechado: false,
				forcaCancelamento: false,
      },
      camposRomaneios: [
        { key: "dataLeitura", sortable: true, label: "Data da leitura", tdClass: "text-left", },
        { key: "codigoPostagem", sortable: true, label: "Código de postagem", tdClass: "text-left", },
        { key: "operadorLeituraNome", sortable: true, label: "Responsável leitura", tdClass: "text-left", },
        { key: "dataAtendimento", sortable: true, label: "Data do atendimento", tdClass: "text-left", },
        { key: "operadorAtendimentoNome", sortable: true, label: "Responsável atendimento", tdClass: "text-left", },
      ],
      camposRomaneiosCancelados: [
        { key: "dataLeitura", sortable: true, label: "Data da leitura", tdClass: "text-left", },
        { key: "codigoPostagem", sortable: true, label: "Código de postagem", tdClass: "text-left", },
        { key: "transportadoraNome", sortable: true, label: "Transportadora", tdClass: "text-left", },
				{ key: "unidadePostadoraNome", sortable: true, label: "Unidade Postadora", tdClass: "text-left", },
				{ key: "numeroContrato", sortable: true, label: "Nº Contrato", tdClass: "text-left", },
				{ key: "numeroCartao", sortable: true, label: "Nº Cartão", tdClass: "text-left", },
        { key: "operadorLeituraNome", sortable: true, label: "Responsável leitura", tdClass: "text-left", },
        { key: "dataCancelamento", sortable: true, label: "Data do cancelamento", tdClass: "text-left", },
        { key: "responsavelCancelamentoNome", sortable: true, label: "Responsável cancelamento", tdClass: "text-left", },
        { key: "dataAtendimento", sortable: true, label: "Data do atendimento", tdClass: "text-left", },
        { key: "operadorAtendimentoNome", sortable: true, label: "Responsável atendimento", tdClass: "text-left", },
      ],
      relatorio: [],
			relatorioItens: [],
			totalAbertos: 0,
			totalFechados: 0,
			totalCancelados: 0,
      paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_GERENTE, this.VS_PERFIL_OPERADOR])
  },
  mounted() {
    this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
		async bootstrap() {
			this.defineContratoCartao()

			await this.listaTransportadoras()
    	await this.listaRomeiosAbertos()
		},
    nomeTransportadora(id) {
      let transp = this.comboTransportadoras.find(el => el.id === id)
      return transp ? transp.text : "" // texto formatado
    },
    async listaTransportadoras() {
      this.comboTransportadoras = []
      let query = "excluido=false"
			let resp = await this.$vsApiRest("GET", `/transportadoras/?${query}`)
       
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.text = `${el.nomeFantasia}`
          el.value = el.id
        })
        this.comboTransportadoras = resp.data
      }
    },
    async listaRomeiosAbertos() {
      let sessao = this.$root.sessao
      let query = "fechado=false"
			
      query += `&idContratante=${sessao.contratante.id}`
      query += `&idFilial=${sessao.filial.id}`

			this.limpaRelatorio()

      let resp = await this.$vsApiRest("GET", `/romaneios/relatorio/?${query}`)
      
			if (resp.status === 200) {
        this.relatorio = resp.data
				this.totalAbertos = resp.data.length
      }
    },
    async listaRomeiosFechados() {
      let sessao = this.$root.sessao
      let dataInicial = new Date(new Date() - 7 * 86400000).toISOString().substring(0, 10) // ultimos 7 dias
      let dataFinal = `${new Date().toISOString().substring(0, 10)}` // hoje
      let query = "fechado=true"

			query += `&idContratante=${sessao.contratante.id}`
      query += `&idFilial=${sessao.filial.id}`
      query += `&dataInicial=${this.$vsConverteDataUTC(`${dataInicial}T00:00:00`)}`
			query += `&dataFinal=${this.$vsConverteDataUTC(`${dataFinal}T23:59:59`)}`

			this.limpaRelatorio()

      let resp = await this.$vsApiRest("GET", `/romaneios/relatorio?${query}`)
      
			if (resp.status === 200) {
				this.relatorio = resp.data
				this.totalFechados = resp.data.length
      }
    },
    async listaRomeiosCancelados() {
      let sessao = this.$root.sessao
      let dataInicial = new Date(new Date() - 7 * 86400000).toISOString().substring(0, 10) // ultimos 7 dias
      let dataFinal = `${new Date().toISOString().substring(0, 10)}` // hoje
      let query = `idContratante=${sessao.contratante.id}`

			query += `&idFilial=${sessao.filial.id}`
			query += `&dataInicial=${this.$vsConverteDataUTC(`${dataInicial}T00:00:00`)}`
			query += `&dataFinal=${this.$vsConverteDataUTC(`${dataFinal}T23:59:59`)}`

			this.limpaRelatorio()

      let resp = await this.$vsApiRest("GET", `/romaneios/cancelados/relatorio?${query}`)
       
			if (resp.status === 200) {
        this.relatorio = resp.data
				this.totalCancelados = resp.data.length
      }
    },
    async novoRomaneio() {
      let transp = this.idTransportadora
      let desc = this.descricaoRomaneio

      if (!transp) {
        this.$vsNotificacao("Informe a transportadora", "warning")
        return
      }
      if (desc.trim().length === 0) {
        this.$vsNotificacao("Informe a descrição/destino para criar um romaneio", "warning")
        return
      }
			if (!this.nomeUnidadePostadora) {
				this.nomeUnidadePostadora = ""
			}
			if (!this.numeroContrato) {
				this.numeroContrato = ""
			}
			if (!this.numeroCartao) {
				this.numeroCartao = ""
			}

      let sessao = this.$root.sessao
      let req = {
        idContratante: sessao.contratante.id,
        idFilial: sessao.filial.id,
        idTransportadora: transp,
        idOperador: sessao.id,
        descricao: desc,
				nomeUnidadePostadora: this.nomeUnidadePostadora.trim(),
				numeroContrato: this.numeroContrato.trim(),
				numeroCartao: this.numeroCartao.trim(),
      }
      let resp = await this.$vsApiRest("POST", "/romaneios/criacao", req)
      
			if (resp.status === 200) {
        this.$vsNotificacao(`Romaneio ${resp.data.codigo} "${desc}" criado com sucesso`, "success")

        this.idRomaneio = resp.data.id // default para proximas leituras
        this.descricaoRomaneio = ""
				this.nomeUnidadePostadora = ""
        this.idTransportadora = null
				this.defineContratoCartao()
        this.$refs.novoRomaneio.hide()

        this.listaRomeiosAbertos()
      }
    },
    async adicionaObjeto() {
      let etiqueta = this.etiqueta.trim()
			let validacaoCorreios = false

      if (etiqueta.length === 0) {
        this.mensagemDestacada("Informe o objeto", "bg-danger")
        return
      }
			if (etiqueta.length === this.VS_ETIQUETA_CORREIO_LEN) {
				etiqueta = etiqueta.toUpperCase()
				validacaoCorreios = true
			}
			if (validacaoCorreios && !this.$vsValidaEtiquetaCorreio(etiqueta)) {
				this.$vsNotificacao("Objeto inválido", "warning")
        return
			}
      if (!this.idRomaneio) {
        this.mensagemDestacada("Crie/Selecione o romaneio", "bg-danger")
        return
      }

			let romaneio = this.relatorio.find(el => el.id === this.idRomaneio)
      let req = {
        idRomaneio: romaneio.id,
        idOperador: this.$root.sessao.id,
        codigoPostagem: etiqueta,
      }
			let resp = await this.$vsApiRest("PATCH", "/romaneios/adicao", req, {}, "json", false)
        
			if (resp.status === 200 && resp.data) {
        this.mensagemDestacada(`Objeto ${etiqueta} adicionado com sucesso`, "bg-success")
          
				this.etiqueta = ""
				this.idSanfonaAberta = null
				romaneio.qtdItens++

        this.lazyLoadSanfona(romaneio, true)
      }
			if (resp.status === 400) {
				this.mensagemDestacada(resp.data.msg, "bg-warning")
			}
    },
    async fechaRomaneio(obj, imprimir = false) {
      let req = {
        idRomaneio: obj.id,
        idOperador: this.$root.sessao.id,
      }
			let resp = await this.$vsApiRest("PATCH", "/romaneios/fechamento", req)
      
			if (resp.status === 200) {
        this.idRomaneio = null
        this.$vsNotificacao(`Romaneio ${obj.codigo} fechado com sucesso`, "success")
        this.listaRomeiosAbertos()

        if (imprimir) {
          this.imprimeRomaneio(obj)
        }
      }
    },
    async cancelaRomaneio() {
	  let sessao = this.$root.sessao
      let cancel = this.cancelamento

      if (!cancel.motivo || !cancel.motivo.trim().length) {
          this.$vsNotificacao("Informe o motivo do cancelamento", "warning")
          return
      }

      let resp = await this.$vsAutenticacao(sessao.email, cancel.senha, sessao.perfil)
        
	  if (!resp || resp.status !== 200) {
        return
      }

      let req = {
        idRomaneio: cancel.idRomaneio,
        idItem: cancel.item ? cancel.item.id : null,
        idResponsavel: sessao.id,
        motivo: cancel.motivo,
				forcaCancelamento: cancel.forcaCancelamento,
      }
      this.$bvModal.hide("modalCancelamento")

      resp = await this.$vsApiRest("PATCH", `/romaneios/cancelamento`, req)
        
	  if (resp.status === 200) {
        let msg = (cancel.item !== null)
          ? `Código de postagem ${cancel.item.codigoPostagem} removido do romaneio ${cancel.index}`
          : `Romaneio ${cancel.index} cancelado`

        this.$vsNotificacao(msg, "warning")

		if (cancel.fechado) {
		  this.listaRomeiosFechados()
		} else {
		  this.listaRomeiosAbertos()
		}
      }        
    },
    async imprimeRomaneio(obj) {
      let resp = await this.$vsApiRest("GET", `/romaneios/impressao/${obj.id}`, {}, {}, "arraybuffer")
       
	  if (resp.status === 200) {
        this.$vsDownload(resp.data, `romaneio${obj.codigo}-postair-${new Date().getTime()}.pdf`)
      }
    },
    mostraModalCancelaRomaneio(romaneio, item = null) {
      let cancel = this.cancelamento
      cancel.index = romaneio.codigo
      cancel.idRomaneio = romaneio.id
      cancel.item = item
      cancel.total = romaneio.qtdItens
      cancel.motivo = ""
      cancel.senha = ""
	  cancel.fechado = romaneio.fechado
      cancel.forcaCancelamento = (!romaneio.fechado && item == null)

      this.$bvModal.show("modalCancelamento")
    },
    mensagemDestacada(msg, cor = "bg-success") {
      this.corMensagem = cor
      this.mensagem = msg
      this.avisoSonoro(cor)
    },
    avisoSonoro(cor) {
      let audio = new (window.AudioContext || window.webkitAudioContext)() // (chromium e firefox || safari)
      if (!audio) {
        return // navegador nao suporta
      }

      let beep = audio.createOscillator()

      if (!beep) {
        return // navegador nao suporta
      }

      // caso seja bg-success não entra no swith case
      let tipo_beep = 'square';
      let frequencia = 800;
      let tempo = 500;

      switch (cor) {
        case 'bg-danger':
          tipo_beep = 'sawtooth';
          frequencia = 100;
          tempo = 1000;
          break;
        case 'bg-warning':
          tipo_beep = 'sine';
          frequencia = 500;
          tempo = 1000;
          break;
      }
      beep.type = tipo_beep
      beep.frequency.value = frequencia; // maior: +agudo, menor: +grave
      beep.connect(audio.destination)
      beep.start()

      setTimeout(function () {
        beep.stop()
      }, tempo)
    },
    buscaOperador(lista, idOperador) {
      return lista.find(el => el.id === idOperador)
    },
    buscaAtendimento(lista, id) {
      return lista.find(el => el.id === id)
    },
		async lazyLoadSanfona(obj, ordemDecrescente = false) {
			let sanfonaDiferente = (this.idSanfonaAberta !== obj.id)

			this.idSanfonaAberta = obj.id

			if (sanfonaDiferente && obj.qtdItens > 0) {
				let query = `idRomaneio=${obj.id}`
				let resp = await this.$vsApiRest("GET", `/romaneios/relatorio-detalhado/?${query}`)
				
				if (resp.status === 200) {
					this.relatorioItens = (ordemDecrescente) ? resp.data.reverse() : resp.data
				}
			}
		},
		limpaRelatorio() {
			this.totalAbertos = 0
			this.totalFechados = 0
			this.totalCancelados = 0
			this.relatorio = []
			this.relatorioItens = []
			this.idSanfonaAberta = null
		},
		defineContratoCartao() {
			let sessao = this.$root.sessao.contratante
			let contrato = sessao.contratosRomaneio[0]

			this.numeroContrato = ""
			this.numeroCartao = ""

			if (contrato) {
				this.numeroContrato = contrato.numeroContrato
				this.numeroCartao = contrato.numeroCartao
			}
		},
  },
  // #endregion
}
</script>
