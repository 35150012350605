<template>
  <div>
    <h1 class="titulo">Relatório de faturamento</h1>

    <!-- #region filtro -->
    <b-form-row>
      <b-col md="2">
        <b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
          <b-form-datepicker locale="pt-BR" 
						v-model="filtro.dataInicio" 
						placeholder="dd/mm/aaaa" 
						:date-format-options="formatoData" 
						@input="limpaTela()"/>
        </b-form-group>
      </b-col>

      <b-col md="2">
        <b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
          <b-form-datepicker locale="pt-BR" 
						v-model="filtro.dataFinal" 
						placeholder="dd/mm/aaaa"
            :date-format-options="formatoData" 
						@input="limpaTela()"/>
        </b-form-group>
      </b-col>

      <b-col md="3" v-if="$root.sessao.perfil === VS_PERFIL_CONTRATANTE">
        <b-form-group class="form-input-cinza" label="Filial" label-cols="12" label-size="lg">
          <!--<b-form-select v-model="filtro.idFilial" :options="comboFilial" @change="limpaTela()" />-->
					<model-select v-model="filtro.idFilial" :options="comboFilial" @input="limpaTela()" />
        </b-form-group>
      </b-col>

      <!--<b-col md="3">
        <b-form-group class="form-input-cinza" label="Transportadora" label-cols="12" label-size="lg">
          <b-form-select v-model="filtro.idTransportadora" :options="comboTransportadora" @change="limpaTela()" />
        </b-form-group>
      </b-col>-->

      <b-col md="2">
        <b-form-group class="text-white" label="." label-cols="12" label-size="lg">
          <b-button variant="primary" v-b-tooltip.hover title="Pesquisar" @click.prevent="buscaFaturamento()">
            <b-icon icon="search" scale="1" />
          </b-button>
        </b-form-group>
      </b-col>
    </b-form-row>
    <!-- #endregion -->

    <!-- #region relatorios -->
    <b-tabs v-if="exibeRegistros" content-class="px-2" class="pt-4">
      <!-- #region sintetico -->
      <b-tab v-if="telaContratante" title="Sintético" title-item-class="h5" :active="abaAtiva == 0" @click="relatorioSintetico()">
				<div>
					<!-- #region opcoes de impressao -->
					<div>
						<span class="my-4 float-right mx-2">
							<b-button @click.prevent="imprimeSinteticoResumoCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
						<span class="my-4 float-right mx-2">
							<b-button @click.prevent="imprimeSinteticoResumoPDF()" class="btn-vermelho" v-b-tooltip.hover title="Imprimir PDF">Exportar PDF</b-button>
						</span>
					</div>
					<!-- #endregion -->

					<!-- #region resumo/totalizador -->
					<b-table  
						class="text-nowrap h5 p-3 cor-fundo-padrao"
						:items="resumoRelatorio" 
						:fields="camposResumoSintetico"
						small 
						responsive 
						borderless
						no-footer-sorting 
						foot-clone>
						<!--===================================================================================
							mapa de colunas (atua em campos sem tratamento) 
							se precisar debugar ou visualizar os dados para ajustes basta descomentar...

							obj.index = indice da linha
							obj.field = nome do campo/coluna/chave json
							obj.item  = objeto json bruto
							obj.value = o valor da chave json com tipo original mantido e sem tratamento, ex:
						====================================================================================-->
						<!--<template #cell()="obj">
								{{ obj.field }}
								{{ obj.item }}
							</template>-->

						<!--===================================================================================
							celulas que precisam de tratamento, 
							sobrescrevem mapa de colunas pela identificacao da chave
							e nao precisam estar em ordem/sequencia
						====================================================================================-->

						<!-- #region celulas customizadas/formatadas -->
						<template #cell(totalQuantidade)="obj">
							{{ $vsFormataNumero(obj.value) }}
						</template>

						<template #cell(totalFreteTransportadora)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFreteContratante)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFretePostagem)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalLucroPercentual)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(descricao)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(totalQuantidade)><span class="float-right">{{ $vsFormataNumero(totalQuantidade(resumoRelatorio)) }}</span></template>
						<template #foot(totalFreteTransportadora)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteTransportadora(resumoRelatorio)) }}</span></template>
						<template #foot(totalFreteContratante)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteContratante(resumoRelatorio)) }}</span></template>
						<template #foot(totalFretePostagem)><span class="float-right">R$ {{ $vsFormataMoeda(totalFretePostagem(resumoRelatorio)) }}</span></template>
						<template #foot(totalLucroPercentual)><span class="float-right">{{ $vsFormataMoeda(totalLucroPercentual(resumoRelatorio)) }} %</span></template>
						<!-- #endregion -->
					</b-table>
					<!-- #endregion -->

					<!-- #region opcoes de impressao -->
					<div>
						<span class="my-4 float-right mx-2">
							<b-button @click.prevent="imprimeSinteticoCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
					</div>
					<!-- #endregion -->

					<!-- #region listagem/paginacao -->
					<b-table 
						id="tabelaRelatorioSintetico" 
						class="text-nowrap h5" 
						:items="tabelaRelatorio"
						:fields="camposRelatorioSintetico" 
						:per-page="totalPagina" 
						:current-page="paginaAtual"
						small 
						hover 
						responsive
						no-footer-sorting 
						foot-clone>
						<!--===================================================================================
							celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

							obj.index = indice da linha
							obj.field = nome do campo/coluna/chave json
							obj.item  = objeto json bruto
							obj.value = o valor da chave json com tipo original mantido e sem tratamento

							para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
						====================================================================================-->
						<!--<template #cell()="obj">
							{{ obj.field }}
							{{ obj.item }}
							{{ obj.value }}
						</template>-->

						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataFaturamento)="obj">
							{{ $vsFormataData(obj.value) }}
						</template>

						<template #cell(totalFreteTransportadora)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFreteContratante)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFretePostagem)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalLucroPercentual)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(dataFaturamento)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(totalQuantidade)><span class="float-right">{{ $vsFormataNumero(totalQuantidade(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFreteTransportadora)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteTransportadora(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFreteContratante)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteContratante(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFretePostagem)><span class="float-right">R$ {{ $vsFormataMoeda(totalFretePostagem(tabelaRelatorio)) }}</span></template>
						<template #foot(totalLucroPercentual)><span class="float-right">{{ $vsFormataMoeda(totalLucroPercentual(tabelaRelatorio)) }} %</span></template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
						</span>
						<span>Total de Registros: {{ $vsFormataNumero(tabelaRelatorio.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" 
							:total-rows="tabelaRelatorio.length"
							:per-page="totalPagina" 
							aria-controls="tabelaRelatorioSintetico"
							v-model="paginaAtual" />
					</div>
					<!-- #endregion -->
					<!-- #endregion -->
				</div>
      </b-tab>
      <!-- #endregion -->

      <!-- #region analitico -->
      <b-tab title="Analítico" title-item-class="h5" :active="abaAtiva == 1" @click="relatorioAnalitico()">
				<div>
					<!-- #region resumo/totalizador -->
					<b-table
						class="text-nowrap h5 p-3 cor-fundo-padrao"
						:items="resumoRelatorio" 
						:fields="camposResumoAnalitico"
						small 
						responsive 
						borderless
						no-footer-sorting 
						foot-clone>

						<!-- #region celulas customizadas/formatadas -->
						<template #cell(detalhes)="obj">
							<a @click.prevent="detalhesResumoAnalitico(obj)" v-b-tooltip.hover.right="'Mostrar/ocultar detalhes'">
								<b-icon v-if="obj.detailsShowing" icon="dash-square-dotted" />
                <b-icon v-else icon="plus-square-dotted" />
							</a>
						</template>

						<template #cell(totalQuantidade)="obj">
							{{ $vsFormataNumero(obj.value) }}
						</template>

						<template #cell(totalFreteTransportadora)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFreteContratante)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFretePostagem)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalLucroPercentual)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->

						<!-- #region linha detalhes -->
						<template #row-details="obj">
							<div class="px-5" style="display: grid;">
								<b-table  
									class="text-nowrap h5 p-3 cor-fundo-padrao"
									:items="obj.item.itens" 
									:fields="camposResumoAnaliticoItens"
									small 
									responsive 
									borderless>

									<!-- #region celulas customizadas/formatadas -->
									<template #cell(totalQuantidade)="obj">
										{{ $vsFormataNumero(obj.value) }}
									</template>

									<template #cell(totalFreteTransportadora)="obj">
										{{ $vsFormataMoeda(obj.value) }}
									</template>

									<template #cell(totalFreteContratante)="obj">
										{{ $vsFormataMoeda(obj.value) }}
									</template>

									<template #cell(totalFretePostagem)="obj">
										{{ $vsFormataMoeda(obj.value) }}
									</template>

									<template #cell(totalLucroPercentual)="obj">
										{{ $vsFormataMoeda(obj.value) }}
									</template>
									<!-- #endregion -->
								</b-table>
							</div>
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(transportadoraNome)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(totalQuantidade)><span class="float-right">{{ $vsFormataNumero(totalQuantidade(resumoRelatorio)) }}</span></template>
						<template #foot(totalFreteTransportadora)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteTransportadora(resumoRelatorio)) }}</span></template>
						<template #foot(totalFreteContratante)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteContratante(resumoRelatorio)) }}</span></template>
						<template #foot(totalFretePostagem)><span class="float-right">R$ {{ $vsFormataMoeda(totalFretePostagem(resumoRelatorio)) }}</span></template>
						<template #foot(totalLucroPercentual)><span class="float-right">{{ $vsFormataMoeda(totalLucroPercentual(resumoRelatorio)) }} %</span></template>
						<!-- #endregion -->
					</b-table>
					<!-- #endregion -->

					<!-- #region opcoes de impressao -->
					<div>
						<span class="my-4 float-right mx-2">
							<b-button @click.prevent="imprimeAnaliticoCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
						<span class="my-4 float-right mx-2">
							<b-button @click.prevent="imprimeAnaliticoPDF()" class="btn-vermelho" v-b-tooltip.hover title="Imprimir PDF">Exportar PDF</b-button>
						</span>
					</div>
					<!-- #endregion -->

					<!-- #region listagem/paginacao -->
					<b-table 
						id="tabelaRelatorioAnalitico" 
						class="text-nowrap h5" 
						:items="tabelaRelatorio"
						:fields="camposRelatorioAnalitico" 
						:per-page="totalPagina" 
						:current-page="paginaAtual"
						small 
						hover 
						responsive
						no-footer-sorting 
						foot-clone>
						<!--===================================================================================
							celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

							obj.index = indice da linha
							obj.field = nome do campo/coluna/chave json
							obj.item  = objeto json bruto
							obj.value = o valor da chave json com tipo original mantido e sem tratamento

							para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
						====================================================================================-->
						<!--<template #cell()="obj">
							{{ obj.field }}
							{{ obj.item }}
							{{ obj.value }}
						</template>-->

						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataFaturamento)="obj">
							{{ $vsFormataData(obj.value) }}
						</template>

						<template #cell(totalQuantidade)="obj">
							{{ $vsFormataNumero(obj.value) }}
						</template>

						<template #cell(totalFreteTransportadora)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFreteContratante)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFretePostagem)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalLucroPercentual)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(dataFaturamento)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(totalQuantidade)><span class="float-right">{{ $vsFormataNumero(totalQuantidade(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFreteTransportadora)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteTransportadora(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFreteContratante)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteContratante(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFretePostagem)><span class="float-right">R$ {{ $vsFormataMoeda(totalFretePostagem(tabelaRelatorio)) }}</span></template>
						<template #foot(totalLucroPercentual)><span class="float-right">{{ $vsFormataMoeda(totalLucroPercentual(tabelaRelatorio)) }} %</span></template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
						</span>
						<span>Total de Regitros: {{ $vsFormataNumero(tabelaRelatorio.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" 
							:total-rows="tabelaRelatorio.length"
							:per-page="totalPagina" 
							aria-controls="tabelaRelatorioAnalitico"
							v-model="paginaAtual" />
					</div>
					<!-- #endregion -->
					<!-- #endregion -->
				</div>
      </b-tab>
      <!-- #endregion -->

      <!-- #region detalhado -->
      <b-tab title="Detalhado" title-item-class="h5" :active="abaAtiva == 2" @click="relatorioDetalhado()">
				<div>
					<!-- #region opcoes de impressao -->
					<div>
						<span class="my-4 float-right mx-2">
							<b-button @click.prevent="imprimeDetalhadoCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
						<span class="my-4 float-right mx-2">
							<b-button @click.prevent="imprimeDetalhadoPDF()" class="btn-vermelho" v-b-tooltip.hover title="Imprimir PDF">Exportar PDF</b-button>
						</span>
					</div>
					<!-- #endregion -->

        	<!-- #region listagem/edicao -->
					<b-table 
						id="tabelaRelatorioDetalhado" 
						class="text-nowrap h5" 
						:items="tabelaRelatorio"
						:fields="camposRelatorioDetalhado" 
						:per-page="totalPagina" 
						:current-page="paginaAtual" 
						small
						hover
						responsive
						no-footer-sorting 
						foot-clone>
						<!--===================================================================================
							celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

							obj.index = indice da linha
							obj.field = nome do campo/coluna/chave json
							obj.item  = objeto json bruto
							obj.value = o valor da chave json com tipo original mantido e sem tratamento

							para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
						====================================================================================-->
						<!--<template #cell()="obj">
							{{ obj.field }}
							{{ obj.item }}
							{{ obj.value }}
						</template>-->

						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataFaturamento)="obj">
							{{ $vsFormataData(obj.value) }}
						</template>

						<template #cell(destinatarioCep)="obj">
							{{ $vsFormataCep(obj.value) }}
						</template>

						<template #cell(totalPeso)="obj">
							{{ $vsFormataNumero(obj.value) }}
						</template>

						<template #cell(totalFreteTransportadora)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFreteContratante)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(totalFretePostagem)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(observacoes)="obj">
							{{ obj.value[0] }}
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(dataFaturamento)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(totalPeso)><span class="float-right">{{ $vsFormataNumero(totalPeso(tabelaRelatorio)) }}</span></template>
						<template #foot(totalQuantidade)><span class="float-right">{{ $vsFormataNumero(totalQuantidade(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFreteTransportadora)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteTransportadora(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFreteContratante)><span class="float-right">R$ {{ $vsFormataMoeda(totalFreteContratante(tabelaRelatorio)) }}</span></template>
						<template #foot(totalFretePostagem)><span class="float-right">R$ {{ $vsFormataMoeda(totalFretePostagem(tabelaRelatorio)) }}</span></template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
						</span>
						<span>Total de Regitros: {{ $vsFormataNumero(tabelaRelatorio.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" 
						  :total-rows="tabelaRelatorio.length"
							:per-page="totalPagina" 
							aria-controls="tabelaRelatorioDetalhado" 
							v-model="paginaAtual" />
					</div>
					<!-- #endregion -->
        	<!-- #endregion -->
				</div>
      </b-tab>
      <!-- #endregion -->
    </b-tabs>
    <!-- #endregion -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select"

export default {
  // #region configs
  name: "relatorio-faturamento",
  path: "/relatorio-faturamento",
  requireAuth: true,
	components: { ModelSelect },
  // #endregion

  // #region dados
  data() {
    return {
      OPT_TODOS: "0",
			//dataInicioLimite: new Date(new Date() - 60 * 86400000).toISOString().substring(0, 10), // d-60
			abaAtiva: 0,
			exibeRegistros: false,
			telaContratante: false,
      comboFilial: [],
      comboTransportadora: [],
      formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
      filtro: {
        dataInicio: new Date(new Date() - 7 * 86400000).toISOString().substring(0, 10), // 7 dias atras
        dataFinal: new Date().toISOString().substring(0, 10), // hoje
        idFilial: null,
        idTransportadora: null,
      },
			camposResumoSintetico: [
        { key: "descricao", label: "Descrição", sortable: false, thClass: "text-left", },
        { key: "totalQuantidade", label: "Qtd.", sortable: false, thClass: "text-left", tdClass: "text-right" },
        { key: "totalFreteTransportadora", label: "Frete Transportadora", sortable: false, thClass: "text-left", tdClass: "text-right" },
        { key: "totalFreteContratante", label: "Frete Contratante", sortable: false, thClass: "text-left", tdClass: "text-right" },
        { key: "totalFretePostagem", label: "Frete Postagem", sortable: false, thClass: "text-left", tdClass: "text-right" },
				{ key: "totalLucroPercentual", label: "Lucro %", sortable: false, thClass: "text-left", tdClass: "text-right" },
      ],
      camposRelatorioSintetico: [
        { key: "dataFaturamento", label: "Data", sortable: true, },
        { key: "filialNome", label: "Filial", sortable: true, },
        { key: "totalQuantidade", label: "Qtd.", sortable: true, tdClass: "text-right" },
        { key: "totalFreteTransportadora", label: "Frete Transportadora", sortable: true, tdClass: "text-right" },
        { key: "totalFreteContratante", label: "Frete Contratante", sortable: true, tdClass: "text-right" },
        { key: "totalFretePostagem", label: "Frete Postagem", sortable: true, tdClass: "text-right" },
				{ key: "totalLucroPercentual", label: "Lucro %", sortable: false, tdClass: "text-right" },
      ],
			camposResumoAnalitico: [
				{ key: "detalhes", label: "", sortable: false, },
        { key: "transportadoraNome", label: "Descrição", sortable: false, thClass: "text-left", },
				{ key: "totalQuantidade", label: "Qtd.", sortable: false, tdClass: "text-right" },
        { key: "totalFreteTransportadora", label: "Frete Transportadora", sortable: false, tdClass: "text-right" },
        { key: "totalFreteContratante", label: "Frete Contratante", sortable: false, tdClass: "text-right" },
        { key: "totalFretePostagem", label: "Frete Postagem", sortable: false, tdClass: "text-right" },
				{ key: "totalLucroPercentual", label: "Lucro %", sortable: false, tdClass: "text-right" },
      ],
			camposResumoAnaliticoItens: [
        { key: "descricao", label: "Descrição", sortable: false, thClass: "text-left", },
        { key: "totalQuantidade", label: "Qtd.", sortable: false, thClass: "text-left", tdClass: "text-right" },
        { key: "totalFreteTransportadora", label: "Frete Transportadora", sortable: false, thClass: "text-left", tdClass: "text-right" },
        { key: "totalFreteContratante", label: "Frete Contratante", sortable: false, thClass: "text-left", tdClass: "text-right" },
        { key: "totalFretePostagem", label: "Frete Postagem", sortable: false, thClass: "text-left", tdClass: "text-right" },
				{ key: "totalLucroPercentual", label: "Lucro %", sortable: false, thClass: "text-left", tdClass: "text-right" },
      ],
      camposRelatorioAnalitico: [
        { key: "dataFaturamento", label: "Data", sortable: true, },
        { key: "filialNome", label: "Filial", sortable: true, },
        { key: "transportadoraNome", label: "Descrição", sortable: true, },
        { key: "totalQuantidade", label: "Qtd.", sortable: true, tdClass: "text-right" },
        { key: "descricao", label: "Descrição", sortable: true, },
        { key: "totalFreteTransportadora", label: "Frete Transportadora", sortable: true, tdClass: "text-right" },
        { key: "totalFreteContratante", label: "Frete Contratante", sortable: true, tdClass: "text-right" },
        { key: "totalFretePostagem", label: "Frete Postagem", sortable: true, tdClass: "text-right" },
        { key: "totalLucroPercentual", label: "Lucro %", sortable: false, tdClass: "text-right" },
      ],
      camposRelatorioDetalhado: [
        { key: "dataFaturamento", label: "Data", sortable: true, },
		{ key: "filialNome", label: "Filial", sortable: true, },
        { key: "pagadorNome", label: "Pagador", sortable: true, },
        { key: "destinatarioNome", label: "Destinatário", sortable: true, },
        { key: "descricao", label: "Serviço", sortable: true, },
        { key: "destinatarioCep", label: "CEP", sortable: true, },
        { key: "destinatarioUF", label: "UF", sortable: true, },
        { key: "totalPeso", label: "Peso(g)", sortable: true, tdClass: "text-right" },
        { key: "totalQuantidade", label: "Qtd.", sortable: true, tdClass: "text-right" },
        { key: "etiqueta", label: "Registro", sortable: true, },
        { key: "totalFreteTransportadora", label: "Frete Transportadora", sortable: true, tdClass: "text-right" },
        { key: "totalFreteContratante", label: "Frete Contratante", sortable: true, tdClass: "text-right" },
        { key: "totalFretePostagem", label: "Frete Postagem", sortable: true, tdClass: "text-right" },
				{ key: "observacoes", label: "Observação", sortable: true,  },
      ],
      resumoRelatorio: [],
      tabelaRelatorio: [],
      paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_CONTRATANTE, this.VS_PERFIL_FILIAL, this.VS_PERFIL_GERENTE])
  },
  mounted() {
		this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
		async bootstrap() {
			this.telaContratante = (this.$root.sessao.perfil === this.VS_PERFIL_CONTRATANTE)
			this.abaAtiva = (this.telaContratante) ? 0 : 1
			
			await this.listaTransportadoras()

			if (this.telaContratante) {
				await this.listaFilial()

                this.camposRelatorioDetalhado.splice(2, 1)
			} else {
				this.camposResumoAnalitico.splice(3, 1) // frete transportadora
				this.camposResumoAnalitico.splice(5, 1) // lucro
				this.camposResumoAnaliticoItens.splice(2, 1) // frete transportadora
				this.camposResumoAnaliticoItens.splice(4, 1) // lucro
				
				this.camposRelatorioAnalitico.splice(1, 1) // filial
				this.camposRelatorioAnalitico.splice(4, 1) // frete transportadora
				this.camposRelatorioAnalitico.splice(6, 1) // lucro

				this.camposRelatorioDetalhado.splice(1, 1)
				this.camposRelatorioDetalhado.splice(8, 1) // frete transportadora
			}
			await this.buscaFaturamento()
		},
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    async listaFilial() {
      this.comboFilial = [];
      let query = `idContratante=${this.$root.sessao.id}`;
			let resp = await this.$vsApiRest("GET", `/usuarios/filiais/?${query}`)

      if (resp.status === 200) {
        resp.data.forEach(e => {
          e.text = e.nome
          e.value = e.id
        })
        this.comboFilial = resp.data
        this.comboFilial.unshift({ value: this.OPT_TODOS, text: "Todas" }) // primeira opcao
        this.filtro.idFilial = this.comboFilial[0].value
      }
    },
    async listaTransportadoras() {
      let query = "excluido=false"
			let resp = await this.$vsApiRest("GET", `/transportadoras/?${query}`)
        
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.text = `${el.nomeFantasia}`
          el.value = el.id
        })
        this.comboTransportadora = resp.data
        this.comboTransportadora.unshift({ value: this.OPT_TODOS, text: "Todas" }) // primeira opcao
        this.filtro.idTransportadora = this.comboTransportadora[0].value
      }
    },
		validacoesFiltro() {
			let form = this.filtro
			let dias = 60
			let dtInicial = new Date(`${form.dataInicio}T00:00:00`)
			let dtFinal = new Date(`${form.dataFinal}T23:59:59`)
			let dtLimite = new Date(dtFinal - dias * 86400000) // d-dias
			let ok = true

			if (dtFinal < dtInicial) {
				this.$vsNotificacao("Data final não pode ser menor que a data inicial", "warning")
				ok = false
			}
			if (dtInicial < dtLimite) {
				this.$vsNotificacao(`Intervalo entre as datas maior que ${dias} dias, faça a correção`, "warning")
				ok = false
			}
			return ok
		},
		montaQueryFiltro() {
			let form = this.filtro
			let sessao = this.$root.sessao
      let query = `idContratante=${sessao.id}`

      if (sessao.perfil === this.VS_PERFIL_FILIAL) {
				query = `idContratante=${sessao.contratante.id}`
        query+= `&idFilial=${this.$root.sessao.id}`
      }
      if (sessao.perfil === this.VS_PERFIL_GERENTE) {
				query = `idContratante=${sessao.contratante.id}`
        query+= `&idFilial=${sessao.filial.id}`
      }
      if (form.idFilial !== null && form.idFilial !== this.OPT_TODOS) {
        query += `&idFilial=${form.idFilial}`
      }
      if (form.idTransportadora !== null && form.idTransportadora !== this.OPT_TODOS) {
        query += `&idTransportadora=${form.idTransportadora}`
      }
      query += `&dataInicio=${this.$vsConverteDataUTC(`${form.dataInicio}T00:00:00`)}`
      query += `&dataFim=${this.$vsConverteDataUTC(`${form.dataFinal}T23:59:59`)}`
			query += `&perfil=${sessao.perfil}`

			return query
		},
		buscaFaturamento() {
			switch (this.abaAtiva) {
				case 0:
					this.relatorioSintetico()
					break
				case 1:
					this.relatorioAnalitico()
					break
				case 2:
					this.relatorioDetalhado()
					break
			}
    },
    async relatorioSintetico() {
			if (!this.validacoesFiltro()) {
				return
			}

			let query = this.montaQueryFiltro()
			this.abaAtiva = 0
			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/faturamento/relatorios/sintetico?${query}`)
        
			if (resp.status === 200) {
				this.resumoRelatorio = resp.data.resumo
				this.tabelaRelatorio = resp.data.detalhe
				this.exibeRegistros = (this.tabelaRelatorio.length > 0)
      }
    },
    async relatorioAnalitico() {
			if (!this.validacoesFiltro()) {
				return
			}

      let query = this.montaQueryFiltro()
			this.abaAtiva = 1
			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/faturamento/relatorios/analitico?${query}`)
        
			if (resp.status === 200) {
				this.resumoRelatorio = resp.data.resumo
				this.tabelaRelatorio = resp.data.detalhe
				this.exibeRegistros =  (this.tabelaRelatorio.length > 0)
      }
    },
    async relatorioDetalhado() {
			if (!this.validacoesFiltro()) {
				return
			}

      let query = this.montaQueryFiltro()
			this.abaAtiva = 2
			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/faturamento/relatorios/detalhado?${query}`)

      if (resp.status === 200) {
				this.tabelaRelatorio = resp.data
				this.exibeRegistros =  (this.tabelaRelatorio.length > 0)
      }
    },
    async imprimeSinteticoResumoPDF() {
			let query = this.montaQueryFiltro()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-sintentico?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status == 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-sintetico-${new Date().getTime()}.zip`)
      }
    },
    async imprimeAnaliticoPDF() {
			let query = this.montaQueryFiltro()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-analitico?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-analitico-${new Date().getTime()}.zip`)
      }
    },
    async imprimeDetalhadoPDF() {
			let query = this.montaQueryFiltro()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-detalhado?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-detalhado-${new Date().getTime()}.zip`)
      }
    },
		async imprimeSinteticoResumoCSV() {
			let query = this.montaQueryFiltro()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-sintentico-resumo-csv?${query}`, {}, {}, "arraybuffer")

      if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-sintetico-resumo-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
      }
    },
		async imprimeSinteticoCSV() {
			let query = this.montaQueryFiltro()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-sintentico-csv?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-sintetico-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
      }
    },
    async imprimeAnaliticoCSV() {
			let query = this.montaQueryFiltro()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-analitico-csv?${query}`, {}, {}, "arraybuffer")
        
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-analitico-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
      }
    },
    async imprimeDetalhadoCSV() {
			let query = this.montaQueryFiltro()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-detalhado-csv?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-detalhado-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
      }
    },
    limpaTela() {
      this.exibeRegistros = false
			this.resumoRelatorio = []
      this.tabelaRelatorio = []
    },
		totalQuantidade(relatorio) {
      return relatorio.reduce((total, obj) => total + obj.totalQuantidade, 0)
    },
    totalFretePostagem(relatorio) {
      return relatorio.reduce((total, obj) => total + obj.totalFretePostagem, 0)
    },
    totalFreteTransportadora(relatorio) {
      return relatorio.reduce((total, obj) => total + obj.totalFreteTransportadora, 0)
    },
    totalFreteContratante(relatorio) {
      return relatorio.reduce((total, obj) => total + obj.totalFreteContratante, 0)
    },
    totalLucroPercentual(relatorio) {
      return ((this.totalFreteContratante(relatorio) / this.totalFretePostagem(relatorio)) - 1) * 100
    },
		totalPeso(relatorio) {
      return relatorio.reduce((total, obj) => total + obj.totalPeso, 0)
    },
		detalhesResumoAnalitico(obj) {
			this.$vsLoadingStart(true, 100)

			if (!obj.detailsShowing) {
				this.resumoRelatorio.forEach((el, i) => {
					if (i !== obj.index) {
						this.$set(el, "_showDetails", false)
					}
				})
			}
			obj.toggleDetails()
		},
  },
  // #endregion
}
</script>
