<template>
  <div>
		<div class="titulo">
			<h1>Logs de sistema</h1>
			<h4>Ocorrências dos últimos 15 dias</h4>
		</div>

		<!-- #region filtro -->
		<form @submit.prevent="listaLogs()">
			<b-form-group label="Filtrar por:" label-size="lg">
				<b-form-row>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
							<b-form-datepicker locale="pt-BR" 
								v-model="filtro.dataInicial"
								placeholder="dd/mm/aaaa" 
								:date-format-options="formatoData" 
								@input="limpaTela()"
								:min="dataMinima" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
							<b-form-datepicker locale="pt-BR" 
								v-model="filtro.dataFinal" 
								placeholder="dd/mm/aaaa" 
								:date-format-options="formatoData" 
								@input="limpaTela()"
								:min="dataMinima" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Tipo" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.tipo" :options="comboTipo" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Escopo/Cenário" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.escopo" :options="comboEscopo" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Contratante" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.idContratante" :options="comboContratante" @change="limpaTela(); defineComboFilial()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Filial" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.idFilial" :options="comboFilial" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Transportadora" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.idTransportadora" :options="comboTransportadora" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="3">
						<b-form-group class="form-input-cinza" label="Login usuário" label-cols="12" label-size="lg">
							<b-form-input type="text" v-model="filtro.loginUsuario" autocomplete="on" @keyup="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="IP usuário" label-cols="12" label-size="lg">
							<b-form-input type="text" v-model="filtro.ipUsuario" autocomplete="on" @keyup="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Status HTTP" label-cols="12" label-size="lg" content-cols-md="">
							<b-form-input class="text-right" type="text" v-model="filtro.statusHTTP" autocomplete="on" @keyup="limpaTela(); mascaraInteiro($event)" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
							<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
								<b-icon icon="search" scale="1" />
							</b-button>
						</b-form-group>
					</b-col>
				</b-form-row>
			</b-form-group>
		</form>
		<!-- #endregion -->

		<!-- #region listagem -->
		<div v-if="mostraLogs">
			<b-table id="tabelaLogs" class="text-nowrap h5"
				:items="tabelaLogs"
				:fields="camposLogs" 
				:per-page="totalPagina" 
				:current-page="paginaAtual" 
				small 
				hover 
				responsive>
				<!-- #region celulas customizadas/formatadas -->
				<template #cell(dataCriacao)="obj">
					{{ $vsFormataData(obj.value, true) }}
				</template>

				<template #cell(tipo)="obj">
					<b-icon v-if="obj.value == 1" icon="exclamation-octagon-fill" class="text-info"/>
					<b-icon v-if="obj.value == 2" icon="exclamation-octagon-fill" class="text-warning"/>
					<b-icon v-if="obj.value == 3" icon="exclamation-octagon-fill" class="text-danger"/>
					&nbsp;{{ descricaoTipo(obj.value) }}
				</template>

				<template #cell(escopo)="obj">
					{{ descricaoEscopo(obj.value) }}
				</template>

				<template #cell(idContratante)="obj">
					{{ nomeContratante(obj.value) }}
				</template>

				<template #cell(idFilial)="obj">
					{{ nomeFilial(obj.value) }}
				</template>

				<template #cell(idTransportadora)="obj">
					{{ nomeTransportadora(obj.value) }}
				</template>

				<template #cell(observacao)="obj">
					<a v-if="obj.value.length > (MAX_CHARS * 2)" class="text-info" @click.prevent="logDetalhe(obj.item)">
						Exibir detalhes
					</a>
					<span v-else>{{ obj.value }}</span>
				</template>

				<template #cell(dadosInternos)="obj">
					<a v-if="obj.value.length > MAX_CHARS" class="text-info" @click.prevent="logDetalhe(obj.item)">
						Exibir detalhes
					</a>
					<span v-if="obj.value.length && obj.value.length <= MAX_CHARS">
						<a class="text-secondary" @click.prevent="copiaClipboard(obj.value)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
							<b-icon icon="clipboard" />
						</a>&nbsp;{{ obj.value }}
					</span>
				</template>

				<template #cell(dadosEnviados)="obj">
					<a v-if="obj.value.length > MAX_CHARS" class="text-info" @click.prevent="logDetalhe(obj.item)">
						Exibir detalhes
					</a>
					<span  v-if="obj.value.length && obj.value.length <= MAX_CHARS">
						<a class="text-secondary" @click.prevent="copiaClipboard(obj.value)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
							<b-icon icon="clipboard" />
						</a>&nbsp;{{ obj.value }}
					</span>
				</template>

				<template #cell(dadosRecebidos)="obj">
					<a v-if="obj.value.length > MAX_CHARS" class="text-info" @click.prevent="logDetalhe(obj.item)">
						Exibir detalhes
					</a>
					<span v-if="obj.value.length && obj.value.length <= MAX_CHARS">
						<a class="text-secondary" @click.prevent="copiaClipboard(obj.value)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
							<b-icon icon="clipboard" />
						</a>&nbsp;{{ obj.value }}
					</span>
				</template>
				<!-- #endregion -->
			</b-table>

			<!-- #region paginacao -->
			<div class="h5">
				<span style="padding-left: 5px;padding-right: 20px;">
					<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
				</span>
				<span>Total de Registros: {{ $vsFormataNumero(tabelaLogs.length) }}</span>
				<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="tabelaLogs.length"
					:per-page="totalPagina" aria-controls="tabelaLogs" v-model="paginaAtual" />
			</div>
			<!-- #endregion -->
		</div>
		<!-- #endregion -->
  
		<!-- #region modais -->
		<b-modal id="modalDetalhes"	title="Log detalhado" size="lg">
			<!-- #region dados -->
			<div v-if="logDetalhado" class="h5 modal-compactado">
				<div>Data: {{ $vsFormataData(logDetalhado.dataCriacao, true) }}</div>
				<div>Tipo: {{ descricaoTipo(logDetalhado.tipo) }}</div>
				<div class="mb-4">Escopo/Cenário: {{ descricaoEscopo(logDetalhado.escopo) }}</div>
				<div>Contratante: {{ nomeContratante(logDetalhado.idContratante) }}</div>
				<div>Filial: {{ nomeFilial(logDetalhado.idFilial) }}</div>
				<div class="mb-4">Transportadora: {{ nomeTransportadora(logDetalhado.idTransportadora) }}</div>
				<div>Login usuário: {{ logDetalhado.loginUsuario }}</div>
				<div>IP usuário: {{ logDetalhado.ipUsuario }}</div>
				<div>Status HTTP: {{ logDetalhado.statusHttp }}</div>
				<div>Descrição: {{ logDetalhado.descricao }}</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">Observação: {{ logDetalhado.observacao }}</div>
				<div>
					Dados internos:
					<a class="text-secondary" @click.prevent="copiaClipboard(logDetalhado.dadosInternos)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
						<b-icon icon="clipboard" />
					</a>
				</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">{{ logDetalhado.dadosInternos }}</div>
				<div>
					Dados enviados:
					<a class="text-secondary" @click.prevent="copiaClipboard(logDetalhado.dadosEnviados)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
						<b-icon icon="clipboard" />
					</a>
				</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">{{ logDetalhado.dadosEnviados }}</div>
				<div>
					Dados Recebidos:
					<a class="text-secondary" @click.prevent="copiaClipboard(logDetalhado.dadosRecebidos)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
						<b-icon icon="clipboard" />
					</a>
				</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">{{ logDetalhado.dadosRecebidos }}</div>
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="float-right">
					<b-button type="button" variant="secondary" @click="hide()">
						<span class="font-weight-bold">Fechar</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->
	</div>
</template>

<script>
export default {
	// #region configs
  name: "logs",
  path: "/logs",
	requireAuth: true,
	// #endregion

	// #region dados
	data() {
		return {
			OPT_TODOS: "0",
			MAX_CHARS: 50,
			formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
			dataMinima: new Date(new Date() - 15 * 86400000).toISOString().substring(0, 10), // d-15
			filtro: {
				dataInicial: new Date().toISOString().substring(0, 10), // hoje
				dataFinal: new Date().toISOString().substring(0, 10), // hoje
				tipo: 0,
				escopo: 0,
				idContratante: null,
				idFilial: null,
				idTransportadora: null,
				loginUsuario: "",
				ipUsuario: "",
				statusHTTP: 0,
			},
			camposLogs: [
				{ key: "dataCriacao", label: "Data", sortable: true,  },
				{ key: "tipo", label: "Tipo", sortable: true,  },
				{ key: "escopo", label: "Escopo/Cenário", sortable: true,  },
				{ key: "idContratante", label: "Contratante", sortable: true,  },
				{ key: "idFilial", label: "Filial", sortable: true,  },
				{ key: "idTransportadora", label: "Transportadora", sortable: true,  },
				{ key: "loginUsuario", label: "Login usuário", sortable: true,  },
				{ key: "ipUsuario", label: "IP usuário", sortable: true,  },
				{ key: "statusHttp", label: "Status HTTP", sortable: true, tdClass: "text-right" },
				{ key: "descricao", label: "Descrição", sortable: true,  },
				{ key: "observacao", label: "Observação", sortable: true,  },
				{ key: "dadosInternos", label: "Dados Internos", sortable: true,  },
				{ key: "dadosEnviados", label: "Dados Enviados", sortable: true,  },
				{ key: "dadosRecebidos", label: "Dados Recebidos", sortable: true,  },
			],
			logDetalhado: null,
			mostraLogs: false,
			tabelaLogs: [],
			comboTipo: [],
			comboEscopo: [],
			comboContratante: [],
			comboFilial: [],
			comboTransportadora: [],
			paginaAtual: 1,
			totalPagina: 100,
			comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
		}
	},
	// #endregion

	// #region triggers
	created() {
		this.$vsDefineAcesso([
			this.VS_PERFIL_SUPERADMIN,
			this.VS_PERFIL_SUPORTE, 
			this.VS_PERFIL_COMERCIAL,
		])
	},
	mounted() {
		this.bootstrap()
	},
	// #endregion

	// #region metodos
	methods: {
		async bootstrap() {
			await this.defineComboTipo()
			await this.defineComboEscopo()
			await this.defineComboContratante()
			await this.defineComboFilial()
			await this.defineComboTransportadora()
			await this.listaLogs()
		},
		mascaraInteiro(evt) {
			let valor = evt.target.value
			let sinal = valor.substring(0, 1)

			valor = this.$vsLimpaFormatacao(valor)

			if (sinal === "-") {
				valor = `${sinal}${valor}`
			}
      evt.target.value = valor
    },
		async defineComboTipo() {
			let resp = await this.$vsApiRest("GET", "/logs/tipos")
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.codigo
					el.text = el.descricao
				})
        this.comboTipo = resp.data
				this.filtro.tipo = 3 // erro
      }
		},
		async defineComboEscopo() {
			this.filtro.escopo = this.OPT_TODOS

			let resp = await this.$vsApiRest("GET", "/logs/escopos")
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.codigo
					el.text = el.descricao
				})
        this.comboEscopo = resp.data
				this.comboEscopo.unshift({ value: this.OPT_TODOS, text: "Todos" })
      }
		},
		async defineComboContratante() {
			let query = "ativo=true&excluido=false"

			this.filtro.idContratante = this.OPT_TODOS

			let resp = await this.$vsApiRest("GET", `/usuarios/contratantes?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.id
					el.text = el.nome
				})
        this.comboContratante = resp.data
				this.comboContratante.unshift({ value: this.OPT_TODOS, text: "Todas" })
      }
		},
	 	async defineComboFilial() {
			let filtro = this.filtro
			let query = "ativo=true&excluido=false"

			filtro.idFilial = this.OPT_TODOS

			if (filtro.idContratante != null && filtro.idContratante !== this.OPT_TODOS) {
				query += `&idContratante=${filtro.idContratante}`
			}

			let resp = await this.$vsApiRest("GET", `/usuarios/filiais?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.id
					el.text = el.nome
				})
        this.comboFilial = resp.data
				this.comboFilial.unshift({ value: this.OPT_TODOS, text: "Todas" })
      }
		},
		async defineComboTransportadora() {
			this.filtro.idTransportadora = this.OPT_TODOS

			let resp = await this.$vsApiRest("GET", "/transportadoras")
       
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.id
					el.text = el.nomeFantasia
				})
        this.comboTransportadora = resp.data
				this.comboTransportadora.unshift({ value: this.OPT_TODOS, text: "Todas" })
      }
		},
		async listaLogs() {
			let filtro = this.filtro
			let query = ""
			let temDataInicial = (filtro.dataInicial && filtro.dataInicial.length > 0)
			let temDataFinal = (filtro.dataFinal && filtro.dataFinal.length > 0)

			if (!temDataInicial || !temDataFinal) {
				this.$vsNotificacao("Necessário as duas datas", "warning")
				return // aborta 
			}
			query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
			query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

			if (filtro.tipo != this.OPT_TODOS) {
				query += `&tipo=${filtro.tipo}`
			}
			if (filtro.escopo != 0) {
				query += `&escopo=${filtro.escopo}`
			}
			if (filtro.idContratante !== this.OPT_TODOS) {
				query += `&idContratante=${filtro.idContratante}`
			}
			if (filtro.idFilial !== this.OPT_TODOS) {
				query += `&idFilial=${filtro.idFilial}`
			}
			if (filtro.idTransportadora !== this.OPT_TODOS) {
				query += `&idTransportadora=${filtro.idTransportadora}`
			}
			if (filtro.statusHTTP != 0) {
				query += `&statusHttp=${filtro.statusHTTP}`
			}
			if (filtro.loginUsuario.trim().length) {
				query += `&loginUsuario=${filtro.loginUsuario.trim()}`
			}
			if (filtro.ipUsuario.trim().length) {
				query += `&ipUsuario=${filtro.ipUsuario.trim()}`
			}

			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/logs?${query}`)
      
			if (resp.status === 200) {
        this.tabelaLogs = resp.data
				this.mostraLogs = (resp.data.length > 0)
      }
		},
		limpaTela() {
			this.mostraLogs = false
			this.tabelaLogs = []
		},
		descricaoTipo(tipo) {
			return this.comboTipo.find(el => el.codigo == tipo).text
		},
		descricaoEscopo(escopo) {
			return this.comboEscopo.find(el => el.codigo == escopo).text
		},
		nomeContratante(id) {
			let obj = this.comboContratante.find(el => el.value == id)
			return obj ? obj.text : ""
		},
		nomeFilial(id) {
			let obj = this.comboFilial.find(el => el.value == id)
			return obj ? obj.text : ""
		},
		nomeTransportadora(id) {
			let obj = this.comboTransportadora.find(el => el.value == id)
			return obj ? obj.text : ""
		},
		logDetalhe(obj) {
			this.logDetalhado = obj
			this.$bvModal.show("modalDetalhes")
		},
		copiaClipboard(texto) {
			navigator.clipboard.writeText(texto)
			this.$vsNotificacao("Dados copiados para a área de transferência (clipboard)", "success")
		},
	},
	// #endregion
}
</script>
