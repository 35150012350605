<template>
  <div>
		<div class="titulo">
			<h1>Meus Vínculos</h1>
			<h4>Gestão de Ponto(s) de Atendimento Vinculado(s)</h4>
		</div>

    <div class="h5">
      <b-form-group class="form-input-cinza" label="Ponto(s) de atendimento vinculado(s)" label-cols="12" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group v-model="vinculos" size="lg" :aria-describedby="ariaDescribedby" stacked switches style="column-count: 2;">
          <b-form-checkbox name="vinculo" v-for="(fil, i) in filiais" :key="i" :value="fil.id" @change="manipulaVinculo(fil.id)" class="pr-4 mb-3">
            <span style="cursor: pointer">{{ fil.nome }}</span>
						<b-form-radio v-if="liberaDefinicaoPadrao(fil)" v-model="vinculoPadrao" :value="fil.id" name="vinculoPadrao" @change="defineVinculoPadrao(fil.id)" class="pr-5" :checked="fil.isPadrao">
							<span style="cursor: pointer">Definir como padrão para pré postagem</span>
						</b-form-radio>
          </b-form-checkbox>
        </b-form-checkbox-group>
      </b-form-group>
		</div>
  </div>
</template>

<script>
export default {
  // #region configs
  name: "vinculos",
  path: "/vinculos",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
      filiais: [],
			vinculos: [],
			vinculoPadrao: null,
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([
			this.VS_PERFIL_CLIENTE,
		])
  },
	mounted() {
		this.listaFiliais()
	},
  // #endregion

  // #region metodos
  methods: {
		async listaFiliais() {
			let sessao = this.$root.sessao
			let query = `idPagador=${sessao.id}&ativo=true`
			let resp = await this.$vsApiRest("GET", `/usuarios/filiais/?${query}`)
        
			if (resp.status === 200) {
				this.filiais = resp.data
				this.defineVinculos()
      }
		},
		defineVinculos() {
			let sessao = this.$root.sessao
			let idsFiliais = this.filiais.map((el) => { return el.id })
			let vinculosAtivos = sessao.vinculos.filter(el => el.isAtivo && idsFiliais.includes(el.idUsuario))
			let padrao = vinculosAtivos.find(el => el.isPadrao)

			this.vinculos = vinculosAtivos.map((el) => { return el.idUsuario })

			if (padrao) {
				this.vinculoPadrao = padrao.idUsuario
			} else if (this.vinculos.length) {
				this.defineVinculoPadrao(this.vinculos[0])
			}
		},
		manipulaVinculo(idVinculo) {
			if (this.vinculos.includes(idVinculo)) {
				this.adicionaVinculo(idVinculo)
			} else {
				this.removeVinculo(idVinculo)
			}
		},
		async adicionaVinculo(idVinculo) {
			let sessao = this.$root.sessao

			if (!this.vinculoPadrao && this.vinculos.length === 1) {
				this.vinculoPadrao = this.vinculos[0]
			}

			let padrao = (this.vinculoPadrao === this.vinculos[0])
      let req = {
         idUsuario: sessao.id,
         vinculo: {
					idUsuario: idVinculo,
					isSuperior: true,
					isAtivo: true,
					isPadrao: padrao,
				},
      }
      let resp = await this.$vsApiRest("PATCH", `/usuarios/vinculos/adicao`, req)
      
			if (resp.status === 200) {
        let filial = this.filiais.find(el => el.id == idVinculo)
				
				this.$vsNotificacao(`Vínculo com ${filial.nome} adicionado com sucesso`, "success")
				this.$vsDefineVinculoUsuario(sessao, idVinculo, false, padrao)
      }
    },
		async removeVinculo(idVinculo) {
			let sessao = this.$root.sessao
      let req = {
          idUsuario: sessao.id,
          idVinculo: idVinculo,
      }
      let resp = await this.$vsApiRest("PATCH", `/usuarios/vinculos/exclusao`, req)
      
			if (resp.status === 200) {
        let filial = this.filiais.find(el => el.id == idVinculo)

				if (this.vinculoPadrao === idVinculo) {
					this.vinculoPadrao = null
				}
				this.$vsNotificacao(`Vínculo com ${filial.nome} removido com sucesso`, "warning")
				this.$vsDefineVinculoUsuario(sessao, idVinculo, true)
      }
    },
		async defineVinculoPadrao(idVinculo) {
			let sessao = this.$root.sessao
      let req = {
         idUsuario: sessao.id,
				 idVinculo: idVinculo,
      }
      let resp = await this.$vsApiRest("PATCH", `/usuarios/vinculos/padrao`, req)
      
			if (resp.status === 200) {
        let filial = this.filiais.find(el => el.id == idVinculo)
				
				this.$vsNotificacao(`Vínculo com ${filial.nome} definido como padrão`, "info")
				this.$vsDefineVinculoUsuario(sessao, idVinculo, false, true)
				this.vinculoPadrao = idVinculo
      }
		},
		liberaDefinicaoPadrao(obj) {
			return this.vinculos.includes(obj.id)
		}
  }
  // #endregion
}
</script>
