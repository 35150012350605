<template>
  <b-container id="app" fluid>
    <div id="impressaoHtml" class="d-none d-print-block"></div>
    <div class="d-print-none">
			<!-- #region cabecalho -->
      <vs-cabecalho v-if="$root.sessao" class="header-logado" />
			<!-- #endregion -->

			<!-- #region Corpo -->
			<div class="row flex-nowrap">
				<!-- #region menu -->
				<div :class="`col-auto p-0 pt-2 bg-white shadow ${defineClasseMenu()}`">
					<transition name="slide">
						<vs-menu v-if="$root.sessao && $root.mostraMenu" />
					</transition>
				</div>
				<!-- #endregion -->

				<!-- #region conteudo -->
				<div :class="`col ${defineClasseConteudo()}`" @click="$vsEscondeMenu()">
					<b-overlay :show="$root.loading">
						<!-- #region loading -->
						<div v-if="$root.loading" class="text-center w-100 position-fixed pt-4" style="z-index: 20;">
							<div class="mb-3">
								<b-iconstack animation="cylon-vertical">
									<b-icon icon="box" animation="spin" />
									<b-icon icon="truck" animation="" scale="4" shift-h="5"/>
								</b-iconstack>
							</div>
							<p class="font-weight-bold">
								Carregando <b-icon icon="three-dots" animation="fade" scale="1.6"/>
							</p>
						</div>
						<!-- #endregion -->

						<!-- #region ponto central (todas as rotas do sistema rodam aqui!!!) -->
						<transition name="fade" mode="out-in">
							<router-view/><!-- rota atual -->
						</transition>
						<!-- #endregion -->

						<template #overlay>
							<div></div>
						</template>
					</b-overlay>
				</div>
				<!-- #endregion -->
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
			<div v-if="!$vsAmbienteProducao()" class="row position-fixed w-100" style="bottom: 0; z-index: 10;">
				<!-- #region aviso de validacoes (nao exibe em producao) -->
				<transition name="fade" mode="out-in">
					<div v-if="isAguardandoValidacao()" class="col-12 p-0 aguardando-validacao-exemplo">Esta página contém <span class="text-danger">{{ this.itensAguardandoValidacao }}</span> elementos com esta borda aguardando validadações ou ajustes ainda não publicados em produção...</div>
				</transition>
				<!-- #endregion -->

				<!-- #region identificacao visual de ambiente (nao exibe em producao) -->
				<div class="col-12 p-0 text-center">
					<div v-if="$vsAmbienteQA()" class="font-weight-bold bg-danger text-white pb-2">
						Ambiente de produção QA, proibido errar aqui <span class="h3">&#128286;</span>
					</div>
					<div v-if="$vsAmbienteHomologacao()" class="font-weight-bold bg-warning pb-2">
						Ambiente de homologação, não pode fazer <span class="h3">&#128169;</span>
					</div>
					<div v-if="$vsAmbienteTeste()" class="font-weight-bold bg-info text-white pb-2">
						Ambiente de desenvolvimento e testes, vamos brincar <span class="h4">&#128520;</span>
						<span v-if="$root.baseProducaoSimulada">(usando base de dados simulada da produção)</span>
					</div>
					<div v-if="$vsAmbienteDesenvolvimento()" class="font-weight-bold bg-dark text-white pb-2">
						Ambiente de desenvolvimento localhost, aqui é <span class="h4">&#128299;</span> <span class="h4">&#128074;</span> e <span class="h4">&#128163;</span>
						<span v-if="$root.baseProducaoSimulada">(simulando base de dados da produção)</span>
					</div>
				</div>
				<!-- #endregion -->
			</div>
			<!-- #endregion -->
    </div>
  </b-container>
</template>

<script>
import VsCabecalho from "./components/templates/vs-cabecalho.vue"
import VsMenu from "./components/templates/vs-menu.vue"

export default {
	// #region configs
  components: { VsCabecalho, VsMenu },
	// #endregion

	// #region 
	data() {
		return {
			itensAguardandoValidacao: 0,
		}
	},
	// #endregion

	// #region metodos
	methods: {
		defineClasseMenu() {
			return this.$root.sessao 
				? this.$vsAmbienteProducao() ? 'menu-logado' : 'menu-logado-dev'
				: ''
		},
		defineClasseConteudo() {
			let producao = this.$vsAmbienteProducao()
			return (this.$root.sessao) 
				? (producao) ? 'conteudo-logado' : 'conteudo-logado-dev'
				: (producao) ? 'conteudo-deslogado' : 'conteudo-deslogado-dev'
		},
		isAguardandoValidacao() {
			this.itensAguardandoValidacao = document.querySelectorAll(".aguardando-validacao").length
			return (!this.$vsAmbienteProducao() && this.itensAguardandoValidacao > 0)
		},
	}
	// #endregion
}
</script>