<template>
  <div>
		<div class="titulo">
			<h1>Endereços</h1>
			<h4>Busca de endereços por cep ou logradouro</h4>
		</div>

    <!-- #region busca -->
    <b-form @submit.prevent="buscar()">
      <!-- #region filtro -->
      <b-form-row>
        <b-col md="2">
          <b-form-group class="form-input-cinza" label="Cep" label-size="lg" label-cols="12">
            <b-form-input type="text" v-model="filtro.cep" @keyup="mascaraCep()" maxlength="9" autocomplete="on" />
          </b-form-group>
        </b-col>

        <b-col md="4">
          <b-form-group class="form-input-cinza" label="Logradouro" label-size="lg" label-cols="12">
            <b-form-input type="text" v-model="filtro.logradouro" autocomplete="on" />
          </b-form-group>
        </b-col>

        <b-col md="3">
          <b-form-group class="form-input-cinza" label="Cidade" label-size="lg" label-cols="12">
            <b-form-input type="text" v-model="filtro.cidade" autocomplete="on" />
          </b-form-group>
        </b-col>

        <b-col md="1">
          <b-form-group class="form-input-cinza" label="UF" label-size="lg" label-cols="12">
            <b-form-input type="text" v-model="filtro.uf" maxlength="2" autocomplete="on" />
          </b-form-group>
        </b-col>

        <b-col md="2">
          <b-form-group class="text-white" label="." label-cols="12" label-size="lg">
            <b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
              <b-icon icon="search" scale="1" />
            </b-button>
          </b-form-group>
        </b-col>
      </b-form-row>
      <!-- #endregion -->

      <!-- #region listagem/exibicao -->
      <div v-if="logradouroServicos.length">
        <b-table 
				  class="text-nowrap h5" 
					id="tabelaLogradouro" 
					:items="logradouroServicos"
          :fields="logradouroCabecalho" 
					:per-page="totalPagina" 
					:current-page="paginaAtual"
					small 
					responsive 
					hover>
          <!--===================================================================================
            celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

            obj.index = indice da linha
            obj.field = nome do campo/coluna/chave json
            obj.item  = objeto json bruto
            obj.value = o valor da chave json com tipo original mantido e sem tratamento

            para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
          ====================================================================================-->
          <!--<template #cell()="obj">
            {{ obj.field }}
            {{ obj.item }}
            {{ obj.value }}
          </template>-->
        </b-table>

        <div class="h5">
          <span style="padding-left: 5px;padding-right: 20px;">
            <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
          </span>
          <span>Total de Registros: {{ $vsFormataNumero(logradouroServicos.length) }}</span>
          <b-pagination 
						class="mt-3 d-flex justify-content-center" 
						:total-rows="logradouroServicos.length"
            :per-page="totalPagina" 
						aria-controls="tabelaLogradouro" 
						v-model="paginaAtual" />
        </div>
      </div>
      <!-- #endregion -->
    </b-form>
    <!-- #endregion -->
  </div>
</template>

<script>
export default {
  // #region configs
  name: "endereco",
  path: "/endereco",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
      filtro: {
        logradouro: "",
        cidade: "",
        uf: "",
        cep: "",
      },
      cepServicos: {
        logradouro: "",
        bairro: "",
        cidade: "",
        uf: "",
        cep: "",
        carregou: false,
      },
      logradouroServicos: [],
      logradouroCabecalho: [
        { key: "cep", sortable: true, label: "Cep", tdClass: "text-left", },
        { key: "logradouro", sortable: true, label: "Endereço", tdClass: "text-left", },
        { key: "bairro", sortable: true, label: "Bairro", tdClass: "text-left", },
        { key: "cidade", sortable: true, label: "Cidade", tdClass: "text-left", },
        { key: "uf", sortable: true, label: "UF", tdClass: "text-left", },
        { key: "complemento", sortable: true, label: "Complemento", tdClass: "text-left", },
      ],
      paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region metodos
  methods: {
    mascaraCep() {
      this.filtro.cep = this.$vsFormataCep(this.filtro.cep)
    },
    async consultaCep() {
      let cep = this.$vsLimpaFormatacao(this.filtro.cep)
      let obj = this.cepServicos

      obj.carregou = false
			this.logradouroServicos = []

      let resp = await this.$vsApiRest("GET", `/tarifacao?cep=${cep}`)

      if (resp.status === 200) {
				let log = resp.data

				if (!log.vazio) {
					this.logradouroServicos.push({
						cep: this.$vsFormataCep(cep),
						logradouro: log.logradouro,
						bairro: log.bairro,
						cidade: log.cidade,
						uf: log.uf,
						complemento: log.logradouro.length === 0 && log.bairro.length === 0 ? "CEP ÚNICO" : ""
					})
				}
      }
    },
    async consultaLogradouros() {
      this.logradouroServicos = []
      let log = this.filtro
      let ok = true

      if (!log.logradouro || log.logradouro.trim().length < 3) {
        this.$vsNotificacao("Informe o logradouro (mínimo 3 letras)", "warning")
        ok = false
      }
      if (!log.cidade || log.cidade.trim().length < 3) {
        this.$vsNotificacao("Informe a cidade (mínimo 3 letras)", "warning")
        ok = false
      }
      if (!log.uf || log.uf.trim().length < 2) {
        this.$vsNotificacao("Informe a UF", "warning")
        ok = false
      }
      if (!ok) {
        return
      }

      let query = `logradouro=${log.logradouro}&cidade=${log.cidade}&uf=${log.uf}`
      let resp = await this.$vsApiRest("GET", `/tarifacao/logradouros?${query}`)

      if (resp.status === 200) {
        this.logradouroServicos = resp.data
      }
    },
    buscar() {
      let cep = this.$vsLimpaFormatacao(this.filtro.cep)

      if (cep.length === 8) {
        this.consultaCep()
      }
      else {
        this.consultaLogradouros()
      }
    }
  },
  // #endregion
}
</script>