<template>
  <div>
		<div class="titulo">
    	<h1>Caixa</h1>
    	<h4>Controle da movimentação dos caixas</h4>
		</div>

    <!-- #region tela gerente -->
    <b-tabs v-if="$root.sessao.perfil === VS_PERFIL_GERENTE" content-class="pt-4 px-2">
      <!-- #region usuario logado -->
      <b-tab title="Minhas movimentações" active title-item-class="h5">
        <!-- impressao caixa fechado (suspenso, logica atual na funcao imprimeCaixaFechado(id)) -->
        <!--<div class="d-none">
          <vs-relatorio-caixa 
            id="impressaoCaixaFechado"
            :filial="$root.sessao.filial"
            :caixa="caixaFechado"
            :operador="$root.sessao"/>
        </div>-->

        <!-- #region opcoes -->
        <b-form-group label="Fluxo de caixa:" label-size="lg" class="mb-4">
          <b-form-row>
            <b-col md="2">
              <button v-if="caixa.bloqueiaAbertura" type="button" class="btn-desabilitado" disabled>Abertura</button>
              <button v-else type="button" class="btn-azul" @click="mostraModal('modalAbreCaixa')">Abertura</button>

              <!-- #region modais -->
              <b-modal id="modalAbreCaixa" title="Abertura de caixa">
                <div class="h5">
                  <div>
                    <span>Saldo atual</span>
                    <span class="float-right">
                      <b-form-input class="text-right" type="text" v-model="caixa.saldoAbertura"
                        @keyup="mascaraMoeda" />
                    </span>
                  </div>
                </div>
                <template #modal-footer="{ hide }">
                  <div class="w-100">
                    <b-button type="button" class="float-right ml-2" variant="info"
                      @click="abreCaixa('modalAbreCaixa')">Confirmar</b-button>
                    <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                  </div>
                </template>
              </b-modal>
              <!-- #endregion -->
            </b-col>
            <b-col md="2">
              <button v-if="caixa.bloqueiaReforco" type="button" class="btn-desabilitado" disabled>Reforço</button>
              <button v-else type="button" class="btn-verde" @click="mostraModal('modalReforcaCaixa')">Reforço</button>

              <!-- #region modais -->
              <b-modal id="modalReforcaCaixa" title="Reforço de caixa">
                <div class="h5">
                  <!-- #region dados basicos -->
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-input class="text-right" type="text" v-model="caixa.saldoReforco" @keyup="mascaraMoeda" />
                    </span>
                    <span>Valor de reforço</span>
                  </div>
                  <!-- #endregion -->

                  <!-- #region autenticacao -->
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-input type="password" v-model="caixa.senha" />
                    </span>
                    <span>Senha</span>
                  </div>
                  <!-- #endregion -->
                </div>
                <template #modal-footer="{ hide }">
                  <div class="w-100">
                    <b-button type="button" class="float-right ml-2" variant="info"
                      @click="reforcaCaixa('modalReforcaCaixa')">Confirmar</b-button>
                    <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                  </div>
                </template>
              </b-modal>
              <!-- #endregion -->
            </b-col>
            <b-col md="2">
              <button v-if="caixa.bloqueiaSangria" type="button" class="btn-desabilitado" disabled>Sangria</button>
              <button v-else type="button" class="btn-laranja"
                @click="mostraModal('modalSangriaCaixa')">Sangria</button>

              <!-- #region modais -->
              <b-modal id="modalSangriaCaixa" title="Sangria de caixa">
                <div class="h5">
                  <!-- #region dados basicos -->
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-input class="text-right" type="text" v-model="caixa.saldoSangria" @keyup="mascaraMoeda" />
                    </span>
                    <span>Valor de sangria</span>
                  </div>
                  <!-- #endregion -->

                  <!-- #region autenticacao -->
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-input type="password" v-model="caixa.senha" />
                    </span>
                    <span>Senha</span>
                  </div>
                  <!-- #endregion -->
                </div>
                <template #modal-footer="{ hide }">
                  <div class="w-100">
                    <b-button type="button" class="float-right ml-2" variant="info"
                      @click="sangriaCaixa('modalSangriaCaixa')">Confirmar</b-button>
                    <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                  </div>
                </template>
              </b-modal>
              <!-- #endregion -->
            </b-col>
            <b-col md="2">
              <button v-if="caixa.bloqueiaFechamento" type="button" class="btn-desabilitado"
                disabled>Fechamento</button>
              <button v-else type="button" class="btn-vermelho"
                @click="mostraModal('modalFechaCaixa')">Fechamento</button>

              <!-- #region modais -->
              <b-modal id="modalFechaCaixa" title="Fechamento de caixa">
                <div class="h5">
                  <!-- #region dados basicos -->
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-datepicker v-model="caixa.dataFechamento" locale="pt-BR" placeholder="dd/mm/aaaa"
                        :date-format-options="formatoData" :max="new Date().toISOString().slice(0, 10)" />
                    </span>
                    <span>Data</span>
                  </div>
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-timepicker v-model="caixa.horaFechamento" locale="pt-BR" placeholder="hh:mm" />
                    </span>
                    <span>Hora</span>
                  </div>
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-input class="text-right" type="text" v-model="caixa.saldoFechamento"
                        @keyup="mascaraMoeda" />
                    </span>
                    <span>Saldo atual</span>
                  </div>
                  <!-- #endregion -->

                  <!-- #region autenticacao -->
                  <div class="mb-4">
                    <span class="float-right">
                      <b-form-input type="password" v-model="caixa.senha" />
                    </span>
                    <span>Senha</span>
                  </div>
                  <!-- #endregion -->
                </div>
                <template #modal-footer="{ hide }">
                  <div class="w-100">
                    <b-button type="button" class="float-right ml-2" variant="info"
                      @click="fechaCaixa('modalFechaCaixa')">Confirmar</b-button>
                    <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                  </div>
                </template>
              </b-modal>
              <!-- #endregion -->
            </b-col>
          </b-form-row>
        </b-form-group>
        <!-- #endregion -->

        <!-- #region filtro -->
        <b-form-group label="Movimentações:" label-size="lg">
          <b-form-row>
            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
                <b-form-datepicker v-model="filtro.movimentacoes.dataInicial" locale="pt-BR" placeholder="dd/mm/aaaa"
                  :date-format-options="formatoData" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
                <b-form-datepicker v-model="filtro.movimentacoes.dataFinal" locale="pt-BR" placeholder="dd/mm/aaaa"
                  :date-format-options="formatoData" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group class="text-white" label="." label-cols="12" label-size="lg">
                <b-button variant="primary" v-b-tooltip.hover title="Pesquisar" @click.prevent="listaMovimentacoes()">
                  <b-icon icon="search" scale="1" />
                </b-button>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form-group>
        <!-- #endregion -->

        <!-- #region listagem/edicao -->
				<div v-if="cadastros.length">
					<b-table 
						class="text-nowrap h5" 
						id="tabelaMovimentacoes"
						:items="cadastros" 
						:fields="campos" 
						:per-page="totalPagina" 
						:current-page="paginaAtual"
						striped 
						hover 
						responsive>
						<!--===================================================================================
							celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

							obj.index = indice da linha
							obj.field = nome do campo/coluna/chave json
							obj.item  = objeto json bruto
							obj.value = o valor da chave json com tipo original mantido e sem tratamento

							para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
						====================================================================================-->
						<!--<template #cell()="obj">
							{{ obj.field }}
							{{ obj.item }}
							{{ obj.value }}
						</template>-->

						<template #cell(dataAbertura)="obj">
							<a class="text-info" @click.prevent="imprimeCaixa(obj.item)" v-b-tooltip.hover title="Imprimir">
								<b-icon icon="printer" />&nbsp;{{ $vsFormataData(obj.value, true) }}
							</a>

							<!-- impressao -->
							<div class="d-none">
								<vs-relatorio-caixa :id="`impressaoCaixa${obj.item.id}`" :filial="$root.sessao.filial" :caixa="obj.item"
									:operador="$root.sessao" />
							</div>
						</template>

						<template #cell(saldoAbertura)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(movimentacoes)="obj">
							<a v-if="obj.value.length" v-b-toggle="`movimentacoes${obj.item.id}`" @click.prevent>
								<span class="cor-azul" v-b-tooltip.hover title="Visualizar/esconder">
									<b-icon icon="three-dots" scale="2" />
								</span>
							</a>
							<b-collapse :id="`movimentacoes${obj.item.id}`">
								<div v-for="(mov, i) in obj.value" :key="i" class="text-nowrap mb-5">
									<span :class="defineCorMovimentacao(mov)">{{ mov.descricao }}</span>&nbsp;
									<span>{{ $vsFormataData(mov.dataCriacao, true) }}</span>
									<div class="mt-2">
										<span class="float-right">{{ $vsFormataMoeda(mov.saldo) }}</span>
									</div>
								</div>
							</b-collapse>
						</template>

						<template #cell(saldoPostagens)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(saldoFechamento)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(dataFechamento)="obj">
							{{ $vsFormataData(obj.value, true) }}
						</template>
					</b-table>

					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
						</span>
						<span>Total de Registros: {{ $vsFormataNumero(cadastros.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="cadastros.length"
							:per-page="totalPagina" aria-controls="tabelaMovimentacoes" v-model="paginaAtual" />
					</div>
				</div>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->

      <!-- #region equipe -->
      <b-tab title="Movimentações da equipe" title-item-class="h5" @click="listaMovimentacoesEquipe()">
        <!-- #region filtro -->
        <b-form-group label="Movimentações:" label-size="lg">
          <b-form-row>
            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
                <b-form-datepicker v-model="filtro.movimentacoesEquipe.dataInicial" locale="pt-BR"
                  placeholder="dd/mm/aaaa" :date-format-options="formatoData" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
                <b-form-datepicker v-model="filtro.movimentacoesEquipe.dataFinal" locale="pt-BR"
                  placeholder="dd/mm/aaaa" :date-format-options="formatoData" />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group class="text-white" label="." label-cols="12" label-size="lg">
                <b-button variant="primary" v-b-tooltip.hover title="Pesquisar"
                  @click.prevent="listaMovimentacoesEquipe()">
                  <b-icon icon="search" scale="1" />
                </b-button>
              </b-form-group>
            </b-col>
          </b-form-row>
        </b-form-group>
        <!-- #endregion -->

        <!-- #region listagem/edicao -->
				<div v-if="cadastrosEquipe.length">
					<b-table 
						class="text-nowrap h5" 
						id="tabelaMovimentacoesEquipe" 
						:items="cadastrosEquipe" 
						:fields="camposEquipe" 
						:per-page="totalPaginaEquipe"
						:current-page="paginaAtualEquipe"
						striped 
						hover 
						responsive>
						<!--===================================================================================
							celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

							obj.index = indice da linha
							obj.field = nome do campo/coluna/chave json
							obj.item  = objeto json bruto
							obj.value = o valor da chave json com tipo original mantido e sem tratamento

							para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
						====================================================================================-->
						<!--<template #cell()="obj">
							{{ obj.field }}
							{{ obj.item }}
							{{ obj.value }}
						</template>-->

						<template #cell(caixa.dataAbertura)="obj">
							<a class="text-info" @click.prevent="imprimeCaixa(obj.item.caixa)" v-b-tooltip.hover title="Imprimir">
								<b-icon icon="printer" />&nbsp;{{ $vsFormataData(obj.value, true) }}
							</a>

							<!-- impressao -->
							<div class="d-none">
								<vs-relatorio-caixa :id="`impressaoCaixa${obj.item.caixa.id}`" :filial="$root.sessao.filial"
									:caixa="obj.item.caixa" :operador="obj.item.operador" />
							</div>
						</template>

						<template #cell(caixa.saldoAbertura)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(caixa.movimentacoes)="obj">
							<a v-if="obj.value.length" v-b-toggle="`movimentacoes${obj.item.caixa.id}`" @click.prevent>
								<span class="cor-azul" v-b-tooltip.hover title="Visualizar/esconder">
									<b-icon icon="three-dots" scale="2" />
								</span>
							</a>
							<b-collapse :id="`movimentacoes${obj.item.caixa.id}`">
								<div v-for="(mov, i) in obj.value" :key="i" class="text-nowrap mb-5">
									<span :class="defineCorMovimentacao(mov)">
										<span v-if="mov.correcao" v-b-tooltip.hover title="Correção feita pelo gerente">
											<b-icon scale="0.85" icon="exclamation-triangle" animation="throb" />&nbsp;{{ mov.descricao }}
										</span>
										<span v-else><span class="font-weight-bold">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>{{
											mov.descricao
										}}&nbsp;</span>
									</span>&nbsp;
									<span>{{ $vsFormataData(mov.dataCriacao, true) }}</span>
									<div class="mt-2">
										<span class="float-right">{{ $vsFormataMoeda(mov.saldo) }}</span>
									</div>
								</div>
							</b-collapse>
						</template>

						<template #cell(caixa.saldoPostagens)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(caixa.saldoFechamento)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(caixa.dataFechamento)="obj">
							{{ $vsFormataData(obj.value, true) }}
						</template>

						<template #cell(opcoes)="obj">
							<div class="pr-5">
								<button type="button" class="btn-verde" style="width: 60%; height: 40px;"
									@click="mostraModal('modalReforcaCaixaEquipe', obj.item.caixa.id)">Reforço</button>
								<button type="button" class="btn-laranja" style="width: 60%; height: 40px;"
									@click="mostraModal('modalSangriaCaixaEquipe', obj.item.caixa.id)">Sangria</button>
							</div>
						</template>
					</b-table>

					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" v-model="totalPaginaEquipe" :options="comboPaginacao"
								style="width: 100px;" />
						</span>
						<span>Total de Registros: {{ $vsFormataNumero(cadastrosEquipe.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="cadastrosEquipe.length"
							:per-page="totalPaginaEquipe" aria-controls="tabelaMovimentacoesEquipe" v-model="paginaAtualEquipe" />
					</div>
				</div>
        <!-- #endregion -->

        <!-- #region modais -->
        <b-modal id="modalReforcaCaixaEquipe" title="Reforço de caixa (correção)">
          <div class="h5">
            <!-- #region dados basicos -->
            <div class="mb-4">
              <span class="float-right">
                <b-form-input class="text-right" type="text" v-model="caixa.saldoReforco" @keyup="mascaraMoeda" />
              </span>
              <span>Valor de reforço</span>
            </div>
            <!-- #endregion -->

            <!-- #region autenticacao -->
            <div class="mb-4">
              <span class="float-right">
                <b-form-input type="password" v-model="caixa.senha" />
              </span>
              <span>Senha</span>
            </div>
            <!-- #endregion -->
          </div>
          <template #modal-footer="{ hide }">
            <div class="w-100">
              <b-button type="button" class="float-right ml-2" variant="info"
                @click="reforcaCaixa('modalReforcaCaixaEquipe', true)">Confirmar</b-button>
              <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
            </div>
          </template>
        </b-modal>

        <b-modal id="modalSangriaCaixaEquipe" title="Sangria de caixa (correção)">
          <div class="h5">
            <!-- #region dados basicos -->
            <div class="mb-4">
              <span class="float-right">
                <b-form-input class="text-right" type="text" v-model="caixa.saldoSangria" @keyup="mascaraMoeda" />
              </span>
              <span>Valor de sangria</span>
            </div>
            <!-- #endregion -->

            <!-- #region autenticacao -->
            <div class="mb-4">
              <span class="float-right">
                <b-form-input type="password" v-model="caixa.senha" />
              </span>
              <span>Senha</span>
            </div>
            <!-- #endregion -->
          </div>
          <template #modal-footer="{ hide }">
            <div class="w-100">
              <b-button type="button" class="float-right ml-2" variant="info"
                @click="sangriaCaixa('modalSangriaCaixaEquipe', true)">Confirmar</b-button>
              <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
            </div>
          </template>
        </b-modal>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->
    </b-tabs>
    <!-- #endregion -->

    <!-- #region tela operador -->
    <div v-else-if="$root.sessao.perfil === VS_PERFIL_OPERADOR">
      <!-- impressao caixa fechado (suspenso, logica atual na funcao imprimeCaixaFechado(id)) -->
      <!--<div class="d-none">
        <vs-relatorio-caixa 
          id="impressaoCaixaFechado"
          :filial="$root.sessao.filial"
          :caixa="caixaFechado"
          :operador="$root.sessao"/>
      </div>-->

      <!-- #region opcoes -->
      <b-form-group label="Fluxo de caixa:" label-size="lg" class="mb-4">
        <b-form-row>
          <b-col md="2">
            <button v-if="caixa.bloqueiaAbertura" type="button" class="btn-desabilitado" disabled>Abertura</button>
            <button v-else type="button" class="btn-azul" @click="mostraModal('modalAbreCaixa')">Abertura</button>

            <!-- #region modais -->
            <b-modal id="modalAbreCaixa" title="Abertura de caixa">
              <div class="h5">
                <div>
                  <span>Saldo atual</span>
                  <span class="float-right">
                    <b-form-input class="text-right" type="text" v-model="caixa.saldoAbertura" @keyup="mascaraMoeda" />
                  </span>
                </div>
              </div>
              <template #modal-footer="{ hide }">
                <div class="w-100">
                  <b-button type="button" class="float-right ml-2" variant="info"
                    @click="abreCaixa('modalAbreCaixa')">Confirmar</b-button>
                  <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                </div>
              </template>
            </b-modal>
            <!-- #endregion -->
          </b-col>
          <b-col md="2">
            <button v-if="caixa.bloqueiaReforco" type="button" class="btn-desabilitado" disabled>Reforço</button>
            <button v-else type="button" class="btn-verde" @click="mostraModal('modalReforcaCaixa')">Reforço</button>

            <!-- #region modais -->
            <b-modal id="modalReforcaCaixa" title="Reforço de caixa">
              <div class="h5">
                <!-- #region dados basicos -->
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input class="text-right" type="text" v-model="caixa.saldoReforco" @keyup="mascaraMoeda" />
                  </span>
                  <span>Valor de reforço</span>
                </div>
                <!-- #endregion -->

                <!-- #region autenticacao gerente -->
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input type="text" v-model="caixa.login" />
                  </span>
                  <span>Login gerente</span>
                </div>
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input type="password" v-model="caixa.senha" />
                  </span>
                  <span>Senha gerente</span>
                </div>
                <!-- #endregion -->
              </div>
              <template #modal-footer="{ hide }">
                <div class="w-100">
                  <b-button type="button" class="float-right ml-2" variant="info"
                    @click="reforcaCaixa('modalReforcaCaixa')">Confirmar</b-button>
                  <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                </div>
              </template>
            </b-modal>
            <!-- #endregion -->
          </b-col>
          <b-col md="2">
            <button v-if="caixa.bloqueiaSangria" type="button" class="btn-desabilitado" disabled>Sangria</button>
            <button v-else type="button" class="btn-laranja" @click="mostraModal('modalSangriaCaixa')">Sangria</button>

            <!-- #region modais -->
            <b-modal id="modalSangriaCaixa" title="Sangria de caixa">
              <div class="h5">
                <!-- #region dados basicos -->
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input class="text-right" type="text" v-model="caixa.saldoSangria" @keyup="mascaraMoeda" />
                  </span>
                  <span>Valor de sangria</span>
                </div>
                <!-- #endregion -->

                <!-- #region autenticacao gerente -->
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input type="text" v-model="caixa.login" />
                  </span>
                  <span>Login gerente</span>
                </div>
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input type="password" v-model="caixa.senha" />
                  </span>
                  <span>Senha gerente</span>
                </div>
                <!-- #endregion -->
              </div>
              <template #modal-footer="{ hide }">
                <div class="w-100">
                  <b-button type="button" class="float-right ml-2" variant="info"
                    @click="sangriaCaixa('modalSangriaCaixa')">Confirmar</b-button>
                  <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                </div>
              </template>
            </b-modal>
            <!-- #endregion -->
          </b-col>
          <b-col md="2">
            <button v-if="caixa.bloqueiaFechamento" type="button" class="btn-desabilitado" disabled>Fechamento</button>
            <button v-else type="button" class="btn-vermelho" @click="mostraModal('modalFechaCaixa')">Fechamento</button>

            <!-- #region modais -->
            <b-modal id="modalFechaCaixa" title="Fechamento de caixa">
              <div class="h5">
                <!-- #region dados basicos -->
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-datepicker v-model="caixa.dataFechamento" locale="pt-BR" placeholder="dd/mm/aaaa"
                      :date-format-options="formatoData" :max="new Date().toISOString().slice(0, 10)" />
                  </span>
                  <span>Data</span>
                </div>
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-timepicker v-model="caixa.horaFechamento" locale="pt-BR" placeholder="hh:mm" />
                  </span>
                  <span>Hora</span>
                </div>
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input class="text-right" type="text" v-model="caixa.saldoFechamento"
                      @keyup="mascaraMoeda" />
                  </span>
                  <span>Saldo atual</span>
                </div>
                <!-- #endregion -->

                <!-- #region autenticacao -->
                <div class="mb-4">
                  <span class="float-right">
                    <b-form-input type="password" v-model="caixa.senha" />
                  </span>
                  <span>Senha</span>
                </div>
                <!-- #endregion -->
              </div>
              <template #modal-footer="{ hide }">
                <div class="w-100">
                  <b-button type="button" class="float-right ml-2" variant="info"
                    @click="fechaCaixa('modalFechaCaixa')">Confirmar</b-button>
                  <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
                </div>
              </template>
            </b-modal>
            <!-- #endregion -->
          </b-col>
        </b-form-row>
      </b-form-group>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
import VsRelatorioCaixa from "../templates/vs-relatorio-caixa.vue"

export default {
  // #region configs
  name: "caixa",
  path: "/caixa",
  requireAuth: true,
  components: { VsRelatorioCaixa, },
  // #endregion

  // #region dados
  data() {
    return {
      filtro: {
        movimentacoes: {
          dataInicial: new Date(new Date() - 7 * 86400000).toISOString().slice(0, 10), // 7 dias atras
          dataFinal: new Date().toISOString().slice(0, 10), // hoje
        },
        movimentacoesEquipe: {
          dataInicial: new Date(new Date() - 7 * 86400000).toISOString().slice(0, 10), // 7 dias atras
          dataFinal: new Date().toISOString().slice(0, 10), // hoje
        },
      },
      caixaFechado: {
        saldoAbertura: 0,
        dataAbertura: null,
        saldoPostagens: 0,
        saldoFechamento: 0,
        dataFechamento: null,
        movimentacoes: [],
      },
      caixa: {
        id: null,
        idContratante: this.$root.sessao.contratante.id,
        idFilial: this.$root.sessao.filial.id,
        loginGerente: null,
        senhaGerente: null,
        idOperador: this.$root.sessao.id,
        saldoAbertura: 0,
        saldoReforco: 0,
        saldoSangria: 0,
        saldoFechamento: 0,
        saldoDisponivel: 0,
        dataFechamento: new Date().toISOString().slice(0, 10), // hoje
        horaFechamento: `${(`0${new Date().getHours()}`).slice(-2)}:${(`0${new Date().getMinutes()}`).slice(-2)}`, // hoje
        bloqueiaAbertura: false,
        bloqueiaReforco: true,
        bloqueiaSangria: true,
        bloqueiaFechamento: true,
      },
      idCaixaEquipe: null,
      formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
      cadastros: [],
      cadastrosEquipe: [],
      campos: [
        { key: "dataAbertura", label: "Data de abertura", sortable: true, },
        { key: "saldoAbertura", label: "Saldo de abertura", sortable: true, tdClass: "text-right" },
        { key: "movimentacoes", label: "Movimentação", sortable: true },
        { key: "saldoPostagens", label: "Atendimentos", sortable: true, tdClass: "text-right" },
        { key: "saldoFechamento", label: "Saldo de fechamento", sortable: true, tdClass: "text-right" },
        { key: "dataFechamento", label: "Data de fechamento", sortable: true, },
      ],
      camposEquipe: [
        { key: "operador.nome", label: "Operador", sortable: true, },
        { key: "caixa.dataAbertura", label: "Data de abertura", sortable: true, },
        { key: "caixa.saldoAbertura", label: "Saldo de abertura", sortable: true, tdClass: "text-right" },
        { key: "caixa.movimentacoes", label: "Movimentação", sortable: true },
        { key: "caixa.saldoPostagens", label: "Atendimentos", sortable: true, tdClass: "text-right" },
        { key: "caixa.saldoFechamento", label: "Saldo de fechamento", sortable: true, tdClass: "text-right" },
        { key: "caixa.dataFechamento", label: "Data de fechamento", sortable: true, },
        { key: "opcoes", label: "Opções", },
      ],
      paginaAtual: 1,
      paginaAtualEquipe: 1,
      totalPagina: 100,
      totalPaginaEquipe: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_GERENTE, this.VS_PERFIL_OPERADOR])

    this.verificaCaixa()
  },
  mounted() {
    this.listaMovimentacoes()
  },
  // #endregion

  // #region metodos
  methods: {
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    async verificaCaixa() {
      if (this.caixa.id) {
        this.menuCaixa()
        return
      }

      let query = `ativo=true&idFilial=${this.caixa.idFilial}&idOperador=${this.caixa.idOperador}`
			let resp = await this.$vsApiRest("GET", `/caixas/?${query}`)
        
			if (resp.status === 200 && resp.data.length) {
        let obj = resp.data[0]
        this.caixa.id = obj.id
      }
      this.menuCaixa()
    },
    menuCaixa() {
      let ok = (this.caixa.id && this.caixa.id.length > 0)
      this.caixa.bloqueiaAbertura = ok
      this.caixa.bloqueiaReforco = !ok
      this.caixa.bloqueiaSangria = !ok
      this.caixa.bloqueiaFechamento = !ok
    },
    async abreCaixa(idModal) {
      let saldo = this.$vsConverteMoeda(this.caixa.saldoAbertura)

      if (isNaN(saldo) || saldo < 0) {
        this.$vsNotificacao("Saldo inválido", "warning")
        return
      }

      // grava abertura (novo caixa)
      let obj = {
        idContratante: this.caixa.idContratante,
        idFilial: this.caixa.idFilial,
        idOperador: this.caixa.idOperador,
        saldoAbertura: saldo,
        gerencia: (this.$root.sessao.perfil === this.VS_PERFIL_GERENTE),
      }
      let resp = await this.$vsApiRest("POST", "/caixas", obj)
      
			if (resp.status === 200) {
        this.caixa.id = resp.data.id
        this.menuCaixa()
        this.listaMovimentacoes()
        this.$bvModal.hide(idModal)
      }
    },
    async reforcaCaixa(idModal, correcao = false) {
      let saldo = this.$vsConverteMoeda(this.caixa.saldoReforco)

      if (isNaN(saldo) || saldo <= 0) {
        this.$vsNotificacao("Valor de reforço inválido", "warning")
        return
      }
			let sessao = this.$root.sessao
			let login = sessao.email
			
			if (sessao.perfil !== this.VS_PERFIL_GERENTE) {
        login = this.caixa.login
			}
      let resp = await this.$vsAutenticacao(login, this.caixa.senha, this.VS_PERFIL_GERENTE)
        
			if (!resp || resp.status !== 200) {
        return
      }

			// grava reforco 
      let obj = {
        idCaixa: correcao ? this.idCaixaEquipe : this.caixa.id,
        idGerente: resp.data.id,
        saldo: saldo,
        correcao: correcao,
      }
      resp = await this.$vsApiRest("POST", "/caixas/reforcos", obj)
        
			if (resp.status === 200) {
        this.caixa.login = null
        this.caixa.senha = null
        this.caixa.saldoReforco = 0

        if (correcao) {
          this.listaMovimentacoesEquipe()
        } else {
          this.menuCaixa()
          this.listaMovimentacoes()
        }
        this.$bvModal.hide(idModal)
      }
    },
    async sangriaCaixa(idModal, correcao = false) {
      let saldo = this.$vsConverteMoeda(this.caixa.saldoSangria)

      if (isNaN(saldo) || saldo <= 0) {
        this.$vsNotificacao("Valor de sangria inválido", "warning")
        return
      }

			let sessao = this.$root.sessao
			let login = sessao.email
			
			if (sessao.perfil !== this.VS_PERFIL_GERENTE) {
        login = this.caixa.login
			}
      let resp = await this.$vsAutenticacao(login, this.caixa.senha, this.VS_PERFIL_GERENTE)
        
			if (!resp || resp.status !== 200) {
        return
      }

      // grava sangria
			let obj = {
        idCaixa: correcao ? this.idCaixaEquipe : this.caixa.id,
        idGerente: resp.data.id,
        saldo: saldo,
        correcao: correcao,
      }
      resp = await this.$vsApiRest("POST", "/caixas/sangrias", obj)
          
			if (resp.status === 200) {
				this.caixa.login = null
        this.caixa.senha = null
        this.caixa.saldoSangria = 0

        if (correcao) {
          this.listaMovimentacoesEquipe()
        } else {
          this.menuCaixa()
          this.listaMovimentacoes()
        }
        this.$bvModal.hide(idModal)
      }  
    },
    async fechaCaixa(idModal) {
      let saldo = this.$vsConverteMoeda(this.caixa.saldoFechamento)

      if (isNaN(saldo) || saldo < 0) {
        this.$vsNotificacao("Saldo de fechamento inválido", "warning")
        return
      }

			let sessao = this.$root.sessao
      let resp = await this.$vsAutenticacao(sessao.email, this.caixa.senha, sessao.perfil)
        
			if (!resp || resp.status !== 200) {
        return
      }
      
			// grava fechamento
			let obj = {
        id: this.caixa.id,
        idGerente: resp.data.id,
        data: this.$vsConverteDataUTC(`${this.caixa.dataFechamento}T${this.caixa.horaFechamento}`), // UTC
        saldo: saldo,
      }
      resp = await this.$vsApiRest("POST", "/caixas/fechamentos", obj)
      
			if (resp.status === 200) {
        let id = this.caixa.id
        this.caixa.id = null
        this.caixa.login = null
        this.caixa.senha = null

        this.verificaCaixa()
        this.listaMovimentacoes()
        this.$bvModal.hide(idModal)

        let vm = this
        setTimeout(function () {
          vm.imprimeCaixaFechado(id)
        }, 200)
      }
    },
    async listaMovimentacoes() {
			let form = this.filtro.movimentacoes
			let sessao = this.$root.sessao

			if (!this.validacoesFiltro(form)) {
				return
			}

      if (sessao.perfil === this.VS_PERFIL_OPERADOR) {
        return
      }

      let query = `idFilial=${sessao.filial.id}`

      query += `&idOperador=${sessao.id}`
			query += `&dataInicial=${this.$vsConverteDataUTC(`${form.dataInicial}T00:00:00`)}`
			query += `&dataFinal=${this.$vsConverteDataUTC(`${form.dataFinal}T23:59:59`)}`

			this.cadastros = []

      let resp = await this.$vsApiRest("GET", `/caixas/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.movimentacoes = this.montaMovimentacoes(el)
        })
        this.cadastros = resp.data
      }
    },
    async listaMovimentacoesEquipe() {
      let form = this.filtro.movimentacoesEquipe

			if (!this.validacoesFiltro(form)) {
				return
			}

      let query = `idFilial=${this.$root.sessao.filial.id}`
      
			query += `&ativo=false&gerencia=false`
			query += `&dataInicial=${this.$vsConverteDataUTC(`${form.dataInicial}T00:00:00`)}`
      query += `&dataFinal=${this.$vsConverteDataUTC(`${form.dataFinal}T23:59:59`)}`

			this.cadastrosEquipe = []
      
      let resp = await this.$vsApiRest("GET", `/caixas/equipe/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.caixa.movimentacoes = this.montaMovimentacoes(el.caixa)
          el.opcoes = true
        })
        this.cadastrosEquipe = resp.data
      }
    },
    montaMovimentacoes(obj) {
      let movs = [].concat(obj.reforcos, obj.sangrias)
      movs.sort(function (a, b) {
        let dta = new Date(a.dataCriacao)
        let dtb = new Date(b.dataCriacao)
        return (dta < dtb) ? -1 : (dta > dtb)
      })
      return movs
    },
    imprimeCaixa(obj) {
      this.$vsImprimeHTML(document.getElementById(`impressaoCaixa${obj.id}`))
    },
    mostraModal(idModal, idCaixa = null) {
      if (idModal === "modalFechaCaixa") {
        this.caixa.horaFechamento = `${(`0${new Date().getHours()}`).slice(-2)}:${(`0${new Date().getMinutes()}`).slice(-2)}` // hoje
      }
      this.caixa.saldoAbertura = 0
      this.caixa.saldoReforco = 0
      this.caixa.saldoSangria = 0
      this.caixa.login = null
      this.caixa.senha = null
      this.idCaixaEquipe = idCaixa

      this.$bvModal.show(idModal)
    },
    async imprimeCaixaFechado(id) {
      let resp = await this.$vsApiRest("GET", `/caixas/?ids=${id}`)
      
			if (resp.status === 200 && resp.data.length) {
				/*
         * PALIATIVO (gambiarra marota)!!!
         *
         * foi necessario forcar a renderizacao e impressao do html via variaveis
         * mas o ideal eh usar o componente vs-relatorio-caixa mas o parametro :caixa
         * nao estava atualizando, o que gerava comportamento estranho na impressao final
         * 
         * pesquisei watch, emit, update etc no vuejs mas sem sucesso, enquanto isso
         * usaremos o template abaixo, porem o componente nao sera descartado...
         */

				let obj = resp.data[0]
        let htmlMovimentacoes = []

        obj.movimentacoes = this.montaMovimentacoes(obj)
        obj.movimentacoes.forEach(mov => {
          htmlMovimentacoes.push(`<tr>
            <td class="border-right border-top pt-3">${mov.descricao}</td>
            <td class="border-right border-top pt-3 px-2">${this.$vsFormataData(mov.dataCriacao, true)}</td>
            <td class="border-top pt-3 pl-3 text-right text-nowrap">${this.$vsFormataMoeda(mov.saldo)}</td>
          </tr>`)
        })

        let divOculta = document.createElement("div")
        divOculta.classList.add("area-impressao")
        divOculta.style.width = "800px"
        divOculta.innerHTML = `
          <div>
            <div class="mb-5">
              <div class="h1 mb-5 text-center">Caixa</div>
              <div class="h2 text-muted">Resumo</div>
            </div>
            <div class="bg-white p-4 h4 mb-5">
              <div class="mb-4">
                <span>${this.$root.sessao.filial.nome.toUpperCase()}</span>
              </div>
              <div class="mb-4">
                <span>Colaborador:&nbsp;&nbsp;${this.$root.sessao.nome.toUpperCase()}</span>
              </div>
              <div class="mb-4">
                <span class="float-right">${this.$vsFormataMoeda(obj.saldoAbertura)}</span>
                <span>Abertura:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;${this.$vsFormataData(obj.dataAbertura, true)}</span>
              </div>
              <div class="mb-4">
                <span class="float-right">${this.$vsFormataMoeda(obj.saldoPostagens)}</span>
                <span>Atendimentos:</span>
              </div>
              <div class="mb-4">
                <span class="float-right">${this.$vsFormataMoeda(obj.saldoFechamento)}</span>
                <span>Fechamento:&nbsp;&nbsp;&nbsp;${this.$vsFormataData(obj.dataFechamento, true)}</span>
              </div>
            </div>
          </div>
          <div class="nova-pagina">
            <div class="mb-5">
              <div class="h2 text-muted">Movimentação</div>
            </div>
            <div class="bg-white p-4 h4 mb-5">
              <table class="w-100">
                <tr>
                  <td class="border-right pb-3 pr-2">Tipo</td>
                  <td class="border-right pb-3 px-2">Data/hora</td>
                  <td class="pb-3 px-2">Valor</td>
                </tr>
                ${htmlMovimentacoes.join('')}
              </table>
            </div>
          </div>`

        this.$vsImprimeHTML(divOculta)
      }
    },
    defineCorMovimentacao(obj) {
      if (obj.descricao.includes('Reforço')) {
        return "text-success"
      }
      if (obj.descricao.includes('Sangria')) {
        return "text-danger"
      }
      return "text-default"
    },
		validacoesFiltro(form) {
			let dtInicial = new Date(`${form.dataInicial}T00:00:00`)
			let dtFinal = new Date(`${form.dataFinal}T23:59:59`)
			let ok = true

			if (dtFinal < dtInicial) {
				this.$vsNotificacao("Data final não pode ser menor que a data inicial", "warning")
				ok = false
			}
			return ok
		},
  },
  // #endregion
}
</script>
