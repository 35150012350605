<template>
  <div>
    <h1 class="titulo">Geração de faturas</h1>

    <!-- #region filtro -->
		<form>
			<b-form-row>
				<!--<b-col md="2">
					<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
						<b-form-datepicker locale="pt-BR" v-model="filtro.dataInicial" placeholder="dd/mm/aaaa" :date-format-options="formatoData" @input="limpaTela()" :max="dataOntem" />
					</b-form-group>
				</b-col>-->

				<b-col md="2">
					<b-form-group class="form-input-cinza" label="Data de corte" label-cols="12" label-size="lg">
						<b-form-datepicker locale="pt-BR" v-model="filtro.dataFinal" placeholder="dd/mm/aaaa" :date-format-options="formatoData" @input="limpaTela()" :max="dataOntem" />
					</b-form-group>
				</b-col>

				<b-col md="3">
					<b-form-group class="form-input-cinza" label="Filial" label-cols="12" label-size="lg">
						<model-select v-model="filtro.idFilial" :options="comboFilial" @input="limpaTela()" />
					</b-form-group>
				</b-col>

				<b-col md="1">
					<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
						<b-button variant="primary" v-b-tooltip.hover title="Pesquisar" @click.prevent="listaFaturas()">
							<b-icon icon="search" scale="1" />
						</b-button>
					</b-form-group>
				</b-col>
			</b-form-row>
		</form>
    <!-- #endregion -->

    <!-- #region listagem/paginacao -->
		<div v-if="exibeRelatorio" class="mt-4">
			<!-- #region opcoes -->
			<div v-if="exibeGeracaoFatura" class="mb-4 d-flex justify-content-between">
				<span class="text-nowrap">
					<b-input-group>
						<b-form-checkbox name="opcaoVencimento" size="lg" v-model="usaDataVencimentoCadastro" switch :value="false">
							<span style="cursor: pointer">Usar data de vencimento para o seguinte dia</span>&nbsp;&nbsp;
						</b-form-checkbox>
						<b-input-group-append>
							<b-form-datepicker locale="pt-BR" v-model="dataVencimentoCustomizada" placeholder="dd/mm/aaaa" :date-format-options="formatoData" />
						</b-input-group-append>
					</b-input-group>
					<b-form-checkbox name="opcaoVencimento" size="lg" v-model="usaDataVencimentoCadastro" switch :value="true">
						<span style="cursor: pointer">Usar data de vencimento do cadastro</span>
					</b-form-checkbox>
				</span>
				<span class="float-right">
					<b-button class="btn-azul" type="button" @click="geraFaturas()">Gerar faturas</b-button>
				</span>
			</div>
			<div v-else>
				<span class="mb-4 float-right">
					<b-button class="btn-azul" type="button" @click="imprimeResumoTodos()">Exportar TODAS</b-button>
				</span>
			</div>
			<!-- #endregion -->

      <b-table id="tabelaRelatorio" class="text-nowrap h5 mt-4" 
			  :items="tabelaRelatorio"
        :fields="camposRelatorio" 
				:per-page="totalPagina" 
				:current-page="paginaAtual"
        small 
				hover 
				responsive
				no-footer-sorting 
				foot-clone>
        <!--===================================================================================
          celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

          obj.index = indice da linha
          obj.field = nome do campo/coluna/chave json
          obj.item  = objeto json bruto
          obj.value = o valor da chave json com tipo original mantido e sem tratamento

          para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
        ====================================================================================-->
        <!--<template #cell()="obj">
          {{ obj.field }}
          {{ obj.item }}
          {{ obj.value }}
        </template>-->

				<!-- #region celulas customizadas/formatadas -->
				<template #head(selecao)="obj">
          <b-form-checkbox v-if="liberaMarcarTodos()" v-model="optMarcarTodos" size="lg" switch v-b-tooltip.hover.right="'Marcar/desmarcar todas'" @change="marcarTodos($event)"/>
        </template>

				<template #cell(selecao)="obj">
          <b-form-checkbox v-if="obj.item.numeroFatura === 0" name="filial" size="lg" v-model="filiaisSelecionadas" switch :value="obj.item.idFilial"  v-b-tooltip.hover.right="'Marcar/desmarcar'" @change="marcouTodos()"/>
        </template>

				<template #cell(numeroFatura)="obj">
					<a v-if="obj.value" class="text-black"  @click.prevent="imprimeResumo(obj.item)"	v-b-tooltip.hover.left="'Imprimir resumo'">
						<b-icon icon="printer" class="text-info" />&nbsp;{{ obj.value }}
					</a>
        </template>

        <template #cell(dataVencimento)="obj">
          <span v-if="obj.item.numeroFatura > 0">{{ $vsFormataData(obj.value) }}</span>
        </template>

        <template #cell(valorTotalPostagens)="obj">
          {{ $vsFormataMoeda(obj.value) }}
        </template>

        <template #cell(valorTotalServicos)="obj">
          {{ $vsFormataMoeda(obj.value) }}
        </template>

        <template #cell(valorTotalLancamentos)="obj">
          {{ $vsFormataMoeda(obj.value) }}
        </template>

        <template #cell(valorTotal)="obj">
          {{ $vsFormataMoeda(obj.value) }}
        </template>
				<!-- #endregion -->

				<!-- #region rodape com totais -->
				<template #foot(filialNome)>Total</template>
				<template #foot()>&nbsp;</template>
				<template #foot(valorTotalPostagens)><span class="float-right">R$ {{ $vsFormataMoeda(valorTotalPostagens()) }}</span></template>
				<template #foot(valorTotalServicos)><span class="float-right">R$ {{ $vsFormataMoeda(valorTotalServicos()) }}</span></template>
				<template #foot(valorTotalLancamentos)><span class="float-right">R$ {{ $vsFormataMoeda(valorTotalLancamentos()) }}</span></template>
				<template #foot(valorTotal)><span class="float-right">R$ {{ $vsFormataMoeda(valorTotal()) }}</span></template>
				<!-- #endregion -->
      </b-table>

			<!-- #region paginacao -->
      <div class="h5">
        <span style="padding-left: 5px;padding-right: 20px;">
          <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
        </span>
        <span> Total de Regitros: {{ $vsFormataNumero(tabelaRelatorio.length) }}</span>
        <b-pagination class="mt-3 d-flex justify-content-center" 
				  :total-rows="tabelaRelatorio.length"
          :per-page="totalPagina" 
					aria-controls="tabelaRelatorio"
          v-model="paginaAtual" />
      </div>
			<!-- #endregion -->
    </div>
    <!-- #endregion -->
	</div>
</template>

<script>
import { ModelSelect } from "vue-search-select"

export default {
  // #region configs
  name: "faturamento-relatorio",
  path: "/faturamento-relatorio",
  requireAuth: true,
	components: { ModelSelect },
  // #endregion

  // #region dados
  data() {
    return {
      OPT_TODOS: "0",
			dataOntem: new Date(new Date() - 1 * 86400000).toISOString().substring(0, 10), // d-1
			exibeGeracaoFatura: true,
			timestampUltimaGeracaoFatura: 0,
			usaDataVencimentoCadastro: false,
			dataVencimentoCustomizada: new Date().toISOString().substring(0, 10), // hoje
      comboFilial: [],
      formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
      filtro: {
        //dataInicial: new Date(new Date() - 1 * 86400000).toISOString().substring(0, 10), // d-1
        dataFinal: new Date(new Date() - 1 * 86400000).toISOString().substring(0, 10), // d-1
        idFilial: null,
      },
      camposRelatorio: [
        { key: "selecao", label: "", sortable: false, thClass: "text-left", },
        { key: "filialNome", label: "Filial", sortable: true, },
        { key: "numeroFatura", label: "Nº Fatura", sortable: true, },
        { key: "dataVencimento", label: "Vencimento", sortable: true, },
        { key: "valorTotalPostagens", label: "Postagens(A)", sortable: true, tdClass: "text-right", },
        { key: "valorTotalServicos", label: "Outros Itens(B)", sortable: true, tdClass: "text-right", },
        { key: "valorTotalLancamentos", label: "Lançamentos(C)", sortable: true, tdClass: "text-right", },
        { key: "valorTotal", label: "Total(A+B+C)", sortable: true, tdClass: "text-right", },
      ],
			optMarcarTodos: false,
			filiaisSelecionadas: [],
			exibeRelatorio: false,
      tabelaRelatorio: [],
      paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_CONTRATANTE])
  },
  mounted() {
		this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
		async bootstrap() {
			await this.listaFilial()
			await this.listaFaturas()
		},
    async listaFilial() {
      let query = `idContratante=${this.$root.sessao.id}`
      let resp = await this.$vsApiRest("GET", `/usuarios/filiais/?${query}`)
      
			if (resp.status === 200) {
				this.defineComboFilial(resp.data)
      }
    },
    async listaFaturas(situacao = this.VS_STATUS_LANCAMENTO_FATURA_NAO_FATURADO) {
			/*if (!this.validacoesFiltro()) {
				return
			}*/

      let filtro = this.filtro
			let statusNaoFaturado = (situacao === this.VS_STATUS_LANCAMENTO_FATURA_NAO_FATURADO)
      let query = `idContratante=${this.$root.sessao.id}&situacao=${situacao}`

      if (filtro.idFilial !== this.OPT_TODOS) {
        query += `&idFilial=${filtro.idFilial}`
      }
      
      //query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
      query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

			if (statusNaoFaturado) {
				this.timestampUltimaGeracaoFatura = 0
			}
			if (this.timestampUltimaGeracaoFatura > 0) {
				query += `&timestampUltimaGeracaoFatura=${this.timestampUltimaGeracaoFatura}`
			}

			query += `&faturaDePara=${this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL}`

			this.limpaTela()
			this.exibeGeracaoFatura = statusNaoFaturado

      let resp = await this.$vsApiRest("GET", `/faturamento/relatorios/geracao/?${query}`)
        
			if (resp.status === 200) {
        this.tabelaRelatorio = resp.data
        this.exibeRelatorio = (resp.data.length > 0)
      }
    },
		defineComboFilial(filiais) {
			filiais.forEach(e => {
        e.text = e.isAtivo ?  e.nome : `${e.nome} (inativo)`
        e.value = e.id
      })
      this.comboFilial = filiais.filter(el => !el.isExcluido)
      this.comboFilial.unshift({ value: this.OPT_TODOS, text: "Todas" }) // primeira opcao
      this.filtro.idFilial = this.OPT_TODOS
		},
    limpaTela() {
			this.exibeRelatorio = false
      this.tabelaRelatorio = []
			this.filiaisSelecionadas = []
			this.optMarcarTodos = false
    },
    valorTotalPostagens() {
      return this.tabelaRelatorio.reduce((total, obj) => total + obj.valorTotalPostagens, 0)
    },
		valorTotalServicos() {
      return this.tabelaRelatorio.reduce((total, obj) => total + obj.valorTotalServicos, 0)
    },
		valorTotalLancamentos() {
      return this.tabelaRelatorio.reduce((total, obj) => total + obj.valorTotalLancamentos, 0)
    },
		valorTotal() {
      return this.tabelaRelatorio.reduce((total, obj) => total + obj.valorTotal, 0)
    },
		liberaMarcarTodos() {
			return (this.tabelaRelatorio.filter(el => el.numeroFatura === 0).length > 0)
		},
		marcarTodos(marcou) {
			if (marcou) {
				this.filiaisSelecionadas = this.tabelaRelatorio.map(el => el.idFilial)
			} else {
				this.filiaisSelecionadas = []
			}
		},
		async geraFaturas() {
			let filiais = this.filiaisSelecionadas

			if (filiais.length === 0) {
				this.$vsNotificacao("Marque ao menos uma filial para gerar a fatura", "warning")
				return
			}

			let filtro = this.filtro
			let sessao = this.$root.sessao
			let req = {
				idContratante: sessao.id,
				idFilial: filtro.idFilial,
				idOperador: sessao.id,
				//dataInicial: this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`),
				dataFinal: this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`),
				idsFaturados: filiais,
				usaDataVencimentoCadastro: this.usaDataVencimentoCadastro,
  			dataVencimentoCustomizada: this.$vsConverteDataUTC(`${this.dataVencimentoCustomizada}T00:00:00`),
				faturaDePara: this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL,
			}

			this.timestampUltimaGeracaoFatura = 0

			let resp = await this.$vsApiRest("POST", `/faturamento/geracao`, req)
      
			if (resp.status === 200) {
        this.timestampUltimaGeracaoFatura = resp.data
				this.$vsNotificacao("Faturas geradas com sucesso", "success")
				this.listaFaturas(this.VS_STATUS_LANCAMENTO_FATURA_FATURADO)
      }
		},
		async imprimeResumo(obj) {
			let resp = await this.$vsApiRest("POST", `/faturamento/impressao-resumo-fatura/`, [obj.idFatura], {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-fatura-resumido-${new Date().getTime()}.zip`)
      }
		},
		async imprimeResumoTodos() {
			let idsFaturas = this.tabelaRelatorio.map((obj) => obj.idFatura)
			let resp = await this.$vsApiRest("POST", `/faturamento/impressao-resumo-fatura/`, idsFaturas, {}, "arraybuffer")

      if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-fatura-resumido-${new Date().getTime()}.zip`)
      }
		},
		marcouTodos() {
			this.optMarcarTodos = (this.tabelaRelatorio.length === this.filiaisSelecionadas.length)
		},
		validacoesFiltro() {
			let form = this.filtro
			let dtInicial = new Date(`${form.dataInicial}T00:00:00`)
			let dtFinal = new Date(`${form.dataFinal}T23:59:59`)
			let ok = true

			if (dtFinal < dtInicial) {
				this.$vsNotificacao("Data final não pode ser menor que a data inicial", "warning")
				ok = false
			}
			return ok
		},
  },
  // #endregion
}
</script>
