<template>
  <div style="min-width: 300px; max-width: min-content; font-size: x-large;" class="text-nowrap pr-3">
    <div v-for="(item, i) in montaMenu()" :key="i">
      <!-- #region menus agrupados -->
      <b-navbar v-if="item.submenus" toggleable>
        <!-- #region titulo/grupo -->
        <b-navbar-toggle :target="`submenu${i}`" class="text-left border-0 w-100 px-0">
          <template #default="{ expanded }">
            <router-link class="d-flex justify-content-between px-0 mx-0" :to="item.rota" :style="`color: ${item.corTexto};`" style="font-size: x-large;">
              <span class="col-11 p-0">
                <b-icon :icon="item.icone" />&nbsp;&nbsp;{{ item.texto }}
              </span>
              <span class="ml-3">
                <b-icon v-if="expanded" icon="chevron-up"/>
                <b-icon v-else icon="chevron-down"/>
              </span>
            </router-link>
          </template>
        </b-navbar-toggle>
        <!-- #endregion -->

        <!-- #region itens -->
        <b-collapse :id="`submenu${i}`" is-nav class="mt-2">
          <div v-for="subitem in item.submenus" :key="subitem.rota" class="mr-5">
            <router-link :to="subitem.rota" :style="`color: ${subitem.corTexto};`">
              <span @click="$vsEscondeMenu()">
                <b-icon :icon="subitem.icone" />&nbsp;&nbsp;{{ subitem.texto }}
              </span>
            </router-link>
            <hr v-if="subitem.temDivisor" class="mx-4 my-2">
            <hr v-else class="border-0 my-3">
          </div>
        </b-collapse>
        <!-- #endregion -->
      </b-navbar>
      <!-- #endregion -->

      <!-- #region menu individual -->
      <b-navbar v-else>
        <router-link :to="item.rota" :style="`color: ${item.corTexto};`">
          <span @click="$vsEscondeMenu()">
            <b-icon :icon="item.icone" />&nbsp;&nbsp;{{ item.texto }}
          </span>
        </router-link>
      </b-navbar>
      <!-- #endregion -->

      <hr v-if="item.temDivisor" class="mx-4 my-1">
      <hr v-else class="border-0 my-2">
    </div>
  </div>
</template>

<script>
export default {
  // #region configs
  name: "vs-menu",
  // #endregion

  // #region watches
  watch: {
    "$route"() {
      this.montaMenu()
    }
  },
  // #endregion

  // #region metodos
  methods: {
    montaMenu() {
      let aguardandoValidacao = this.$vsAguardandoValidacao()
      let menu = []
			let sessao = this.$root.sessao

      // #region acesso privado
      switch (sessao.perfil) {
				// #region usuarios admin/visualset
        case this.VS_PERFIL_SUPERADMIN:
          menu.push({ rota: "/transportadoras", texto: "Transportadoras", temDivisor: true, icone: "truck", })
					menu.push({ rota: "/mensagens", texto: "Mensagens", temDivisor: true, icone: "megaphone", })
					menu.push({ rota: "/logs", texto: "Logs de sistema", temDivisor: true, icone: "file-earmark-text", })
					menu.push({ rota: "/gerador-senha", texto: "Gerador de senhas", temDivisor: true, icone: "shield-lock", })
          break
				
				case this.VS_PERFIL_DIRETORIA:
					break

				case this.VS_PERFIL_SUPORTE:
					menu.push({ rota: "/logs", texto: "Logs de sistema", temDivisor: true, icone: "file-earmark-text", })
          break

				case this.VS_PERFIL_COMERCIAL:
					menu.push({ rota: "/logs", texto: "Logs de sistema", temDivisor: true, icone: "file-earmark-text", })
          break
				// #endregion

				// #region usuarios comuns/regra de negocio
        case this.VS_PERFIL_CONTRATANTE:
					menu.push({
            rota: "", texto: "Relatórios", temDivisor: true, icone: "graph-up",
            submenus: [
              { rota: "/relatorio-movimentacoes", texto: "Movimentações", temDivisor: false, icone: "", },
              { rota: "/relatorio-faturamento", texto: "Faturamento", temDivisor: false, icone: "", },
            ]
          })
					menu.push({
            rota: "", texto: "Faturamento", temDivisor: true, icone: "currency-dollar",
            submenus: [
						  { rota: "/faturamento-lancamentos", texto: "Lançamentos", temDivisor: false, icone: "", }, 
							{ rota: "/faturamento-relatorio", texto: "Geração de faturas", temDivisor: false, icone: "", },
              { rota: "/faturamento-gestao", texto: "Gestão de faturas", temDivisor: false, icone: "", },
            ]
          })					
          menu.push({ rota: "/clientes", texto: "Clientes", temDivisor: true, icone: "person-circle", })
          menu.push({ rota: "/operacional", texto: "Operacional", temDivisor: true, icone: "people-fill", })
          menu.push({ rota: "/servicos", texto: "Logística", temDivisor: true, icone: "truck", })
					//menu.push({ rota: "/meus-dados", texto: "Meus Dados", temDivisor: true, icone: "file-earmark-person", })
          break

        case this.VS_PERFIL_FILIAL:
					menu.push({
            rota: "", texto: "Relatórios", temDivisor: true, icone: "graph-up",
            submenus: [
              { rota: "/relatorio-movimentacoes", texto: "Movimentações", temDivisor: false, icone: "", },
              { rota: "/relatorio-faturamento", texto: "Faturamento", temDivisor: false, icone: "", },
            ]
          })
					menu.push({
            rota: "", texto: "Faturamento", temDivisor: true, icone: "currency-dollar",
            submenus: [
						  { rota: "/faturamento-lancamentos", texto: "Lançamentos", temDivisor: false, icone: "", },
              { rota: "/faturamento-gestao", texto: "Gestão de faturas", temDivisor: false, icone: "", },
            ]
          })
					menu.push({
            rota: "", texto: "Faturamento Cliente", temDivisor: true, icone: "currency-dollar",
            submenus: [
						  { rota: "/faturamento-cliente-lancamentos", texto: "Lançamentos", temDivisor: false, icone: "", },
							{ rota: "/faturamento-cliente-relatorio", texto: "Geração de faturas", temDivisor: false, icone: "", },
              { rota: "/faturamento-cliente-gestao", texto: "Gestão de faturas", temDivisor: false, icone: "", },
            ]
          })
					menu.push({ rota: "/clientes", texto: "Clientes", temDivisor: true, icone: "person-circle", })
          menu.push({ rota: "/servicos", texto: "Logística", temDivisor: true, icone: "truck", })
          menu.push({ rota: "/operacoes", texto: "Operações", temDivisor: true, icone: "gear", })
          menu.push({ rota: "/equipe", texto: "Equipe", temDivisor: false, icone: "people-fill", })
          break;

        case this.VS_PERFIL_GERENTE:
          menu.push({
            rota: "", texto: "Relatórios", temDivisor: true, icone: "graph-up",
            submenus: [
              { rota: "/relatorio-movimentacoes", texto: "Movimentações", temDivisor: false, icone: "", },
              { rota: "/relatorio-faturamento", texto: "Faturamento", temDivisor: false, icone: "", },
            ]
          })
					menu.push({
            rota: "", texto: "Faturamento Cliente", temDivisor: true, icone: "currency-dollar",
            submenus: [
							{ rota: "/faturamento-cliente-lancamentos", texto: "Lançamentos", temDivisor: false, icone: "", },
							{ rota: "/faturamento-cliente-relatorio", texto: "Geração de faturas", temDivisor: false, icone: "", },
              { rota: "/faturamento-cliente-gestao", texto: "Gestão de faturas", temDivisor: false, icone: "", },
            ]
          })
          menu.push({
            rota: "", texto: "Gestão", temDivisor: true, icone: "gear",
            submenus: [
						  { rota: "/clientes", texto: "Clientes", temDivisor: false, icone: "", },
              { rota: "/equipe", texto: "Equipe", temDivisor: false, icone: "", },
              { rota: "/caixa", texto: "Caixa", temDivisor: false, icone: "", },
            ]
          })
          menu.push({
            rota: "", texto: "Serviços", temDivisor: true, icone: "truck",
            submenus: [
              { rota: "/atendimento", texto: "Atendimento", temDivisor: false, icone: "", },
              { rota: "/romaneio", texto: "Romaneio", temDivisor: false, icone: "", },
              { rota: "/rastreio", texto: "Rastreamento", temDivisor: false, icone: "", },
              { rota: "/cotacao", texto: "Cotação", temDivisor: false, icone: "", },
              { rota: "/endereco", texto: "Endereços", temDivisor: false, icone: "", }
            ],
          })
          break

        case this.VS_PERFIL_OPERADOR:
					menu.push({
            rota: "", texto: "Relatórios", temDivisor: true, icone: "graph-up",
            submenus: [
              { rota: "/relatorio-movimentacoes", texto: "Movimentações", temDivisor: false, icone: "", },
            ]
          })
          menu.push({
            rota: "", texto: "Serviços", temDivisor: true, icone: "truck",
            submenus: [
              { rota: "/atendimento", texto: "Atendimento", temDivisor: false, icone: "", },
              { rota: "/romaneio", texto: "Romaneio", temDivisor: false, icone: "", },
              { rota: "/rastreio", texto: "Rastreamento", temDivisor: false, icone: "", },
              { rota: "/cotacao", texto: "Cotação", temDivisor: false, icone: "", },
              { rota: "/endereco", texto: "Endereços", temDivisor: false, icone: "", }
            ],
          })
          menu.push({ rota: "/caixa", texto: "Caixa", temDivisor: true, icone: "calculator", })
          break

				case this.VS_PERFIL_CLIENTE:
					menu.push({
            rota: "", texto: "Faturamento", temDivisor: true, icone: "currency-dollar",
            submenus: [
							{ rota: "/faturamento-cliente-lancamentos", texto: "Lançamentos", temDivisor: false, icone: "", },
              { rota: "/faturamento-cliente-gestao", texto: "Gestão de faturas", temDivisor: false, icone: "", },
            ]
          })
					menu.push({ rota: "/meus-envios", texto: "Meus Envios", temDivisor: false, icone: "graph-up", })
					menu.push({ rota: "/pre-postagem", texto: "Pré Postagem", temDivisor: true, icone: "box", })
					menu.push({ rota: "/convites", texto: "Convites", temDivisor: true, icone: "envelope", })
					menu.push({ rota: "/vinculos", texto: "Meus Vínculos", temDivisor: true, icone: "people-fill", })
					break
				// #endregion
      }
			// #endregion

      // #region acesso publico
			if (sessao.perfil !== this.VS_PERFIL_CLIENTE) {
				menu.push({ rota: "/ferramentas", texto: "Ferramentas", temDivisor: true, icone: "tools", })
			}

      menu.push({ rota: "/logout", texto: "Sair", temDivisor: false, icone: "power", })
			// #endregion

      this.defineCor(menu)

      return menu
    },
    defineCor(menu) {
      let corSelecionada = "#04B2D9"
      let corPadrao = "#000000"
      let rotaAtual = this.$route.path

      for (let i = 0; i < menu.length; i++) {
        let item = menu[i]

        item.corTexto = (item.rota === rotaAtual) ? corSelecionada : corPadrao

        if (item.submenus) {
          item.submenus.forEach(subitem => {
            subitem.corTexto = corPadrao

            if (subitem.rota === rotaAtual) {
              item.corTexto = corSelecionada
              subitem.corTexto = corSelecionada
            }
          })
        }
      }
    },
  },
  // #endregion
}
</script>
