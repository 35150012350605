<template>
  <div>
		<div class="titulo">
			<h1>Logística</h1>
    	<h4>Cadastro de serviços a serem disponibilizados para uso</h4>
		</div>

    <!-- #region tela contratante -->
    <div v-if="$root.sessao.perfil === VS_PERFIL_CONTRATANTE">
      <!-- #region cadastro/edicao -->
      <form>
        <!-- #region dados -->
        <b-form-row>
          <b-col md="3">
            <b-form-group class="form-input-cinza" label="Transportadora" label-cols="12" label-size="lg">
              <b-form-select v-model="servico.idTransportadora" :options="comboTransportadoras.filter(el => el.isAtivo)" @change="filtraServicosTransportadora()" />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group class="form-input-cinza" label="Código Serviço" label-size="lg" label-cols="12">
              <b-form-select v-model="servico.codigo" :options="comboServicos" @change="filtraNiveisContratoServico()" />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group class="form-input-cinza" label="Descrição Serviço" label-size="lg" label-cols="12">
              {{ servico.descricao }}
            </b-form-group>
          </b-col>

          <b-col md="3" v-if="exibeNivelContrato()">
            <b-form-group class="form-input-cinza" label="Nível contrato serviço" label-size="lg" label-cols="12">
              <b-form-select v-model="servico.nivelContrato" :options="comboNiveisContrato" />
            </b-form-group>
          </b-col>
        </b-form-row>
        <!-- #endregion -->

        <!-- #region acoes -->
        <b-form-row>
          <b-col md="2">
            <b-button class="btn-azul" @click.prevent="gravaServico()">Gravar</b-button>
          </b-col>
          <b-col md="2">
            <b-button class="btn-cinza" @click.prevent="limpaForm()">Cancelar</b-button>
          </b-col>
        </b-form-row>
        <!-- #endregion -->
      </form>
      <!-- #endregion -->

      <!-- #region filtro -->
      <b-form-group label="Filtrar por:" label-size="lg" class="mt-5">
        <b-form-row>
          <b-col md="3">
            <b-form-group class="form-input-cinza" label="Transportadora" label-cols="12" label-size="lg">
              <b-form-select v-model="filtro.idTransportadora" :options="comboFiltroTransportadora" />
            </b-form-group>
          </b-col>

          <b-col md="3">
            <b-form-group class="form-input-cinza" label="Status" label-cols="12" label-size="lg">
              <b-form-select v-model="filtro.status" :options="comboFiltroStatusServico" />
            </b-form-group>
          </b-col>

          <b-col md="2">
            <b-form-group class="text-white" label="." label-cols="12" label-size="lg">
              <b-button variant="primary" v-b-tooltip.hover title="Pesquisar" @click.prevent="listaServicos()">
                <b-icon icon="search" scale="1" />
              </b-button>
            </b-form-group>
          </b-col>
        </b-form-row>
      </b-form-group>
      <!-- #endregion -->

      <!-- #region listagem/exibicao -->
      <div v-if="tabelaServicos.length" class="h5 mt-4 mb-5">
        <b-table 
					class="text-nowrap h5" 
					id="tabelaServicos" 
					:items="tabelaServicos"
          :fields="cabecalhoTabelaServicos" 
					:per-page="totalPagina" 
					:current-page="paginaAtual"
					small 
					responsive 
					hover>
          <!--===================================================================================
            celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

            obj.index = indice da linha
            obj.field = nome do campo/coluna/chave json
            obj.item  = objeto json bruto
            obj.value = o valor da chave json com tipo original mantido e sem tratamento

            para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
          ====================================================================================-->
          <!--<template #cell()="obj">
            {{ obj.field }}
            {{ obj.item }}
            {{ obj.value }}
          </template>-->
          <template #cell(idTransportadora)="obj">
            {{ nomeTransportadora(obj.value) }}
          </template>

          <template #cell(nivelContrato)="obj">
            {{ nomeNivelContratoServico(obj.item) }}
          </template>

          <template #cell(opcoes)="obj">
            <b-form-checkbox class="form-input-cinza" size="lg" v-model="obj.item.ativo" switch
              @change="mudaStatusServico(obj.item)">
              <span style="cursor: pointer; color: black;">Ativo/Inativo</span>
            </b-form-checkbox>
          </template>
        </b-table>

        <div class="h5">
          <span style="padding-left: 5px;padding-right: 20px;">
            <b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
          </span>
          <span> Total de Registros: {{ $vsFormataNumero(tabelaServicos.length) }}</span>
          <b-pagination class="mt-3 d-flex justify-content-center" 
					  :total-rows="tabelaServicos.length"
            :per-page="totalPagina" 
						aria-controls="tabelaServicos" 
						v-model="paginaAtual" />
        </div>
      </div>
      <!-- #endregion -->

      <!-- #region modais -->
      <b-modal id="modalInativaServico" title="Deseja realmente inativar este serviço?" no-close-on-esc
        no-close-on-backdrop hide-header-close>
        <div class="h5">
          <!-- #region descricao -->
          <div class="mb-4 text-danger font-weight-bold">
            A inativação será efetuada em todos os serviços de todas as filiais
          </div>
          <div class="mb-4">
            <div>
              Transportadora:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              {{ nomeTransportadora(servicoExclusao.idTransportadora) }}
            </div>
            <div>
              Código:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{{ servicoExclusao.codigo }}
            </div>
            <div>
              Descrição:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              &nbsp;&nbsp;&nbsp;&nbsp;{{ servicoExclusao.descricao }}
            </div>
            <div>Nível de contrato:&nbsp;&nbsp;&nbsp;{{ servicoExclusao.nivelContrato }}</div>
            <div class="mt-4">Deseja continuar?</div>
          </div>
          <!-- #endregion -->

          <!-- #region autenticacao -->
          <div class="mb-4">
            <span class="float-right">
              <b-form-input type="password" v-model="servicoExclusao.senha" />
            </span>
            <span>Senha:</span>
          </div>
          <!-- #endregion -->
        </div>
        <template #modal-footer>
          <div class="w-100">
            <b-button type="button" class="float-right ml-2" variant="info"
              @click="inativaServico()">Confirmar</b-button>
            <b-button class="float-right" variant="secondary" @click="cancelaInativacaoServico()">Cancelar</b-button>
          </div>
        </template>
      </b-modal>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->

    <!-- #region tela filial -->
    <div v-if="$root.sessao.perfil === VS_PERFIL_FILIAL">
      <div class="mb-4 h5">** Negativo para desconto</div>

			<!-- #region listagem/exibicao -->
			<div v-if="tabelaServicosFilial.length">
				<b-table 
				  class="text-nowrap h5" 
					id="tabelaServicosFilial" 
					:items="tabelaServicosFilial"
					:fields="cabecalhoTabelaServicosFilial" 
					:per-page="totalPagina" 
					:current-page="paginaAtual"
					small 
					responsive 
					hover>
					<!--===================================================================================
						celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

						obj.index = indice da linha
						obj.field = nome do campo/coluna/chave json
						obj.item  = objeto json bruto
						obj.value = o valor da chave json com tipo original mantido e sem tratamento

						para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
					====================================================================================-->
					<!--<template #cell()="obj">
						{{ obj.field }}
						{{ obj.item }}
						{{ obj.value }}
					</template>-->
					<template #cell(idTransportadora)="obj">
						{{ nomeTransportadora(obj.value) }}
					</template>

					<template #cell(spreadPercentualFilial)="obj">
						<b-form-input class="text-right" type="text" v-model="obj.item.spreadPercentualFilial" @keyup="mascaraMoeda" />
					</template>

					<template #cell(spreadMonetarioFilial)="obj">
						<b-form-input class="text-right" type="text" v-model="obj.item.spreadMonetarioFilial" @keyup="mascaraMoeda" />
					</template>

					<template #cell(disponivelAtendimentoFilial)="obj">
						<b-form-checkbox class="form-input-cinza" type="text" v-model="obj.item.disponivelAtendimentoFilial" size="lg" switch>
							<span v-if="obj.value" style="cursor: pointer">Sim</span>
							<span v-else style="cursor: pointer">Não</span>
						</b-form-checkbox>
					</template>

					<template #cell(opcoes)="obj">
						<div class="pr-5">
							<button type="button" class="btn-verde" style="width: 150px; height: 40px;" @click="gravaServicoFilial(obj.item)">Gravar ajustes</button>
						</div>
					</template>
				</b-table>

				<div class="h5">
					<span style="padding-left: 5px;padding-right: 20px;">
						<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
					</span>
					<span>Total de Registros: {{ $vsFormataNumero(tabelaServicosFilial.length) }}</span>
					<b-pagination class="mt-3 d-flex justify-content-center" 
					  :total-rows="tabelaServicosFilial.length"
						:per-page="totalPagina" 
						aria-controls="tabelaServicosFilial" 
						v-model="paginaAtual" />
				</div>
			</div>
      <!-- #endregion -->
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
export default {
  // #region configs
  name: "servicos",
  path: "/servicos",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
      filtro: {
        idTransportadora: null,
        status: null,
      },
      servico: {
        id: null,
        idTransportadora: null,
        idContratante: this.$root.sessao.id,
        nivelContrato: "",
        codigo: "",
        descricao: "",
      },
      servicoExclusao: {
        id: null,
        idTransportadora: null,
        nivelContrato: "",
        codigo: "",
        descricao: "",
        senha: "",
        obj: null,
      },
      comboFiltroTransportadora: [],
      comboFiltroStatusServico: [
        { text: "Todos", value: null },
        { text: "Ativos", value: true },
        { text: "Inativos", value: false },
      ],
      comboTransportadoras: [],
      comboServicos: [],
      comboNiveisContrato: [],
      cabecalhoTabelaServicos: [
        { key: "idTransportadora", sortable: true, label: "Transportadora", tdClass: "text-left", },
        { key: "codigo", sortable: true, label: "Código serviço", tdClass: "text-left", },
        { key: "descricao", sortable: true, label: "Descrição serviço", tdClass: "text-left", },
        { key: "nivelContrato", sortable: true, label: "Nível contrato serviço", tdClass: "text-left", },
      ],
      tabelaServicos: [],
      cabecalhoTabelaServicosFilial: [
        { key: "idTransportadora", sortable: true, label: "Transportadora", tdClass: "text-left", },
        { key: "codigoServicoAtendimento", sortable: true, label: "Código", tdClass: "text-left", },
        { key: "descricaoServicoAtendimentoTela", sortable: true, label: "Descrição", tdClass: "text-left", },
        { key: "spreadPercentualFilial", sortable: true, label: "Spread % **", tdClass: "text-right", },
        { key: "spreadMonetarioFilial", sortable: true, label: "Spread R$ **", tdClass: "text-right", },
        { key: "disponivelAtendimentoFilial", sortable: true, label: "Disponível no atendimento?", tdClass: "text-left", },
        { key: "opcoes", sortable: false, label: "Opções", tdClass: "text-left", },
      ],
      tabelaServicosFilial: [],
      paginaAtual: 1,
      totalPagina: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_CONTRATANTE, this.VS_PERFIL_FILIAL])
  },
  mounted() {
    this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
		async bootstrap() {
			await	this.listaTransportadoras()
			await this.listaServicos()

			if (this.$root.sessao.perfil === this.VS_PERFIL_FILIAL) {
				await this.listaServicosFilial()
			}
		},
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    async listaTransportadoras() {
      let query = "excluido=false"
      let resp = await this.$vsApiRest("GET", `/transportadoras/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.text = `${el.nomeFantasia}`
          el.value = el.id
        })
        this.comboTransportadoras = resp.data
        this.comboFiltroTransportadora = resp.data
        this.comboFiltroTransportadora.unshift({ text: "Todas", value: null })
      }
    },
    nomeTransportadora(id) {
      let transp = this.comboTransportadoras.find(el => el.id === id)
      return (transp) ? `${transp.nomeFantasia}` : ""
    },
    async listaServicos() {
      let idContratante = this.$root.sessao.perfil === this.VS_PERFIL_CONTRATANTE
        ? this.$root.sessao.id
        : this.$root.sessao.contratante.id
      let filtro = this.filtro
      let query = `idContratante=${idContratante}`

      if (filtro.idTransportadora !== null) {
        query += `&idTransportadora=${filtro.idTransportadora}`
      }
      if (filtro.status !== null) {
        query += `&excluido=${!filtro.status}`
      }

      this.tabelaServicos = []

      let resp = await this.$vsApiRest("GET", `/tarifacao/servicos/contratante/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.ativo = !el.excluido
        })
        this.tabelaServicos = resp.data
        this.defineOpcoes()
      }
      
    },
    async gravaServico() {
      if (!this.validacoes()) {
        return
      }
      let resp = await this.$vsApiRest("POST", `/tarifacao/servicos/contratante`, this.servico)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Serviço cadastrado com sucesso", "success")
        this.limpaForm()
        this.listaServicos()
      }
    },
    validacoes() {
      let ok = true
      let form = this.servico
      let transp = this.comboTransportadoras.find(el => el.id === form.idTransportadora)

      if (!transp) {
        this.$vsNotificacao("Informe a transportadora", "warning")
        ok = false
      }
      if (transp && transp.usaNivelContrato && (!form.nivelContrato || !form.nivelContrato.trim().length)) {
        this.$vsNotificacao("A transportadora exige o nível de contrato para tarifação", "warning")
        ok = false
      }
      if (!form.codigo || !form.codigo.trim().length || !form.descricao || !form.descricao.trim().length) {
        this.$vsNotificacao("Informe o código e descrição para a tarifação", "warning")
        ok = false
      }
      return ok
    },
    limpaForm() {
      let form = this.servico
      form.id = null
      form.idTransportadora = null
      form.nivelContrato = ""
      form.codigo = ""
      form.descricao = ""
    },
    mudaStatusServico(obj) {
      let form = this.servicoExclusao
      form.id = obj.id
      form.idTransportadora = obj.idTransportadora
      form.nivelContrato = obj.nivelContrato
      form.codigo = obj.codigo
      form.descricao = obj.descricao
      form.senha = ""
      form.obj = obj

      if (obj.ativo) {
        this.ativaServico()
        return
      }
      this.$bvModal.show("modalInativaServico")
    },
    async ativaServico() {
      let form = this.servicoExclusao
      let resp = await this.$vsApiRest("PATCH", `/tarifacao/servicos/contratante/${form.id}/true`)

      if (resp.status === 200) {
        this.$vsNotificacao(`Serviço ${form.codigo} ${form.descricao} ativado com sucesso`, "success")
      }  
    },
    async inativaServico() {
			let form = this.servicoExclusao
      let resp = await this.$vsAutenticacao(this.$root.sessao.email, form.senha, this.VS_PERFIL_CONTRATANTE)
        
      if (!resp || resp.status !== 200) {
        return
      }

      resp = await this.$vsApiRest("PATCH", `/tarifacao/servicos/contratante/${form.id}/false`)
        
			if (resp.status === 200) {
        this.$vsNotificacao(`Serviço ${form.codigo} ${form.descricao} apagado com sucesso`, "success")
        this.$bvModal.hide("modalInativaServico")
      }
    },
    cancelaInativacaoServico() {
      let form = this.servicoExclusao
      form.obj.ativo = true
      this.$bvModal.hide("modalInativaServico")
    },
    async listaServicosFilial() {
      let query = `idFilial=${this.$root.sessao.id}&excluido=false&disponivelFilial=true`
      this.tabelaServicosFilial = []

      let resp = await this.$vsApiRest("GET", `/tarifacao/servicos/filial/?${query}`)
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
          el.spreadPercentualFilial = this.$vsFormataMoeda(el.spreadPercentualFilial)
          el.spreadMonetarioFilial = this.$vsFormataMoeda(el.spreadMonetarioFilial)
        })
        this.tabelaServicosFilial = resp.data
      }
    },
    codigoServicoContratante(servicoFilial) {
      let serv = this.tabelaServicos.find(el => el.id === servicoFilial.idServico)
      return (serv) ? `${serv.codigo}` : ""
    },
    nomeServicoContratante(servicoFilial) {
      let serv = this.tabelaServicos.find(el => el.id === servicoFilial.idServico)
      return (serv) ? `${serv.descricao}` : ""
    },
    async gravaServicoFilial(obj) {
      let req = {
        idServicoFilial: obj.id,
        spreadPercentualFilial: this.$vsConverteMoeda(obj.spreadPercentualFilial),
        spreadMonetarioFilial: this.$vsConverteMoeda(obj.spreadMonetarioFilial),
        disponivelAtendimentoFilial: obj.disponivelAtendimentoFilial,
      }
      let resp = await this.$vsApiRest("PATCH", `/tarifacao/servicos/filial`, req)

      if (resp.status === 200) {
        this.$vsNotificacao("Serviço atualizado com sucesso", "success")
        this.listaServicosFilial()
      }
    },
    filtraServicosTransportadora() {
      let form = this.servico
      let tra = this.comboTransportadoras.find(el => el.id === form.idTransportadora)

      if (tra) {
        tra.servicos.forEach(el => {
          el.text = el.codigo
          el.value = el.codigo
        })

        form.codigo = ""
        form.descricao = ""
        this.comboServicos = tra.servicos.filter(el => el.isAtivo)
      }
    },
    filtraNiveisContratoServico() {
      let form = this.servico
      let srv = this.comboServicos.find(el => el.codigo === form.codigo)

      if (srv) {
        this.comboNiveisContrato = []

        srv.niveisContrato.forEach(el => {
          this.comboNiveisContrato.push({
            text: `${el.codigo} - ${el.descricao}`,
            value: el.codigo,
          })
        })

        form.descricao = srv.descricao
        form.nivelContrato = (this.comboNiveisContrato.length === 1) ? this.comboNiveisContrato[0].value : ""
      }
    },
    exibeNivelContrato() {
      let form = this.servico
      let tra = this.comboTransportadoras.find(el => el.id === form.idTransportadora)
      return (tra && tra.usaNivelContrato)
    },
    nomeNivelContratoServico(obj) {
      try {
        let transp = this.comboTransportadoras.find(el => el.id === obj.idTransportadora)
        let serv = transp.servicos.find(el => el.codigo === obj.codigo)
        let nivel = serv.niveisContrato.find(el => el.codigo === obj.nivelContrato)

        return `${nivel.codigo} - ${nivel.descricao}`
      } catch {
        return ""
      }
    },
    defineOpcoes() {
      let filtro = this.filtro

      if (this.cabecalhoTabelaServicos.length === 4) {
        this.cabecalhoTabelaServicos.push({ key: "opcoes", sortable: false, label: "Opções", tdClass: "text-left", })
      }
      if (filtro.idTransportadora === null) {
        this.cabecalhoTabelaServicos.pop() // remove opcoes
      }
    },
  },
  // #endregion
}
</script>
