<template>
  <div>
    <!-- #region Area logada -->
    <b-row v-if="$root.sessao">
      <b-col>
        <h1 class="titulo">Rastreamento</h1>

        <b-row>
          <b-col class="h5">
            <!-- #region pesquisa -->
            <form>
              <b-form-row>
                <b-col md="4">
                  <b-form-group class="form-input-cinza" label="N° encomenda" label-cols="12" label-size="lg">
                    <b-form-input class="text-uppercase" type="text" size="lg" v-model="codigo" autocomplete="on" />
                  </b-form-group>
                </b-col>

                <b-col md="2">
                  <b-form-group style="color: transparent;" label=".">
                    <button class="btn-azul" @click.prevent="rastrear()">Pesquisar</button>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </form>
            <!-- #endregion -->

            <!-- #region listagem -->
            <b-row class="mt-5">
              <b-col>
                <vs-rastreio-encomenda :key="indice" :etiqueta="codigo" />
              </b-col>
              <b-col cols="2" />
            </b-row>
            <!-- #endregion -->
          </b-col>
        </b-row>
      </b-col>
    </b-row>
    <!-- #endregion -->

    <!-- #region Area publica/externa -->
    <div v-else>
      <b-row>
        <b-col class="p-4 d-flex justify-content-center">
          <img src="@/assets/img/logo.png" width="80">
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12" class="text-center h5">
          <h1 class="titulo">Veja onde está seu objeto!</h1>

          <!-- #region pesquisa -->
          <div class="mt-5">
            <form class="d-flex justify-content-center">
              <b-form-row>
                <b-col md="1" />
                <b-col md="7">
                  <b-form-group class="form-input-cinza" label="Código de rastreamento" label-cols="12" label-size="lg"
                    label-align="left">
                    <b-form-input class="text-uppercase" type="text" size="lg" v-model="codigo" autocomplete="on" />
                  </b-form-group>
                </b-col>
                <b-col md="3">
                  <b-form-group style="color: transparent;" label=".">
                    <button class="btn-azul" @click.prevent="rastrear()">Pesquisar</button>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </form>
          </div>
          <!-- #endregion -->

          <!-- #region listagem -->
          <b-row class="mt-5">
            <b-col cols="2" />
            <b-col>
              <vs-rastreio-encomenda :key="indice" :etiqueta="codigo" />
            </b-col>
            <b-col cols="2" />
          </b-row>
          <!-- #endregion -->
        </b-col>
      </b-row>
    </div>
    <!-- #endregion -->
  </div>
</template>

<script>
import VsRastreioEncomenda from '../templates/vs-rastreio-encomenda.vue'

export default {
  // #region configs
  name: "rastreio",
  path: "/rastreio",
  components: { VsRastreioEncomenda, },
  // #endregion

  // #region dados
  data() {
    return {
      indice: 0,
      codigo: "",
    }
  },
  // #endregion

  // #region metodos
  methods: {
    rastrear() {
      this.indice++
    },
  }
  // #endregion
}
</script>
