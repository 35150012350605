<template>
  <div>
		<div class="titulo">
			<h1>Mensagens</h1>
		</div>

		<!-- #region cadastro -->
		<div class="h5 mb-4">
			<a v-b-toggle.novoCadastro @click.prevent>
				<span class="cor-azul"><b-icon icon="plus" scale="1" variant="primary" />&nbsp;Novo cadastro</span>
			</a>
			<b-collapse id="novoCadastro" class="mt-3">
				<form @submit.prevent="grava()" class="mb-5">
					<b-form-group label="Nova mensagem:" label-size="lg">
						<!-- #region dados -->
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="cadastro.dataInicial"
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData"
										:min="dataMinima" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" 
										v-model="cadastro.dataFinal" 
										placeholder="dd/mm/aaaa" 
										:date-format-options="formatoData"
										:min="dataMinima" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Perfil" label-cols="12" label-size="lg">
									<b-form-select v-model="cadastro.perfil" :options="comboPerfil.filter(el => el.value !== OPT_TODOS)" @change="limpaTela()" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Tipo" label-cols="12" label-size="lg">
									<b-form-select v-model="cadastro.tipo" :options="comboTipo.filter(el => el.value !== OPT_TODOS)" />
								</b-form-group>
							</b-col>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Dispositivo" label-cols="12" label-size="lg">
									<b-form-select v-model="cadastro.dispositivo" :options="comboDispositivo" />
								</b-form-group>
							</b-col>
							<b-col md="5">
								<b-form-group class="form-input-cinza" label="Canal de comunicação" label-cols="12" label-size="lg">
									<b-form-checkbox name="canais" v-model="canais" v-for="(obj, i) in comboCanal" :key="obj.codigo" :value="obj.codigo" @change="defineCanais(obj.codigo)" inline size="lg" switch>
										<span style="cursor: pointer;">{{ obj.descricao }}</span>
									</b-form-checkbox>
								</b-form-group>
							</b-col>
							<b-col md="5">
								<b-form-group class="form-input-cinza" label="Observação" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="cadastro.observacao" autocomplete="on" />
								</b-form-group>
							</b-col>
							<b-col md="6">
								<b-form-group class="form-input-cinza" label="Título" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="cadastro.titulo" autocomplete="on" />
								</b-form-group>
							</b-col>
							<!--<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-form-checkbox v-model="cadastro.isHtml" value="true" size="lg" switch>
										<span style="cursor: pointer; color: black !important;">Conteúdo HTML?</span>
									</b-form-checkbox>
								</b-form-group>
							</b-col>-->
							<b-col md="10">
								<b-form-group class="form-input-cinza" label="Corpo" label-cols="12" label-size="lg">
									<b-form-textarea type="text" v-model="cadastro.corpo" rows="5" />
								</b-form-group>
							</b-col>
						</b-form-row>
						<!-- #endregion -->

						<!-- #region acoes -->
						<b-form-row class="mt-3">
							<b-col md="2">
								<button type="button" class="btn-verde" @click="modal('modalTeste', cadastro)">Testar</button>
							</b-col>
							<b-col md="2">
								<button type="submit" class="btn-azul">Gravar</button>
							</b-col>
							<b-col md="2">
								<button type="button" class="btn-cinza" @click="limpaForm()">Cancelar</button>
							</b-col>
						</b-form-row>
						<!-- #endregion -->
					</b-form-group>
				</form>
			</b-collapse>
		</div>
		<!-- #endregion -->

		<!-- #region filtro -->
		<form @submit.prevent="lista()">
			<b-form-group label="Filtrar por:" label-size="lg">
				<b-form-row>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
							<b-form-datepicker locale="pt-BR" 
								v-model="filtro.dataInicial"
								placeholder="dd/mm/aaaa" 
								:date-format-options="formatoData" 
								@input="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
							<b-form-datepicker locale="pt-BR" 
								v-model="filtro.dataFinal" 
								placeholder="dd/mm/aaaa" 
								:date-format-options="formatoData" 
								@input="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Perfil" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.perfil" :options="comboPerfil" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Tipo" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.tipo" :options="comboTipo" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Canal" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.canalComunicacao" :options="comboCanal" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="form-input-cinza" label="Dispositivo" label-cols="12" label-size="lg">
							<b-form-select v-model="filtro.dispositivo" :options="comboDispositivo" @change="limpaTela()" />
						</b-form-group>
					</b-col>
					<b-col md="2">
						<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
							<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
								<b-icon icon="search" scale="1" />
							</b-button>
						</b-form-group>
					</b-col>
				</b-form-row>
			</b-form-group>
		</form>
		<!-- #endregion -->

		<!-- #region listagem -->
		<div v-if="mostrar">
			<b-table id="tabela" class="text-nowrap h5"
				:items="tabela"
				:fields="campos" 
				:per-page="totalPagina" 
				:current-page="paginaAtual" 
				small 
				hover 
				responsive>
				<!-- #region celulas customizadas/formatadas -->
				<template #cell(dataCriacao)="obj">
					{{ $vsFormataData(obj.value, true) }}
				</template>

				<template #cell(periodo)="obj">
					{{ $vsFormataData(obj.item.dataInicial) }} até
					{{ $vsFormataData(obj.item.dataFinal) }}
				</template>

				<template #cell(perfil)="obj">
					{{ descricaoPerfil(obj.value) }}
				</template>

				<template #cell(tipo)="obj">
					<b-icon v-if="obj.value == 1" icon="exclamation-octagon-fill" class="text-info"/>
					<b-icon v-if="obj.value == 2" icon="exclamation-octagon-fill" class="text-warning"/>
					<b-icon v-if="obj.value == 3" icon="exclamation-octagon-fill" class="text-danger"/>
					&nbsp;{{ descricaoTipo(obj.value) }}
				</template>

				<template #cell(canalComunicacao)="obj">
					{{ descricaoCanal(obj.value) }}
				</template>

				<template #cell(dispositivo)="obj">
					{{ descricaoDispositivo(obj.value) }}
				</template>

				<template #cell(titulo)="obj">
					<div class="d-flex justify-content-between">
						<span class="col">
							{{ obj.value }}
						</span>
						<span class="col text-right">
							<a @click.prevent="modal('modalTeste', obj.item)" v-b-tooltip.hover.left="'Testar mensagem'">
								<b-icon icon="chat-text" class="text-success"/>
							</a>&nbsp;
							<a @click.prevent="modal('modalExclusao', obj.item)" v-b-tooltip.hover.left="'Apagar mensagem'">
								<b-icon icon="trash" class="text-danger"/>
							</a>
						</span>
					</div>
				</template>

				<template #cell(observacao)="obj">
					<a v-if="obj.value.length > (MAX_CHARS * 2)" class="text-info" @click.prevent="modal('modalDetalhes', obj.item)">
						Exibir detalhes
					</a>
					<span v-else>{{ obj.value }}</span>
				</template>

				<template #cell(corpo)="obj">
					<a v-if="obj.value.length > (MAX_CHARS * 2)" class="text-info" @click.prevent="modal('modalDetalhes', obj.item)">
						Exibir detalhes
					</a>
					<span v-if="obj.value.length && obj.value.length <= MAX_CHARS">
						<a class="text-secondary" @click.prevent="copiaClipboard(obj.value)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
							<b-icon icon="clipboard" />
						</a>&nbsp;{{ obj.value }}
					</span>
				</template>
				<!-- #endregion -->
			</b-table>

			<!-- #region paginacao -->
			<div class="h5">
				<span style="padding-left: 5px;padding-right: 20px;">
					<b-form-select class="numPerPage" v-model="totalPagina" :options="comboPaginacao" style="width: 100px;" />
				</span>
				<span>Total de Registros: {{ $vsFormataNumero(tabela.length) }}</span>
				<b-pagination class="mt-3 d-flex justify-content-center" :total-rows="tabela.length"
					:per-page="totalPagina" aria-controls="tabela" v-model="paginaAtual" />
			</div>
			<!-- #endregion -->
		</div>
		<!-- #endregion -->
	
		<!-- #region modais -->
		<!-- #region detalhe -->
		<b-modal id="modalDetalhes"	title="Mensagem detalhada" size="lg">
			<!-- #region dados -->
			<div v-if="cadastroDetalhado" class="h5 modal-compactado">
				<div class="d-flex justify-content-between">
					Período: {{ $vsFormataData(cadastroDetalhado.dataInicial) }} até {{ $vsFormataData(cadastroDetalhado.dataFinal) }}
				</div>
				<div class="mb-4 d-flex justify-content-between">
					<span class="col p-0">Perfil: {{ descricaoPerfil(cadastroDetalhado.perfil) }}</span>
					<span class="col p-0">Tipo: {{ descricaoTipo(cadastroDetalhado.tipo) }}</span>
					<span class="col p-0">Dispositivo: {{ descricaoDispositivo(cadastroDetalhado.dispositivo) }}</span>
					<span class="col p-0">Canal: {{ descricaoCanal(cadastroDetalhado.canalComunicacao) }}</span>
				</div>
				<div>Título: {{ cadastroDetalhado.titulo }}</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">Observação: {{ cadastroDetalhado.observacao }}</div>
				<div>
					Corpo:
					<a class="text-secondary" @click.prevent="copiaClipboard(cadastroDetalhado.corpo)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
						<b-icon icon="clipboard" />
					</a>
				</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">{{ cadastroDetalhado.corpo }}</div>
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="float-right">
					<b-button type="button" variant="secondary" @click="hide()">
						<span class="font-weight-bold">Fechar</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->

		<!-- #region confirmacao -->
		<b-modal id="modalConfirmacao"	
			title="Informação" 
			size="lg" 
			hide-header-close 
			no-close-on-esc 
			no-close-on-backdrop>
			<!-- #region dados -->
			<div class="h5">
				<div class="mb-4">Deseja reaproveitar a mensagem para outro perfil?</div>
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="float-right">
					<b-button type="button" variant="primary" @click="hide(); limpaForm(true)">
						<span class="font-weight-bold">OK</span>
					</b-button>&nbsp;
					<b-button type="button" variant="secondary" @click="hide(); limpaForm()">
						<span class="font-weight-bold">Cancelar</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->

		<!-- #region exclusao -->
		<b-modal id="modalExclusao"	title="Alerta" size="lg">
			<!-- #region dados -->
			<div v-if="cadastroDetalhado" class="h5 modal-compactado">
				<div class="mb-5 font-weight-bold">Deseja mesmo apagar essa mensagem?</div>
				<div class="d-flex justify-content-between">
					Período: {{ $vsFormataData(cadastroDetalhado.dataInicial) }} até {{ $vsFormataData(cadastroDetalhado.dataFinal) }}
				</div>
				<div class="mb-4 d-flex justify-content-between">
					<span class="col p-0">Perfil: {{ descricaoPerfil(cadastroDetalhado.perfil) }}</span>
					<span class="col p-0">Tipo: {{ descricaoTipo(cadastroDetalhado.tipo) }}</span>
					<span class="col p-0">Dispositivo: {{ descricaoDispositivo(cadastroDetalhado.dispositivo) }}</span>
					<span class="col p-0">Canal: {{ descricaoCanal(cadastroDetalhado.canalComunicacao) }}</span>
				</div>
				<div>Título: {{ cadastroDetalhado.titulo }}</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">Observação: {{ cadastroDetalhado.observacao }}</div>
				<div>
					Corpo:
					<a class="text-secondary" @click.prevent="copiaClipboard(cadastroDetalhado.corpo)" v-b-tooltip.hover.top="'Copiar dados para o clipboard'">
						<b-icon icon="clipboard" />
					</a>
				</div>
				<div class="mb-4" style="width: 95%; word-wrap: break-word !important;">{{ cadastroDetalhado.corpo }}</div>
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="float-right">
					<b-button type="button" variant="danger" @click="apaga()">
						<span class="font-weight-bold">Apagar</span>
					</b-button>&nbsp;
					<b-button type="button" variant="secondary" @click="hide()">
						<span class="font-weight-bold">Cancelar</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->
		
		<!-- #region teste -->
		<b-modal id="modalTeste"	
			title="Teste de mensagem" 
			size="lg" 
			hide-header-close 
			no-close-on-esc 
			no-close-on-backdrop>
			<!-- #region dados -->
			<div class="h5" v-if="cadastroDetalhado">
				<div class="mb-5 font-weight-bold">{{ cadastroDetalhado.titulo }}</div>
				<div class="mb-4" style="white-space: pre-line;">{{ cadastroDetalhado.corpo }}</div>
			</div>
			<!-- #endregion -->

			<!-- #region rodape -->
			<template #modal-footer="{ hide }">
				<div class="float-right">
					<b-button type="button" variant="primary" @click="hide()">
						<span class="font-weight-bold">OK</span>
					</b-button>
				</div>
			</template>
			<!-- #endregion -->
		</b-modal>
		<!-- #endregion -->
		<!-- #endregion -->
	</div>
</template>

<script>
export default {
	// #region configs
  name: "mensagens",
  path: "/mensagens",
	requireAuth: true,
	// #endregion

	// #region dados
	data() {
		return {
			MAX_CHARS: 50,
			OPT_TODOS: "0",
			formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
			dataMinima: new Date(new Date() - 0 * 86400000).toISOString().substring(0, 10), // hoje
			cadastro: {
				dataInicial: new Date().toISOString().substring(0, 10), // hoje
				dataFinal: new Date(new Date() - 0 * 86400000).toISOString().substring(0, 10), // amanha
				tipo: 1,
				canalComunicacao: 0,
				dispositivo: 0,
				titulo: "",
				corpo: "",
				observacao: "",
				isHtml: false,
				perfil: null,
			},
			cadastroDetalhado: null,
			filtro: {
				dataInicial: new Date(new Date() - 7 * 86400000).toISOString().substring(0, 10), // 7 dias
				dataFinal: new Date().toISOString().substring(0, 10), // hoje
				tipo: 1,
				canalComunicacao: 0,
				dispositivo: 0,
				perfil: 0,
			},
			campos: [
				{ key: "periodo", label: "Período", sortable: true,  },
				{ key: "perfil", label: "Perfil", sortable: true,  },
				{ key: "tipo", label: "Tipo", sortable: true,  },
				{ key: "titulo", label: "Título", sortable: true,  },
				{ key: "observacao", label: "Observação", sortable: true,  },
				{ key: "dispositivo", label: "Dispositivo", sortable: true,  },
				{ key: "canalComunicacao", label: "Canal", sortable: true,  },
				{ key: "corpo", label: "Corpo", sortable: true,  },
			],
			mostrar: false,
			canais: [0],
			tabela: [],
			comboTipo: [],
			comboCanal: [],
			comboDispositivo: [],
			comboSituacao: [],
			comboPerfil: [],
			paginaAtual: 1,
			totalPagina: 100,
			comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
		}
	},
	// #endregion

	// #region triggers
	created() {
		this.$vsDefineAcesso([
			this.VS_PERFIL_SUPERADMIN,
		])
	},
	mounted() {
		this.bootstrap()
	},
	// #endregion

	// #region metodos
	methods: {
		async bootstrap() {
			await this.defineComboTipo()
			await this.defineComboCanal()
			await this.defineComboDispositivo()
			await this.defineComboPerfil()
			await this.lista()
		},
		async defineComboTipo() {
			let resp = await this.$vsApiRest("GET", "/mensagens/tipos")
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.codigo
					el.text = el.descricao
				})
        this.comboTipo = resp.data
				this.comboTipo.unshift({ text: "Todos", value: this.OPT_TODOS })
				this.filtro.tipo = this.OPT_TODOS
      }
		},
		async defineComboCanal() {
			let resp = await this.$vsApiRest("GET", "/mensagens/canais")
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.codigo
					el.text = el.descricao
				})
        this.comboCanal = resp.data
      }
		},
		async defineComboDispositivo() {
			let resp = await this.$vsApiRest("GET", "/mensagens/dispositivos")
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.codigo
					el.text = el.descricao
				})
        this.comboDispositivo = resp.data
      }
		},
		async defineComboPerfil() {
			let resp = await this.$vsApiRest("GET", "/mensagens/perfis")
      
			if (resp.status === 200) {
        resp.data.forEach(el => {
					el.value = el.codigo
					el.text = el.descricao
				})

				let sessao = this.$root.sessao
				if (sessao.perfil === this.VS_PERFIL_SUPERADMIN) {
					resp.data = resp.data.filter(el => el.value != this.VS_PERFIL_SUPERADMIN)
				}
        this.comboPerfil = resp.data
				this.comboPerfil.sort(function (a, b) {	return (a.descricao < b.descricao) ? -1 : 1	})
				this.comboPerfil.unshift({ text: "Todos", value: this.OPT_TODOS })
				this.filtro.perfil = this.OPT_TODOS
      }
		},
		async lista() {
			let filtro = this.filtro
			let query = ""
			let temDataInicial = (filtro.dataInicial && filtro.dataInicial.length > 0)
			let temDataFinal = (filtro.dataFinal && filtro.dataFinal.length > 0)

			if (!temDataInicial || !temDataFinal) {
				this.$vsNotificacao("Necessário as duas datas", "warning")
				return // aborta 
			}
			query += `dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
			query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`
			query += `&canalComunicacao=${filtro.canalComunicacao}`
			query += `&dispositivo=${filtro.dispositivo}`

			if (filtro.tipo != this.OPT_TODOS) {
				query += `&tipo=${filtro.tipo}`
			}
			if (filtro.perfil != this.OPT_TODOS) {
				query += `&perfil=${filtro.perfil}`
			}

			this.limpaTela()

			let resp = await this.$vsApiRest("GET", `/mensagens?${query}`)
      
			if (resp.status === 200) {
        this.tabela = resp.data
				this.mostrar = (resp.data.length > 0)
      }
		},
		limpaTela() {
			this.mostrar = false
			this.tabela = []
		},
		descricaoTipo(tipo) {
			return this.comboTipo.find(el => el.codigo == tipo).text || ""
		},
		descricaoCanal(canal) {
			return this.comboCanal.find(el => el.codigo == canal).text || ""
		},
		descricaoDispositivo(dispositivo) {
			return this.comboDispositivo.find(el => el.codigo == dispositivo).text || ""
		},
		descricaoPerfil(perfil) {
			return this.comboPerfil.find(el => el.codigo == perfil).text
		},
		defineCanais(canal) {
			let todos = 0

			if (canal == todos) {
				this.canais = [0]
			} else {
				this.canais = [...new Set(this.canais.filter(el => el != todos))]
			}
		},
		validacoes() {
			let cad = this.cadastro
			let ok = true

			if (cad.perfil == null) {
				this.$vsNotificacao("Inform o perfil", "warning")
				ok = false
			}
			if (this.canais.length == 0) {
				this.$vsNotificacao("Informe o canal de comunicação", "warning")
				ok = false
			}
			if (cad.titulo.trim().length == 0) {
				this.$vsNotificacao("Informe o título", "warning")
				ok = false
			}
			if (cad.corpo.trim().length == 0) {
				this.$vsNotificacao("Informe o corpo", "warning")
				ok = false
			}

			return ok
		},
		async grava() {
			if (!this.validacoes()) {
				return
			}

			let cad = this.cadastro
			let cads = []

			this.canais.forEach(el => {
				let obj = JSON.parse(JSON.stringify(cad))
				obj.canalComunicacao = el
				obj.dataInicial = this.$vsConverteDataUTC(`${cad.dataInicial}T00:00:00`)
				obj.dataFinal = this.$vsConverteDataUTC(`${cad.dataFinal}T23:59:59`)
				cads.push(obj)
			})

			let resp = await this.$vsApiRest("POST", "/mensagens", cads)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Mensagem cadastrada com sucesso", "success")
				this.$bvModal.show("modalConfirmacao")
				this.lista()
      }
		},
		async apaga() {
			let cad = this.cadastroDetalhado
			let resp = await this.$vsApiRest("DELETE", `/mensagens/${cad.id}`)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Mensagem apagada com sucesso", "success")
        this.$bvModal.hide("modalExclusao")
				this.lista()
      }
		},
		limpaForm(apenasPerfil=false) {
			let cad = this.cadastro

			if (apenasPerfil) {
				cad.perfil = null
				return
			}

			cad.dataInicial = new Date().toISOString().substring(0, 10), // hoje
			cad.dataFinal = new Date().toISOString().substring(0, 10), // hoje
			cad.tipo = 1
			cad.dispositivo = 0
			cad.titulo = ""
			cad.corpo = ""
			cad.observacao = ""
			cad.isHtml = false
			cad.perfil = null
			this.canais = [0]
		},
		modal(id, obj) {
			this.cadastroDetalhado = obj
			this.$bvModal.show(id)
		},
		copiaClipboard(texto) {
			navigator.clipboard.writeText(texto)
			this.$vsNotificacao("Dados copiados para a área de transferência (clipboard)", "success")
		},
	},
	// #endregion
}
</script>
