<template>
  <div>
		<div class="titulo">
			<h1>Operações</h1>
    	<h4>Cadastro de categorias, produtos e serviços</h4>
		</div>

    <b-tabs content-class="pt-4 px-2">
      <!-- #region grupos -->
      <b-tab title="Categorias" @click="listaGrupos()" active title-item-class="h5">
        <!-- #region cadastro/edicao -->
        <form>
          <b-form-row>
            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Descrição" label-size="" label-cols-md="12">
                <b-form-input type="text" v-model="formGrupo.descricao" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label-class="text-white" label="." label-size="" label-cols-md="12">
                <b-form-checkbox class="form-input-cinza" size="lg" v-model="formGrupo.ativo" switch>
                  <span style="cursor: pointer">Ativo/Inativo</span>
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-form-row>

          <!-- #region acoes -->
          <b-form-row>
            <b-col md="2">
              <button type="button" class="btn-azul" @click="gravaGrupo()">Gravar</button>
            </b-col>
            <b-col md="2">
              <button type="button" class="btn-cinza" @click="limpaFormGrupo()">Cancelar</button>
            </b-col>
          </b-form-row>
          <!-- #endregion -->
        </form>
        <!-- #endregion -->

        <!-- #region listagem/edicao -->
        <b-form-row class="mt-4">
          <b-col class="h5">
            <b-table 
						  class="text-nowrap" 
							id="tabelaGrupos" 
							:items="cadGrupos"
              :fields="tabelaGruposCabecalho" 
							:per-page="totalPaginaGrupo" 
							:current-page="paginaAtualGrupo"
							small 
							responsive 
							hover>
              <!--===================================================================================
                celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

                obj.index = indice da linha
                obj.field = nome do campo/coluna/chave json
                obj.item  = objeto json bruto
                obj.value = o valor da chave json com tipo original mantido e sem tratamento

                para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
              ====================================================================================-->
              <!--<template #cell()="obj">
                {{ obj.field }}
                {{ obj.item }}
                {{ obj.value }}
              </template>-->

              <template #cell(dataCriacao)="obj">
                {{ $vsFormataData(obj.value, true) }}
              </template>

              <template #cell(ativo)="obj">
                <span v-if="obj.value">Ativo</span>
                <span v-else>Inativo</span>
              </template>

              <template #cell(opcoes)="obj">
                <div class="pr-5">
                  <button type="button" class="btn-laranja" style="width: 100px; height: 35px;"
                    @click="editaGrupo(obj.item)">Editar</button>
                  <button type="button" class="btn-vermelho ml-3" style="width: 100px; height: 35px;"
                    @click="mostraModalExclusao('apagaGrupo', obj.item)">Excluir</button>
                </div>
              </template>
            </b-table>

            <div v-if="cadGrupos.length" class="h5">
              <span style="padding-left: 5px;padding-right: 20px;">
                <b-form-select class="numPerPage" v-model="totalPaginaGrupo" :options="comboPaginacao"
                  style="width: 100px;" />
              </span>
              <span>Total de Registros: {{ $vsFormataNumero(cadGrupos.length) }}</span>
              <b-pagination class="mt-3 d-flex justify-content-center" 
							  :total-rows="cadGrupos.length"
                :per-page="totalPaginaGrupo" 
								v-model="paginaAtualGrupo" 
								aria-controls="tabelaGrupos" />
            </div>
          </b-col>
        </b-form-row>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->

      <!-- #region operacoes -->
      <b-tab title="Produtos/Serviços" @click="listaOperacoes()" title-item-class="h5">
        <!-- #region cadastro/edicao -->
        <form>
          <!-- #region dados basicos -->
          <b-form-row>
            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Categoria" label-size="" label-cols-md="12">
                <b-form-select :options="cadGrupos" v-model="formOperacao.idGrupo" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Tipo" label-size="" label-cols-md="12">
                <b-form-select v-if="formOperacao.edicao" :options="comboCategorias" :value="formOperacao.categoria" disabled />
                <b-form-select v-else :options="comboCategorias" v-model="formOperacao.categoria" @change="defineCategoria()" />
              </b-form-group>
            </b-col>

            <b-col md="3">
              <b-form-group class="form-input-cinza" label="Descrição" label-size="" label-cols-md="12">
                <b-form-input type="text" v-model="formOperacao.descricao" />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Tipo de valor" label-size="" label-cols-md="12">
                <b-form-select v-if="formOperacao.categoria === VS_OPERACAO_BALCAO_CATEGORIA_SERVICO" :options="comboTipoValor" v-model="formOperacao.tipoValor" @change="defineValor()" />
                <b-form-select v-else :options="comboTipoValor" :value="formOperacao.tipoValor" disabled />
              </b-form-group>
            </b-col>

            <b-col md="2">
              <b-form-group class="form-input-cinza" label="Valor" label-size="" label-cols-md="12">
                <b-form-input v-if="formOperacao.tipoValor === VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO" class="text-right" type="text" v-model="formOperacao.valorMonetario" @keyup="mascaraMoeda" />
                <b-form-input v-else class="text-right" type="text" :value="formOperacao.valorMonetario" disabled />
              </b-form-group>
              <div style="color: #808080;" class="h6">Negativo para desconto</div>
            </b-col>
          </b-form-row>
          <!-- #endregion -->

          <!-- #region configs -->
          <b-form-row>
            <b-col md="2">
              <b-form-checkbox v-if="formOperacao.categoria === VS_OPERACAO_BALCAO_CATEGORIA_SERVICO && formOperacao.tipoValor !== VS_OPERACAO_BALCAO_TIPO_VALOR_LIVRE" class="form-input-cinza" size="lg" v-model="formOperacao.usaNumeroEncomenda" @change="desativaQuantidade()" switch>
                <span style="cursor: pointer">Usa nº de encomenda?</span>
              </b-form-checkbox>
              <b-form-checkbox v-else size="lg" switch disabled>Usa nº de encomenda?</b-form-checkbox>
            </b-col>

            <b-col md="2">
              <b-form-checkbox v-if="formOperacao.categoria === VS_OPERACAO_BALCAO_CATEGORIA_SERVICO && !formOperacao.usaNumeroEncomenda" class="form-input-cinza" size="lg" v-model="formOperacao.usaQuantidade" @change="defineQuantidade()" switch>
                <span style="cursor: pointer">Usa quantidade?</span>
              </b-form-checkbox>
              <b-form-checkbox v-else size="lg" switch disabled>Usa quantidade?</b-form-checkbox>
            </b-col>

            <b-col md="2">
              <b-form-checkbox v-if="formOperacao.tipoValor !== VS_OPERACAO_BALCAO_TIPO_VALOR_SEMVALOR && !formOperacao.usaNumeroEncomenda" class="form-input-cinza" size="lg" v-model="formOperacao.usaDesconto" switch>
                <span style="cursor: pointer">Usa apenas negativo?</span>
              </b-form-checkbox>
              <b-form-checkbox v-else size="lg" switch disabled>Usa apenas negativo?</b-form-checkbox>
            </b-col>

			<b-col md="2">
              <b-form-checkbox v-if="formOperacao.tipoValor !== VS_OPERACAO_BALCAO_TIPO_VALOR_SEMVALOR && !formOperacao.usaNumeroEncomenda && formOperacao.categoria !== this.VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO" class="form-input-cinza" size="lg" v-model="formOperacao.usaPercentual" switch>
                <span style="cursor: pointer">Usa percentual?</span>
              </b-form-checkbox>
              <b-form-checkbox v-else size="lg" switch disabled>Usa percentual?</b-form-checkbox>
            </b-col>

            <b-col md="2">
              <b-form-checkbox class="form-input-cinza" size="lg" v-model="formOperacao.ativo" switch>
                <span style="cursor: pointer">Ativo/Inativo</span>
              </b-form-checkbox>
            </b-col>
          </b-form-row>
          <!-- #endregion -->

          <!-- #region acoes -->
          <b-form-row class="mt-4">
            <b-col md="2">
              <button type="button" class="btn-azul" @click="gravaOperacao()">Gravar</button>
            </b-col>
            <b-col md="2">
              <button type="button" class="btn-cinza" @click="limpaFormOperacao()">Cancelar</button>
            </b-col>
          </b-form-row>
          <!-- #endregion -->
        </form>
        <!-- #endregion -->

        <!-- #region listagem/edicao -->
        <b-form-row class="mt-4">
          <b-col class="h5">
            <b-table 
						  class="text-nowrap" 
							id="tabelaOperacoes" 
							:items="cadOperacoes"
              :fields="tabelaOperacoesCabecalho" 
							:per-page="totalPaginaOperacao" 
							:current-page="paginaAtualOperacao"
							small 
							responsive 
							hover>
              <!--===================================================================================
                celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

                obj.index = indice da linha
                obj.field = nome do campo/coluna/chave json
                obj.item  = objeto json bruto
                obj.value = o valor da chave json com tipo original mantido e sem tratamento

                para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
              ====================================================================================-->
              <!--<template #cell()="obj">
                {{ obj.field }}
                {{ obj.item }}
                {{ obj.value }}
              </template>-->

              <template #cell(dataCriacao)="obj">
                {{ $vsFormataData(obj.value, true) }}
              </template>

              <template #cell(idGrupo)="obj">
                {{ buscaNomeGrupo(obj.value) }}
              </template>

              <template #cell(categoria)="obj">
                {{ $vsListaOperacaoBalcaoCategorias(obj.value).text }}
              </template>

              <template #cell(tipoValor)="obj">
                {{ $vsListaOperacaoBalcaoTipoValor(obj.value).text }}
              </template>

              <template #cell(valorMonetario)="obj">
                {{ $vsFormataMoeda(obj.value) }}
              </template>

              <template #cell(usaQuantidade)="obj">
                <span v-if="obj.value">Sim</span>
                <span v-else>Não</span>
              </template>

              <template #cell(usaNumeroEncomenda)="obj">
                <span v-if="obj.value">Sim</span>
                <span v-else>Não</span>
              </template>

              <template #cell(usaDesconto)="obj">
                <span v-if="obj.value">Sim</span>
                <span v-else>Não</span>
              </template>

							<template #cell(usaPercentual)="obj">
                <span v-if="obj.value">Sim</span>
                <span v-else>Não</span>
              </template>

              <template #cell(ativo)="obj">
                <span v-if="obj.value">Ativo</span>
                <span v-else>Inativo</span>
              </template>

              <template #cell(opcoes)="obj">
                <div class="pr-5">
                  <button type="button" class="btn-laranja" style="width: 100px; height: 35px;" @click="editaOperacao(obj.item)">Editar</button>
                  <button type="button" class="btn-vermelho ml-3" style="width: 100px; height: 35px;" @click="mostraModalExclusao('apagaOperacao', obj.item)">Excluir</button>
                </div>
              </template>
            </b-table>

            <div v-if="cadOperacoes.length">
              <span style="padding-left: 5px;padding-right: 20px;">
                <b-form-select class="numPerPage" v-model="totalPaginaOperacao" :options="comboPaginacao" style="width: 100px;" />
              </span>
              <span>Total de Registros: {{ $vsFormataNumero(cadOperacoes.length) }}</span>
              <b-pagination class="mt-3 d-flex justify-content-center" 
								:total-rows="cadOperacoes.length" 
								:per-page="totalPaginaOperacao" 
								v-model="paginaAtualOperacao" 
								aria-controls="tabelaOperacoes"/>
            </div>
          </b-col>
        </b-form-row>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->
    </b-tabs>

    <!-- #region modais -->
    <b-modal id="apagaGrupo" title="Alerta" body-class="h5">
      <div>
        <div>Essa ação pode impactar nos relatórios e na tela de atendimento</div><br>
        <div>Código: {{ formExclusao.codigo }}</div>
        <div>Descrição: {{ formExclusao.descricao }}</div><br>
        <div>Deseja mesmo excluir o cadastro?</div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button class="float-right ml-2" variant="danger" @click="excluiGrupo()">Excluir</b-button>
          <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>

    <b-modal id="apagaOperacao" title="Alerta" body-class="h5">
      <div>
        <div>Essa ação pode impactar nos relatórios e na tela de atendimento</div><br>
        <div>Código: {{ formExclusao.codigo }}</div>
        <div>Descrição: {{ formExclusao.descricao }}</div><br>
        <div>Deseja mesmo excluir o cadastro?</div>
      </div>
      <template #modal-footer="{ hide }">
        <div class="w-100">
          <b-button class="float-right ml-2" variant="danger" @click="excluiOperacao()">Excluir</b-button>
          <b-button class="float-right" variant="secondary" @click="hide()">Cancelar</b-button>
        </div>
      </template>
    </b-modal>
    <!-- #endregion -->
  </div>
</template>

<script>
export default {
  // #region configs
  name: "operacoes",
  path: "/operacoes",
  requireAuth: true,
  // #endregion

  // #region dados
  data() {
    return {
      categoriaEdicao: null,
      comboCategorias: [],
      comboTipoValor: [],
      formGrupo: {
        id: null,
        idContratante: this.$root.sessao.contratante.id,
        idFilial: this.$root.sessao.id,
        descricao: "",
        ativo: true,
      },
      formOperacao: {
        edicao: false,
        id: null,
        idContratante: this.$root.sessao.contratante.id,
        idFilial: this.$root.sessao.id,
        idGrupo: null,
        categoria: this.VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO,
        tipoValor: this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO,
        descricao: "",
        valorMonetario: 0,
        quantidade: 1,
        usaNumeroEncomenda: false,
        usaQuantidade: false,
        usaDesconto: false,
		usaPercentual: false,
        ativo: true,
      },
      formExclusao: {
        id: null,
        codigo: null,
        descricao: null,
      },
      tabelaGruposCabecalho: [
        { key: "dataCriacao", sortable: true, label: "Data", tdClass: "text-left", },
        { key: "codigo", sortable: true, label: "Código", tdClass: "text-left", },
        { key: "descricao", sortable: true, label: "Descrição", tdClass: "text-left", },
        { key: "ativo", sortable: true, label: "Status", tdClass: "text-left", },
        { key: "opcoes", sortable: false, label: "Opções", tdClass: "text-left", },
      ],
      tabelaOperacoesCabecalho: [
        { key: "dataCriacao", sortable: true, label: "Data", tdClass: "text-left", },
        { key: "idGrupo", sortable: true, label: "Categoria", tdClass: "text-left", },
        { key: "categoria", sortable: true, label: "Tipo", tdClass: "text-left", },
        { key: "codigo", sortable: true, label: "Código", tdClass: "text-left", },
        { key: "descricao", sortable: true, label: "Descrição", tdClass: "text-left", },
        { key: "tipoValor", sortable: true, label: "Tipo de Valor", tdClass: "text-left", },
        { key: "valorMonetario", sortable: true, label: "Valor", tdClass: "text-right", },
        { key: "quantidade", sortable: false, label: "Quantidade", tdClass: "text-right", },
        { key: "usaNumeroEncomenda", sortable: true, label: "Usa Nº encomenda?", tdClass: "text-left", },
        { key: "usaQuantidade", sortable: true, label: "Usa Qtd.?", tdClass: "text-left", },
        { key: "usaDesconto", sortable: true, label: "Usa apenas negativo?", tdClass: "text-left", },
		{ key: "usaPercentual", sortable: true, label: "Usa percentual?", tdClass: "text-left", },
        { key: "ativo", sortable: true, label: "Status", tdClass: "text-left", },
        { key: "opcoes", sortable: false, label: "Opções", tdClass: "text-left", },
      ],
      cadGrupos: [],
      cadOperacoes: [],
      paginaAtualGrupo: 1,
      paginaAtualOperacao: 1,
      totalPaginaGrupo: 100,
      totalPaginaOperacao: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([this.VS_PERFIL_FILIAL])

    this.comboCategorias = this.$vsListaOperacaoBalcaoCategorias()
    this.comboTipoValor = this.$vsListaOperacaoBalcaoTipoValor()
  },
  mounted() {
    this.listaGrupos()
  },
  // #endregion

  // #region metodos
  methods: {
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
    mascaraInteiro(evt) {
      evt.target.value = this.$vsLimpaFormatacao(evt.target.value)
    },
    async listaGrupos() {
      this.cadGrupos = []

      let form = this.formGrupo
      let query = `idContratante=${form.idContratante}&idFilial=${form.idFilial}&excluido=false`
			let resp = await this.$vsApiRest("GET", `/operacoes/balcao/grupos/?${query}`)
      
			if (resp.status === 200) {
        this.cadGrupos = resp.data
        this.montaComboGrupo()
        this.limpaFormGrupo()
      }
    },
    async listaOperacoes() {
      this.cadOperacoes = []

      let form = this.formOperacao
      let query = `idContratante=${form.idContratante}&idFilial=${form.idFilial}&excluido=false`
			let resp = await this.$vsApiRest("GET", `/operacoes/balcao/?${query}`)
        
			if (resp.status === 200) {
        this.cadOperacoes = resp.data
        this.limpaFormOperacao()
      }
    },
    montaComboGrupo() {
      // reaproveita estrutura adicionando campos (evita novo array)
      this.cadGrupos.forEach(el => {
        el.value = el.id
        el.text = `${el.codigo} - ${el.descricao}`
      })
    },
    validaGrupo() {
      let ok = true
      let form = this.formGrupo
      let descricao = form.descricao.trim()

      if (!descricao.length) {
        ok = false
        this.$vsNotificacao("Informe a descrição", "warning")
      }
      return ok
    },
    validaOperacao() {
      let ok = true
      let form = this.formOperacao
      let valor = this.$vsConverteMoeda(form.valorMonetario)
      let descricao = form.descricao.trim()

      if (!descricao.length) {
        ok = false
        this.$vsNotificacao("Informe a descrição", "warning")
      }
      if (form.tipoValor === this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO && valor === 0) {
        ok = false
        this.$vsNotificacao("Informe o valor", "warning")
      }
      if (form.usaPercentual && Math.abs(valor) > this.VS_OPERACAO_BALCAO_DESCONTO_PERCENTUAL_MAXIMO) {
        ok = false
        this.$vsNotificacao(`O valor percentual não pode ser maior que ${this.VS_OPERACAO_BALCAO_DESCONTO_PERCENTUAL_MAXIMO}%`, "warning")
      }
      return ok
    },
    async gravaGrupo() {
      if (!this.validaGrupo()) {
        return
      }

      let form = this.formGrupo
      form.descricao = form.descricao.trim()

      let resp = await this.$vsApiRest("POST", `/operacoes/balcao/grupos`, form)
      
			if (resp.status === 200) {
        this.$vsNotificacao("Grupo gravado com sucesso", "success")
        this.listaGrupos()
      }
    },
    async gravaOperacao() {
      if (!this.validaOperacao()) {
        return
      }

      let form = JSON.parse(JSON.stringify(this.formOperacao))

      form.descricao = form.descricao.trim()
      form.valorMonetario = this.$vsConverteMoeda(form.valorMonetario)

      if (form.valorMonetario < 0 && !form.usaDesconto) {
        form.usaDesconto = true
      }
      if (form.usaDesconto && form.valorMonetario > 0) {
        form.valorMonetario = form.valorMonetario * -1
      }
      if (form.quantidade <= 0) {
        form.quantidade = 1
      }

      let resp = await this.$vsApiRest("POST", `/operacoes/balcao`, form)
      
	  if (resp.status === 200) {
        this.$vsNotificacao("Produto/Serviço gravado com sucesso", "success")
        this.listaOperacoes()
      }
    },
    editaGrupo(obj) {
      let form = this.formGrupo

      form.id = obj.id
      form.descricao = obj.descricao
      form.ativo = obj.ativo
    },
    editaOperacao(obj) {
      let form = this.formOperacao

      form.id = obj.id
      form.idGrupo = obj.idGrupo
      form.categoria = obj.categoria
      form.descricao = obj.descricao
      form.ativo = obj.ativo
      form.usaQuantidade = obj.usaQuantidade
      form.usaNumeroEncomenda = obj.usaNumeroEncomenda
      form.usaDesconto = obj.usaDesconto
	  form.usaPercentual = obj.usaPercentual
      form.quantidade = obj.quantidade
      form.tipoValor = obj.tipoValor
      form.valorMonetario = this.$vsFormataMoeda(obj.valorMonetario)
      form.edicao = true
    },
    limpaFormGrupo() {
      let form = this.formGrupo
      form.id = null
      form.descricao = ""
      form.ativo = true
    },
    limpaFormOperacao() {
      let form = this.formOperacao
      form.id = null
      form.idGrupo = this.cadGrupos[0].value
      form.categoria = this.VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO
      form.tipoValor = this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO
      form.descricao = ""
      form.valorMonetario = 0
      form.quantidade = 1
      form.usaNumeroEncomenda = false
      form.usaQuantidade = false
      form.usaDesconto = false
	  form.usaPercentual = false
      form.ativo = true
      form.edicao = false
    },
    mostraModalExclusao(idModal, obj) {
      this.formExclusao = obj
      this.$bvModal.show(idModal)
    },
    async excluiGrupo() {
      let obj = this.formExclusao
	  let resp = await this.$vsApiRest("DELETE", `/operacoes/balcao/grupos/${obj.id}`)
        
	  if (resp.status === 200) {
        this.$vsNotificacao("Grupo excluido com sucesso", "success")
      	this.listaGrupos()
       	this.$bvModal.hide("apagaGrupo")
      }
    },
    async excluiOperacao() {
      let obj = this.formExclusao
	  let resp = await this.$vsApiRest("DELETE", `/operacoes/balcao/${obj.id}`)
      
	  if (resp.status === 200) {
        this.$vsNotificacao("Produto/Serviço excluido com sucesso", "success")
        this.listaOperacoes()
        this.$bvModal.hide("apagaOperacao")
      }
    },
    buscaNomeGrupo(id) {
      let grupo = this.cadGrupos.find(el => el.id === id)
      if (grupo) {
        return `${grupo.codigo} - ${grupo.descricao}`
      }
      return ""
    },
    desativaQuantidade() {
      let form = this.formOperacao
      form.usaQuantidade = false
      form.quantidade = 0
      form.usaDesconto = false
	  form.usaPercentual = false
      //form.tipoValor = this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO
    },
    defineQuantidade() {
      let form = this.formOperacao
      form.quantidade = Number(form.usaQuantidade)
    },
    defineCategoria() {
      let form = this.formOperacao

      if (form.categoria === this.VS_OPERACAO_BALCAO_CATEGORIA_PRODUTO) {
        form.tipoValor = this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO
        form.usaNumeroEncomenda = false
        form.usaQuantidade = false
        form.usaPercentual = false
        form.quantidade = 1
      }
    },
    defineValor() {
      let form = this.formOperacao

      if (form.tipoValor !== this.VS_OPERACAO_BALCAO_TIPO_VALOR_FIXO) {
        form.valorMonetario = 0
      }
      if (form.tipoValor === this.VS_OPERACAO_BALCAO_TIPO_VALOR_SEMVALOR) {
        form.usaDesconto = false
		form.usaPercentual = false
      }
	  if (form.tipoValor === this.VS_OPERACAO_BALCAO_TIPO_VALOR_LIVRE) {
        form.usaNumeroEncomenda = false
      }
    },
  },
  // #endregion
}
</script>