<template>
  <div>
    <h1 class="titulo">Lançamentos</h1>

    <!-- #region relatorios -->
    <b-tabs content-class="px-2" class="pt-4">
      <!-- #region tipos -->
      <b-tab v-if="$root.sessao.perfil === VS_PERFIL_CONTRATANTE" title="Tipos" title-item-class="h5">
				<!-- #region cadastro -->
				<form class="mt-4">
					<b-form-row>
						<b-col md="5">
							<b-form-group class="form-input-cinza" label="Descrição do tipo de lançamento avulso" label-cols="12" label-size="lg">
								<b-form-input  type="text" v-model="formTipo.descricao" autocomplete="on" />
							</b-form-group>
						</b-col>

						<b-col md="2">
							<b-form-group style="color: transparent;" label="." label-cols="12" label-size="lg">
                <b-form-checkbox class="form-input-cinza" v-model="formTipo.ativo" size="lg" switch>
                  <span style="cursor: pointer; color: black;">Ativo?</span>
                </b-form-checkbox>
              </b-form-group>
						</b-col>

						<b-col md="3">
							<b-form-group style="color: transparent;" label="." label-cols="12" label-size="lg">
                <b-form-checkbox class="form-input-cinza" v-model="formTipo.usaValorNegativo" size="lg" switch>
                  <span style="cursor: pointer; color: black;">É negativo?</span>
                </b-form-checkbox>
              </b-form-group>
						</b-col>
					</b-form-row>

					<b-form-row>
						<b-col md="2">
							<b-button class="btn-azul" @click.prevent="gravaTipo()">Gravar</b-button>
						</b-col>
					</b-form-row>
				</form>
				<!-- #endregion -->
			
				<!-- #region filtro -->
				<form class="mt-5" @submit.prevent="listaTipos()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="4">
								<b-form-group class="form-input-cinza" label="Descrição" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="filtro.tipo.descricao" @keyup="limpaTipoLancamento()" />
								</b-form-group>
							</b-col>

							<b-col md="1">
								<b-form-group class="form-input-cinza" label="Status" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.tipo.ativo" :options="comboStatusTipoLancamento" @change="limpaTipoLancamento()" />
								</b-form-group>
							</b-col>

							<b-col md="2">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->
				
        <!-- #region listagem/paginacao -->
        <div v-if="exibeRelatorioTipoLancamento" class="mt-4">
          <b-table id="tabelaRelatorioTipos" class="text-nowrap h5" 
					  :items="tabelaRelatorioTipos"
            :fields="camposRelatorioTipos" 
						:per-page="totalPaginaTipos" 
						:current-page="paginaAtualTipos"
            small 
						hover 
						responsive>
            <!--===================================================================================
              celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

              obj.index = indice da linha
              obj.field = nome do campo/coluna/chave json
              obj.item  = objeto json bruto
              obj.value = o valor da chave json com tipo original mantido e sem tratamento

              para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
            ====================================================================================-->
            <!--<template #cell()="obj">
              {{ obj.field }}
              {{ obj.item }}
              {{ obj.value }}
            </template>-->

						<template #cell(ativo)="obj">
							<b-form-checkbox class="form-input-cinza" v-model="obj.item.ativo" size="lg" switch @change="mudaStatusTipo(obj.item)">
								<span v-if="obj.value" style="cursor: pointer; color: black;">Sim</span>
								<span v-else style="cursor: pointer; color: black;">Não</span>
              </b-form-checkbox>
            </template>

            <template #cell(usaValorNegativo)="obj">
              <span v-if="obj.value">Sim</span>
							<span v-else>Não</span>
            </template>
          </b-table>

          <div class="h5">
            <span style="padding-left: 5px;padding-right: 20px;">
              <b-form-select class="numPerPage" 
							  v-model="totalPaginaTipos" 
								:options="comboPaginacao" 
								style="width: 100px;" />
            </span>
            <span> Total de Regitros: {{ $vsFormataNumero(tabelaRelatorioTipos.length) }}</span>
            <b-pagination class="mt-3 d-flex justify-content-center" 
						  :total-rows="tabelaRelatorioTipos.length" 
							:per-page="totalPaginaTipos" 
							aria-controls="tabelaRelatorioTipos" 
							v-model="paginaAtualTipos" />
          </div>
        </div>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->

      <!-- #region lancamentos -->
      <b-tab title="Lançamentos" title-item-class="h5" @click="listaLancamentos()">
				<!-- #region cadastro -->
				<form v-if="$root.sessao.perfil === VS_PERFIL_CONTRATANTE" class="mt-4">
					<b-form-group label="Novo lançamento:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" v-model="formLancamento.dataLancamento" placeholder="dd/mm/aaaa" :date-format-options="formatoData" />
								</b-form-group>
							</b-col>

							<b-col md="3">
								<b-form-group class="form-input-cinza" label="Filial" label-cols="12" label-size="lg">
									<!--<b-form-select v-model="formLancamento.idFilial" :options="comboFilial" />-->
									<model-select v-model="formLancamento.idFilial" :options="comboFilial" />
								</b-form-group>
							</b-col>

							<b-col md="3">
								<b-form-group class="form-input-cinza" label="Tipo" label-cols="12" label-size="lg">
									<b-form-select v-model="formLancamento.idTipo" :options="comboTipos" />
								</b-form-group>
							</b-col>

							<b-col md="1">
								<b-form-group class="form-input-cinza" label="Qtd." label-cols="12" label-size="lg">
									<b-form-input class="text-right" type="text" v-model="formLancamento.quantidade" @keyup="mascaraInteiro" />
								</b-form-group>
							</b-col>

							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Valor" label-cols="12" label-size="lg">
									<b-form-input class="text-right" type="text" v-model="formLancamento.valor" @keyup="mascaraMoeda" />
								</b-form-group>
							</b-col>

							<b-col md="5">
								<b-form-group class="form-input-cinza" label="Observação" label-cols="12" label-size="lg">
									<b-form-input type="text" v-model="formLancamento.observacao" />
								</b-form-group>
							</b-col>
						</b-form-row>

						<b-form-row>
							<b-col md="2">
								<b-button class="btn-azul" @click.prevent="confirmaTodasFiliais()">Gravar</b-button>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

				<!-- #region filtro -->
				<form class="mt-5" @submit.prevent="listaLancamentos()">
					<b-form-group label="Filtrar por:" label-size="lg">
						<b-form-row>
							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data inicial" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" v-model="filtro.lancamento.dataInicial" placeholder="dd/mm/aaaa" :date-format-options="formatoData" @input="limpaRelatorioLancamento()" />
								</b-form-group>
							</b-col>

							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Data final" label-cols="12" label-size="lg">
									<b-form-datepicker locale="pt-BR" v-model="filtro.lancamento.dataFinal" placeholder="dd/mm/aaaa" :date-format-options="formatoData" @input="limpaRelatorioLancamento()" />
								</b-form-group>
							</b-col>

							<b-col v-if="$root.sessao.perfil === VS_PERFIL_CONTRATANTE" md="2">
								<b-form-group class="form-input-cinza" label="Filial" label-cols="12" label-size="lg">
									<model-select v-model="filtro.lancamento.idFilial" :options="comboFilialFiltro" @input="limpaRelatorioLancamento()" />
								</b-form-group>
							</b-col>

							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Tipo" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.lancamento.idTipo" :options="comboTiposFiltro" />
								</b-form-group>
							</b-col>

							<b-col md="1">
								<b-form-group class="form-input-cinza" label="Status" label-cols="12" label-size="lg">
									<b-form-select v-model="filtro.lancamento.situacao" :options="comboStatusLancamento" @change="limpaRelatorioLancamento()" />
								</b-form-group>
							</b-col>

							<b-col md="2">
								<b-form-group class="form-input-cinza" label="Fatura" label-cols="12" label-size="lg">
									<b-form-input class="text-right" type="text" v-model="filtro.lancamento.numeroFatura" @keyup="limpaRelatorioLancamento(); mascaraInteiro($event)" />
								</b-form-group>
							</b-col>

							<b-col md="1">
								<b-form-group class="text-white" label="." label-cols="12" label-size="lg">
									<b-button type="submit" variant="primary" v-b-tooltip.hover title="Pesquisar">
										<b-icon icon="search" scale="1" />
									</b-button>
								</b-form-group>
							</b-col>
						</b-form-row>
					</b-form-group>
				</form>
				<!-- #endregion -->

        <!-- #region listagem/edicao -->
				<div v-if="exibeRelatorioLancamento" class="mt-4">
					<!-- #region opcoes -->
					<div class="">
						<span class="mb-4 float-right mx-2">
							<b-button @click.prevent="imprimeCSV()" class="btn-azul" v-b-tooltip.hover title="Imprimir CSV">Exportar CSV</b-button>
						</span>
					</div>
					<!-- #endregion -->

					<b-table id="tabelaRelatorioLancamentos" class="text-nowrap h5" 
					  :items="tabelaRelatorioLancamentos"
						:fields="camposRelatorioLancamentos" 
						:per-page="totalPaginaLancamentos" 
						:current-page="paginaAtualLancamentos" 
						small
						hover 
						responsive
						no-footer-sorting 
						foot-clone>
						<!--===================================================================================
							celulas que precisam de tratamento/formatacao (nao precisam estar em ordem/sequencia)

							obj.index = indice da linha
							obj.field = nome do campo/coluna/chave json
							obj.item  = objeto json bruto
							obj.value = o valor da chave json com tipo original mantido e sem tratamento

							para debugar ou visualizar os dados para ajustes descomente o <template> abaixo:
						====================================================================================-->
						<!--<template #cell()="obj">
							{{ obj.field }}
							{{ obj.item }}
							{{ obj.value }}
						</template>-->

						<!-- #region celulas customizadas/formatadas -->
						<template #cell(dataLancamento)="obj">
							{{ $vsFormataData(obj.value) }}
						</template>

						<template #cell(idFilial)="obj">
							{{ nomeFilial(obj.value) }}
						</template>

						<template #cell(idTipo)="obj">
							{{ descricaoTipo(obj.value) }}
						</template>

						<template #cell(numeroFatura)="obj">
							<span v-if="obj.value">{{ obj.value }}</span>
						</template>

						<template #cell(situacao)="obj">
							<span v-if="obj.value === VS_STATUS_LANCAMENTO_FATURA_NAO_FATURADO">Não Faturado</span>
							<span v-else-if="obj.value === VS_STATUS_LANCAMENTO_FATURA_FATURADO">Faturado</span>
							<span v-else-if="obj.value === VS_STATUS_LANCAMENTO_FATURA_EXCLUIDO" 
								v-b-tooltip.hover.left="`Cancelado por ${obj.item.usuarioCancelamentoNome} em ${$vsFormataData(obj.item.dataCancelamento)}`">Excluído</span>
						</template>

						<template #cell(valor)="obj">
							{{ $vsFormataMoeda(obj.value) }}
						</template>

						<template #cell(opcoes)="obj">
							<a v-if="liberaCancelamentoLancamento(obj.item)" class="text-danger" @click.prevent="mostraModalCancelaLancamento(obj.item)" v-b-tooltip.hover.left="'Cancelar lançamento'">
								<b-icon icon="trash" />
							</a>
						</template>
						<!-- #endregion -->

						<!-- #region rodape com totais -->
						<template #foot(dataLancamento)>Total</template>
						<template #foot()>&nbsp;</template>
						<template #foot(quantidade)><span class="float-right">{{ $vsFormataNumero(quantidadeTotalLancamentos()) }}</span></template>
						<template #foot(valor)><span class="float-right">R$ {{ $vsFormataMoeda(valorTotalLancamentos()) }}</span></template>
						<!-- #endregion -->
					</b-table>

					<!-- #region paginacao -->
					<div class="h5">
						<span style="padding-left: 5px;padding-right: 20px;">
							<b-form-select class="numPerPage" 
							  v-model="totalPaginaLancamentos" 
								:options="comboPaginacao"
								style="width: 100px;" />
						</span>
						<span>Total de Regitros: {{ $vsFormataNumero(tabelaRelatorioLancamentos.length) }}</span>
						<b-pagination class="mt-3 d-flex justify-content-center" 
						  :total-rows="tabelaRelatorioLancamentos.length"
							:per-page="totalPaginaLancamentos" 
							aria-controls="tabelaRelatorioLancamentos" 
							v-model="paginaAtualLancamentos" />
					</div>
					<!-- #endregion -->
				</div>
        <!-- #endregion -->
      </b-tab>
      <!-- #endregion -->
    </b-tabs>
    <!-- #endregion -->

		<!-- #region modais -->
		<b-modal id="modalConfirmaFiliais" title="Atenção" size="">
			<div class="h5 text-warning font-weight-bold">
				Deseja mesmo adicionar o lançamento para todas as filiais?
			</div>
			<template #modal-footer="{ hide }">
				<div class="w-100">
					<b-button type="button" class="float-right ml-2" variant="info" @click="hide(); gravaLancamento()">Confirmar</b-button>
					<b-button type="button" class="float-right ml-2" variant="secondary" @click="hide()">Cancelar</b-button>
				</div>
			</template>
		</b-modal>

		<b-modal id="modalCancelaLancamento" title="Alerta" size="">
			<div v-if="formLancamentoExclusao.id" class="h5">
				<!-- #region descritivos -->
				<div class="mb-4 text-danger font-weight-bold">Deseja mesmo cancelar o lançamento?</div>
				<div class="mb-3">Data: {{ $vsFormataData(formLancamentoExclusao.dataLancamento) }}</div>
				<div class="mb-3">Filial: {{ nomeFilial(formLancamentoExclusao.idFilial) }}</div>
				<div class="mb-3">Tipo: {{ descricaoTipo(formLancamentoExclusao.idTipo) }}</div>
				<div class="mb-3">
					Status:
					<span v-if="formLancamentoExclusao.situacao === VS_STATUS_LANCAMENTO_FATURA_NAO_FATURADO">Não Faturado</span>
					<span v-else-if="formLancamentoExclusao.situacao === VS_STATUS_LANCAMENTO_FATURA_FATURADO">Faturado</span>
					<span v-else-if="formLancamentoExclusao.situacao === VS_STATUS_LANCAMENTO_FATURA_EXCLUIDO">Excluído</span>
				</div>
				<div class="mb-3">Qtd: {{ $vsFormataNumero(formLancamentoExclusao.quantidade) }}</div>
				<div class="mb-3">Valor: {{ $vsFormataMoeda(formLancamentoExclusao.valor) }}</div>
				<div class="mb-3">Observacação: {{ formLancamentoExclusao.observacao }}</div>
				<!-- #endregion -->

				<!-- #region confirmacao -->
				<div>
					<span class="float-right">
						<b-form-input type="text" v-model="textoExclusao" />
					</span>
					Digite <span class="font-weight-bold">EXCLUÍDO</span>
				</div>
				<!-- #endregion -->
			</div>
			<template #modal-footer="{ hide }">
				<div class="w-100">
					<b-button type="button" class="float-right ml-2" variant="info" @click="cancelaLancamento()">Confirmar</b-button>
					<b-button type="button" class="float-right ml-2" variant="secondary" @click="hide()">Cancelar</b-button>
				</div>
			</template>
		</b-modal>
		<!-- #endregion -->
  </div>
</template>

<script>
import { ModelSelect } from "vue-search-select"

export default {
  // #region configs
  name: "faturamento-lancamentos",
  path: "/faturamento-lancamentos",
  requireAuth: true,
	components: { ModelSelect },
  // #endregion

  // #region dados
  data() {
    return {
			OPT_TODOS: "0",
			formatoData: { year: 'numeric', month: 'numeric', day: 'numeric', },
			textoExclusao: "",
			formTipo: {
				idContratante: this.$root.sessao.id,
				idFilial: this.$root.sessao.id,
				descricao: "",
				usaValorNegativo: false,
				ativo: true,
				faturaDePara: this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL,
			},
      formLancamento: {
				idContratante: this.$root.sessao.id,
        idFilial: null,
				idOperador: this.$root.sessao.id,
        idTipo: null,
				dataLancamento: new Date().toISOString().substring(0, 10), // hoje
				quantidade: 1,
				valor: 0,
				observacao: "",
				faturaDePara: this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL,
      },
			formLancamentoExclusao: {
				id: null,
				idContratante: null,
        idFilial: null,
				idOperador: null,
        idTipo: null,
				dataLancamento: null,
				situacao: 0,
				quantidade: 0,
				valor: 0,
				observacao: "",
				faturaDePara: this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL,
      },
      camposRelatorioTipos: [
        { key: "descricao", label: "Descrição", sortable: true, },
				{ key: "ativo", label: "Ativo?", sortable: true, },
        { key: "usaValorNegativo", label: "É negativo?", sortable: true, },
      ],
      camposRelatorioLancamentos: [
        { key: "dataLancamento", label: "Data", sortable: true, },
        { key: "idFilial", label: "Filial", sortable: true, },
        { key: "idTipo", label: "Tipo", sortable: true, },
        { key: "numeroFatura", label: "Fatura", sortable: true, tdClass: "text-right", },
        { key: "situacao", label: "Status", sortable: true, },
        { key: "quantidade", label: "Qtd.", sortable: true, tdClass: "text-right", },
        { key: "valor", label: "Valor", sortable: true, tdClass: "text-right", },
        { key: "opcoes", label: "Opções", sortable: true, tdClass: "text-center", },
				{ key: "observacao", label: "Observacação", sortable: true, },
      ],
			filtro: {
				tipo: {
					descricao: "",
					ativo: true,
				},
				lancamento: {
					dataInicial: new Date().toISOString().substring(0, 10), // hoje
					dataFinal: new Date().toISOString().substring(0, 10), // hoje
					idFilial: null,
					idTipo: null,
					situacao: null,
					numeroFatura: "",
				},
			},
			filiais: [],
			comboFilial: [],
			comboFilialFiltro: [],
			comboTipos: [],
			comboTiposFiltro: [],
			comboStatusLancamento: [],
			comboStatusTipoLancamento: [],
			exibeRelatorioTipoLancamento: false,
			exibeRelatorioLancamento: false,
      tabelaRelatorioTipos: [],
      tabelaRelatorioLancamentos: [],
      paginaAtualTipos: 1,
      paginaAtualLancamentos: 1,
      totalPaginaTipos: 100,
      totalPaginaLancamentos: 100,
      comboPaginacao: [10, 20, 50, 100, 200, { value: Number.MAX_SAFE_INTEGER, text: "Todos" }],
    }
  },
  // #endregion

  // #region triggers
  created() {
    this.$vsDefineAcesso([
			this.VS_PERFIL_CONTRATANTE, 
			this.VS_PERFIL_FILIAL,
		])
  },
  mounted() {
		this.bootstrap()
  },
  // #endregion

  // #region metodos
  methods: {
	 	async bootstrap() {
			await this.listaTipos(true)
			await this.listaFilial()

			if (this.$root.sessao.perfil !== this.VS_PERFIL_CONTRATANTE) {
				this.camposRelatorioLancamentos.splice(7, 1) // opcoes
			}
			this.defineComboStatusLancamento()
			this.defineComboTiposStatus()
		},
		mascaraInteiro(evt) {
      evt.target.value = this.$vsLimpaFormatacao(evt.target.value)
    },
    mascaraMoeda(evt) {
      evt.target.value = this.$vsFormataMoeda(evt.target.value)
    },
		async listaFilial() {
      this.comboFilial = []
			let sessao = this.$root.sessao
      let query = `idContratante=${sessao.id}`

			if (sessao.perfil !== this.VS_PERFIL_CONTRATANTE) {
				query = `idContratante=${sessao.contratante.id}`
			}

      let resp = await this.$vsApiRest("GET", `/usuarios/filiais/?${query}`)

      if (resp.status === 200) {
				this.filiais = resp.data
        this.defineComboFilial(resp.data)
      }
    },
    async listaTipos() {
			let filtro = this.filtro.tipo
			let sessao = this.$root.sessao
      let query = `idContratante=${sessao.id}`

			if (sessao.perfil !== this.VS_PERFIL_CONTRATANTE) {
				query = `idContratante=${sessao.contratante.id}`
			}

			query += `&faturaDePara=${this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL}`

			this.limpaTipoLancamento()

			// alimenta os combos
			let resp = await this.$vsApiRest("GET", `/faturamento/lancamentos/avulsos/tipos/?${query}`)
      
			if (resp.status === 200) {
        this.defineComboTipos(resp.data)
      }

			// alimenta a tabela
			if (filtro.descricao.trim().length) {
				query += `&descricao=${filtro.descricao.trim()}`
			}

			if (filtro.ativo !== this.OPT_TODOS) {
				query += `&ativo=${filtro.ativo}`
			}

      resp = await this.$vsApiRest("GET", `/faturamento/lancamentos/avulsos/tipos/?${query}`)
      
			if (resp.status === 200) {
        this.tabelaRelatorioTipos = resp.data
				this.exibeRelatorioTipoLancamento = (resp.data.length > 0)
      }
    },
		montaQueryLancamentos() {
			let filtro = this.filtro.lancamento
			let numeroFatura = isNaN(filtro.numeroFatura) ? 0 : parseInt(filtro.numeroFatura)
			let sessao = this.$root.sessao
			let query = `idContratante=${sessao.id}`

			if (sessao.perfil !== this.VS_PERFIL_CONTRATANTE) {
				query = `idContratante=${sessao.contratante.id}`
			}

			query += `&faturaDePara=${this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL}`

			if (numeroFatura > 0) {
				query += `&numeroFatura=${numeroFatura}`
			} else {
				query += `&dataInicial=${this.$vsConverteDataUTC(`${filtro.dataInicial}T00:00:00`)}`
      	query += `&dataFinal=${this.$vsConverteDataUTC(`${filtro.dataFinal}T23:59:59`)}`

				if (sessao.perfil === this.VS_PERFIL_FILIAL) {
					query += `&idFilial=${sessao.id}`
				} else if (filtro.idFilial !== this.OPT_TODOS) {
					query += `&idFilial=${filtro.idFilial}`
				}
				if (filtro.idTipo !== this.OPT_TODOS) {
					query += `&idTipo=${filtro.idTipo}`
				}
				if (filtro.situacao !== this.OPT_TODOS) {
					query += `&situacao=${filtro.situacao}`
				}
			}
			return query
		},
		async listaLancamentos() {
			if (!this.validacoesFiltro()) {
				return
			}

      let query = this.montaQueryLancamentos()

			this.limpaRelatorioLancamento()

      let resp = await this.$vsApiRest("GET", `/faturamento/lancamentos/avulsos/?${query}`)
       
			if (resp.status === 200) {
        this.tabelaRelatorioLancamentos = resp.data
				this.exibeRelatorioLancamento = (resp.data.length > 0)
      }
    },
		defineComboTipos(tipos) {
			tipos.forEach(el => {
				let descricao = el.descricao

				if (el.usaValorNegativo) {
					descricao = `${el.descricao} (é negativo)`
				}
				
				el.value = el.id
				el.text = descricao
			})

			this.comboTipos = tipos.filter(el => el.ativo)
			this.comboTiposFiltro = JSON.parse(JSON.stringify(tipos))
			this.comboTiposFiltro.unshift({ value: this.OPT_TODOS, text: "Todos" }) // primeira opcao
			this.filtro.lancamento.idTipo = this.OPT_TODOS
		},
		defineComboTiposStatus() {
			this.comboStatusTipoLancamento = [
				{text: "Todos", value: this.OPT_TODOS },
				{text: "Ativos", value: true },
				{text: "Inativos", value: false },
			]
			this.filtro.tipo.ativo = true
		},
		defineComboFilial(filiais) {
			filiais.forEach(e => {
          e.text = e.nome
          e.value = e.id
      })

			this.comboFilial = filiais.filter(el => el.isAtivo && !el.isExcluido)
      this.comboFilial.unshift({ value: this.OPT_TODOS, text: "Todas" }) // primeira opcao
      this.formLancamento.idFilial = this.OPT_TODOS

			this.comboFilialFiltro = filiais
			this.comboFilialFiltro.unshift({ value: this.OPT_TODOS, text: "Todas" }) // primeira opcao
			this.filtro.lancamento.idFilial = this.OPT_TODOS
		},
		defineComboStatusLancamento() {
			this.comboStatusLancamento = [
				{ value: this.OPT_TODOS, text: "Todos" },  // primeira opcao
				{value: this.VS_STATUS_LANCAMENTO_FATURA_NAO_FATURADO, text: "Não Faturado"},
				{value: this.VS_STATUS_LANCAMENTO_FATURA_FATURADO, text: "Faturado"},
				{value: this.VS_STATUS_LANCAMENTO_FATURA_EXCLUIDO, text: "Excluído"},
			]
			this.filtro.lancamento.situacao = this.OPT_TODOS
		},
		confirmaTodasFiliais() {
			let form = this.formLancamento
			
			if (form.idFilial === this.OPT_TODOS) {
				this.$bvModal.show("modalConfirmaFiliais")
				return
			}
			this.gravaLancamento()
		},
		validaLancamento(form, optTipo) {
			let ok = true

			if (!form.idTipo) {
				this.$vsNotificacao("Informe o tipo", "warning")
				ok = false
			}
			if (form.quantidade <= 0) {
				this.$vsNotificacao("Quantidade inválida", "warning")
				ok = false
			}
			if (form.valor === 0) {
				this.$vsNotificacao("Valor inválido", "warning")
				ok = false
			}
			if (optTipo && !optTipo.usaValorNegativo && form.valor < 0) {
				this.$vsNotificacao("Tipo de lançamento só aceita valor positivo", "warning")
				ok = false
			}

			return ok
		},
		async gravaTipo() {
			let form = this.formTipo

			if (form.descricao.trim().length === 0) {
				this.$vsNotificacao("Informe a descrição", "warning")
				return
			}

			form.faturaDePara = this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL

			let resp = await this.$vsApiRest("POST", `/faturamento/lancamentos/avulsos/tipos`, form)
      
			if (resp.status === 200) {
				this.$vsNotificacao("Tipo de lançamento gravado com sucesso", "success")
				this.limpaFormTipo()
				this.listaTipos()
      }
    },
		async gravaLancamento() {
			let form = JSON.parse(JSON.stringify(this.formLancamento)) // clonagem
			let optTipo = this.comboTipos.find(el => el.value === form.idTipo)

			form.dataLancamento = this.$vsConverteDataUTC(`${form.dataLancamento}T00:00:00`)
			form.quantidade = parseInt(form.quantidade)
			form.valor = this.$vsConverteMoeda(form.valor)

			if (!this.validaLancamento(form, optTipo)) {
				return
			}

			if (optTipo.usaValorNegativo && form.valor > 0) {
				form.valor = form.valor * -1
			}
			if (form.idFilial === this.OPT_TODOS) {
				form.idFilial = null
			}

			form.faturaDePara = this.VS_FATURA_DEPARA_CONTRATANTE_FILIAL

      let resp = await this.$vsApiRest("POST", `/faturamento/lancamentos/avulsos`, form)
      
			if (resp.status === 200) {
				this.$vsNotificacao("Lançamento gravado com sucesso", "success")
				this.limpaFormLancamento()
				this.listaLancamentos()
      }
    },
		limpaFormTipo() {
			let form = this.formTipo

			form.descricao = ""
			form.usaValorNegativo = false
			form.ativo = true
		},
		limpaFormLancamento() {
			let form = this.formLancamento

			form.idFilial = this.OPT_TODOS
			form.idTipo = null
			form.quantidade = 1
			form.valor = 0
			form.observacao = ""
		},
		nomeFilial(id) {
			let filial = this.filiais.find(el => el.value === id)
			return (filial) ? filial.nome : ""
		},
		descricaoTipo(id) {
			let tipo = this.comboTiposFiltro.find(el => el.value === id)
			return (tipo) ? tipo.descricao : ""
		},
		liberaCancelamentoLancamento(obj) {
			return obj.situacao === this.VS_STATUS_LANCAMENTO_FATURA_NAO_FATURADO
		},
		mostraModalCancelaLancamento(obj) {
			let form = this.formLancamentoExclusao

			form.id = obj.id
			form.idContratante = obj.idContratante
			form.idFilial = obj.idFilial
			form.idTipo = obj.idTipo
			form.dataLancamento = obj.dataLancamento
			form.situacao = obj.situacao
			form.quantidade = obj.quantidade
			form.valor = obj.valor
			form.observacao = obj.observacao

			this.textoExclusao = ""

			this.$bvModal.show("modalCancelaLancamento")
		},
		async cancelaLancamento() {
			let form = this.formLancamentoExclusao

			if (this.textoExclusao !== "EXCLUÍDO") {
				this.$vsNotificacao("Digite o texto exatamente igual, sem espaços e com letras maiúsculas", "warning")
				return
			}

			let resp = await this.$vsApiRest("DELETE", `/faturamento/lancamentos/avulsos/${form.id}`)
      
			if (resp.status === 200) {
				this.textoExclusao = ""
				this.$bvModal.hide("modalCancelaLancamento")
				this.$vsNotificacao("Lançamento cancelado com sucesso", "success")
				this.listaLancamentos()
      }
		},
		limpaRelatorioLancamento() {
			this.exibeRelatorioLancamento = false
			this.tabelaRelatorioLancamentos = []
		},
		limpaTipoLancamento() {
			this.exibeRelatorioTipoLancamento = false
			this.tabelaRelatorioTipos = []
		},
		quantidadeTotalLancamentos() {
			return this.tabelaRelatorioLancamentos.reduce((total, obj) => total + obj.quantidade, 0)
		},
		valorTotalLancamentos() {
			return this.tabelaRelatorioLancamentos.reduce((total, obj) => total + obj.valor, 0)
		},
		async mudaStatusTipo(obj) {
			let resp = await this.$vsApiRest("PATCH", `/faturamento/lancamentos/avulsos/tipos/status/${obj.id}/${obj.ativo}`)
      
			if (resp.status === 200) {
				this.$vsNotificacao(`Tipo de lançamento "${obj.descricao}" gravado com sucesso`, "success")
				this.defineComboTipos(this.tabelaRelatorioTipos)
      } else {
				obj.ativo = !obj.ativo
			}
		},
		async imprimeCSV() {
			let query = this.montaQueryLancamentos()
			let resp = await this.$vsApiRest("GET", `/faturamento/impressao-lancamentos-csv/?${query}`, {}, {}, "arraybuffer")
      
			if (resp.status === 200) {
        this.$vsDownload(resp.data, `relatorio-faturamento-gestao-${new Date().getTime()}.csv`, "text/csv; charset=utf-8")
      }
    },
		validacoesFiltro() {
			let form = this.filtro.lancamento
			let dtInicial = new Date(`${form.dataInicial}T00:00:00`)
			let dtFinal = new Date(`${form.dataFinal}T23:59:59`)
			let ok = true

			if (dtFinal < dtInicial) {
				this.$vsNotificacao("Data final não pode ser menor que a data inicial", "warning")
				ok = false
			}
			return ok
		},
  },
  // #endregion
}
</script>
