/**
 * @author mnunes
 * @author rsouza
 * @since 1.0 (2021-09-09)
 * 
 * @description Arquivo de configuracoes iniciais do vuejs...
 */

// #region ========== DEPENDENCIAS =======================================================================
import Vue from "vue"
import { BootstrapVue, IconsPlugin } from "bootstrap-vue"
import PortalVue from "portal-vue"
import VueRouter from "vue-router"
import Axios from "axios"
import VisualSetPlugin from "./assets/js/visualset.js"
import jsPDF from "jspdf"

const { convert } = require("html-to-text")
// #endregion

// #region ========== CSS ================================================================================
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap-vue/dist/bootstrap-vue.min.css"
import "vue-search-select/dist/VueSearchSelect.css"
import "./assets/css/visualset.css"
// #endregion

// #region ========== CONFIGS ============================================================================
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(PortalVue)
Vue.use(VueRouter)
Vue.use(VisualSetPlugin)

Vue.config.productionTip = false

Vue.prototype.$http = Axios // lib ajax
Vue.prototype.$pdf = jsPDF
Vue.prototype.$conversorTexto = convert
// #endregion

// #region ========== COMPONENTES ========================================================================
import App from "./App.vue"

/*========== SISTEMA DE ROTAS =======================================
 
  ARQUIVOS EM rotas DEVEM CONTER O ATRIBUTO path 
  (exemplo em rotas/login.vue)

  COMO DITO NO README.md, CASO A ROTA PRECISA DE VALIDACAO,
  O ATRIBUTO requireAuth: true NO COMPONENTE SE FAZ NECESSARIO
  (exemplo em rotas/home.vue)
  
===================================================================*/
const routes = []
const req = require.context('./components/rotas/', false, /\.vue$/i)

req.keys().forEach(file => {
    const page = req(file).default
    routes.push({
        path: page.path,
        component: page,
        meta: { requireAuth: page.requireAuth || false },
    })
})
// #endregion

// #region ========== START ==============================================================================
new Vue({
    // #region configs
    el: '#app',
    router: new VueRouter({ routes }),
    render: h => h(App),
    // #endregion

    // #region dados globais
    data() {
        /* 
         * formas de acesso nos componentes: 
		 *    $root.variavel      (escopo <template>)
         *    this.$root.variavel (escopo <script>)
         */
        return {
            loading: false,
            sessao: null,
            mostraMenu: false,
			baseProducaoSimulada: false,
        }
    },
    // #endregion

    // #region triggers
    created() {
		this.$vsBootstrap()
    },
    // #endregion
})
// #endregion
